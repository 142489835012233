import { TranslatableObject } from './general';

export const ORDER_STATUS_SUCCESS = 'success';

export enum KioskReferenceType {
    ADVENTURE = 'events',
    EVENT = 'events',
    DESTINATION = 'destinations',
    TRAIL_AREA = 'trackGroups',
    ORGANISATION = 'organisations',
    PREMIUM = 'premium'
}

export enum ProductCategory {
    ADVENTURE_ACCESS = 'eventAccess',
    EVENT_ACCESS = 'eventAccess',
    HQ_MAPS = 'hqmaps',
    ORGANISATION_MEMBERSHIP = 'organisationMembership',
    PREMIUM = 'premium'
}

export enum ProductVariantType {
    PERIODIC = 'periodic',
    FOLLOW_MEMBERSHIP = 'followsMembership',
    ACTIVATE = 'activate'
}

export enum DurationType {
    DAY = 'day',
    MONTH = 'month',
    YEAR = 'year',
    FOREVER = 'forever'
}

export enum PaymentType {
    IAP = 'iap',
    ACTIVATION_CODE = 'activationCode',
    GIVE_AWAY = 'giveAway'
}

export interface Currency {
    currency: string;
    currencyCode: number;
    name?: string;
}

export interface UpsellProduct {
    referenceKey: string;
    productCategory: ProductCategory;
}

// interface GroupDiscount {
//    discountPercentage: number,
//    minimumMembers: number
// }

export interface PriceEntry {
    amount?: number;
    saleAmount?: number;
    organisations?: { [organisationKey: string]: number };
    /**
     * @todo This is still just an idea.
     */
    //    groupDiscounts?: { [discountKey: string]: GroupDiscount };
}

export interface PriceStructure extends Currency {
    variants: { [variantKey: string]: PriceEntry };
}

export interface KioskProductVariant extends TranslatableObject {
    key?: string;
    name: string;
    variantType: ProductVariantType;
    durationType?: DurationType;
    duration?: number;
    sortOrder: number;
}

interface TeaserItem extends TranslatableObject {
    title: string;
    description: string;
}

export interface KioskProduct extends TranslatableObject {
    key: string;

    referenceType: KioskReferenceType;
    referenceKey: string;
    productCategory: ProductCategory;

    name: string;
    header?: string;
    teaserTitle?: string;
    teaserDescription?: string;
    teaserDisclaimer?: string;
    teaserItems?: TeaserItem[];

    recurring: boolean;
    defaultPriceStructure: PriceStructure;
    countryPriceStructures?: { [countryCode: string]: PriceStructure };

    defaultUpSaleProduct?: UpsellProduct;
    countryUpSaleProducts?: { [countryCode: string]: UpsellProduct };
}


export interface KioskCustomer {
    city: string;
    country: string;
    name: string;
    street: string;
    zip: string;
}

interface KioskOrderExtra {
    expiryTimeMillis?: string;
    startTimeMillis?: string;
}

export interface KioskOrder extends Currency {
    key: string;
    amount: number;
    count: number;
    createdTimestamp: number;
    extra?: KioskOrderExtra;
    kioskCustomer: KioskCustomer;
    kioskProduct: KioskProduct;
    orderID: number;
    orderText: string;
    paymentType: PaymentType;
    realIncome: boolean;
    status: string;
    successTimestamp?: number;
    transactionID?: string;
    userID: string;
}

export interface KioskProductVariantReference {
    productKey: string;
    variantKey: string;
    quantity: number;
    name?: string;
}
