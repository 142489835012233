import {AfterViewChecked, Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

// Services
import {AuthService} from '../../../core/auth.service';
import {EventManager, EventManagerListItem} from "../../../interfaces/event-manager";
import {TrailAreaService} from "../../../firebase-services/trail-area.service";
import {EventManagerService} from "./event-manager.service";
import {Roles} from "../../../interfaces/role";
import {UserService} from "../../../firebase-services/user.service";
import {RoleService} from "../../../core/role.service";
import {TrailArea} from "../../../interfaces/trailArea";
import {Country} from "../../../interfaces/countries";
import {Destination} from "../../../interfaces/destination";
import {CountryService} from "../../../firebase-services/country.service";
import {DestinationService} from "../../../firebase-services/destination.service";

declare var $: any;

// Interfaces

@Component({
    selector: 'app-event-managers',
    templateUrl: './event-managers.component.html',
    styleUrls: ['./event-managers.component.css']
})
export class EventManagersComponent implements OnDestroy, AfterViewChecked {
    destroy$: Subject<boolean> = new Subject<boolean>();
    roles = Roles;
    eventManagers: EventManager[];
    eventManagerListItems: EventManagerListItem[];
    state: 0;
    saveRoleDisabled = true;
    selectedUserKey: string;
    selectedUserName: string;
    selectedUserImage: string;
    selectedCountryKey: string;
    selectedCountryName: string;
    selectedDestinationKey: string;
    selectedDestinationName: string;
    selectedTrailAreaKey: string;
    selectedTrailAreaName: string;
    allIsLoaded = false;
    dataTableCreated = false;
    dataTable: any = null;
    managersDataTableName = "#eventManagersDataTable";
    selectedType = '';

    constructor(
        public authService: AuthService,
        private eventManagerService: EventManagerService,
        private countryService: CountryService,
        private destinationService: DestinationService,
        private trailAreaService: TrailAreaService,
        private userService: UserService,
        private roleService: RoleService
    ) {
        this.eventManagerService.getEventManagersListItems()
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe((eventManagerList) => {
                this.eventManagerListItems = eventManagerList;
                this.allIsLoaded = true;
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && $("#eventManagersDataTable>tbody>tr").length > 0) {
            this.dataTable = $(this.managersDataTableName).DataTable({
                order: [[0, "asc"]],
                columnDefs: [
                    {orderable: false, targets: 1},
                    {orderable: false, targets: 2},
                    {orderable: false, targets: 3}
                ]
            });
            this.dataTableCreated = true;
        }
    }

    onCountryAdded($event: string, userKey: string): Promise<void> {
        this.resetDataTable();
        return this.eventManagerService.addCountryToEventManager(userKey, $event);
    }

    onCountryRemoved(userKey: string, countryCode: string): Promise<void> {
        this.resetDataTable();
        for (let item of this.eventManagerListItems) {
            if (item.user.userID === userKey && item.countries.length === 1 && item.destinations.length < 1 && item.trailAreas.length < 1) {
                this.eventManagerService.removeCountryFromEventManager(userKey, countryCode).then(() => {
                    return this.roleService.revokeUserRole(userKey, this.roles.EVENT_MANAGER)
                        .catch((err) => console.error('Sorry, an error occurred: ', err.message));
                }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
            } else {
                return this.eventManagerService.removeCountryFromEventManager(userKey, countryCode)
                    .catch((err) => console.error('Sorry, an error occurred: ', err.message));
            }
        }
        return;
    }

    onDestinationAdded($event: string, userKey: string): Promise<void> {
        this.resetDataTable();
        return this.eventManagerService.addDestinationToEventManager(userKey, $event);
    }

    onDestinationRemoved(userKey: string, destinationKey: string): Promise<void> {
        this.resetDataTable();
        for (let item of this.eventManagerListItems) {
            if (item.user.userID === userKey && item.countries.length < 1 && item.destinations.length === 1 && item.trailAreas.length < 1) {
                this.eventManagerService.removeDestinationFromEventManager(userKey, destinationKey).then(() => {
                    return this.roleService.revokeUserRole(userKey, this.roles.EVENT_MANAGER)
                        .catch((err) => console.error('Sorry, an error occurred: ', err.message));
                }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
            } else {
                return this.eventManagerService.removeDestinationFromEventManager(userKey, destinationKey)
                    .catch((err) => console.error('Sorry, an error occurred: ', err.message));
            }
        }
        return;
    }

    onAreaAdded($event: string, userKey: string): Promise<void> {
        this.resetDataTable();
        return this.eventManagerService.addTrailAreaToEventManager(userKey, $event).then(() => {
            return this.trailAreaService.enableEvents($event);
        });
    }

    onAreaRemoved(userKey: string, areaKey: string): Promise<void> {
        this.resetDataTable();
        for (let item of this.eventManagerListItems) {
            if (item.user.userID === userKey && item.countries.length < 1 && item.destinations.length < 1 && item.trailAreas.length === 1) {
                this.eventManagerService.removeTrailAreaFromEventManager(userKey, areaKey).then(() => {
                    return this.roleService.revokeUserRole(userKey, this.roles.EVENT_MANAGER)
                        .catch((err) => console.error('Sorry, an error occurred: ', err.message));
                }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
            } else {
                return this.eventManagerService.removeTrailAreaFromEventManager(userKey, areaKey)
                    .catch((err) => console.error('Sorry, an error occurred: ', err.message));
            }
        }
        return;
    }

    onUserSelected(userKey: string) {
        this.selectedUserKey = userKey;
        this.userService.getUser(userKey)
            .pipe(take(1)).subscribe((user) => {
            this.selectedUserName = user.name;
            this.selectedUserImage = user.userPicture;
        });
        if (this.selectedCountryKey || this.selectedDestinationKey || this.selectedTrailAreaKey) this.saveRoleDisabled = false;
    }

    onCountrySelected(countryKey: string) {
        this.selectedCountryKey = countryKey;
        this.countryService.getCountry(countryKey)
            .pipe(take(1)).subscribe((country) => this.selectedCountryName = country.name);
        this.selectedDestinationKey = undefined;
        this.selectedDestinationName = undefined;
        this.selectedTrailAreaKey = undefined;
        this.selectedTrailAreaName = undefined;
        if (this.selectedUserKey) this.saveRoleDisabled = false;
    }

    onDestinationSelected(destinationKey: string) {
        this.selectedDestinationKey = destinationKey;
        this.destinationService.getDestination(destinationKey)
            .pipe(take(1)).subscribe((destination) => this.selectedDestinationName = destination.name);
        this.selectedCountryKey = undefined;
        this.selectedCountryName = undefined;
        this.selectedTrailAreaKey = undefined;
        this.selectedTrailAreaName = undefined;
        if (this.selectedUserKey) this.saveRoleDisabled = false;
    }

    onAreaSelected(areaKey: string) {
        this.selectedTrailAreaKey = areaKey;
        this.trailAreaService.getTrailArea(areaKey)
            .pipe(take(1)).subscribe((area) => this.selectedTrailAreaName = area.name);
        this.selectedCountryKey = undefined;
        this.selectedCountryName = undefined;
        this.selectedDestinationKey = undefined;
        this.selectedDestinationName = undefined;
        if (this.selectedUserKey) this.saveRoleDisabled = false;
    }

    assignEventManager(): void {
        if (this.selectedCountryKey) {
            this.roleService.assignUserRole(this.selectedUserKey, this.roles.EVENT_MANAGER, this.authService.user.userID).then(() => {
                return this.eventManagerService.addCountryToEventManager(this.selectedUserKey, this.selectedCountryKey).then(() => {
                    this.state++
                    this.resetAssignEventManager();
                }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
            }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
        }
        if (this.selectedDestinationKey) {
            this.roleService.assignUserRole(this.selectedUserKey, this.roles.EVENT_MANAGER, this.authService.user.userID).then(() => {
                return this.eventManagerService.addDestinationToEventManager(this.selectedUserKey, this.selectedDestinationKey).then(() => {
                    this.state++
                    this.resetAssignEventManager();
                }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
            }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
        }
        if (this.selectedTrailAreaKey) {
            this.roleService.assignUserRole(this.selectedUserKey, this.roles.EVENT_MANAGER, this.authService.user.userID).then(() => {
                return this.eventManagerService.addTrailAreaToEventManager(this.selectedUserKey, this.selectedTrailAreaKey).then(() => {
                    return this.trailAreaService.enableEvents(this.selectedTrailAreaKey).then(() => {
                        this.state++
                        this.resetAssignEventManager();
                    }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
                }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
            }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
        }
    }

    async revokeEventManager(managerKey: string, countries: Country[], destinations: Destination[], trailAreas: TrailArea[]): Promise<void> {
        this.resetDataTable();
        try {
            const removeCountryPromises = countries.map(country =>
                this.eventManagerService.removeCountryFromEventManager(managerKey, country.countryCode)
            );

            const removeDestinationPromises = destinations.map(destination =>
                this.eventManagerService.removeDestinationFromEventManager(managerKey, destination.key)
            );

            const removeTrailAreaPromises = trailAreas.map(area =>
                this.eventManagerService.removeTrailAreaFromEventManager(managerKey, area.key)
            );

            await Promise.all([removeCountryPromises, removeDestinationPromises, removeTrailAreaPromises]);

            await this.roleService.revokeUserRole(managerKey, this.roles.EVENT_MANAGER);

        } catch (err) {
            console.error('Sorry, an error occurred: ', err.message);
        }
    }

    resetDataTable() {
        this.allIsLoaded = false;
        this.dataTable.destroy();
        this.dataTableCreated = false;
    }

    resetAssignEventManager() {
        this.selectedUserKey = undefined;
        this.selectedUserName = undefined;
        this.selectedUserImage = undefined;
        this.selectedCountryKey = undefined;
        this.selectedCountryName = undefined;
        this.selectedDestinationKey = undefined;
        this.selectedDestinationName = undefined;
        this.selectedTrailAreaKey = undefined;
        this.selectedTrailAreaName = undefined;
        this.selectedType = '';
        this.saveRoleDisabled = true;
    }
}
