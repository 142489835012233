import { Injectable } from '@angular/core';
import firebase from "firebase/compat";
import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from "@angular/fire/compat/database";
import {UserService} from "./user.service";
import {Event, EventItem, EventItemStatus, Infolet, InfoletType} from "../interfaces/event";
import {combineLatest, Observable, of} from "rxjs";
import {map, switchMap, take} from "rxjs/operators";
import {GeoService} from "../services/geo.service";
import {Explorer, ExplorerItemStatus, User} from "../interfaces/user";

@Injectable({
  providedIn: 'root'
})
export class EventService {
  eventsRef: firebase.database.Reference;
  eventItemsRef: firebase.database.Reference;
  eventStatusRef: firebase.database.Reference;
  eventItemStatusRef: firebase.database.Reference;

  constructor(
      private db: AngularFireDatabase,
      private userService: UserService
  ) {
    this.eventsRef = this.db.database.ref('events');
    this.eventItemsRef = this.db.database.ref('eventItems');
    this.eventStatusRef = this.db.database.ref('eventStatus');
    this.eventItemStatusRef = this.db.database.ref('eventItemStatus');
  }

  private static getEventItemFromSnap(eventItemSnap: AngularFireAction<DatabaseSnapshot<{}>>): EventItem {
    const eventItem: EventItem = <EventItem>eventItemSnap.payload.val();
    eventItem.key = eventItemSnap.key;
    eventItem.mapOnly = (typeof eventItem.mapOnly === 'boolean') ? eventItem.mapOnly : false;
    eventItem.lang = eventItem.lang || {};
    eventItem.eventPoints = eventItem.eventPoints || null;
    eventItem.timeboxStart = eventItem.timeboxStart || null;
    eventItem.timeboxEnd = eventItem.timeboxEnd || null;
    eventItem.description = eventItem.description || null;
    eventItem.header = eventItem.header || null;
    eventItem.voucherPin = eventItem.voucherPin || null;
    return eventItem;
  }

  /**
   * Gets an event observable from a db-key
   */
  getEvent(eventKey: string): Observable<Event> {
    // Gets the event - since we also need the key, we get this from snapshot, rather than just value
    return this.db.object<Event>(this.eventsRef.child(eventKey).ref).snapshotChanges().pipe(
        map((eventSnapshot) => {
          if (!eventSnapshot.payload.exists()) {
            return null;
          }

          const eventVal: Event = eventSnapshot.payload.val();
          if (!eventVal.infolets) {
            eventVal.infolets = [];
          }
          for (let i = 0; i < 3; i++) {
            if (!eventVal.infolets[i]) {
              eventVal.infolets[i] = {
                active: false,
                type: InfoletType.EDITOR,
                tabName: null,
                bodyContent: null,
                content: null
              };
            } else if (typeof eventVal.infolets[i].bodyContent === 'string') {
              eventVal.infolets[i].type = eventVal.infolets[i].type || InfoletType.EDITOR;
              eventVal.infolets[i].content = null;
            } else if (typeof eventVal.infolets[i].content === 'string') {
              eventVal.infolets[i].type = eventVal.infolets[i].type || InfoletType.RAW;
              eventVal.infolets[i].bodyContent = null;
            } else {
              eventVal.infolets[i].content = null;
              eventVal.infolets[i].bodyContent = null;
            }
          }
          eventVal.active = (eventVal.accessProductKey ? (eventVal.active || false) : false);
          eventVal.creatorKey = eventVal.creatorKey || null;
          eventVal.description = eventVal.description || null;
          eventVal.header = eventVal.header || null;
          eventVal.lang = eventVal.lang || {};
          return <Event>{
            key: eventSnapshot.key,
            ...eventVal
          };
        })
    );
  }

  /**
   * Get events from an array of event-keys
   */
  getEvents(eventKeys: string[]): Observable<Event[]> {
    if (eventKeys.length === 0) {
      return of([]);
    }
    return combineLatest(eventKeys.map((eventKey) => this.getEvent(eventKey)))
        .pipe(
            take(1),
            map((events) => {
              events.forEach((event) => {
                event.explorerCount = -1;
                this.getExplorerCount(event.key)
                    .pipe(take(1))
                    .subscribe((count) => event.explorerCount = count);
              });
              return events;
            })
        );
  }

  getEventItem(eventItemKey: string): Observable<EventItem> {
    return <Observable<EventItem>>this.db.object(this.eventItemsRef.child(eventItemKey).ref).snapshotChanges()
        .pipe(
            map(eventItemSnapshot => EventService.getEventItemFromSnap(eventItemSnapshot))
        );
  }

  getEventItems(eventKey: string): Observable<EventItem[]> {
    return this.db.list<EventItem>(
        this.eventItemsRef.ref,
        ref => ref.orderByChild('eventKey').equalTo(eventKey)
    ).snapshotChanges()
        .pipe(
            map((eventItemsSnaps) => {
              const eventItems = eventItemsSnaps.map((eventItemsSnap) => {
                return EventService.getEventItemFromSnap(eventItemsSnap);
              });
              eventItems.sort((a, b) => a.order - b.order);
              return eventItems;
            })
        );
  }

  getEventElementOriginalName(element: EventItem): Observable<string> {
    switch (element.refType) {
      case 1:
        return this.db.object<string>(`pois/${element.refKey}/name`).valueChanges();
      case 2:
        return this.db.object<string>(`tracks/${element.refKey}/name`).valueChanges();
    }
  }

  /**
   * Updates the settings of the Event
   */
  updateSettings(event: Event): Promise<Event> {
    const updateEventSettings = <Event>{
      active: event.active,
      latitude: event.latitude,
      longitude: event.longitude,
      geohash: GeoService.latLngLiteralToGeohash({lat: event.latitude, lng: event.longitude}),
      tintColor: event.tintColor,
      expirationDate: null
    };
    return this.eventsRef.child(event.key).update(updateEventSettings)
        .then(() => Object.assign(event, updateEventSettings));
  }

  /**
   * Updates the texts of the event
   */
  updateTexts(event: Event): Promise<void> {
    // Delete texts that are blank
    for (const lang of Object.keys(event.lang)) {
      for (const text of Object.keys(event.lang[lang])) {
        if (!event.lang[lang][text] || event.lang[lang][text] === 'undefined') {
          event.lang[lang][text] = null;
        }
      }
    }
    const updateEventTexts = {
      name: event.name,
      header: event.header,
      description: event.description,
      lang: event.lang
    };
    return this.eventsRef.child(event.key).update(updateEventTexts);
  }

  /**
   * Update an event infolet
   */
  updateInfolet(eventKey: string, infoletIndex: number, infolet: Infolet): Promise<void> {
    if (infolet.content !== null) {
      infolet.bodyContent = null;
    } else {
      infolet.content = null;
    }
    return this.eventsRef.child(eventKey).child('infolets').child(infoletIndex.toString()).update(infolet);
  }

  /**
   * Updates the trail area image (called trackGroup in Firebase)
   */
  updateImage(event: Event): Promise<void> {
    const updateEventImg = {
      imageUrl: event.imageUrl
    };
    return this.eventsRef.child(event.key).update(updateEventImg);
  }

  updateEventItemSettings(eventItem: EventItem): Promise<void> {
    const updateObject: EventItem = <EventItem>{
      timeboxStart: eventItem.timeboxStart || null,
      timeboxEnd: eventItem.timeboxEnd || null,
      voucherPin: eventItem.voucherPin || null,
      eventPoints: eventItem.eventPoints || null,
      order: eventItem.order || null
    };
    return this.eventItemsRef.child(eventItem.key).update(updateObject);
  }

  updateEventItemTexts(eventItem: EventItem): Promise<void> {
    // Delete texts that are blank
    for (const lang of Object.keys(eventItem.lang)) {
      for (const text of Object.keys(eventItem.lang[lang])) {
        if (!eventItem.lang[lang][text] || eventItem.lang[lang][text] === 'undefined') {
          eventItem.lang[lang][text] = null;
        }
      }
    }
    const updateEventItemTexts = {
      name: eventItem.name,
      header: eventItem.header || null,
      description: eventItem.description || null,
      lang: eventItem.lang
    };
    return this.eventItemsRef.child(eventItem.key).update(updateEventItemTexts);
  }

  updatePayment(event: Event): Promise<void> {
    const paymentUpdates: Event = <Event>{
      accessProductKey: event.accessProductKey || null
    };
    return this.eventsRef.child(event.key).update(paymentUpdates);
  }

  /**
   * Creates a new event in firebase
   */
  createNewEvent(newEvent: Event): string {
    const eventThenableRef = this.eventsRef.push(newEvent);
    return eventThenableRef.key;
  }

  createNewEventItem(newItem: EventItem): string {
    const eventItemThenableRef: firebase.database.ThenableReference = this.eventItemsRef.push(newItem);
    return eventItemThenableRef.key;
  }

  /**
   * Deletes an event item.
   * @param {string} eventItemKey
   * @returns {Promise<void>} A promise, which is resolved when the event item has been deleted.
   */
  deleteEventItem(eventItemKey: string): Promise<void> {
    return this.eventItemsRef.child(eventItemKey).remove();
  }

  getExplorers(eventKey: string): Observable<User[]> {
    return this.db.list<User>(
        this.eventStatusRef.ref,
        ref => ref.orderByChild(`${eventKey}/activatedTimeStamp`).startAt(1)
    ).snapshotChanges()
        .pipe(
            take(1),
            switchMap((userSnaps) => {
              return this.userService.getUsers(userSnaps.map((userSnap) => userSnap.key))
                  .pipe(map((users) => users.filter((user) => user)));
            })
        );
  }

  getExplorerCount(eventKey: string): Observable<number> {
    return this.db.list(
        this.eventStatusRef.ref,
        ref => ref.orderByChild(`${eventKey}/activatedTimeStamp`).startAt(1)
    ).valueChanges()
        .pipe(
            take(1),
            map((userSnaps) => userSnaps.length)
        );
  }

  getExplorersItemStatuses(eventKey: string, eventItems: EventItem[], users: User[]): Observable<Explorer[]> {
    return combineLatest(users.map(user => this.getExplorerItemStatuses(eventKey, eventItems, user))).pipe(take(1));
  }

  setEventElementStatus(profileKey: string, eventKey: string,
                            eventElementKey: string, updateElement: ExplorerItemStatus): Promise<void> {
    return this.eventItemStatusRef.child(profileKey).child(eventKey).child(eventElementKey).update(updateElement);
  }

  deleteEventElementStatus(profileKey: string, eventKey: string, eventElementKey: string): Promise<void> {
    return this.eventItemStatusRef.child(profileKey).child(eventKey).child(eventElementKey).remove();
  }

  private getExplorerItemStatuses(eventKey: string, eventItems: EventItem[], user: User): Observable<Explorer> {
    return this.db.list<EventItemStatus>(this.eventItemStatusRef.child(user.userID).child(eventKey).ref).snapshotChanges()
        .pipe(
            take(1),
            switchMap((statusSnaps) => {
              const explorer: Explorer = <Explorer>user;
              explorer.itemStatuses = [];
              explorer.score = 0;
              explorer.percentageScore = 0;
              eventItems.forEach((eventItem, eventItemIndex) => {
                let startTime = 0;
                let percentage: number = null;
                statusSnaps.forEach((statusSnap) => {
                  if (statusSnap.key === eventItem.key) {
                    const statusVal: ExplorerItemStatus = <ExplorerItemStatus>{
                      eventElementKey: statusSnap.key,
                      ...statusSnap.payload.val()
                    };
                    startTime = statusVal.startTime;
                    if (typeof statusVal.percentageCompleted === 'number') {
                      percentage = statusVal.percentageCompleted;
                    }
                  }
                });
                explorer.itemStatuses[eventItemIndex] = {
                  eventElementKey: eventItems[eventItemIndex].key,
                  startTime: startTime,
                  type: eventItems[eventItemIndex].refType
                };
                if (startTime > 0) {
                  explorer.itemStatuses[eventItemIndex].percentageCompleted = percentage;
                  explorer.score += eventItems[eventItemIndex].eventPoints || 0;
                  explorer.percentageScore +=
                      Math.round((eventItems[eventItemIndex].eventPoints || 0) * (percentage || 100) / 100);
                }
              });
              return of(explorer);
            })
        );
  }
}
