import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { TrailAreaService } from '../../../../firebase-services/trail-area.service';
import { EventService } from '../../../../firebase-services/event.service';

// Interfaces
import { TrailArea } from '../../../../interfaces/trailArea';
import { Event, EventItem } from '../../../../interfaces/event';

@Component({
    selector: 'app-trail-area-event-item-edit',
    templateUrl: './trail-area-event-item-edit.component.html',
    styleUrls: ['./trail-area-event-item-edit.component.css']
})
export class TrailAreaEventItemEditComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    area: TrailArea = null;
    event: Event = null;
    eventItem: EventItem = null;
    routerRoot: string;
    routerBack: string;

    constructor(
        private route: ActivatedRoute,
        private trailAreaService: TrailAreaService,
        private eventService: EventService
    ) {
    }

    ngOnInit() {
        this.trailAreaService.getTrailArea(this.route.snapshot.paramMap.get('areaKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((area) => {
                this.routerRoot = '/trail-area/' + area.key;
                this.area = area;
            });
        this.eventService.getEvent(this.route.snapshot.paramMap.get('eventKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((event) => {
                this.routerBack = '/events/' + event.key;
                this.event = event;
            });
        this.eventService.getEventItem(this.route.snapshot.paramMap.get('eventItemKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((eventItem) => this.eventItem = eventItem);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
