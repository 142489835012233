import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';

export interface TimeZoneData {
    timeZoneId: string;
    timeZoneName: string;
    dstOffset: number; // seconds offset for daylight savings
    rawOffset: number; // seconds offset from UTC (without DST)
}

@Injectable({
    providedIn: 'root'
})
export class TimeZoneService {
    private apiKey = environment.timezone.apiKey;

    constructor(
        private http: HttpClient
    ) {
    }

    /**
     * Get the time zone info for the given latitude and longitude.
     * @param lat Latitude
     * @param lng Longitude
     * @param timestamp Unix timestamp in seconds (to account for DST)
     * @returns Observable that emits the time zone data.
     */
    getTimeZone(lat: number, lng: number, timestamp: number = Math.floor(Date.now() / 1000)): Observable<TimeZoneData> {
        const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${this.apiKey}`;
        return this.http.get<any>(url).pipe(
            take(1),
            map(response => {
                if (response.status !== 'OK') {
                    throw new Error(`Time Zone API error: ${response.status}`);
                }
                return {
                    timeZoneId: response.timeZoneId,
                    timeZoneName: response.timeZoneName,
                    dstOffset: response.dstOffset,
                    rawOffset: response.rawOffset
                } as TimeZoneData;
            })
        );
    }
}