import { TranslatableObject } from './general';

export const EVENT_ITEM_TIME_FORMAT = 'DD-MM-YYYY HH:mm';
export const EVENT_REF_TYPE_POI = 1;
export const EVENT_REF_TYPE_TRAIL = 2;

export const EVENT_TINT_COLOR = '#137FC3';

enum EventItemActivatedBy {
    VOUCHER_PIN = 'voucherPin'
}

export enum InfoletType {
    EDITOR = 'editor',
    RAW = 'raw',
    RANKING = 'ranking'
}

export interface Infolet {
    active: boolean;
    type: InfoletType;
    tabName: string;
    content?: string;
    bodyContent?: string;
}

export interface Event extends TranslatableObject {
    key: string;
    active: boolean;
    creatorKey?: string;s
    name: string;
    description: string;
    header: string;
    imageUrl: string;
    infolets: Infolet[];
    tintColor: string;

    accessProductKey?: string;

    explorerCount?: number;

    /**
     * @deprecated
     */
    expirationDate?: null;
    /**
     * @deprecated
     */
    kioskProductKey?: string;
    /**
     * @deprecated
     */
    shopUrl?: string;
    /**
     * @deprecated
     */
    latitude?: number;
    /**
     * @deprecated
     */
    longitude?: number;
    /**
     * @deprecated
     */
    geohash?: string;
}

export interface EventItem extends TranslatableObject {
    key: string;
    eventKey: string;

    mapOnly: boolean;
    order?: number;
    eventPoints?: number;

    /**
     * 1: POI, 2: Trail
     */
    refType: number;
    refKey: string;

    timeboxStart?: number;
    timeboxEnd?: number;
    name?: string;
    description?: string;
    header?: string;
    /**
     * Four digit number
     */
    voucherPin?: string;
}

interface RideOnEventItem {
    algorithmVersion: number;
    percentageCompleted: number;
    startTime: number;
}

export interface EventItemStatus {
    activatedBy?: EventItemActivatedBy;
    eventElementKey?: string;
    eventKey?: string;
    percentageCompleted?: number;
    profileKey?: string;
    rideKey?: string;
    startTime: number;
    rides?: {
        [rideKey: string]: RideOnEventItem
    };
}
