import {Component} from '@angular/core';
import {Subject} from "rxjs";
import {Roles} from "../../../interfaces/role";
import {User} from "../../../interfaces/user";
import {AuthService} from "../../../core/auth.service";
import {RoleService} from "../../../core/role.service";
import {takeUntil} from "rxjs/operators";

declare var $: any;

@Component({
    selector: 'app-news-room-managers',
    templateUrl: './news-room-managers.component.html',
    styleUrl: './news-room-managers.component.css'
})
export class NewsRoomManagersComponent {
    destroy$: Subject<boolean> = new Subject<boolean>();
    roles = Roles;
    newsRoomManagers: User[];
    allIsLoaded = false;
    dataTableCreated = false;
    dataTable: any = null;
    managersDataTableName = "#newsRoomManagersDataTable";

    constructor(
        public authService: AuthService,
        private roleService: RoleService,
    ) {
        this.roleService.getRoleUsers(this.roles.NEWS_ROOM_MANAGER).pipe(takeUntil(this.destroy$)).subscribe((managers) => {
            this.newsRoomManagers = managers;
            this.allIsLoaded = true;
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && $("#newsRoomManagersDataTable>tbody>tr").length > 0) {
            this.dataTable = $(this.managersDataTableName).DataTable({
                order: [[0, "asc"]]
            });
            this.dataTableCreated = true;
        }
    }

    assignNewsRoomManager(userKey: string): Promise<void> {
        return this.roleService.assignUserRole(userKey, this.roles.NEWS_ROOM_MANAGER, this.authService.user.userID).then(() => {
            this.resetDataTable();
        });
    }

    revokeNewsRoomManager(userKey: string): Promise<void> {
        return this.roleService.revokeUserRole(userKey, this.roles.NEWS_ROOM_MANAGER).then(() => {
            this.resetDataTable();
        });
    }

    resetDataTable() {
        this.allIsLoaded = false;
        this.dataTable.destroy();
        this.dataTableCreated = false;
    }
}
