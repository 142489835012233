<div class="content-wrapper">
    <app-content-header-section contentPageId="exportFacilitydb" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">

                    <div class="card card-outline card-maroon">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-database"></i> Export Trails to Facilitetsdatabasen</h3>
                        </div>
                        <div class="card-body px-0">
                            <table *ngIf="trails && trailAreas" id="trailsDataTable" class="table table-bordered table-striped">
                                <thead>
                                <tr>
                                    <th *ngFor="let header of tableHeaders">{{ header }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let trail of trails">
                                    <td [attr.data-order]="trail.key">
                                        {{ trail.key }}
                                    </td>
                                    <td [attr.data-order]="trail.municipality">
                                        {{ trail.municipality }}
                                    </td>
                                    <td [attr.data-order]="trail.municipalityCode">
                                        {{ trail.municipalityCode }}
                                    </td>
                                    <td [attr.data-order]="trail.region">
                                        {{ trail.region }}
                                    </td>
                                    <td>
                                        Mountainbikespor
                                    </td>
                                    <td>
                                        <div>
                                            <ng-container *ngFor="let trailArea of trailAreas">
                                                <ng-container *ngFor="let trailRef of objectKeys(trailArea.trailKeys)">
                                                    <div *ngIf="trailRef === trail.key">{{ trailArea.name }}</div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td [attr.data-order]="trail.name">
                                        {{ trail.name }}
                                    </td>
                                    <td [attr.data-order]="trail.postalCode">
                                        {{ trail.postalCode }} {{ trail.city }}
                                    </td>
                                    <td>
                                        <div>
                                            <ng-container *ngFor="let trailArea of trailAreas">
                                                <ng-container *ngFor="let trailRef of objectKeys(trailArea.trailKeys)">
                                                    <div *ngIf="trailRef === trail.key">{{ trailArea.maintainers }}</div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <ng-container *ngFor="let trailArea of trailAreas">
                                                <ng-container *ngFor="let trailRef of objectKeys(trailArea.trailKeys)">
                                                    <div *ngIf="trailRef === trail.key">{{ trailArea.maintainers }}</div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <ng-container *ngFor="let trailArea of trailAreas">
                                                <ng-container *ngFor="let trailRef of objectKeys(trailArea.trailKeys)">
                                                    <div *ngIf="trailRef === trail.key">{{ trailArea.maintainers }}</div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <ng-container *ngFor="let trailArea of trailAreas">
                                                <ng-container *ngFor="let trailRef of objectKeys(trailArea.trailKeys)">
                                                    <div *ngIf="trailRef === trail.key">{{ trailArea.owner }}</div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <ng-container *ngFor="let trailArea of trailAreas">
                                                <ng-container *ngFor="let trailRef of objectKeys(trailArea.trailKeys)">
                                                    <div *ngIf="trailRef === trail.key">{{ trailArea.maintainers }}</div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td>
                                        WGS84
                                    </td>
                                    <td [attr.data-order]="trail.startPoint.longitude">
                                        {{ trail.startPoint.longitude }}
                                    </td>
                                    <td [attr.data-order]="trail.startPoint.latitude">
                                        {{ trail.startPoint.latitude }}
                                    </td>
                                    <td>
                                        Udendørs
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        Data leveret af Mountainbike United
                                    </td>
                                    <td>
                                        Se yderligere info hos Mountainbike United her {{ trail.shortDynamicLink }}
                                    </td>
                                    <td>
                                        Morten Kamp Schubert
                                    </td>
                                    <td>
                                        +457242444 / info@mtbutd.com
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</div>
