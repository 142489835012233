<div *ngIf="authService.user as user" class="content-wrapper">

    <app-content-header-section contentPageId="dashboard"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4">

                    <div *ngIf="authService.trailAreas as areas" class="card card-outline card-primary">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-map mr-2"></i> My Trail Areas</h3>
                        </div>
                        <div class="card-body p-0">
                            <ul class="list-group list-group-flush">
                                <a *ngFor="let trailArea of areas" routerLink="/trail-area/{{trailArea.key}}/settings"
                                   class="list-group-item list-group-item-action d-flex justify-content-between">
                                    <div><i class="fas fa-map-o"></i>&nbsp;{{ trailArea.name }}</div>
                                    <small>
                                        <a [routerLink]="'/trail-area/'+trailArea.key+'/trails'" [title]="'Trails in '+trailArea.name"
                                           appClickStopPropagation><i class="fas fa-route"></i></a>
                                        <a [routerLink]="'/trail-area/'+trailArea.key+'/pois'" [title]="'POI\'s in '+trailArea.name"
                                           appClickStopPropagation class="ml-1"><i class="fas fa-map-marker-alt"></i></a>
                                        <a [routerLink]="'/trail-area/'+trailArea.key+'/news-stories'" [title]="'News Stories in '+trailArea.name"
                                           appClickStopPropagation class="ml-1"><i class="far fa-newspaper"></i></a>
                                        <a *ngIf="trailArea.adventuresEnabled" [routerLink]="'/trail-area/'+trailArea.key+'/adventures'"
                                           [title]="'Adventures in '+trailArea.name" appClickStopPropagation class="ml-1"><i
                                                class="far fa-compass"></i></a>
                                        <a [routerLink]="'/trail-area/'+trailArea.key+'/area-managers'" [title]="'Area Managers for '+trailArea.name"
                                           appClickStopPropagation class="ml-1"><i class="fas fa-users-cog"></i></a>
                                        <a [routerLink]="'/trail-area/'+trailArea.key+'/ride-statistics'"
                                           [title]="'Ride statistics for '+trailArea.name" appClickStopPropagation class="ml-1"><i
                                                class="fas fa-chart-pie"></i></a>
                                    </small>
                                </a>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="col-md-8">

                    <div class="card card-outline card-olive">
                        <div *ngIf="country" class="card-header">
                            <h3 class="card-title"><i class="fas fa-globe mr-2"></i> Ride the World - {{ country.name }}</h3>
                        </div>
                        <div class="card-body p-0">
                            <div #gmap id="gmap"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
