import { environment } from '../../environments/environment';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import firebase from 'firebase/compat';

// Services
import { AngularFireDatabase } from '@angular/fire/compat/database/';

// Interfaces
import { ActivationCode } from '../interfaces/activation-code';
import { switchMap } from 'rxjs/operators';
import { KioskProductVariantReference } from '../interfaces/kiosk';

@Injectable({
    providedIn: 'root'
})
export class ActivationCodeService {
    version: string = environment.version;
    activationCodesRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase
    ) {
        this.activationCodesRef = this.db.database.ref('activationCodes');
    }

    generateActivationCodes(count: number, length: number, productVariantRefs: KioskProductVariantReference[]): Observable<string[]> {
        return this.getExistingActivationCodes()
            .pipe(
                take(1),
                switchMap((existingCodes) => {
                    const newCodes: string[] = [];
                    const savedCodePromises = [];
                    while (newCodes.length < count) {
                        const newCode = this.generateActivationCode(length, productVariantRefs);
                        if (existingCodes.indexOf(newCode.code) === -1) {
                            existingCodes.push(newCode.code);
                            newCodes.push(newCode.code);
                            savedCodePromises.push(this.activationCodesRef.child(newCode.code).set(newCode));
                        }
                    }
                    return Promise.all(savedCodePromises)
                        .then(() => newCodes);
                })
            );
    }

    private generateActivationCode(length: number, productVariantRefs: KioskProductVariantReference[]): ActivationCode {
        const chars = [...'abcdefghijkmnpqrstuvwxyz23456789'];
        const code: string = [...Array(length)].map(() => chars[Math.floor(Math.random() * chars.length)]).join('');

        const products = {};
        productVariantRefs.forEach((ref, index) => {
            delete ref.name;
            products['product-' + index] = ref;
        });

        return {
            products: products,
            code: code,
            createdAt: Date.now(),
            generatedBy: 'admin ' + this.version
        };
    }

    private getExistingActivationCodes(): Observable<string[]> {
        return this.db.list(this.activationCodesRef.ref).snapshotChanges()
            .pipe(
                take(1),
                map((activationCodeSnaps) => {
                    const activationCodes: string[] = [];
                    for (const activationCodeSnap of activationCodeSnaps) {
                        activationCodes.push(activationCodeSnap.key);
                    }
                    return activationCodes;
                })
            );
    }
}
