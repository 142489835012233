import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';

import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from '@angular/fire/compat/database';

import {Meetup, MeetupDash, MeetupFeedEntry, MeetupParticipantsAction} from '../interfaces/meetup';
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import firebase from "firebase/compat";
import {User} from "../interfaces/user";
import {UserService} from "./user.service";


@Injectable({
    providedIn: 'root'
})
export class MeetupService {
    meetupsRef: firebase.database.Reference;
    groupMeetupsRef: firebase.database.Reference;
    meetupFeedEntryRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private aFireFunctions: AngularFireFunctions,
        private userService: UserService
    ) {
        this.meetupsRef = db.database.ref('meetups');
        this.groupMeetupsRef = db.database.ref('groupMeetups');
        this.meetupFeedEntryRef = db.database.ref('feedEntries');
    }

    getMeetup(meetupKey: string): Observable<Meetup> {
        return this.db.object<Meetup>(this.meetupsRef.child(meetupKey).ref).snapshotChanges()
            .pipe(take(1), map((meetupSnapshot) => this.meetupFromSnap(meetupSnapshot)));
    }

    getAllMeetUps(): Observable<MeetupDash[]> {
        return this.db.list<MeetupDash>(this.meetupsRef.ref).snapshotChanges().pipe(
            take(1),
            map(meetupSnaps => {
                const meetups: MeetupDash[] = [];
                meetupSnaps.forEach((meetupSnap) => {
                    const meetUpDBEntry = <MeetupDash>meetupSnap.payload.val();

                    let meetup: MeetupDash = null;
                    // Only for the past two years
                    if (typeof meetUpDBEntry.timestamp === 'number' &&
                        meetUpDBEntry.timestamp > Date.now() - 2 * 365 * 24 * 60 * 60 * 1000) {
                        let date: Date = new Date(meetUpDBEntry.timestamp);
                        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                        date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
                        const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
                        const weekNo = Math.ceil((((date.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
                        meetup = {
                            key: meetupSnap.key,
                            timestamp: meetUpDBEntry.timestamp,
                            public: meetUpDBEntry.public,
                            year: date.getUTCFullYear(),
                            week: weekNo
                        };
                        meetups.push(meetup);
                    }
                });
                return meetups;
            })
        );
    }

    getGroupMeetups(groupKey: string): Observable<Meetup[]> {
        return this.db.list(this.groupMeetupsRef.child(groupKey).ref).snapshotChanges()
            .pipe(
                switchMap((meetupKeys) => {
                    return forkJoin(meetupKeys.map((meetupKey) =>
                        this.getMeetup(meetupKey.key)));
                })
            );
    }

    getMeetupFeedEntry(meetupKey: string): Observable<MeetupFeedEntry> {
        return this.db.object<MeetupFeedEntry>(this.meetupFeedEntryRef.child(meetupKey).ref).snapshotChanges()
            .pipe(map((meetupFeedEntrySnapshot) => this.meetupFeedEntryFromSnap(meetupFeedEntrySnapshot)));
    }

    getMeetupParticipantUsers(userKeys: string[]): Observable<User[]> {
        return this.userService.getUsers(userKeys);
    }

    getAllMeetupParticipants(meetupKey: string): Observable<User[]> {
        return this.getMeetupFeedEntry(meetupKey)
            .pipe(
                switchMap((entry) => {
                    const users$ = entry.participants.map((participant) =>
                        this.userService.getUser(participant).pipe(take(1))
                    );
                    return forkJoin(users$);
                })
            );
    }

    private meetupFromSnap(meetupSnap: AngularFireAction<DatabaseSnapshot<Meetup>>): Meetup {
        const meetupData = meetupSnap.payload.val();
        const participants = meetupData.participants ? Object.keys(meetupData.participants) : [];
        return <Meetup>{
            key: meetupSnap.key || '',
            meetupId: meetupSnap.key || '',
            name: meetupData?.name || '',
            description: meetupData?.description || '',
            public: meetupData?.public ?? false,
            timestamp: meetupData?.timestamp || 0,
            imageUrl: meetupData?.imageUrl || '',
            groupKey: meetupData?.groupKey || '',
            trailAreaKey: meetupData?.trailAreaKey || '',
            latitude: meetupData?.latitude || 0,
            longitude: meetupData?.longitude || 0,
            participants: participants,
            year: meetupData?.year || 0,
            week: meetupData?.week || 0,
        };
    }

    private meetupFeedEntryFromSnap(snap: AngularFireAction<DatabaseSnapshot<MeetupFeedEntry>>): MeetupFeedEntry {
        const entryData = snap.payload.val();
        return <MeetupFeedEntry>{
            participants: entryData.participants || []
        }
    }

    createNewMeetup(meetup: Meetup): Observable<string> {
        meetup.meetupId = this.meetupsRef.push().key;
        return this.aFireFunctions.httpsCallable('createMeetUp')(meetup).pipe(take(1));
    }

    updateMeetup(meetup: Meetup): Observable<boolean> {
        return this.aFireFunctions.httpsCallable('updateMeetUp')(meetup).pipe(take(1));
    }

    deleteMeetup(meetup: Meetup): Observable<boolean> {
        return this.aFireFunctions.httpsCallable('deleteMeetUp')(meetup).pipe(take(1));
    }

    inviteMeetupParticipants(inviteList: MeetupParticipantsAction): Observable<boolean> {
        return this.aFireFunctions.httpsCallable('meetupParticipationAction')(inviteList).pipe(take(1));
    }
}
