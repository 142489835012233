<div class="content-wrapper" *ngIf="pressCampGroup">
    <app-content-header-section contentPageId="pressCampGroupEdit"
                                [pageParams]="{pressCampKey: pressCampGroup.groupKey, pressCampName: pressCampGroup.name}">
    </app-content-header-section>

    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-5">
                    <div class="col-md-12">
                        <div class="card card-outline card-info">
                            <div class="card-header">
                                <h3 class="card-title"><i class="fas fa-cogs"></i> Settings</h3>
                            </div>
                            <div class="card-body">
                                <app-alert-info-box infoTrigger="{{ groupSettingsSaved }}">
                                    <h4>Press camp group settings saved successfully</h4>
                                </app-alert-info-box>
                                <div class="form-group">
                                    <label for="pressCampName">Name</label>
                                    <div class="input-group">
                                        <input class="form-control" id="pressCampName"
                                               [(ngModel)]="pressCampGroup.name"
                                               aria-describedby="pressCampNameHelp"
                                               placeholder="Enter group name">
                                    </div>
                                    <small class="form-text text-muted" id="pressCampNameHelp">
                                        Choose a name for your press camp.
                                    </small>
                                    <small *ngIf="!pressCampGroup.name" class="form-text text-danger"><i
                                            class="fas fa-exclamation-triangle"></i>
                                        Please provide a group name.
                                    </small>
                                </div>
                                <div class="form-group">
                                    <label for="pressCampDescription">Description</label>
                                    <div class="input-group">
                                    <textarea class="form-control" id="pressCampDescription"
                                              [(ngModel)]="pressCampGroup.description"
                                              aria-describedby="pressCampDescriptionHelp" rows="3"
                                              placeholder="Enter group description">
                                    </textarea>
                                    </div>
                                    <small class="form-text text-muted" id="pressCampDescriptionHelp">
                                        Describe your press camp.
                                    </small>
                                    <small *ngIf="!pressCampGroup.description" class="form-text text-danger"><i
                                            class="fas fa-exclamation-triangle"></i>
                                        Please provide a group description.
                                    </small>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button
                                        [disabled]="saveGroupSettingsDisabled"
                                        type="submit"
                                        class="btn btn-primary position-relative"
                                        (click)="saveSettings()">
                                    <span class="d-block text-center"
                                          [style.visibility]="savingSettings ? 'hidden' : 'visible'">
                                        Save settings
                                    </span>
                                    <span *ngIf="savingSettings"
                                          class="position-absolute w-100 text-center"
                                          style="top: 50%; left: 0; transform: translateY(-50%); text-align: center;">
                                        <i class="fas fa-sync-alt fa-spin"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" id="groupMemberBox">
                        <div class="card card-outline card-warning">
                            <div class="card-header">
                                <h3 class="card-title"><i class="fas fa-users"></i> Members
                                </h3>
                            </div>
                            <div class="card-body">
                                <app-alert-info-box infoTrigger="{{ groupMembersInvited }}">
                                    <h4>Press camp group members invited successfully</h4>
                                </app-alert-info-box>
                                <ul class="todo-list">
                                    <li class="primaryListItem" *ngFor="let member of members">
                                        <img class="img-fluid img-circle img-sm"
                                             src="{{ member.imageUrl }}" alt="">
                                        <span class="text">{{ member.name }}</span>
                                        <small class="badge badge-default">{{ member.role }}</small>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-footer d-flex align-items-center">
                                <app-find-user modalId="inviteUser"
                                               modalTitle="Find user to invite" id="selectInviteUser"
                                               (userSelected)="inviteUser($event)"
                                               btnClassType="success">Invite user
                                </app-find-user>&nbsp;&nbsp;
                                <app-find-group modalId="selectInviteGroup"
                                                modalTitle="Find group members to invite to group"
                                                id="selectGroupUsers"
                                                (groupSelected)="onGroupSelected($event)"
                                                btnClassType="success"> Invite group
                                </app-find-group>&nbsp;&nbsp;
                                <app-follows-people modalId="selectFollowsPeople"
                                                    modalTitle="Select users from people you follow"
                                                    [filterList]="filterList"
                                                    id="selectFollowsUsers"
                                                    (peopleSelected)="onFollowPeopleSelected($event)"
                                                    btnClassType="success"> Invite follow user
                                </app-follows-people>
                                <span *ngIf="invitingMembers" class="ml-auto">
                                    <i class="fas fa-sync-alt fa-spin"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <app-image-handler *ngIf="pressCampGroup" imageName="Press camp cover image" imageIndex="0"
                                       sizeNote="No particular preferred size though an aspect ratio of 1:1 is good."
                                       imageUrl="{{ pressCampGroup.coverImgUrl }}"
                                       imagePath="groups/{{ pressCampGroup.groupKey }}/"
                                       (uploadedImageUrl)="onUploadedImageUrl($event)">
                    </app-image-handler>
                </div>
            </div>
        </div>
    </div>
</div>

