import {Injectable} from '@angular/core';
import {Group, GroupMember, GroupMemberActions, GroupMembersAction} from "../interfaces/group";
import {AuthService} from "../core/auth.service";
import {GroupService} from "../firebase-services/group.service";
import {firstValueFrom, Observable} from "rxjs";
import {PressCampManagerService} from "../root/role-manager/press-camp-managers/press-camp-manager.service";
import {MeetupService} from "../firebase-services/meetup.service";
import {Meetup, MeetupParticipantActions, MeetupParticipantsAction} from "../interfaces/meetup";
import {User} from "../interfaces/user";

@Injectable({
    providedIn: 'root'
})
export class PressCampService {

    constructor(
        private authService: AuthService,
        private groupService: GroupService,
        private meetupService: MeetupService,
        private pressCampManagerService: PressCampManagerService
    ) {
    }

    // region press camp group
    getPressCampGroup(groupKey: string): Observable<Group> {
        return this.groupService.getGroup(groupKey);
    }

    getPressCampGroupMembers(groupKey: string): Observable<GroupMember[]> {
        return this.groupService.getGroupMembers(groupKey);
    }

    createNewPressCampGroup(): Promise<string> {
        return firstValueFrom(
            this.groupService.createNewGroup(
                <Group>{
                    name: 'New Press Camp',
                    description: 'A newly created press camp group',
                    isPrivate: true,
                }
            )
        ).then((key) => {
            return this.pressCampManagerService.addPressCampGroupToManager(this.authService.user.userID, key)
                .then(() => key);
        });
    }

    savePressCampGroupSettings(group: Group): Promise<boolean> {
        return firstValueFrom(
            this.groupService.updateGroup(group)
        ).then((success) => success);
    }

    invitePressCampGroupMembers(groupKey: string, users: string[]): Promise<boolean> {
        return firstValueFrom(
            this.groupService.inviteGroupMembers(
                <GroupMembersAction>{
                    groupKey: groupKey,
                    action: GroupMemberActions.ACTION_INVITE,
                    whoKeys: users
                }
            )
        ).then((success) => success);
    }

    deletePressCampGroup(group: Group): Promise<boolean> {
        return firstValueFrom(
            this.groupService.deleteGroup(group)
        ).then((success) => {
            if (success) {
                return this.pressCampManagerService.removePressCampGroupFromManager(this.authService.user.userID, group.groupKey)
                    .then(() => success);
            } else return success;
        });
    }

    // endregion

    // region press camp meetup
    getPressCampMeetup(meetupKey: string): Observable<Meetup> {
        return this.meetupService.getMeetup(meetupKey);
    }

    getPressCampMeetups(groupKey: string): Observable<Meetup[]> {
        return this.meetupService.getGroupMeetups(groupKey);
    }

    createNewPressCampMeetup(groupKey: string): Promise<string> {
        return firstValueFrom(
            this.meetupService.createNewMeetup(
                <Meetup>{
                    name: 'New Press Camp Meetup',
                    description: 'A newly created press camp group meetup',
                    public: false,
                    groupKey: groupKey
                }
            )
        ).then((key) => key);
    }

    savePressCampMeetupSettings(meetup: Meetup): Promise<boolean> {
        return firstValueFrom(
            this.meetupService.updateMeetup(meetup)
        ).then((success) => success);
    }

    deletePressCampMeetup(meetup: Meetup): Promise<boolean> {
        return firstValueFrom(
            this.meetupService.deleteMeetup(meetup)
        ).then((success) => success);
    }

    invitePressCampMeetupParticipants(meetupKey: string, users: string[]): Promise<boolean> {
        return firstValueFrom(
            this.meetupService.inviteMeetupParticipants(
                <MeetupParticipantsAction>{
                    meetupKey: meetupKey,
                    action: MeetupParticipantActions.ACTION_INVITE,
                    whoKeys: users
                }
            )
        ).then((success) => success);
    }

    getPressCampMeetupPendingInvites(meetupKey: string): Observable<User[]> {
        return this.meetupService.getAllMeetupParticipants(meetupKey);
    }

    getPressCampMeetupParticipants(userKeys: string[]): Observable<User[]> {
        return this.meetupService.getMeetupParticipantUsers(userKeys);
    }

    // endregion
}
