import {Injectable} from '@angular/core';
import {combineLatest, mergeMap, Observable, of} from "rxjs";
import {AssignableRole, AssignRoleEntry, SaveRoleEntry} from "../interfaces/role";
import {map, take} from "rxjs/operators";
import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from "@angular/fire/compat/database";
import firebase from "firebase/compat";
import {User} from "../interfaces/user";
import {UserService} from "../firebase-services/user.service";

@Injectable({providedIn: 'root'})
export class RoleService {
    rolesRef: firebase.database.Reference;
    userRolesRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private userService: UserService
    ) {
        this.rolesRef = this.db.database.ref('roles')
        this.userRolesRef = this.db.database.ref('userRoles');
    }

    public getAuthRoles(userKey: string): Observable<string[]> {
        return this.db.list<string>(this.userRolesRef.child(userKey).ref).snapshotChanges()
            .pipe(
                map((roleSnapShots) => roleSnapShots.map(snapShot => snapShot.key))
            );
    }

    getAdminUsers(): Observable<User[]> {
        return this.db.list(this.userRolesRef, ref => ref.orderByChild(`admin/by`).startAt('')).snapshotChanges()
            .pipe(
                mergeMap(userRoleSnapshots => {
                    const userKeys = userRoleSnapshots.map(snapshot => snapshot.key);
                    return userKeys.length
                        ? combineLatest(userKeys.map(userKey => this.userService.getUser(userKey).pipe(take(1))))
                        : of([]);
                })
            );
    }

    public getAssignableRoles(): Observable<AssignableRole[]> {
        return this.db.list<AssignableRole>(this.rolesRef.ref).snapshotChanges()
            .pipe(
                map((roleSnapshots) => roleSnapshots.map(roleSnapshot => this.assignableRoleFromSnap(roleSnapshot)))
            );
    }

    public assignUserRole(profileKey: string, role: string, currentProfileKey: string): Promise<void> {
        const userRoleEntry: AssignRoleEntry = {
            at: Date.now(),
            by: currentProfileKey
        };
        return this.userRolesRef.child(profileKey).child(role).update(userRoleEntry);
    }

    public revokeUserRole(userKey: string, role: string) {
        return this.userRolesRef.child(userKey).child(role).remove();
    }

    public saveNewRole(roleName: string, roleDescription: string) {
        let newRoleName = this.roleNameToUpperCase(roleName);
        let newRoleId = this.roleIdToLowerCase(newRoleName);
        newRoleId = newRoleId.replace(/ /g, '');
        return this.rolesRef.child(newRoleId).update(<SaveRoleEntry>{
            name: newRoleName,
            description: roleDescription
        });
    }

    public updateRoleDescription(role: string, description: string): Promise<void> {
        return this.rolesRef.child(role).child('description').update(description);
    }

    private assignableRoleFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<AssignableRole>>): AssignableRole {
        const role: AssignableRole = snapshot.payload.val();
        role.id = snapshot.key;
        role.description = role.description || null;
        role.name = role.name || null;
        return role;
    }

    private roleNameToUpperCase(str) {
        let splitStr = str.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    private roleIdToLowerCase(str) {
        let splitStr = str.split(' ');
        splitStr[0] = splitStr[0].toLowerCase();
        return splitStr.join(' ');
    }
}

