import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {combineLatest, Observable, of, zip} from 'rxjs';
import {catchError, map, switchMap, take} from 'rxjs/operators';

// Services
import {CountryService} from './country.service';
import {DestinationService} from './destination.service';
import {OrganisationService} from './organisation.service';

// Interfaces
import {
    EmailExtractUserAddress,
    User,
    UserAdminRights,
    UserReview
} from '../interfaces/user';
import {LastWeekTotalKpi} from '../interfaces/general';

import {Md5} from 'ts-md5/dist/md5';
import firebase from 'firebase/compat';

const BATCH_SIZE = 500;

@Injectable({
    providedIn: 'root'
})
export class UserService {
    profileRef: firebase.database.Reference;
    secureUserDataRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private httpClient: HttpClient,
    ) {
        this.profileRef = this.db.database.ref('profile');
        this.secureUserDataRef = this.db.database.ref('secure/userData');
    }

    /**
     * Gets a user from a key
     */
    getUser(profileKey: string, ensureImage: boolean = true): Observable<User> {
        return this.db.object<User>(this.profileRef.child(profileKey).ref).valueChanges()
            .pipe(
                switchMap((profile) => {
                    if (typeof profile !== 'object' || profile === null) {
                        return of(null);
                    }
                    if (!ensureImage) {
                        return of(profile);
                    }
                    return this.ensureProfileImage(profile);
                })
            );
    }

    ensureProfileImage(profile: User): Observable<User> {
        if (!profile.userID) {
            console.error('Invalid user!', profile);
            profile.userPicture = 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&s=128&f=y';
            return of(profile);
        }
        return this.httpClient.get(profile.userPicture, {responseType: 'text'})
            .pipe(
                map(() => profile),
                catchError((error) => {
                    return this.getProfileEmailFromKey(profile.userID)
                        .pipe(
                            map((email) => {
                                profile.userPicture = 'https://www.gravatar.com/avatar/' + Md5.hashStr(email.trim().toLowerCase()) + '?d=wavatar&s=128';
                                console.warn('Profile missing image - using:', profile.userPicture, error);
                                return profile;
                            }),
                            catchError((error2) => {
                                profile.userPicture = 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&s=128&f=y';
                                console.error('Profile email also missing', profile.userPicture, error2);
                                return of(profile);
                            })
                        );
                })
            );
    }

    getUsers(profileKeys: string[]): Observable<User[]> {
        return combineLatest(profileKeys.map((profileKey) => this.getUser(profileKey)));
    }

    getUsersInRange(fromKey: string, toKey: string): Observable<User[]> {
        return this.db.list<User>(this.profileRef.ref, ref => ref.orderByKey().startAt(fromKey).endAt(toKey)).valueChanges();
    }

    /**
     * Gets all managers in deprecated db node "userGroups", for migration script in dev-tools
     */
    getAllManagers(): Observable<{ key: string, data: UserAdminRights }[]> {
        return this.db.list<UserAdminRights>('/userGroups/admin').snapshotChanges()
            .pipe(take(1), map((userAdminRightsSnaps) => {
                const managers: { key: string, data: UserAdminRights }[] = [];
                for (const userAdminRightsSnap of userAdminRightsSnaps) {
                    const key = userAdminRightsSnap.payload.key;
                    const adminEntry: UserAdminRights = userAdminRightsSnap.payload.val();
                    if (key) {
                        managers.push({key, data: adminEntry});
                    }
                }
                return managers;
            }));
    }

    getProfileEmailFromKey(profileKey: string): Observable<string> {
        return this.db.object<string>(this.secureUserDataRef.ref.child(profileKey).child('email')).valueChanges();
    }

    loadEmails(): Observable<EmailExtractUserAddress[]> {
        return this.db.list<{ email: string }>(this.secureUserDataRef.ref).snapshotChanges()
            .pipe(
                take(1),
                switchMap((emailSnaps) => {
                    console.log('Total email entries in db', emailSnaps.length);
                    const emailObjects = emailSnaps.map((emailSnap) => {
                        const email = emailSnap.payload.val().email;
                        // Illegal emails:
                        if (typeof email === 'undefined' || !email.includes('@')) {
                            console.log('Illegal email', emailSnap.key, emailSnap.payload.val());
                            return null;
                        }
                        return {
                            profileKey: emailSnap.key,
                            email: email
                        };
                    });
                    const validEntries: { profileKey: string, email: string }[] = emailObjects.filter((nn) => nn);

                    // const emailWithProfileInfoBlockObservables: Observable<EmailExtractUser[]>[] = [];
                    const emailWithProfileInfoBlockObservables: Observable<EmailExtractUserAddress[]>[] = [];

                    let fromKey: string;
                    let toKey: string;

                    for (let i = 0; i < validEntries.length; i += BATCH_SIZE) {
                        const firstEntry = i;
                        const lastEntry = Math.min(i + BATCH_SIZE, validEntries.length) - 1;

                        fromKey = validEntries[firstEntry].profileKey;
                        toKey = validEntries[lastEntry].profileKey;

                        console.log('Getting users in range', fromKey, toKey, firstEntry, lastEntry);
                        // const emailWithProfileInfoBlockObservable: Observable<EmailExtractUser[]> =
                        const emailWithProfileInfoBlockObservable: Observable<EmailExtractUserAddress[]> =
                            this.getProfilesWithEmailsInRange(fromKey, toKey, validEntries.slice(firstEntry, lastEntry + 1));
                        emailWithProfileInfoBlockObservables.push(emailWithProfileInfoBlockObservable);
                    }

                    return zip(...emailWithProfileInfoBlockObservables)
                        .pipe(
                            map((emailExtractBlocks) => {
                                let emailProfiles: EmailExtractUserAddress[] = [];
                                emailExtractBlocks.forEach((emailExtractBlock) => {
                                    emailProfiles = emailProfiles.concat(emailExtractBlock);
                                });
                                const validEmailProfiles: EmailExtractUserAddress[] = emailProfiles.filter((nn) => nn);
                                console.log('Blocks:', emailExtractBlocks.length, ', valid emails:', emailProfiles.length, ', with profiles:', validEmailProfiles.length);
                                return validEmailProfiles;
                            })
                        );
                })
            );
    }

    getReviewKpis(): Observable<LastWeekTotalKpi> {
        return this.db.list(`/trackRatings`).snapshotChanges().pipe(
            take(1),
            switchMap(trailReviewsSnap => {
                const lastWeekThreshold = Date.now() - 7 * 24 * 60 * 60 * 1000;
                let total = 0;
                let lastWeek = 0;
                for (const trailReviewSnap of trailReviewsSnap) {
                    if (trailReviewSnap.payload.exists()) {
                        const userReviews: UserReview[] = <UserReview[]>trailReviewSnap.payload.val();
                        for (const i in userReviews) {
                            if (typeof userReviews[i] === 'object') {
                                if (userReviews[i].time > lastWeekThreshold) {
                                    lastWeek++;
                                }
                                total++;
                            }
                        }
                    }
                }
                return of({total: total, lastWeek: lastWeek});
            })
        );
    }

    private getProfilesWithEmailsInRange(fromKey: string, toKey: string,
                                         validEntries: { profileKey: string, email: string }[]): Observable<EmailExtractUserAddress[]> {
        return this.getUsersInRange(fromKey, toKey)
            .pipe(
                take(1),
                map((profiles) => {
                    console.log('Got profiles', profiles.length, fromKey, toKey);
                    // const profilesWithEmail: EmailExtractUser[] = [];
                    const profilesWithEmail: EmailExtractUserAddress[] = [];

                    for (let i = 0; i < validEntries.length; i++) {
                        const emailEntry = validEntries[i];
                        let found = false;

                        for (const profile of profiles) {
                            if (profile.userID === emailEntry.profileKey) {
                                const emailExtractUser: EmailExtractUserAddress = <EmailExtractUserAddress>profile;
                                emailExtractUser.email = emailEntry.email;
                                profilesWithEmail.push(emailExtractUser);
                                found = true;
                                break;
                            }
                        }

                        if (!found) {
                            console.log('Email without profile', emailEntry);
                        }
                    }

                    console.log('Batch finished with', profilesWithEmail.length, 'out of', validEntries.length);
                    return profilesWithEmail;
                })
            );
    }
}
