<div class="content-wrapper">
    <app-content-header-section *ngIf="draftPoi && country" [contentPageId]="contentPageId"
                                [pageParams]="{ countryName: country.name, countryCode: country.countryCode, poiName: draftPoi.name }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row" *ngIf="draftPoi">
                <div class="col-12 col-lg-6 col-xl-4">

                    <app-published-indicator [publishedState]="state.progress" (saveDraft)="saveDraft()" (publishElement)="publish()"
                                             (unpublishElement)="unpublish()" [modalSettings]="modalSettings"
                                             [contentName]="draftPoi.name || 'the POI'"
                                             contentType="poi" (leaveConfirmed)="canDeactivateReply($event)"></app-published-indicator>

                </div>
                <div class="col-lg-6 col-xl-4">

                    <app-translatable-text-edit textsName="POI" [textModel]="textModel" [textObject]="draftPoi" [publishedObject]="publicPoi"
                                                (textChanged)="handlePoiAltered()"></app-translatable-text-edit>

                </div>
                <div class="col-lg-6 col-xl-4">

                    <div class="card card-outline card-navy">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-quote-left mr-2"></i> Fixed POI Texts</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="mail">e-mail address (not required)</label>
                                <input [(ngModel)]="draftPoi.mail" (change)="handlePoiAltered()" (keyup)="handlePoiAltered()" id="mail"
                                       aria-describedby="mailHelp" class="form-control" placeholder="welcome@my-poi.com">
                                <small class="form-text text-muted" id="mailHelp">E-mail address to contact from the POI</small>
                                <div *ngIf="publicPoi && draftPoi.mail !== publicPoi.mail" class="text-danger text-sm">
                                    <i class="fas fa-globe-europe"></i> Published value "{{ publicPoi.mail }}".
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="phone">Phone (not required)</label>
                                <input [(ngModel)]="draftPoi.phone" (change)="handlePoiAltered()" (keyup)="handlePoiAltered()" id="phone"
                                       aria-describedby="phoneHelp" class="form-control" placeholder="555-dial-my-poi">
                                <small class="form-text text-muted" id="phoneHelp">Phone number to contact from the POI</small>
                                <div *ngIf="publicPoi && draftPoi.phone !== publicPoi.phone" class="text-danger text-sm">
                                    <i class="fas fa-globe-europe"></i> Published value "{{ publicPoi.phone }}".
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-lg-6 col-xl-4">

                    <app-image-handler imageName="POI Image" imageIndex="0" [imageUrl]="draftPoi.imageUrl"
                                       [imagePath]="'poiImages/' + draftPoi.key + '/'" [imageUrlOnline]="publicPoi ? publicPoi.imageUrl : ''"
                                       (imageFileSelected)="onImageSelected($event)" [disableSave]="true" [required]="false"
                                       (uploadedImageUrl)="onUploadedImageUrl($event)" [doUploadImage]="doUploadImage"></app-image-handler>

                </div>
                <div class="col-12 col-xl-8">

                    <div class="card card-outline card-danger">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-map-marker-alt mr-2"></i> Marker Settings</h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-6 col-xl-8">

                                    <div class="form-group" *ngIf="draftPoi && country && publicPoiCategories">
                                        <div #gmap id="gmap"></div>
                                        <small class="text-muted">
                                            Click and drag the icon to the desired position.<br>
                                            Pos: ({{ draftPoi.latitude }}, {{ draftPoi.longitude }})
                                        </small>
                                        <div *ngIf="publicPoi && (draftPoi.latitude !== publicPoi.latitude ||draftPoi.longitude !== publicPoi.longitude)"
                                             class="text-danger text-sm">
                                            <i class="fas fa-globe-europe"></i>
                                            Published position: ({{ publicPoi.latitude }}, {{ publicPoi.longitude }}).
                                        </div>
                                    </div>
                                    <div>
                                        <div class="flex-fill form-group" *ngIf="draftPoi.iconUrl">
                                            <label>Default icon</label>
                                            <div class="d-flex">
                                                <div class="mr-2">
                                                    <img [src]="draftPoi.iconUrl" alt="Default icon" class="poi-cat-default">
                                                </div>
                                                <div *ngIf="poiCategories">
                                                    <div *ngFor="let publicCategory of publicPoiCategories | keyValuePublicPoiCategory"
                                                         class="form-check" [class.d-none]="!selectedIcons[publicCategory.key]">
                                                        <input type="radio" class="form-check-input" [id]="publicCategory.key + 'Radio'"
                                                               (change)="onIconChanged()" [(ngModel)]="draftPoi.iconUrl"
                                                               [value]="publicCategory.iconUrl"
                                                               title="Set as default image">
                                                        <label class="form-check-label" [for]="publicCategory.key + 'Radio'">
                                                            {{ publicCategory.name }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <small class="text-muted">Choose which icon should represent the POI.</small>
                                            <div *ngIf="publicPoi && publicPoi.categoryIconUrls[0] !== draftPoi.iconUrl" class="text-danger text-sm">
                                                <i class="fas fa-globe-europe"></i> Published icon:
                                                <img [src]="publicPoi.categoryIconUrls[0]" alt="Published Icon" width="24" height="24">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-12 col-md-6 col-xl-4">
                                    <div class="form-group" *ngIf="poiCategories">
                                        <label>POI Categories</label>
                                        <small class="form-text text-danger" *ngIf="draftPoi.categories.length === 0">
                                            <i class="fas fa-exclamation-triangle mr-1"></i> You are required to select at least one category.</small>
                                        <ng-container *ngFor="let publicCategory of publicPoiCategories | keyValuePublicPoiCategory">
                                            <div class="d-flex flex-row">
                                                <input class="mr-1 height-26" type="checkbox" [id]="publicCategory.key + 'Select'"
                                                       title="Add category" [disabled]="disableCategory(publicCategory.key)"
                                                       (change)="updatePoiCategories()" [(ngModel)]="selectedCategories[publicCategory.key]">
                                                <div>
                                                    <label [for]="publicCategory.key + 'Select'" class="font-weight-normal mb-0">
                                                        {{ publicCategory.name }}
                                                    </label>
                                                    <img [src]="publicCategory.iconUrl" alt="Icon" width="24" height="24"
                                                         [ngClass]="'ml-2 poi-cat-' + (!selectedIcons[publicCategory.key] ? 'disabled' : (draftPoi.iconUrl === publicCategory.iconUrl ? 'default' : 'enabled'))">
                                                    <ng-container *ngIf="publicCategory.subCategories">
                                                        <div *ngFor="let publicSubCategory of publicPoiCategories[publicCategory.key].subCategories | keyValuePublicPoiSubCategory">
                                                            <input type="checkbox" class="mr-1" [id]="publicSubCategory.key + 'Select'"
                                                                   (change)="updatePoiCategories()"
                                                                   [disabled]="disableSubCategory(publicCategory.key, publicSubCategory.key)"
                                                                   [(ngModel)]="selectedCategories[publicSubCategory.key]">
                                                            <label [for]="publicSubCategory.key + 'Select'" class="font-weight-normal mb-0">
                                                                {{ publicSubCategory.name }}
                                                            </label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <small class="form-text text-muted">Select categories for this POI.</small>
                                        <div *ngIf="categoriesOutOfSync()" class="text-danger text-sm">
                                            <i class="fas fa-globe-europe"></i>
                                            Published categories:
                                            <img *ngFor="let iconUrl of publicPoi.categoryIconUrls" [src]="iconUrl"
                                                 alt="Published Category Icon" width="24" height="24" class="mr-1">
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
