<div class="content-wrapper">
    <app-content-header-section contentPageId="sponsor" [pageParams]="{}"></app-content-header-section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-5">
                    <div class="card card-outline card-teal">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-plus"></i> Create New Campaign</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <div>
                                    <label for="newCampaignAlias">Alias</label>
                                    <input [(ngModel)]="newCampaignAlias"
                                           (change)="newCampaignAliasChanged()"
                                           (keyup)="newCampaignAliasChanged()" id="newCampaignAlias"
                                           aria-describedby="newCampaignHelp" class="form-control"
                                           placeholder="Mtb Shop Sponsor">
                                </div>
                                <small class="form-text text-muted" id="newCampaignHelp">Choose an alias for your new campaign. This is an internal name for the campaign, and is not used
                                    publicly.</small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="disableCreateCampaign" (click)="createNewCampaign()" class="btn btn-success">
                                Create campaign
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card card-outline card-teal">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fa fa-gift"></i> Sponsor campaigns</h3>
                        </div>
                        <div class="card-body px-0">
                            <table *ngIf="allIsLoaded" id="sponsorDataTable" class="table row-bordered table-striped">
                                <thead>
                                <tr>
                                    <th>Campaign</th>
                                    <th>Target</th>
                                    <th>Active</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let sponsor of sponsors">
                                    <td>
                                        <div><strong>{{ sponsor.alias }}</strong></div>
                                        <img src="{{ sponsor.imageURL }}" width="50" [alt]="sponsor.alias"/>
                                        <div>
                                            <button class="btn btn-xs btn-success"
                                                    routerLink="/root/sponsor/{{ sponsor.key }}">
                                                <i class="far fa-edit"></i> Edit
                                            </button>&nbsp;
                                            <button *ngIf="sponsor.activeFrom" class="btn btn-xs btn-info" (click)="copyCampaign(sponsor)">
                                                <i class="far fa-copy"></i> Copy
                                            </button>&nbsp;
                                            <button class="btn btn-xs btn-danger" (click)="deleteCampaign(sponsor)">
                                                <i class="fas fa-trash"></i> Delete
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <strong>{{ sponsorTargetType2LabelMapping[sponsor.sponsorType] }}</strong>
                                            <div [innerHtml]="getSponsorTargetSpec(sponsor)"></div>
                                        </div>
                                    </td>
                                    <td [attr.data-order]="sponsor.activeTo">
                                        From {{ sponsor.activeFrom | date : 'dd/MM/yyyy HH:mm' }}<br/>
                                        To {{ sponsor.activeTo | date : 'dd/MM/yyyy HH:mm' }}
                                        <div>
                                            <span *ngIf="now < sponsor.activeFrom" class="badge badge-warning">
                                              Planned in the future
                                            </span>
                                            <div *ngIf="now > sponsor.activeFrom && now < sponsor.activeTo"
                                                 class="progress">
                                                <div class="progress-bar progress-bar-green" role="progressbar"
                                                     [style]="setProgressWidth(sponsor)">
                                                    <span class="sr-only">40% Complete (success)</span>
                                                </div>
                                            </div>
                                            <span *ngIf="now > sponsor.activeTo" class="badge badge-danger">
                                              Campaign is expired
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
