import { Injectable } from '@angular/core';
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database/';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { FileService } from '../services/file.service';

// Interfaces
import { RtwEvent, RtwEventParticipant } from '../interfaces/rtw-event';

import firebase from 'firebase/compat';

@Injectable({
    providedIn: 'root'
})
export class RtwEventService {
    rtwEventRef: firebase.database.Reference;
    rtwEventParticipantRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private fileService: FileService
    ) {
        this.rtwEventRef = this.db.database.ref('rtwEvents');
        this.rtwEventParticipantRef = this.db.database.ref('rtwEventParticipants');
    }

    private static getRtwEventFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<RtwEvent>>): RtwEvent {
        const rtwEvent: RtwEvent = snapshot.payload.val();
        rtwEvent.key = snapshot.key;
        if (!rtwEvent.lang) {
            rtwEvent.lang = {};
        }
        return rtwEvent;
    }

    getRtwEvents(): Observable<RtwEvent[]> {
        return this.db.list<RtwEvent>(this.rtwEventRef.ref, ref => ref.orderByChild('startTime')).snapshotChanges()
            .pipe(
                map((rtwEventsSnapshot) => {
                    const events: RtwEvent[] = [];
                    rtwEventsSnapshot.forEach((rtwEventSnapshot) => {
                        events.push(RtwEventService.getRtwEventFromSnap(rtwEventSnapshot));
                    });
                    return events;
                })
            );
    }

    getRtwEvent(rtwEventKey: string): Observable<RtwEvent> {
        return this.db.object<RtwEvent>(this.rtwEventRef.child(rtwEventKey).ref).snapshotChanges()
            .pipe(
                map((rtwEventSnap) => {
                    return RtwEventService.getRtwEventFromSnap(rtwEventSnap);
                })
            );
    }

    createRtwEvent(rtwEvent: RtwEvent): firebase.database.ThenableReference {
        return this.rtwEventRef.push(rtwEvent);
    }

    updateSettings(rtwEvent: RtwEvent): Promise<any> {
        const updateRtwSettings = <RtwEvent>{
            active: rtwEvent.active || false,
            color: rtwEvent.color,
            endTime: rtwEvent.endTime || null,
            startTime: rtwEvent.startTime || null
        };
        return this.rtwEventRef.child(rtwEvent.key).update(updateRtwSettings);
    }

    updateTexts(rtwEvent: RtwEvent): Promise<void> {
        // Delete texts that are blank
        Object.keys(rtwEvent.lang).forEach((lang) => {
            Object.keys(rtwEvent.lang[lang]).forEach((text) => {
                if (!rtwEvent.lang[lang][text]) {
                    rtwEvent.lang[lang][text] = null;
                }
            });
        });
        const updateObject: RtwEvent = <RtwEvent>{
            title: rtwEvent.title,
            feedEntryTitle: rtwEvent.feedEntryTitle,
            feedEntryDescription: rtwEvent.feedEntryDescription,
            lang: rtwEvent.lang
        };
        return this.rtwEventRef.child(rtwEvent.key).update(updateObject);
    }

    updateImage(rtwEvent: RtwEvent, newImageUrl: string): Promise<any> {
        let deleteOnlinePromise: Promise<void>;
        if (rtwEvent.imageUrl !== null) {
            deleteOnlinePromise = this.fileService.deleteStorageFile(rtwEvent.imageUrl)
                .catch((err) => console.error('Couldn\'t delete old file' + rtwEvent.imageUrl, err));
        } else {
            deleteOnlinePromise = Promise.resolve();
        }

        return deleteOnlinePromise
            .then(() => this.rtwEventRef.child(rtwEvent.key).child('imageUrl').set(newImageUrl));
    }

    updateHeroSettings(rtwEvent: RtwEvent, newImage: boolean): Promise<any> {
        const updateRtwSettings = <RtwEvent>{
            heroKey: rtwEvent.heroKey,
            heroName: rtwEvent.heroName
        };
        if (newImage) {
            updateRtwSettings.heroImageUrl = rtwEvent.heroImageUrl;
        }

        return this.rtwEventRef.child(rtwEvent.key).update(updateRtwSettings);
    }

    updateHeroImage(rtwEvent: RtwEvent, newImageUrl: string): Promise<any> {
        let deleteOnlinePromise: Promise<void>;
        if (typeof rtwEvent.heroImageUrl === 'string' && newImageUrl !== rtwEvent.heroImageUrl && rtwEvent.heroImageUrl
            .includes('rtwEvents')) {
            deleteOnlinePromise = this.fileService.deleteStorageFile(rtwEvent.heroImageUrl)
                .catch((err) => console.error('Couldn\'t delete old file' + rtwEvent.heroImageUrl, err));
        } else {
            deleteOnlinePromise = Promise.resolve();
        }

        return deleteOnlinePromise
            .then(() => this.rtwEventRef.child(rtwEvent.key).child('heroImageUrl').set(newImageUrl));
    }

    getRtwEventParticipants(rtwEventKey: string): Observable<RtwEventParticipant[]> {
        return this.db.list<RtwEventParticipant>(this.rtwEventParticipantRef.child(rtwEventKey).ref).snapshotChanges()
            .pipe(
                map((snaps) => {
                    const participants: RtwEventParticipant[] = [];
                    snaps.forEach((snap) => {
                        const participant: RtwEventParticipant = snap.payload.val();
                        participant.profileKey = snap.key;
                        participants.push(participant);
                    });
                    return participants;
                })
            );
    }
}
