import {AfterViewChecked, Component, EventEmitter, Input, Output} from '@angular/core';
import {take} from 'rxjs/operators';
import {Country} from "../../interfaces/countries";
import {CountryService} from "../../firebase-services/country.service";

declare var $: any;

@Component({
    selector: 'app-find-country',
    templateUrl: './find-country.component.html',
    styleUrls: ['./find-country.component.css']
})
export class FindCountryComponent implements AfterViewChecked {
    @Input() modalId: string;
    @Input() modalTitle: string;
    @Input() btnClassType: string;
    @Output() countrySelected = new EventEmitter<string>();

    SEARCH_TIMEOUT = 1000;
    searchTimeout: number;
    lastSearchTerm = '';
    disableSearch = false;
    searchInput: HTMLInputElement = null;
    foundCountries: Country[] = [];

    constructor(
        private countryService: CountryService
    ) {
    }

    ngAfterViewChecked() {
        if (this.searchInput === null) {
            this.searchInput = <HTMLInputElement>document.getElementById('searchForCountries' + this.modalId);
        }
    }

    focusInSearch(): void {
        window.setTimeout(() => this.searchInput.focus(), 500);
    }

    searchForCountries(): void {
        window.clearTimeout(this.searchTimeout);
        const searchTerm: string = this.searchInput.value;
        if (searchTerm === this.lastSearchTerm || searchTerm.length < 3) {
            return;
        }
        this.searchTimeout = window.setTimeout(() => this.startDatabaseSearch(searchTerm), this.SEARCH_TIMEOUT);
    }

    startDatabaseSearch(searchTerm: string): void {
        if (this.disableSearch) {
            return;
        }
        this.disableSearch = true;
        this.lastSearchTerm = searchTerm;
        const countries$ = this.countryService.searchByName(searchTerm);
        countries$
            .pipe(take(1))
            .subscribe((countries) => {
                this.foundCountries = countries;
                this.disableSearch = false;
                window.setTimeout(() => this.searchInput.focus(), 0);
            });
    }

    selectCountry(countryCode: string): void {
        this.countrySelected.emit(countryCode);
        $('#findCountryModal_' + this.modalId).modal('hide');
    }

    copyText(text: string): Promise<void> {
        return navigator.clipboard.writeText(text);
    }
}
