<div class="content-wrapper">
    <app-content-header-section contentPageId="pressCampManagerDashboard"
                                [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4" *ngFor="let camp of pressCamps">
                    <div [ngClass]="'small-box pointer'">
                        <div class="card-body custom-background"
                             [routerLink]="layout.getUrl('pressCampOverview', { pressCampKey: camp.groupKey, pressCampName: camp.name })"
                             [ngStyle]="{ 'background-image': 'url(' + (camp.coverImgUrl || 'https://firebasestorage.googleapis.com/v0/b/singletracker-beta.appspot.com/o/mediaLibrary%2FdefaultImages%2Fsub_bg.png?alt=media&token=b025f409-ef29-4290-aba3-b920c2705200') + ')' }">
                            <div class="inner">
                                <h4>{{camp.name}}</h4>
                            </div>
                            <div class="icon">
                                <i [style.color]="'white'"
                                   [ngClass]="layout.getPluralIcon('pressCamp')"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div [ngClass]="'small-box pointer'">
                        <div class="card-body gray-background"
                             (click)="createNewPressCamp()">
                            <div class="inner">
                                <h4>Create new press camp</h4>
                            </div>
                            <div class="icon">
                                <i [ngClass]="layout.getPluralIcon('plusCard')"></i>
                            </div>
                            <div *ngIf="creatingGroup" class="card-img-overlay">
                                <i class="fas fa-3x fa-sync-alt fa-spin"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>