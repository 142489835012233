<div class="content-wrapper">
    <app-content-header-section contentPageId="rides" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-8 col-lg-6 offset-lg-1">
                    <div class="card card-maroon">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-cogs"></i> Ride search settings</h3>
                        </div>
                        <div class="card-body">

                            <div class="form-group">
                                <label for="periodInput">Time period</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <i class="input-group-text far fa-clock"></i>
                                    </div>
                                    <input type="text" class="form-control" id="periodInput">
                                    <span class="input-group-append">
                                        <button (click)="searchForRidesInPeriod()" class="btn btn-info float-right">Search</button>
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="findRidersRides">Load rides from user</label>
                                <div class="input-group">
                                    <app-find-user id="findRidersRides" modalId="ridersRides" modalTitle="Find user to load rides for"
                                                   (userSelected)="searchForUserRides($event)"
                                                   btnClassType="info">Search
                                    </app-find-user>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="!dataReady" class="overlay">
                            <i class="fas fa-spinner fa-3x fa-spin"></i>
                        </div>
                    </div>

                </div>
                <!--div class="col-4">

                    <div class="card card-maroon">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fa fa-filter"></i> Ride filters</h3>
                        </div>
                        <div class="card-body" id="filterBox">
                            <div class="form-group">
                                <label>Device Type</label>
                                <div class="radio row" id="deviceTypeFilter" (change)="filterOnDeviceType()">
                                    <div class="col-4">
                                        <label>
                                            <input type="radio" name="deviceTypeFilter" value="Android">
                                            <i class="fab fa-android"></i> Android
                                        </label>
                                    </div>
                                    <div class="col-4">
                                        <label>
                                            <input type="radio" name="deviceTypeFilter" value="iOS">
                                            <i class="fab fa-apple"></i> iOS
                                        </label>
                                    </div>
                                    <div class="col-4">
                                        <label>
                                            <input type="radio" name="deviceTypeFilter" value="?">
                                            <i class="fas fa-question-circle"></i> Unknown
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Validation</label>
                                <div class="radio row" id="validFilter" (change)="filterOnValid()">
                                    <div class="col-4">
                                        <label>
                                            <input type="radio" name="validFilter" value="ok">
                                            <i class="fa fa-check"></i> Valid
                                        </label>
                                    </div>
                                    <div class="col-4">
                                        <label>
                                            <input type="radio" name="validFilter" value="fail">
                                            <i class="fa fa-times"></i> Invalid
                                        </label>
                                    </div>
                                    <div class="col-4">
                                        <label>
                                            <input type="radio" name="validFilter" value="?">
                                            <i class="fa fa-question"></i> Not validated
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="userFilter">User key</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <i class="input-group-text fas fa-user"></i>
                                    </div>
                                    <input (change)="filterOnUserKey()" (keyup)="filterOnUserKey()" type="text" class="form-control" id="userFilter">
                                </div>
                            </div>

                        </div>
                    </div>

                </div-->
            </div>
            <div *ngIf="profileRides" class="row">
                <div class="col-12">

                    <div [ngClass]="'card card-outline card-' + layout.getTheme('ride')">
                        <div class="card-header">
                            <h3 class="card-title"><i [ngClass]="layout.getPluralIcon('ride')"></i> Rides</h3>
                        </div>
                        <div class="card-body px-0">
                            <table id="ridesDataTable" class="table row-bordered table-striped">
                                <thead>
                                <tr>
                                    <th>About</th>
                                    <th>Primary trail area</th>
                                    <th>Distance</th>
                                    <th>Time</th>
                                    <th>Trail Areas</th>
                                    <th>Trails</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let profileRide of profileRides">
                                    <td [attr.data-order]="profileRide.startTime">
                                        <div>
                                            <span class="badge badge-default">{{ profileRide.profileKey }}</span> -
                                            <span class="badge badge-warning">{{ profileRide.rideKey }} <a (click)="copyKey(profileRide.rideKey)"
                                                                                                           title="Copy key" href="javascript:"><i
                                                    class="far fa-copy"></i></a></span> -
                                            <a *ngIf="profileRide.algorithmVersion" class="mr-1" href="/root/rides/ride/{{ profileRide.rideKey }}"
                                               target="_blank"><i class="fa fa-street-view"></i></a>
                                            <a class="ml-2" href="{{ firebaseRoot }}/rides/{{ profileRide.rideKey }}" target="_blank"><i
                                                    class="fas fa-road"></i></a>
                                            <a class="ml-2" href="{{ firebaseRoot }}/rideData/{{ profileRide.rideKey }}" target="_blank"><i
                                                    class="fa fa-list-ol"></i></a>
                                            <a *ngIf="profileRide.algorithmVersion" class="ml-2"
                                               href="{{ firebaseRoot }}/profileRides/{{ profileRide.profileKey }}/{{ profileRide.rideKey }}"
                                               target="_blank"><i class="fa fa-user"></i></a>
                                        </div>
                                        <div *ngIf="profileRide.algorithmVersion">
                                            <div>
                                                <span>Version: {{ profileRide.algorithmVersion }}</span>
                                                @{{ profileRide.metricsTimestamp | date:'dd/MM/yyy HH:mm:ss' }}
                                            </div>
                                            <div>
                                                <span>Start time: {{ profileRide.startTime | date:'dd/MM/yyy HH:mm:ss' }}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="!profileRide.algorithmVersion">
                                            <div>This ride has not been calculated yet.</div>
                                            <!--<button>Do it for this user</button>-->
                                        </div>
                                    </td>
                                    <td [attr.data-order]="profileRide.primaryTrailAreaName">
                                        <div *ngIf="profileRide.algorithmVersion">
                                            <img src="{{ profileRide.imageURL }}" width="50" [alt]="profileRide.primaryTrailAreaName" /> {{ profileRide.primaryTrailAreaName }}
                                        </div>
                                    </td>
                                    <td [attr.data-order]="profileRide.totalDistance">
                                        <div *ngIf="profileRide.algorithmVersion">
                                            <div>On trail distance: <span>{{ profileRide.onTrailDistance / 1000 | number:'1.0-2' }} km</span></div>
                                            <div>Total distance: <span>{{ profileRide.totalDistance / 1000 | number:'1.0-2' }} km</span></div>
                                        </div>
                                    </td>
                                    <td [attr.data-order]="profileRide.totalTime">
                                        <div *ngIf="profileRide.algorithmVersion">
                                            <div>On trail time: <span>{{ profileRide.onTrailTime / 60000 | number:'1.0-2' }} min</span></div>
                                            <div>Total time: <span>{{ profileRide.totalTime / 60000 | number:'1.0-2' }} min</span></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngIf="profileRide.algorithmVersion">
                                            <div *ngFor="let trailArea of profileRide.trailAreas">
                                                <span>{{ trailArea.name }}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngIf="profileRide.algorithmVersion">
                                            <div *ngFor="let trail of profileRide.trails">
                                                <span>{{ trail.name }}</span>,
                                                <span>{{ trail.distance }} m</span>,
                                                <span>{{ trail.time }} s</span>,
                                                <span>{{ trail.percentageVisited }}%</span>
                                                <span *ngIf="trail.isLegacy" class="badge badge-danger">Legacy trail</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="!dataReady" class="overlay">
                            <i class="fas fa-spinner fa-3x fa-spin"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</div>
