import {Component, OnInit} from '@angular/core';

// Services
import {AuthService} from '../../../core/auth.service';
import {FileService} from '../../../services/file.service';
import {TrailAreaService} from '../../../firebase-services/trail-area.service';
import {TrailService} from '../../../firebase-services/trail.service';
import {UserService} from '../../../firebase-services/user.service';
import {GoogleGeocodeService} from "../../../services/google-geocode.service";

// Interfaces
import {TrailArea} from '../../../interfaces/trailArea';
import {
    AustrianTrailCandidate,
    GPSPoint,
    Trail,
    TrailDifficulty,
    TrailImport,
    TrailImportGb,
    UKTrailCandidate
} from '../../../interfaces/trail';

import * as proj4 from 'proj4';
import * as polyTool from '@pirxpilot/google-polyline';

// pipes
import {formatDate} from '@angular/common'
import {DefaultMapProp} from "../../../interfaces/map";
import {take, takeUntil} from "rxjs/operators";
import {RoleService} from "../../../core/role.service";
import {Roles} from "../../../interfaces/role";
import {TrailAreaManagerService} from "../../role-manager/trail-area-managers/trail-area-manager.service";

declare var $: any;

@Component({
    selector: 'app-trails-import',
    templateUrl: './trails-import.component.html',
    styleUrls: ['./trails-import.component.css']
})
export class TrailsImportComponent implements OnInit {

    selectedFile: File = null;
    trailData: AustrianTrailCandidate = null;
    trailDataUK: UKTrailCandidate = null;
    trailAreas: TrailArea[] = null;
    trails: TrailImportGb[][] = [];
    mapDifficulty: TrailDifficulty[] = [];
    parseDone = false;
    displayMapFor: TrailArea = null;
    displayMapForTrails: Trail[] = null;
    existingTrailAreas: TrailArea[] = null;
    existingTrails: Trail[] = null;
    mergedAreas: TrailArea[] = null;
    mergedTrails: Trail[][] = null;
    oldAreasForDeletion: string[][] = null;
    newTrailAreaKeys: string[] = null;
    roles = Roles;

    constructor(
        private authService: AuthService,
        private roleService: RoleService,
        private trailAreaManagerService: TrailAreaManagerService,
        private fileService: FileService,
        private trailAreaService: TrailAreaService,
        private trailService: TrailService,
        private googleGeocodeService: GoogleGeocodeService
    ) {
    }

    ngOnInit() {
        this.mapDifficulty.push({
            text: 'Unknown',
            iconUrl: 'https://firebasestorage.googleapis.com/v0/b/sweltering-inferno-5571.appspot.com/o/icons%2Ftracks%2Fukendt.png?alt=media&token=d4d199e9-45ec-4e31-bc0d-2816911013be',
            color: '#9013FE',
            de_text: 'purple',
            en_text: 'purple'
        });
        this.mapDifficulty.push({
            text: 'Easy',
            iconUrl: 'https://firebasestorage.googleapis.com/v0/b/sweltering-inferno-5571.appspot.com/o/icons%2Ftracks%2F2b_let.png?alt=media&token=1fba2dca-d6d7-4861-afff-d278286cb899',
            color: '#137FC3',
            de_text: 'leicht',
            en_text: 'blue'
        });
        this.mapDifficulty.push({
            text: 'Moderate',
            iconUrl: 'https://firebasestorage.googleapis.com/v0/b/sweltering-inferno-5571.appspot.com/o/icons%2Ftracks%2F3b_middel.png?alt=media&token=a59eb191-07ec-43af-876a-342dc2b56319',
            color: '#D51D29',
            de_text: 'mittelschwierig',
            en_text: 'red'
        });
        this.mapDifficulty.push({
            text: 'Difficult',
            iconUrl: 'https://firebasestorage.googleapis.com/v0/b/sweltering-inferno-5571.appspot.com/o/icons%2Ftracks%2F4b_svaer.png?alt=media&token=b5731685-34bc-4004-b9b6-1c9bae26bbfb',
            color: '#231F20',
            de_text: 'schwierig',
            en_text: 'black'
        });

        const existingTrailAreasObservable = this.trailAreaService.getImportedTrailAreas();
        existingTrailAreasObservable.subscribe(trailAreas => this.existingTrailAreas = trailAreas);

        const existingTrailsObservable = this.trailService.getImportedTrails();
        existingTrailsObservable.subscribe(trails => this.existingTrails = trails);

    }

    showMap(trailAreaImportKey: string): void {
        const trailAreaIndex: number = this.getTrailAreaIndexFromImportKey(trailAreaImportKey);
        this.displayMapFor = this.trailAreas[trailAreaIndex];
        this.displayMapForTrails = this.trails[trailAreaIndex];
        const map = new google.maps.Map(document.getElementById(trailAreaImportKey) as HTMLElement, DefaultMapProp);
        let north = -90;
        let south = 90;
        let east = -180;
        let west = 180;
        const trails = this.trails[trailAreaIndex];

        for (let trailIndex = 0; trailIndex < trails.length; trailIndex++) {
            const trail: TrailImportGb = trails[trailIndex];
            for (let waypoint_i = 0; waypoint_i < trail.polyline.length; waypoint_i++) {
                north = Math.max(trail.polyline[waypoint_i][1], north);
                south = Math.min(trail.polyline[waypoint_i][1], south);
                east = Math.max(trail.polyline[waypoint_i][0], east);
                west = Math.min(trail.polyline[waypoint_i][0], west);
            }
            const path: google.maps.LatLngLiteral[] = [];
            trail.polyline.forEach((coords) => path.push({lat: coords[1], lng: coords[0]}));
            let pathColour = trail.color ?? 'gray';
            let pathWeight = 4;
            let pathOpacity = 1;

            const polyline: google.maps.Polyline = new google.maps.Polyline({
                path: path,
                map: map,
                strokeColor: pathColour,
                strokeWeight: pathWeight,
                strokeOpacity: pathOpacity
            });

            const polylineAnchor: google.maps.Marker = new google.maps.Marker({
                map: map,
                position: path[0],
                visible: false
            });


        }

        this.trailAreas[trailAreaIndex].boundsNorth = north;
        this.trailAreas[trailAreaIndex].boundsSouth = south;
        this.trailAreas[trailAreaIndex].boundsEast = east;
        this.trailAreas[trailAreaIndex].boundsWest = west;

        map.fitBounds({east, north, west, south}, 1);
    }

    /**
     * File selected for trails import handling
     */
    onImportFileSelected(files: FileList): void {
        this.selectedFile = this.fileService.onFileSelected(files, 'application/gpx+xml');
        if (this.selectedFile !== null) {
            $('#filename').val(this.selectedFile.name);
            const reader = new FileReader();
            reader.onload = () => {
                this.trailData = JSON.parse(reader.result.toString());
                this.parseAustrianTrailData($('#showDisqualified').is(':checked'));
            };
            reader.readAsText(this.selectedFile);
        } else {
            $('#filename').val('');
        }
    }


    /**
     * File selected for trails import handling
     */
    onImportGeoJSONFileSelected(files: FileList): void {
        console.log(files)
        this.selectedFile = this.fileService.onFileSelected(files, 'application/json');
        console.log(this.selectedFile)

        if (this.selectedFile !== null) {
            $('#filenameGeoJSON').val(this.selectedFile.name);
            const reader = new FileReader();
            reader.onload = () => {
                this.trailDataUK = JSON.parse(reader.result.toString());
                console.log(this.trailDataUK)

                this.parseUKTrailData($('#showDisqualifiedGeoJson').is(':checked'));
            };
            reader.readAsText(this.selectedFile);
        } else {
            $('#filenameGeoJSON').val('');
        }
    }

    parseAustrianTrailData(showDisqualified: boolean): void {
        this.parseDone = false;

        const COUNTRY = 'at';
        const IN_LANGUAGE = 'de';
        const LENGTH_TYPE = 'km';
        const COMMON_TRAIL = 0;
        const AUSTRIA_PROJECTION = '+proj=tmerc +lat_0=0 +lon_0=10.33333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +no_defs';
        const WGS84PROJECTION = '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees';

        this.trailAreas = [];
        for (let route_import_index = 0; route_import_index < this.trailData.features.length; route_import_index++) {

            // Sort out trails with multiple sections
            const teilen = this.trailData.features[route_import_index].geometry.paths.length;
            if (!showDisqualified) {
                if (teilen > 1) {
                    continue;
                }

                // Sort out Radwanderroute
                if (this.trailData.features[route_import_index].attributes.ROUTEN_TYP.indexOf('Radwanderroute') !== -1) {
                    continue;
                }
            }

            const trailAreaImportKey = this.trailData.features[route_import_index].attributes.GEMEINDE_NAME;

            let trailAreaIndex = this.getTrailAreaIndexFromImportKey(trailAreaImportKey);
            if (trailAreaIndex === null) {
                let trailAreaExists = false;

                for (let eAreaIndex = 0; eAreaIndex < this.existingTrailAreas.length; eAreaIndex++) {
                    const existingTrailArea = this.existingTrailAreas[eAreaIndex];
                    if (existingTrailArea.importKey === trailAreaImportKey) {
                        this.trailAreas.push(existingTrailArea);
                        this.trails[this.getTrailAreaIndexFromImportKey(existingTrailArea.importKey)] = [];
                        trailAreaExists = true;
                        break;
                    }
                }

                if (!trailAreaExists) {
                    const dateCreated = new Date(this.trailData.features[route_import_index].attributes.CREATETIMESTAMP);
                    const newTrailArea: TrailArea = {
                        key: null,
                        importKey: trailAreaImportKey,
                        country: COUNTRY,
                        region: this.trailData.features[route_import_index].attributes.BEZIRK_REGION,
                        name: trailAreaImportKey,
                        dateCreated: dateCreated.getFullYear() + '-' +
                            (dateCreated.getMonth() + 1).toString().padStart(2, '0') + '-' +
                            dateCreated.getDate().toString().padStart(2, '0')
                    };
                    newTrailArea.lang = {
                        [IN_LANGUAGE]: {
                            name: trailAreaImportKey
                        }
                    };
                    this.trailAreas.push(newTrailArea);
                }

                trailAreaIndex = this.trailAreas.length - 1;
            }

            for (let teil_import_index = 0; teil_import_index < teilen; teil_import_index++) {
                const routenNummer = this.trailData.features[route_import_index].attributes.ROUTENNUMMER;
                const trailImportKey = 'OID' + this.trailData.features[route_import_index].attributes.OBJECTID +
                    '-ROUTENNUMMER' + routenNummer + '-TEIL' + teil_import_index;

                let trailExists = false;
                let startPoint: GPSPoint;
                let polyline: Array<any> = [];
                let trailKey: string = null;
                let active = true;
                if (teilen > 1 || this.trailData.features[route_import_index].attributes.ROUTEN_TYP.indexOf('Radwanderroute') !== -1) {
                    trailKey = 'noImport';
                    active = false;
                } else {
                    for (let eTrailIndex = 0; eTrailIndex < this.existingTrails.length; eTrailIndex++) {
                        if (this.existingTrails[eTrailIndex].importKey === trailImportKey) {
                            trailExists = true;
                            startPoint = this.existingTrails[eTrailIndex].startPoint;
                            polyline = polyTool.decode(this.existingTrails[eTrailIndex].encodedPolyline, {factor: 1e6});
                            trailKey = this.existingTrails[eTrailIndex].key;
                            break;
                        }
                    }
                }

                if (!trailExists) {
                    const path = this.trailData.features[route_import_index].geometry.paths[teil_import_index];
                    for (let point_import_index = 0; point_import_index < path.length; point_import_index++) {
                        const waypoint = proj4(AUSTRIA_PROJECTION, WGS84PROJECTION, path[point_import_index]);
                        polyline.push(waypoint);
                    }
                    startPoint = {
                        latitude: polyline[0][1],
                        longitude: polyline[0][0]
                    };
                }

                const name = this.trailData.features[route_import_index].attributes.ROUTENNAME;
                const trail_name = name + (teilen > 1 ? ' - Route ' + routenNummer + ' Teil ' + (teil_import_index + 1) : '');
                // const trail_header = this.trailData.features[route_import_index].attributes.ROUTENSTART + ' - ' +
                //     this.trailData.features[route_import_index].attributes.ROUTENZIEL +
                //     ' - Route ' + routenNummer + (teilen > 1 ? ' Teil ' + (teil_import_index + 1) : '');

                const trailDifficulty: TrailDifficulty =
                    this.getDifficultyFromDEText(this.trailData.features[route_import_index].attributes.SCHWIERIGKEITSGRAD);
                const heightInc: string = (this.trailData.features[route_import_index].attributes.HM_BERGAUF !== null ?
                    this.trailData.features[route_import_index].attributes.HM_BERGAUF.toString() : null);
                const heightDec: string = (this.trailData.features[route_import_index].attributes.HM_BERGAB !== null ?
                    this.trailData.features[route_import_index].attributes.HM_BERGAB.toString() : null);

                const trailImport: TrailImport = <TrailImport>{
                    key: trailKey,
                    importKey: trailImportKey,
                    active: active,
                    name: trail_name,
                    description: this.trailData.features[route_import_index].attributes.ROUTENBESCHREIBUNG,
                    color: trailDifficulty.color,
                    iconUrl: trailDifficulty.iconUrl,
                    trackType: COMMON_TRAIL,
                    countrie: COUNTRY,
                    length: this.trailData.features[route_import_index].attributes.LAENGE_HAUPTROUTE_KM.toString(),
                    lengthType: LENGTH_TYPE,
                    heightIncrease: heightInc,
                    heightDecrease: heightDec,
                    maxHeight: Math.max(this.trailData.features[route_import_index].attributes.HOEHE_START,
                        this.trailData.features[route_import_index].attributes.HOEHE_ZIEL).toString(),
                    minHeight: Math.min(this.trailData.features[route_import_index].attributes.HOEHE_START,
                        this.trailData.features[route_import_index].attributes.HOEHE_ZIEL).toString(),
                    estimatedTime: this.trailData.features[route_import_index].attributes.FAHRZEIT,
                    polyline: polyline,
                    startPoint: startPoint,
                    status: '',
                    importVars: {
                        OBJECTID: this.trailData.features[route_import_index].attributes.OBJECTID,
                        OBJEKT: this.trailData.features[route_import_index].attributes.OBJEKT,
                        ROUTEN_TYP: this.trailData.features[route_import_index].attributes.ROUTEN_TYP,
                        GEMEINDE_NAME: trailAreaImportKey,
                        ROUTENNUMMER: routenNummer,
                        ROUTENNAME: name,
                        ROUTE_IMPORT_INDEX: route_import_index,
                        TEIL_IMPORT_INDEX: teil_import_index,
                        CREATEUSER: this.trailData.features[route_import_index].attributes.CREATEUSER,
                        CREATETIMESTAMP: this.trailData.features[route_import_index].attributes.CREATETIMESTAMP,
                        UPDATEUSER: this.trailData.features[route_import_index].attributes.UPDATEUSER,
                        UPDATETIMESTAMP: this.trailData.features[route_import_index].attributes.UPDATETIMESTAMP,
                        STAND: this.trailData.features[route_import_index].attributes.STAND
                    }
                };

                // this.trails[trailAreaIndex].push(trailImport);
            }
        }

        this.parseDone = true;
    }

    parseUKTrailData(showDisqualified: boolean): void {
        this.parseDone = false;

        const COUNTRY = 'gb';
        const IN_LANGUAGE = 'en';
        const LENGTH_TYPE = 'km';
        const COMMON_TRAIL = 0;

        let currentOsmId = "";
        this.trailAreas = [];
        this.trailDataUK.features.sort((a, b) => (a.properties.osm_id < b.properties.osm_id) ? -1 : 1)
        for (let route_import_index = 0; route_import_index < this.trailDataUK.features.length; route_import_index++) {
            let taName = "";
            let taRegion = "";

            let regionLookup = this.googleGeocodeService.lookupRegion(this.trailDataUK.features[route_import_index].geometry.coordinates[0][1].toString() + "," + this.trailDataUK.features[route_import_index].geometry.coordinates[0][0].toString(), "en-GB");
            regionLookup.subscribe((region) => {
                    if (region !== null) {
                        taRegion = region.regionName;
                        console.log(region);
                        let cityLookup = this.googleGeocodeService.lookupCity(this.trailDataUK.features[route_import_index].geometry.coordinates[0][1].toString() + "," + this.trailDataUK.features[route_import_index].geometry.coordinates[0][0].toString(), "en-GB");
                        cityLookup.subscribe((city) => {
                                if (city !== null) {
                                    taName = city.city;
                                    console.log(city);
                                    let featureProperties = this.trailDataUK.features[route_import_index].properties;
                                    const trailAreaImportKey = 'osm_id' + featureProperties.osm_id.toString();
                                    let trailAreaIndex = this.getTrailAreaIndexFromImportKey(trailAreaImportKey);

                                    if (trailAreaImportKey !== currentOsmId) {
                                        currentOsmId = trailAreaImportKey

                                        if (trailAreaIndex === null) {
                                            let trailAreaExists = false;

                                            // check if trail area already exists in db
                                            for (let eAreaIndex = 0; eAreaIndex < this.existingTrailAreas.length; eAreaIndex++) {
                                                const existingTrailArea = this.existingTrailAreas[eAreaIndex];
                                                if (existingTrailArea.importKey === trailAreaImportKey) {
                                                    this.trailAreas.push(existingTrailArea);
                                                    this.trails[this.getTrailAreaIndexFromImportKey(existingTrailArea.importKey)] = [];
                                                    trailAreaExists = true;
                                                    break;
                                                }
                                            }

                                            // check if trail area has already been imported
                                            for (let eAreaIndex = 0; eAreaIndex < this.trailAreas.length; eAreaIndex++) {
                                                const existingTrailArea = this.trailAreas[eAreaIndex];
                                                if (existingTrailArea.importKey === trailAreaImportKey) {
                                                    this.trails[this.getTrailAreaIndexFromImportKey(existingTrailArea.importKey)] = [];
                                                    trailAreaExists = true;
                                                    break;
                                                }
                                            }

                                            if (!trailAreaExists) {
                                                const dateCreated = formatDate(Date.now(), "dd-MM-yyyy", "da-DA");
                                                // handle special naming cases for trail areas
                                                if (featureProperties.name !== null && featureProperties.name.toLowerCase().startsWith("great north trail")) {
                                                    taName = featureProperties.name;
                                                }

                                                const newTrailArea: TrailArea = {
                                                    key: null,
                                                    importKey: trailAreaImportKey,
                                                    country: COUNTRY,
                                                    region: taRegion,
                                                    name: taName,
                                                    imageUrl: "https://firebasestorage.googleapis.com/v0/b/sweltering-inferno-5571.appspot.com/o/mediaLibrary%2FdefaultImages%2Fmeetup_bg.png?alt=media&token=af69f1d5-057f-44c2-848d-7221cb152b6d",
                                                    dateCreated: dateCreated
                                                };
                                                newTrailArea.lang = {
                                                    [IN_LANGUAGE]: {
                                                        name: taName
                                                    }
                                                };
                                                this.trailAreas.push(newTrailArea);
                                                console.log(newTrailArea)
                                            }

                                            trailAreaIndex = this.trailAreas.length - 1;
                                        }
                                    }

                                    const trailImportKey = this.trailDataUK.features[route_import_index].id;

                                    let trailExists = false;
                                    let startPoint: GPSPoint;
                                    let stopPoint: GPSPoint;
                                    let polyline: Array<any> = [];
                                    let trailKey: string = null;
                                    let active = true;

                                    for (let eTrailIndex = 0; eTrailIndex < this.existingTrails.length; eTrailIndex++) {
                                        if (this.existingTrails[eTrailIndex].importKey === trailImportKey) {
                                            trailExists = true;
                                            startPoint = this.existingTrails[eTrailIndex].startPoint;
                                            polyline = polyTool.decode(this.existingTrails[eTrailIndex].encodedPolyline, {factor: 1e6});
                                            trailKey = this.existingTrails[eTrailIndex].key;
                                            break;
                                        }
                                    }

                                    if (!trailExists) {
                                        const path = this.trailDataUK.features[route_import_index].geometry.coordinates;
                                        for (let point_import_index = 0; point_import_index < path.length; point_import_index++) {
                                            polyline.push(path[point_import_index]);
                                        }
                                        startPoint = {
                                            latitude: polyline[0][1],
                                            longitude: polyline[0][0]
                                        };
                                        stopPoint = {
                                            latitude: polyline[polyline.length-1][1],
                                            longitude: polyline[polyline.length-1][0]
                                        };
                                    }

                                    let name = city.city;
                                    let description = "No description available.";
                                    let trailDifficulty = this.getDifficultyFromENText("purple")
                                    // extract trail name
                                    if (featureProperties.name !== null) {
                                        name = featureProperties.name;
                                        // handle special naming cases for trails
                                        if (featureProperties.name.toLowerCase().startsWith("great north trail")) {
                                            if (featureProperties.from !== null && featureProperties.to !== null) {
                                                const part = this.trailDataUK.features.filter(list => 'osm_id' + list.properties.osm_id.toString() === currentOsmId).indexOf(this.trailDataUK.features[route_import_index]) + 1;
                                                name = featureProperties.from + " to " + featureProperties.to + " part " + part;
                                            }
                                        }
                                        // extract difficulty from name attribute
                                        if (featureProperties.name.toLowerCase().includes("blue")) {
                                            trailDifficulty = this.getDifficultyFromENText("blue")
                                        }
                                        if (featureProperties.name.toLowerCase().includes("red")) {
                                            trailDifficulty = this.getDifficultyFromENText("red")
                                        }
                                        if (featureProperties.name.toLowerCase().includes("black")) {
                                            trailDifficulty = this.getDifficultyFromENText("black")
                                        }
                                    }
                                    // extract trail description
                                    if (featureProperties.description !== null) {
                                        description = featureProperties.description;
                                    }
                                    if (featureProperties.website !== null) {
                                        description = description + " Learn more, visit: " + featureProperties.website;
                                    }

                                    const trailImport: TrailImportGb = <TrailImportGb>{
                                        key: trailKey,
                                        importKey: trailImportKey,
                                        active: active,
                                        name: name,
                                        description: description,
                                        color: trailDifficulty.color,
                                        iconUrl: trailDifficulty.iconUrl,
                                        trackType: COMMON_TRAIL,
                                        countrie: COUNTRY,
                                        length: "0",
                                        lengthType: LENGTH_TYPE,
                                        heightIncrease: null,
                                        heightDecrease: null,
                                        maxHeight: "",
                                        minHeight: "",
                                        estimatedTime: "",
                                        polyline: polyline,
                                        startPoint: startPoint,
                                        stopPoint: stopPoint,
                                        imageUrl: "https://firebasestorage.googleapis.com/v0/b/sweltering-inferno-5571.appspot.com/o/mediaLibrary%2FdefaultImages%2Fmeetup_bg.png?alt=media&token=af69f1d5-057f-44c2-848d-7221cb152b6d",
                                        status: "",
                                        importVars: {
                                            osm_id: featureProperties.osm_id,
                                            access: featureProperties.access,
                                            bicycle: featureProperties.bicycle,
                                            foot: featureProperties.foot,
                                            highway: featureProperties.highway,
                                            name: featureProperties.name,
                                            oneway: featureProperties.oneway,
                                            operator: featureProperties.operator,
                                            ref: featureProperties.ref,
                                            route: featureProperties.route,
                                            smoothness: featureProperties.smoothness,
                                            surface: featureProperties.surface,
                                            tourism: featureProperties.tourism,
                                            tracktype: featureProperties.tracktype,
                                            trail_visibility: featureProperties.trail_visibility,
                                            width: featureProperties.width,
                                            z_order: featureProperties.z_order,
                                            way_area: featureProperties.way_area,
                                            distance: featureProperties.distance,
                                            from: featureProperties.from,
                                            to: featureProperties.to,
                                            incline: featureProperties.incline,
                                            ascent: featureProperties.ascent,
                                            descent: featureProperties.descent,
                                            description: featureProperties.description,
                                            unsigned: featureProperties.unsigned,
                                            website: featureProperties.website,
                                            network: featureProperties.network,
                                            roundtrip: featureProperties.roundtrip,
                                            wikidata: featureProperties.wikidata,
                                            source: featureProperties.source,
                                            cycleway: featureProperties.cycleway,
                                            designation: featureProperties.designation,
                                            addr_housename: featureProperties.addr_housename,
                                            mtb_scale: featureProperties.mtb_scale,
                                            mtb_scale_imba: featureProperties.mtb_scale_imba,
                                            mtb_scale_uphill: featureProperties.mtb_scale_uphill,
                                            mtb_type: featureProperties.mtb_type,
                                            mtb_description: featureProperties.mtb_description
                                        }
                                    };

                                    console.log(trailImport)

                                    if (this.trails[trailAreaIndex] == undefined) {
                                        this.trails[trailAreaIndex] = []
                                    }

                                    this.trails[trailAreaIndex].push(trailImport);
                                } else {
                                    taName = "Unknown Trail Area";
                                    console.log("city lookup was unsuccessful");
                                }
                            }
                        );
                    } else {
                        taRegion = "Unknown Region";
                        console.log("region lookup was unsuccessful");
                    }
                }
            );
        }
        this.parseDone = true;
    }

    mergeTrailAreasByCityName() : void {
        // set up to merge GB import data
        const mergeAreas = this.existingTrailAreas.filter(area => area.importKey.startsWith("osm_id"));
        this.mergedAreas = [];
        this.mergedTrails = [];
        this.oldAreasForDeletion = [];
        let currentAreaName = "";

        mergeAreas.sort((a, b) => (a.name < b.name) ? -1 : 1)
        for (let trailAreaIndex = 0; trailAreaIndex < mergeAreas.length; trailAreaIndex++) {
            let area: TrailArea = mergeAreas[trailAreaIndex];

            if (currentAreaName !== area.name) {
                currentAreaName = area.name;

                const dateCreated = formatDate(Date.now(), "dd-MM-yyyy", "da-DA");

                const newTrailArea: TrailArea = {
                    key: null,
                    country: area.country,
                    region: area.region,
                    name: area.name,
                    imageUrl: "https://firebasestorage.googleapis.com/v0/b/sweltering-inferno-5571.appspot.com/o/mediaLibrary%2FdefaultImages%2Fmeetup_bg.png?alt=media&token=af69f1d5-057f-44c2-848d-7221cb152b6d",
                    dateCreated: dateCreated
                };
                newTrailArea.lang = {
                    ['en']: {
                        name: area.name
                    }
                };

                this.mergedAreas.push(newTrailArea)
            }

            if (this.oldAreasForDeletion[this.mergedAreas.length -1] == undefined) {
                this.oldAreasForDeletion[this.mergedAreas.length -1] = []
            }
            this.oldAreasForDeletion[this.mergedAreas.length -1].push(area.key);

            const trailsInArea = this.existingTrails.filter(t => t.trailAreaKey === area.key);
            const areaIndex = this.mergedAreas.length-1;
            if (this.mergedTrails[areaIndex] == undefined) {
                this.mergedTrails[areaIndex] = []
            }
            trailsInArea.forEach(t => {
                this.mergedTrails[areaIndex].push(t);
            });
        }

        // check if already merged trail areas are missing map point
        this.newTrailAreaKeys = [];
        this.existingTrails.filter(t => t.importKey.startsWith("gb_line")).forEach(t => {
            let listAlreadyContainsKey = false;
            for (let i = 0; i < this.newTrailAreaKeys.length; i++) {
                if (t.trailAreaKey === this.newTrailAreaKeys[i]) {
                    listAlreadyContainsKey = true;
                }
            }
            if (!listAlreadyContainsKey) {
                this.newTrailAreaKeys.push(t.trailAreaKey);
            }
        });

        console.log("new areas found that can be updated in mapPointsWorld: " + this.newTrailAreaKeys.length);
        console.log(this.newTrailAreaKeys);
        console.log("trail areas eligible for merging: " + mergeAreas.length);
        console.log("trail areas after merging: " + this.mergedAreas.length);
        console.log("trails that can be made searchable: " + this.existingTrails.filter(t => t.importKey.startsWith("gb_line")).length);
        console.log(this.mergedAreas);
        console.log(this.mergedTrails);
        console.log(this.oldAreasForDeletion);
    }

    postProcessImportedData() : void {
        this.mergedAreas.forEach(area => {
            this.mergeImportedTrailAreas(area, this.mergedAreas.indexOf(area)).then(r => {
                if (r) {
                    this.oldAreasForDeletion[this.mergedAreas.indexOf(area)].forEach(key => {
                        this.trailAreaService.deleteTrailArea(key).then(() => {});
                    })
                }
            })
        })
    }

    importTrails(trailAreaToImport: TrailArea): Promise<boolean> {
        const trailAreaIndex: number = this.getTrailAreaIndexFromImportKey(trailAreaToImport.importKey);
        const trailsToImport = this.trails[trailAreaIndex];
        return this.createTrailArea(trailAreaToImport)
            .then((trailArea) => {
                this.trails[trailAreaIndex] = [];
                const trailsAddedPromises = [];
                for (const trailImport of trailsToImport) {
                    const trailInAreaRefPromise = this.createTrail(trailImport)
                        .then((trail) => {
                            this.trails[trailAreaIndex].push(trail);
                            return this.trailAreaService.addTrail(trail.key, trailArea);
                        });
                    trailsAddedPromises.push(trailInAreaRefPromise);
                }
                return Promise.all(trailsAddedPromises)
                    .then(() => {
                        // trailArea updated and trails reattached
                        this.trailAreas[trailAreaIndex] = trailArea;
                        console.log(trailArea.name + " imported")
                        return true;
                    });
            });
    }

    mergeImportedTrailAreas(trailAreaToImport: TrailArea, trailAreaIndex: number): Promise<boolean> {
        const trailsToMerge = this.mergedTrails[trailAreaIndex];
        return this.createTrailArea(trailAreaToImport)
            .then((trailArea) => {
                this.mergedTrails[trailAreaIndex] = [];
                const trailsAddedPromises = [];
                for (let i = 0; i < trailsToMerge.length; i++){
                    const trail = trailsToMerge[i];
                    this.addTrailToTrailArea(trail.key, trailArea).then(r =>{
                        this.mergedTrails[trailAreaIndex].push(trail)
                        trailsAddedPromises.push(r);
                    });
                }
                return Promise.all(trailsAddedPromises)
                    .then(() => {
                        // new trailArea created and trails reattached
                        this.mergedAreas[trailAreaIndex] = trailArea;
                        console.log(trailArea.name + " merged.")
                        return true;
                    });
            });
    }

    addTrailToTrailArea(trailKey: string, trailArea: TrailArea) : Promise<any> {
        const trailUpdatedPromise = this.trailService.setTrailAreaKey(trailKey, trailArea.key);
        const trailAreaUpdatedPromise = this.trailAreaService.addTrail(trailKey, trailArea);
        return Promise.all([trailUpdatedPromise, trailAreaUpdatedPromise])
            .then(r => {
                return r;
            })
            .catch((err) => {
                console.error(err);
                console.log('An error occurred while attaching trail: ' + trailKey + ' to area: ' + trailArea.key + '. Check the console for stacktrace' );
            });
    }

    makeTrailsSearchable() : void {
        this.existingTrails.filter(t => t.importKey.startsWith("gb_line")).forEach(t => {
            // set of trigger in cloud functions by changing name and update the text
            const tName = t.name;
            t.name = tName.replace("*", "");
            this.trailService.updateTexts(t).then(() => {
                console.log(t.name + " made searchable.");
            });
            // t.name = tName;
            // this.trailService.updateTexts(t).then(() => {});
        });
    }

    ensureTrailAreaHasMapPoint() : void {
        // set of trigger in cloud functions by changing name and update the text
        this.trailAreaService.getTrailAreas(this.newTrailAreaKeys).pipe(take(1)).subscribe(areas => {
            for (let i = 0; i < areas.length; i++) {
                areas[i].name = areas[i].name.replace("*", "");
                this.trailAreaService.updateTexts(areas[i]).then(() => {
                    this.trailAreaService.doesTrailAreaHaveMapPoint(areas[i].key).subscribe((hasMapPoint) => {
                        if (!hasMapPoint) {
                            console.log(areas[i].name + " does not have map point.");
                        } else console.log(areas[i].name + " up to date.");
                    });
                });
            }
        });
    }

    disableImportButton(): boolean {
        return $(this).closest('table').find(':checkbox').length === 0;
    }

    private getDifficultyFromDEText(de_text: string): TrailDifficulty {
        for (let d = 0; d < this.mapDifficulty.length; d++) {
            if (this.mapDifficulty[d].de_text === de_text) {
                return this.mapDifficulty[d];
            }
        }
        console.error('Missing difficulty ID for ' + de_text);
    }

    private getDifficultyFromENText(en_text: string): TrailDifficulty {
        for (let d = 0; d < this.mapDifficulty.length; d++) {
            if (this.mapDifficulty[d].en_text === en_text) {
                return this.mapDifficulty[d];
            }
        }
        console.error('Missing difficulty ID for ' + en_text);
    }

    private getTrailAreaIndexFromImportKey(importKey: string): number {
        if (this.trailAreas === null) {
            return null;
        }
        for (let ta = 0; ta < this.trailAreas.length; ta++) {
            if (this.trailAreas[ta].importKey === importKey) {
                return ta;
            }
        }
        return null;
    }

    private createTrailArea(areaDraft: TrailArea): Promise<TrailArea> {
        if (areaDraft.key === null) {
            console.log('Importing trail area as new: ' + areaDraft.name, areaDraft);
            areaDraft.key = this.trailAreaService.createTrailAreaFromDraft(areaDraft);
            return this.roleService.assignUserRole(this.authService.user.userID, this.roles.TRAIL_AREA_MANAGER, this.authService.user.userID)
                .then(() => {
                    return this.trailAreaManagerService.addTrailAreaToTrailAreaManager(this.authService.user.userID, areaDraft.key).then(() => areaDraft);
                });
        } else {
            console.log(areaDraft.name + ' trail area already exist.', areaDraft);
            return Promise.resolve(areaDraft);
        }
    }

    private createTrail(trail: TrailImportGb): Promise<TrailImportGb> {
        if (trail.active && trail.key === null) {
            console.log('Importing trail: ' + trail.name, trail);
            return this.trailService.createTrailFromDraftWithPolyline(trail);
        } else {
            console.log('Skipping import of trail: ' + trail.name, trail);
            return Promise.resolve(trail);
        }
    }

    public importAllTrails() {
        this.trailAreas.forEach(it => this.importTrails(it));
    }
}
