import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '../../core/layout.service';
import { CountryService } from '../../firebase-services/country.service';
import { EventService } from '../../firebase-services/event.service';

// Interfaces
import { Country } from '../../interfaces/countries';
import { Event, EVENT_TINT_COLOR } from '../../interfaces/event';
import { CreateOnMap } from '../../interfaces/map';
import {AuthService} from "../../core/auth.service";

@Component({
    selector: 'app-country-events',
    templateUrl: './country-events.component.html',
    styleUrls: ['./country-events.component.css']
})
export class CountryEventsComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    country: Country = null;
    events: Event[] = null;
    routerRoot: string;

    constructor(
        public layout: LayoutService,
        private route: ActivatedRoute,
        private router: Router,
        private countryService: CountryService,
        private eventService: EventService,
        private authService: AuthService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((event) => {
                // If it is a NavigationEnd event re-initalise the component
                if (event instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    init() {
        this.country = null;
        this.events = null;
        this.countryService.getCountry(this.route.snapshot.paramMap.get('countryCode'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((country) => {
                this.eventService.getEvents(country.events)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((events) => this.events = events);
                this.routerRoot = '/country-manager/' + country.countryCode;
                this.country = country;
            });
    }

    createNewEvent(newEventOnMap: CreateOnMap): void {
        var newEvent = <Event>{
            name: newEventOnMap.name,
            latitude: newEventOnMap.lat,
            longitude: newEventOnMap.lng,
            geohash: newEventOnMap.geohash,
            tintColor: EVENT_TINT_COLOR,
            creatorKey: this.authService.user.userID
        };
        var newKey = this.eventService.createNewEvent(newEvent);
        // Fire and forget
        this.countryService.addEventToCountry(this.country.countryCode, newKey);

        this.router.navigate([this.routerRoot + '/events/' + newKey]);
    }
}
