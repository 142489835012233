import {AfterViewChecked, Component, EventEmitter, Input, Output} from '@angular/core';
import {take} from 'rxjs/operators';
import {Group} from "../../interfaces/group";
import {GroupService} from "../../firebase-services/group.service";

declare var $: any;

@Component({
    selector: 'app-find-group',
    templateUrl: './find-group.component.html',
    styleUrls: ['./find-group.component.css']
})
export class FindGroupComponent implements AfterViewChecked {
    @Input() modalId: string;
    @Input() modalTitle: string;
    @Input() btnClassType: string;
    @Output() groupSelected = new EventEmitter<string>();

    SEARCH_TIMEOUT = 1000;
    searchTimeout: number;
    lastSearchTerm = '';
    disableSearch = false;
    searchInput: HTMLInputElement = null;
    foundGroups: Group[] = [];

    constructor(
        private groupService: GroupService
    ) {
    }

    ngAfterViewChecked() {
        if (this.searchInput === null) {
            this.searchInput = <HTMLInputElement>document.getElementById('searchForGroups' + this.modalId);
        }
    }

    focusInSearch(): void {
        window.setTimeout(() => this.searchInput.focus(), 500);
    }

    searchForGroups(): void {
        window.clearTimeout(this.searchTimeout);
        const searchTerm: string = this.searchInput.value;
        if (searchTerm === this.lastSearchTerm || searchTerm.length < 3) {
            return;
        }
        this.searchTimeout = window.setTimeout(() => this.startDatabaseSearch(searchTerm), this.SEARCH_TIMEOUT);
    }

    startDatabaseSearch(searchTerm: string): void {
        if (this.disableSearch) {
            return;
        }
        this.disableSearch = true;
        this.lastSearchTerm = searchTerm;
        const groups$ = this.groupService.searchByName(searchTerm);
        groups$
            .pipe(take(1))
            .subscribe((groups) => {
                this.foundGroups = groups;
                this.disableSearch = false;
                window.setTimeout(() => this.searchInput.focus(), 0);
            });
    }

    selectGroup(groupKey: string): void {
        this.groupSelected.emit(groupKey);
        $('#findGroupModal_' + this.modalId).modal('hide');
    }

    copyText(text: string): Promise<void> {
        return navigator.clipboard.writeText(text);
    }
}
