<div class="content-wrapper">
    <app-content-header-section contentPageId="poiManagers" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3" *ngIf="authService.isUser(roles.ADMIN)">
                    <div class="card card-outline card-warning">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-user-plus"></i> Assign POI Manager</h3>
                        </div>
                        <div class="card-body">
                            <app-alert-info-box infoTrigger="{{ state }}">
                                <h4>PoiManager saved successfully</h4>
                            </app-alert-info-box>
                            <div class="form-group">
                                <label for="selectPoiManager"><i class="fas fa-user"></i> Select User</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <app-find-user modalId="selectPoiManagerUser"
                                                           modalTitle="Find user to assign as poi manager"
                                                           id="selectPoiManager"
                                                           (userSelected)="onUserSelected($event)"
                                                           btnClassType="smaller custom-btn">
                                                <i title="Search for user" class="fas fa-search"></i>
                                            </app-find-user>
                                        </span>
                                    </div>
                                    <input id="selectedUserText" aria-describedby="userHelp"
                                           class="form-control custom-input" value="{{ selectedUserName }}"
                                           placeholder="No user selected" readonly>
                                </div>
                                <small class="form-text text-muted" id="selectUserHelp">Select a user to assign as
                                    poiManager.</small>
                            </div>
                            <div class="form-group">
                                <label for="selectType"><i class="fas fa-list"></i> Select Type</label>
                                <select id="selectType" class="form-control" [(ngModel)]="selectedType">
                                    <option value="country">Country</option>
                                    <option value="destination">Destination</option>
                                    <option value="trailArea">Trail Area</option>
                                </select>
                            </div>
                            <div class="form-group" *ngIf="selectedType === 'country'">
                                <label for="selectCountry"><i class="fas fa-route"></i> Select Country</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <app-find-country modalId="selectCountryEm"
                                                              modalTitle="Find country to assign to poi manager"
                                                              id="selectCountryEm"
                                                              (countrySelected)="onCountrySelected($event)"
                                                              btnClassType="smaller custom-btn">
                                                <i title="Search for country" class="fas fa-search"></i>
                                            </app-find-country>
                                        </span>
                                    </div>
                                    <input id="selectedCountryText" aria-describedby="countryHelp"
                                           class="form-control custom-input" value="{{ selectedCountryName }}"
                                           placeholder="No country selected" readonly>
                                </div>
                                <small class="form-text text-muted" id="selectCountryHelp">Select a country to assign to
                                    an poiManager.</small>
                            </div>
                            <div class="form-group" *ngIf="selectedType === 'destination'">
                                <label for="selectDestination"><i class="fas fa-route"></i> Select Destination</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <app-find-destination modalId="selectDestinationEm"
                                                                  modalTitle="Find destination to assign to poi manager"
                                                                  id="selectDestinationEm"
                                                                  (destinationSelected)="onDestinationSelected($event)"
                                                                  btnClassType="smaller custom-btn">
                                                <i title="Search for destination" class="fas fa-search"></i>
                                            </app-find-destination>
                                        </span>
                                    </div>
                                    <input id="selectedDestinationText" aria-describedby="destinationHelp"
                                           class="form-control custom-input" value="{{ selectedDestinationName }}"
                                           placeholder="No destination selected" readonly>
                                </div>
                                <small class="form-text text-muted" id="selectDestinationHelp">Select a destination to assign to
                                    an poiManager.</small>
                            </div>
                            <div class="form-group" *ngIf="selectedType === 'trailArea'">
                                <label for="selectTrailArea"><i class="fas fa-route"></i> Select Trail Area</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <app-find-trail-area modalId="selectTrailAreaEm"
                                                                 modalTitle="Find trail area to assign to poi manager"
                                                                 id="selectTrailAreaEm"
                                                                 (areaSelected)="onAreaSelected($event)"
                                                                 btnClassType="smaller custom-btn">
                                                <i title="Search for trail area" class="fas fa-search"></i>
                                            </app-find-trail-area>
                                        </span>
                                    </div>
                                    <input id="selectedAreaText" aria-describedby="areaHelp"
                                           class="form-control custom-input" value="{{ selectedTrailAreaName }}"
                                           placeholder="No trail area selected" readonly>
                                </div>
                                <small class="form-text text-muted" id="selectAreaHelp">Select a trail area to assign to
                                    an
                                    poiManager.</small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="saveRoleDisabled" type="submit" class="btn btn-primary"
                                    (click)="assignPoiManager()">Save
                            </button>
                            <button type="reset" class="btn btn-default discard-btn"
                                    (click)="resetAssignPoiManager()">
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-user-plus"></i> POI Managers
                            </h3>
                        </div>
                        <div class="card-body px-0">
                            <table *ngIf="allIsLoaded" id="poiManagersDataTable" class="table">
                                <thead>
                                <tr>
                                    <th>Manager</th>
                                    <th>Countries</th>
                                    <th>Destinations</th>
                                    <th>Trail Areas</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let manager of poiManagerListItems">
                                    <td>
                                        <img class="img-fluid img-circle img-sm"
                                             src="{{ manager.user.userPicture }}" alt="">&nbsp;
                                        <strong>{{ manager.user.name }}</strong>
                                        <div class="mt-3" *ngIf="authService.isUser(roles.ADMIN)">
                                            <app-find-country modalId="findCountryFor{{manager.user.userID}}"
                                                              modalTitle="Find country" id="selectCountry"
                                                              (countrySelected)="onCountryAdded($event, manager.user.userID)"
                                                              btnClassType="xs btn-success">
                                                <i class="fas fa-flag"></i> Add country
                                            </app-find-country>&nbsp;
                                            <app-find-destination modalId="findDestinationFor{{manager.user.userID}}"
                                                                  modalTitle="Find destination" id="selectDestination"
                                                                  (destinationSelected)="onDestinationAdded($event, manager.user.userID)"
                                                                  btnClassType="xs btn-success">
                                                <i class="fas fa-heart"></i> Add destination
                                            </app-find-destination>&nbsp;
                                            <app-find-trail-area modalId="findTrailAreaFor{{manager.user.userID}}"
                                                                 modalTitle="Find trail area" id="selectTrailArea"
                                                                 (areaSelected)="onAreaAdded($event, manager.user.userID)"
                                                                 btnClassType="xs btn-success">
                                                <i class="fas fa-route"></i> Add area
                                            </app-find-trail-area>&nbsp;
                                            <button class="btn btn-xs btn-danger"
                                                    (click)="revokePoiManager(manager.user.userID, manager.countries, manager.destinations, manager.trailAreas)">
                                                <i class="fas fa-trash"></i> Revoke manager
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <ul class="todo-list">
                                            <li *ngFor="let country of manager.countries">
                                                <span class="text-secondary">{{ country.name }}</span>&nbsp;
                                                <small class="badge badge-default">{{ country.countryCode }}</small>
                                                <div *ngIf="authService.isUser(roles.ADMIN)" class="tools">
                                                    <i title="Remove country from user"
                                                       (click)="onCountryRemoved(manager.user.userID, country.countryCode)"
                                                       class="far fa-trash-alt mr-2">
                                                    </i>
                                                </div>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul class="todo-list">
                                            <li *ngFor="let destination of manager.destinations">
                                                <span class="text-secondary">{{ destination.name }}</span>&nbsp;
                                                <small class="badge badge-default">{{destination.key }}</small>
                                                <div *ngIf="authService.isUser(roles.ADMIN)" class="tools">
                                                    <i title="Remove destination from user"
                                                       (click)="onDestinationRemoved(manager.user.userID, destination.key)"
                                                       class="far fa-trash-alt mr-2">
                                                    </i>
                                                </div>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul class="todo-list">
                                            <li *ngFor="let area of manager.trailAreas">
                                                <span class="text-secondary">{{ area.name }}</span>&nbsp;
                                                <small class="badge badge-danger">{{ area.country }}</small>
                                                <small class="badge badge-default">{{ area.key }}</small>
                                                <div *ngIf="authService.isUser(roles.ADMIN)" class="tools">
                                                    <i title="Remove trail area from user"
                                                       (click)="onAreaRemoved(manager.user.userID, area.key)"
                                                       class="far fa-trash-alt mr-2">
                                                    </i>
                                                </div>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>