import {Component} from '@angular/core';
import {Subject} from "rxjs";
import {Country} from "../../../interfaces/countries";
import {AuthService} from "../../../core/auth.service";
import {CountryService} from "../../../firebase-services/country.service";
import {takeUntil} from "rxjs/operators";
import {CountryManagersService} from "./country-managers.service";
import {CountryManagers} from "../../../interfaces/country-manager";
import {RoleService} from "../../../core/role.service";
import {Roles} from "../../../interfaces/role";

@Component({
    selector: 'app-country-managers',
    templateUrl: './country-managers.component.html',
    styleUrl: './country-managers.component.css'
})
export class CountryManagersComponent {
    destroy$: Subject<boolean> = new Subject<boolean>();
    roles = Roles;
    countries: Country[] = [];
    countryManagers: CountryManagers = {};

    constructor(
        private authService: AuthService,
        private roleService: RoleService,
        private countryService: CountryService,
        private countryManagersService: CountryManagersService
    ) {
        this.countryService.getAllCountries()
            .pipe(takeUntil(this.destroy$))
            .subscribe((countries) => this.countries = countries);
        this.countryManagersService.getCountriesManagers()
            .pipe(takeUntil(this.destroy$))
            .subscribe((cList) => {
                this.countryManagers = cList;
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    addCountryManager(profileKey: string, countryCode: string): Promise<void> {
        return this.roleService.assignUserRole(profileKey, this.roles.COUNTRY_MANAGER, this.authService.user.userID).then(() => {
            return this.countryManagersService.addCountryManager(profileKey, countryCode);
        })
    }

    removeCountryManager(profileKey: string, countryCode: string): Promise<void> {
        let revokeRole = true;
        for (const country in this.countryManagers) {
            if (country !== countryCode) {
                this.countryManagers[countryCode].forEach((user) => {
                    if (user.userID === profileKey) {
                        revokeRole = false;
                    }
                });
            }
        }
        return this.countryManagersService.removeCountryManager(profileKey, countryCode).then(() => {
            console.log(revokeRole);
            if (revokeRole) {
                return this.roleService.revokeUserRole(profileKey, this.roles.COUNTRY_MANAGER);
            }
        }).catch((err) => console.log(err));
    }

    copyText(text: string): Promise<void> {
        return navigator.clipboard.writeText(text);
    }
}
