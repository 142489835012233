import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

// Services
import {AuthService} from '../../core/auth.service';
import {TrailAreaService} from '../../firebase-services/trail-area.service';
import {CountryService} from '../../firebase-services/country.service';

// Interfaces
import {Country} from '../../interfaces/countries';
import {RoleService} from "../../core/role.service";
import {TrailAreaManagerService} from "../../root/role-manager/trail-area-managers/trail-area-manager.service";
import {Roles} from "../../interfaces/role";

@Component({
    selector: 'app-country-new-trail-area',
    templateUrl: './country-new-trail-area.component.html',
    styleUrls: ['./country-new-trail-area.component.css']
})
export class CountryNewTrailAreaComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    trailAreaName: string = '';
    countryCode: string;
    country: Country = null;

    disableCreate: boolean = true;

    roles = Roles;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private trailAreaService: TrailAreaService,
        private countryService: CountryService,
        private roleService: RoleService,
        private trailAreaManagerService: TrailAreaManagerService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initalise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    init() {
        this.countryCode = this.route.snapshot.paramMap.get('countryCode');
        this.countryService.getCountry(this.countryCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe((country) => {
                this.country = country;
            });
    }

    valueChanged() {
        this.disableCreate = !(this.countryCode && this.countryCode.length && this.trailAreaName && this.trailAreaName.length);
    }

    createTrailArea() {
        this.disableCreate = true;
        const newTrailAreaThenable = this.trailAreaService.createTrailArea(this.trailAreaName, this.countryCode);
        newTrailAreaThenable
            .then(() => {
                this.roleService.assignUserRole(this.authService.user.userID, this.roles.TRAIL_AREA_MANAGER, this.authService.user.userID)
                    .then(() => {
                        return this.trailAreaManagerService.addTrailAreaToTrailAreaManager(this.authService.user.userID, newTrailAreaThenable.key)
                    })
                    .catch((err) => alert('Sorry, an error occurred: ' + err));
            });
    }
}
