import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import firebase from 'firebase/compat';

// Services
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database';

// Interfaces
import { DraftPoi, PoiCluster, PublicPoi } from '../interfaces/poi';
import { PublicPoiCategory } from '../interfaces/poi-category';
import { FirebaseObjectService } from '../services/firebase-object.service';

@Injectable({
    providedIn: 'root'
})
export class PoiService {
    poisRef: firebase.database.Reference;
    publicPoisRef: firebase.database.Reference;
    countryToPoisRef: firebase.database.Reference;
    poiToCountriesRef: firebase.database.Reference;
    destinationToPoisRef: firebase.database.Reference;
    poiToDestinationsRef: firebase.database.Reference;
    trailAreaToPoisRef: firebase.database.Reference;
    poiToTrailAreasRef: firebase.database.Reference;
    

    constructor(
        private db: AngularFireDatabase
    ) {
        this.poisRef = this.db.database.ref('pois');
        this.publicPoisRef = this.db.database.ref('public').child('pois');
        this.countryToPoisRef = this.db.database.ref('countryToPois');
        this.poiToCountriesRef = this.db.database.ref('poiToCountriesAUTO');
        this.destinationToPoisRef = this.db.database.ref('destinationToPois');
        this.poiToDestinationsRef = this.db.database.ref('poiToDestinationsAUTO');
        this.trailAreaToPoisRef = this.db.database.ref('trailAreaToPois');
        this.poiToTrailAreasRef = this.db.database.ref('poiToTrailAreasAUTO');
    }

    static isBackendPoi(poi: DraftPoi | PublicPoi): poi is DraftPoi {
        return 'iconUrl' in poi;
    }

    static isPublicPoi(poi: DraftPoi | PublicPoi): poi is PublicPoi {
        return 'categoryIconUrls' in poi;
    }

    static getPoiClusterIcons(poi: DraftPoi, publicPoiCategories: { [label: string]: PublicPoiCategory }): string[] {
        const sortedSelectedIcons: string[] = [];
        if (poi.categories) {
            sortedSelectedIcons.push(poi.iconUrl);
            poi.categories.forEach((categoryForPoi) => {
                const iconUrl = publicPoiCategories[categoryForPoi.label].iconUrl;
                if (iconUrl !== poi.iconUrl) {
                    sortedSelectedIcons[categoryForPoi.sortOrder] = iconUrl;
                }
            });
        }
        return sortedSelectedIcons.filter((val) => val);
    }

    private static poiFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<DraftPoi>>): DraftPoi {
        try {
            if (!snapshot.payload.exists()) {
                console.log('POI doesn\'t exist anymore', snapshot.key);
                return null;
            }
            const poiVal: DraftPoi = snapshot.payload.val();
            poiVal.key = snapshot.key;
            if (!poiVal.lang) {
                poiVal.lang = {};
            }
            poiVal.categories ??= [];
            poiVal.latitude ??= 45.8326;
            poiVal.longitude ??= 6.8652;
            return poiVal;
        } catch (err) {
            console.error('Couldn\'t load POI', snapshot, err);
            return null;
        }
    }

    savePoiDraft(poi: DraftPoi, active: boolean): Promise<any> {
        poi.active = active;
        poi.latitude = +(Math.round(+(poi.latitude + 'e+7')) + 'e-7');
        poi.longitude = +(Math.round(+(poi.longitude + 'e+7')) + 'e-7');
        const preparedPoi = <DraftPoi>FirebaseObjectService.prepareSetObject(poi);
        const poiKey = preparedPoi.key;
        delete preparedPoi.key;
        return this.poisRef.child(poiKey).set(preparedPoi);
    }

    setActive(poiKey: string, active: boolean): Promise<any> {
        return this.poisRef.child(poiKey).child('active').set(active);
    }

    getPoisForTrailArea(trailAreaKey: string): Observable<DraftPoi[]> {
        return this.getPoiKeysForTrailArea(trailAreaKey)
            .pipe(
                switchMap((poiKeys) => this.getPois(poiKeys))
            );
    }

    getPoisForCountry(countryCode: string): Observable<DraftPoi[]> {
        return this.getPoiKeysForCountry(countryCode)
            .pipe(
                switchMap((poiKeys) => this.getPois(poiKeys))
            );
    }

    getPoisForDestination(destinationKey: string): Observable<DraftPoi[]> {
        return this.getPoiKeysForDestination(destinationKey)
            .pipe(
                switchMap((poiKeys) => this.getPois(poiKeys))
            );
    }


    getPois(poiKeys: string[]): Observable<DraftPoi[]> {
        if (poiKeys.length === 0) {
            return of([]);
        }
        return zip(...poiKeys.map((poiKey) => this.getPoi(poiKey)));
    }

    getPoi(poiKey: string): Observable<DraftPoi> {
        return this.db.object<DraftPoi>(this.poisRef.child(poiKey).ref).snapshotChanges()
            .pipe(
                map((poiSnap) => PoiService.poiFromSnap(poiSnap))
            );
    }

    getAllPois(): Observable<DraftPoi[]> {
        return this.db.list<DraftPoi>(`pois`).snapshotChanges()
            .pipe(
                map((poisSnap) => poisSnap.map(poi => <DraftPoi>PoiService.poiFromSnap(poi)))
            );
    }

    createCountryPoi(countryCode: string, newPoi: DraftPoi): Promise<string> {
        const poiThenableRef = this.poisRef.push(newPoi);
        const newPoiKey = poiThenableRef.key;
        return this.countryToPoisRef.child(countryCode).child(newPoiKey).set(true)
            .then(() => newPoiKey);
    }

    createDestinationPoi(destinationKey: string, newPoi: DraftPoi): Promise<string> {
        const poiThenableRef = this.poisRef.push(newPoi);
        const newPoiKey = poiThenableRef.key;
        return this.destinationToPoisRef.child(destinationKey).child(newPoiKey).set(true)
            .then(() => newPoiKey);
    }

    createTrailAreaPoi(trailAreaKey: string, newPoi: DraftPoi): Promise<string> {
        const poiThenableRef = this.poisRef.push(newPoi);
        const newPoiKey = poiThenableRef.key;
        return this.trailAreaToPoisRef.child(trailAreaKey).child(newPoiKey).set(true)
            .then(() => newPoiKey);
    }

    getPublicPoi(poiKey: string): Observable<PublicPoi> {
        return this.db.object<PublicPoi>(this.publicPoisRef.child(poiKey).ref).valueChanges();
    }

    getPoiIconUrl(poi: DraftPoi, reduceSize: boolean = false) {
        return {
            url: poi.iconUrl,
            scaledSize: new google.maps.Size(reduceSize ? 24 : 32, reduceSize ? 24 : 32)
        };
    }

    getPoiClusters(): Observable<PoiCluster[]> {
        return this.db.list<DraftPoi>(this.poisRef.ref).snapshotChanges()
            .pipe(
                map((snaps) => {
                    const poiIcons: { [mediaIconKey: string]: { [iconUrl: string]: DraftPoi[] } } = {};
                    snaps.forEach((snap) => {
                        const poi = PoiService.poiFromSnap(snap);
                        if (!poiIcons[poi.mediaIconKey]) {
                            poiIcons[poi.mediaIconKey] = {};
                        }
                        const poiIconUrl = poi.iconUrl || '*NO ICON*';
                        if (!poiIcons[poi.mediaIconKey][poiIconUrl]) {
                            poiIcons[poi.mediaIconKey][poiIconUrl] = [];
                        }
                        poiIcons[poi.mediaIconKey][poiIconUrl].push(poi);
                    });

                    const poiClusters: PoiCluster[] = [];
                    Object.keys(poiIcons).forEach((mediaIconKey) => {
                        Object.keys(poiIcons[mediaIconKey]).forEach((iconUrl) => {
                            const poiCluster: PoiCluster = {
                                iconUrl: iconUrl,
                                mediaIconKey: mediaIconKey,
                                amount: poiIcons[mediaIconKey][iconUrl].length,
                                pois: poiIcons[mediaIconKey][iconUrl]
                            };
                            poiClusters.push(poiCluster);
                        });
                    });
                    return poiClusters;
                })
            );
    }

    // migrateClusterToMediaIcon(cluster: PoiCluster, mediaIcon: MediaIcon): Promise<void> {
    //     const updatePromises: Promise<BackendPoi>[] = [];
    //     cluster.pois.forEach((poi) => {
    //         if (poi.mediaIconKey === cluster.mediaIconKey && poi.iconUrl === cluster.iconUrl) {
    //             poi.mediaIconKey = mediaIcon.key;
    //             poi.iconUrl = mediaIcon.pngUrl;
    //             updatePromises.push(this.savePoiDraft(poi));
    //         }
    //     });
    //     return Promise.all(updatePromises).then();
    // }

    // addCategoryToCluster(cluster: PoiCluster, label: string, sortOrder: number, subLabel?: string, subSortOrder?: number):
    // Promise<void> {
    //     const updatePromises: Promise<BackendPoi>[] = [];
    //     cluster.pois.forEach((poi) => {
    //         poi.categories.push({
    //             label: label,
    //             sortOrder: sortOrder,
    //             subLabel: subLabel || null,
    //             subSortOrder: subSortOrder ?? null
    //         });
    //         updatePromises.push(this.savePoiDraft(poi));
    //     });
    //     return Promise.all(updatePromises).then();
    // }

    addPoiToCountry(poiKey: string, countryCode: string): Promise<any> {
        return this.countryToPoisRef.child(countryCode).child(poiKey).set(true);
    }

    removePoiFromCountry(poiKey: string, countryCode: string): Promise<any> {
        return this.countryToPoisRef.child(countryCode).child(poiKey).remove();
    }

    getPoiCountForCountry(countryCode: string): Observable<number> {
        return this.getPoiKeysForCountry(countryCode)
            .pipe(map((keys) => keys.length));
    }

    private getPoiKeysForCountry(countryCode: string): Observable<string[]> {
        return this.db.list<boolean>(this.countryToPoisRef.child(countryCode).ref).snapshotChanges()
            .pipe(
                map((list) => {
                    const poiKeys: string[] = [];
                    list.forEach((entry) => {
                        poiKeys.push(entry.key);
                    });
                    return poiKeys;
                })
            );
    }

    getCountryKeysForPoi(poiKey: string): Observable<string[]> {
        return this.db.list<boolean>(this.poiToCountriesRef.child(poiKey).ref).snapshotChanges()
            .pipe(
                map((list) => {
                    const countryCode: string[] = [];
                    list.forEach((entry) => {
                        countryCode.push(entry.key);
                    });
                    return countryCode;
                })
            );
    }

    addPoiToDestination(poiKey: string, destinationKey: string): Promise<any> {
        return this.destinationToPoisRef.child(destinationKey).child(poiKey).set(true);
    }

    removePoiFromDestination(poiKey: string, destinationKey: string): Promise<any> {
        return this.destinationToPoisRef.child(destinationKey).child(poiKey).remove();
    }

    getPoiCountForDestination(destinationKey: string): Observable<number> {
        return this.getPoiKeysForDestination(destinationKey)
            .pipe(map((keys) => keys.length));
    }

    private getPoiKeysForDestination(destinationKey: string): Observable<string[]> {
        return this.db.list<boolean>(this.destinationToPoisRef.child(destinationKey).ref).snapshotChanges()
            .pipe(
                map((list) => {
                    const poiKeys: string[] = [];
                    list.forEach((entry) => {
                        poiKeys.push(entry.key);
                    });
                    return poiKeys;
                })
            );
    }

    getDestinationKeysForPoi(poiKey: string): Observable<string[]> {
        return this.db.list<boolean>(this.poiToCountriesRef.child(poiKey).ref).snapshotChanges()
            .pipe(
                map((list) => {
                    const destinationKey: string[] = [];
                    list.forEach((entry) => {
                        destinationKey.push(entry.key);
                    });
                    return destinationKey;
                })
            );
    }

    addPoiToTrailArea(poiKey: string, trailAreaKey: string): Promise<any> {
        return this.trailAreaToPoisRef.child(trailAreaKey).child(poiKey).set(true);
    }

    removePoiFromTrailArea(poiKey: string, trailAreaKey: string): Promise<any> {
        return this.trailAreaToPoisRef.child(trailAreaKey).child(poiKey).remove();
    }

    getPoiCountForTrailArea(trailAreaKey: string): Observable<number> {
        return this.getPoiKeysForTrailArea(trailAreaKey)
            .pipe(map((keys) => keys.length));
    }

    private getPoiKeysForTrailArea(trailAreaKey: string): Observable<string[]> {
        return this.db.list<boolean>(this.trailAreaToPoisRef.child(trailAreaKey).ref).snapshotChanges()
            .pipe(
                map((list) => {
                    const poiKeys: string[] = [];
                    list.forEach((entry) => {
                        poiKeys.push(entry.key);
                    });
                    return poiKeys;
                })
            );
    }

    getTrailAreaKeysForPoi(poiKey: string): Observable<string[]> {
        return this.db.list<boolean>(this.poiToTrailAreasRef.child(poiKey).ref).snapshotChanges()
            .pipe(
                map((list) => {
                    const trailAreaKeys: string[] = [];
                    list.forEach((entry) => {
                        trailAreaKeys.push(entry.key);
                    });
                    return trailAreaKeys;
                })
            );
    }

}
