import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';

// Services
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database';

// Interfaces
import { ExposureType, MINIMUM_TIME_SLOT_MILLISECONDS, Treat, TreatExposure, TreatHit, TreatsTabTexts } from '../interfaces/treat';

import firebase from 'firebase/compat';
import * as moment from 'moment';
import { TextObject } from '../interfaces/text-model';

@Injectable({
    providedIn: 'root'
})
export class TreatService {

    treatsRef: firebase.database.Reference;
    treatHitRef: firebase.database.Reference;
    treatExposureRef: firebase.database.Reference;
    treatsTabTextsRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase
    ) {
        this.treatsRef = this.db.database.ref('treat');
        this.treatHitRef = this.db.database.ref('treatHit');
        this.treatExposureRef = this.db.database.ref('treatExposure');
        this.treatsTabTextsRef = this.db.database.ref('texts').child('treatsTab');
    }

    private static treatSnapshotToTreat(snapshot: AngularFireAction<DatabaseSnapshot<Treat>>): Treat {
        const treat = {
            key: snapshot.key,
            ...snapshot.payload.val()
        };
        if (typeof treat.lang !== 'object' || treat.lang === null) {
            treat.lang = {};
        }
        return treat;
    }

    /**
     * Gets a specific treat from key
     */
    getTreat(treatKey: string): Observable<Treat> {
        return this.db.object<Treat>(`treat/${treatKey}`).snapshotChanges().pipe(
            map((rawTreat) => TreatService.treatSnapshotToTreat(rawTreat))
        );
    }

    /**
     * Gets all treats without statistics
     */
    getTreats(): Observable<Treat[]> {
        return this.db.list<Treat>(`treat`).snapshotChanges().pipe(
            take(1),
            map((rawTreats) => rawTreats.map((rawTreat) => TreatService.treatSnapshotToTreat(rawTreat)))
        );
    }

    enhanceTreatWithStatistics(treat: Treat): Observable<Treat> {
        const hitsObservable = this.db.list<TreatHit>(this.treatHitRef.child(treat.key).ref).valueChanges();
        const exposuresObservable = this.db.list<TreatExposure>(this.treatExposureRef.child(treat.key).ref).snapshotChanges();
        return zip(hitsObservable, exposuresObservable).pipe(
            take(1),
            map(([hits, exposureSnaps]) => {
                const exposures: { [key: string]: TreatExposure } = {};
                exposureSnaps.forEach((exposureSnap) => {
                    exposures[exposureSnap.key] = exposureSnap.payload.val();
                });
                treat.myTreatsExposures = 0;
                treat.topOfMindExposures = 0;
                treat.myTreatsHits = 0;
                treat.topOfMindHits = 0;
                Object.keys(exposures).forEach((exposureKey) => {
                    if (exposures[exposureKey].type === ExposureType.MYTREATS) {
                        treat.myTreatsExposures++;
                    } else {
                        treat.topOfMindExposures++;
                    }
                });
                hits.forEach((hit) => {
                    if (exposures[hit.treatExposureKey].type === ExposureType.MYTREATS) {
                        treat.myTreatsHits++;
                    } else {
                        treat.topOfMindHits++;
                    }
                });
                return treat;
            })
        );
    }

    /**
     * Updates the settings of the Treat
     */
    updateSettings(treat: Treat): Promise<void> {
        return this.treatsRef.child(treat.key).update(<Treat>{
            activeFrom: treat.activeFrom || 0,
            activeTo: treat.activeTo || (treat.activeFrom + 900000) || 900000,
            alias: treat.alias || null,
            targetTopOfMind: treat.targetTopOfMind || 0,
            targetMyTreats: treat.targetMyTreats || 0
        });
    }

    /**
     * Updates the image of the Treat
     */
    uploadedImage(treat: Treat): Promise<void> {
        return this.treatsRef.child(treat.key).update(<Treat>{
            imageURL: treat.imageURL
        });
    }

    /**
     * Updates the texts of the Treat
     */
    updateTexts(treat: Treat): Promise<void> {
        // Delete texts that are blank
        Object.keys(treat.lang).forEach((lang) => {
            Object.keys(treat.lang[lang]).forEach((text) => {
                if (!treat.lang[lang][text] || treat.lang[lang][text] === 'undefined') {
                    treat.lang[lang][text] = null;
                }
            });
        });

        const updateElement = <Treat>{
            topOfMindTitle: treat.topOfMindTitle,
            topOfMindSubHeader: treat.topOfMindSubHeader || null,
            link: treat.link || null,
            lang: treat.lang
        };
        return this.treatsRef.child(treat.key).update(updateElement);
    }

    /**
     * Copy treat
     * @todo: Copy image
     */
    copyTreat(treat: Treat): Promise<string> {
        const activeFrom: number = Math.ceil(Math.max(treat.activeTo, moment.now()) / MINIMUM_TIME_SLOT_MILLISECONDS)
            * MINIMUM_TIME_SLOT_MILLISECONDS;
        const activeTo: number = Math.ceil(
            (activeFrom + Math.max(Math.abs(treat.activeTo - treat.activeFrom), MINIMUM_TIME_SLOT_MILLISECONDS))
            / MINIMUM_TIME_SLOT_MILLISECONDS) * MINIMUM_TIME_SLOT_MILLISECONDS;
        const copy: Treat = {
            alias: (treat.alias || (treat.topOfMindTitle.substr(0, 10) + '...')) + ' copy',
            topOfMindTitle: treat.topOfMindTitle,
            topOfMindSubHeader: treat.topOfMindSubHeader,
            link: treat.link,
            lang: treat.lang,
            activeFrom: activeFrom,
            activeTo: activeTo,
            targetMyTreats: treat.targetMyTreats,
            targetTopOfMind: treat.targetTopOfMind
        };
        const thenablePromise = this.treatsRef.push(copy);
        return thenablePromise
            .then(() => thenablePromise.key);
    }

    getTreatsTabTexts(): Observable<TreatsTabTexts> {
        return this.db.object<TreatsTabTexts>(this.treatsTabTextsRef.ref).valueChanges();
    }

    updateTreatsTabTexts(treatsTabTexts: TextObject): Promise<void> {
        // Delete texts that are blank
        for (const lang in treatsTabTexts.lang) {
            if (typeof treatsTabTexts.lang[lang] === 'object') {
                for (const text in treatsTabTexts.lang[lang]) {
                    if (!treatsTabTexts.lang[lang][text] || treatsTabTexts.lang[lang][text] === 'undefined') {
                        treatsTabTexts.lang[lang][text] = null;
                    }
                }
            }
        }

        const updateElement = <TextObject>{
            title: treatsTabTexts.title,
            description: treatsTabTexts.description || null,
            lang: treatsTabTexts.lang
        };
        return this.treatsTabTextsRef.update(updateElement);
    }
}
