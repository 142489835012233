// environment
import { environment } from '../../../environments/environment';

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions/';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

import * as PolygonTools from 'polygon-tools';

// Services
import { CollectionService } from '../../firebase-services/collection.service';
import { CountryService } from '../../firebase-services/country.service';
import { TrailAreaService } from '../../firebase-services/trail-area.service';
import { FileService } from '../../services/file.service';

// Interfaces
import { DefaultMapProp } from '../../interfaces/map';
import { Country } from '../../interfaces/countries';
import { LatLngArray, MappedTrailAreaShapes, SeedObject } from '../../interfaces/trailArea';

@Component({
    selector: 'app-voronoi',
    templateUrl: './voronoi.component.html',
    styleUrls: ['./voronoi.component.css']
})
export class VoronoiComponent implements OnInit, OnDestroy {
    @ViewChild('countryBoundsMap') countryBoundsMapElement: ElementRef;

    destroy$: Subject<boolean> = new Subject<boolean>();

    /**
     * States:
     * 0: Select country
     * 1: Country selected: Adjust bounds and select a file
     * 2: Import file selected (spinner-state)
     * 3: Country file imported: Ready to store
     * 4: Country shaped stored (or skipped): Recalculate trail area shapes?
     * 5: Trail area shapes are being generated for country
     */
    status = 0;

    countries: Country[] = [];
    countryCodesNeedingUpdate: string[] = [];
    selectedCountry: Country = null;

    // Regions is a WIP project
    // regions: { [countryCode: string]: Region[] } = {};
    // selectedRegion: Region = null;

    bounds: google.maps.LatLngBoundsLiteral = null;
    landShapes: LatLngArray[] = null;

    pointWarningLimit = 5000;
    pointLimit = 20000; // or up to 150000, but it really does not make sense
    pointsToDescribe: number = null;

    seedObjects: SeedObject[] = null;
    currentTrailAreaCount = 0;
    collectionUpdatedTimestamp = 0;
    private trailAreasShapes: MappedTrailAreaShapes = null;

    private countryBoundsMap: google.maps.Map = null;
    private countryRectangle: google.maps.Rectangle = null;
    private infoWindow: google.maps.InfoWindow = null;
    private mapElements: (google.maps.Polygon | google.maps.Marker)[] = [];
    private GEOMETRIES_TYPE_POLYGON = 'Polygon';
    private GEOMETRIES_TYPE_MULTI_POLYGON = 'MultiPolygon';

    constructor(
        private fileService: FileService,
        private countryService: CountryService,
        private trailAreaService: TrailAreaService,
        private afFunctions: AngularFireFunctions,
        private collectionService: CollectionService
    ) {
    }

    ngOnInit() {
        this.trailAreaService.getCountryCodeNeedingVoronoiUpdate()
            .pipe(takeUntil(this.destroy$))
            .subscribe((countryCodesNeedingUpdate) => this.countryCodesNeedingUpdate = countryCodesNeedingUpdate);
        this.countryService.getCountries()
            .pipe(takeUntil(this.destroy$))
            .subscribe((countries) => this.countries = countries);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    reset() {
        if (this.countryRectangle !== null) {
            this.countryRectangle.setMap(null);
        }

        this.mapElements.forEach((mapCountryShape) => mapCountryShape.setMap(null));
        this.mapElements = [];

        this.selectedCountry = null;
        this.bounds = null;

        this.trailAreasShapes = null;
        this.seedObjects = null;

        // Regions is a WIP project
        // this.selectedRegion = null;

        this.status = 0;
    }

    skipToTrailAreaShapes() {
        this.status = 4;
        this.drawOnMap();
    }

    onSelectCountry() {
        this.landShapes = null;
        this.bounds = {
            east: this.selectedCountry.boundsEast,
            north: this.selectedCountry.boundsNorth,
            south: this.selectedCountry.boundsSouth,
            west: this.selectedCountry.boundsWest
        };
        const seedObservable = this.trailAreaService.getCountrySeedObjects(this.selectedCountry.countryCode)
            .pipe(take(1));
        const countryShapesObservable = this.countryService.loadCountryShapes(this.selectedCountry.countryCode)
            .pipe(take(1));
        const trailAreaShapesObservable = this.loadTrailAreaShapes();
        const updatedTimestampObservable = this.collectionService.getCollectionUpdated(this.selectedCountry.countryCode);

        // Regions is a WIP project
        // if (typeof this.regions[this.selectedCountry.countryCode] === 'undefined') {
        //     this.countryService.getRegionsInCountry(this.selectedCountry.countryCode)
        //         .pipe(take(1))
        //         .subscribe((regions) => this.regions[this.selectedCountry.countryCode] = regions);
        // }

        combineLatest([seedObservable, countryShapesObservable, trailAreaShapesObservable, updatedTimestampObservable])
            .subscribe(([seedObjects, countryShapes, trailAreaShapes, updateTimestamp]) => {
                if (Array.isArray(countryShapes) && countryShapes.length > 0) {
                    this.landShapes = countryShapes;
                }

                if (seedObjects.length === 0) {
                    window.alert('No trail areas in ' + this.selectedCountry.name);
                    console.log('No trail areas in ' + this.selectedCountry.name, seedObjects, trailAreaShapes);
                    // return this.reset();
                }
                this.seedObjects = seedObjects;
                this.trailAreasShapes = trailAreaShapes;
                this.currentTrailAreaCount = (trailAreaShapes ? Object.keys(trailAreaShapes).length : 0);
                console.log(this.seedObjects.length, this.currentTrailAreaCount, this.trailAreasShapes);

                this.collectionUpdatedTimestamp = updateTimestamp || 0;

                this.initMap();
            });
    }

    onImportFileSelected(files: FileList): void {
        this.landShapes = null;
        this.status = 2;

        const selectedFile: File = this.fileService.onFileSelected(files, 'application/json');
        if (selectedFile !== null) {
            const reader = new FileReader();
            reader.onload = () => {
                this.landShapes = this.parseGeoJSON(reader.result.toString());
                this.pointsToDescribe = 0;
                this.landShapes.forEach((landShape) => {
                    this.pointsToDescribe += landShape.length;
                });
                this.drawOnMap();
                this.status = 3;
            };
            reader.readAsText(selectedFile);
        }
    }

    storeCountryShapes(): Promise<void> {
        let result = null;
        if (this.pointsToDescribe <= this.pointWarningLimit || window.confirm('Country is very large. Continue anyway?')) {
            result = this.countryService.storeCountryShapes(this.selectedCountry.countryCode, this.landShapes)
                .then(() => this.status = 4);
        }
        return result;
    }

    generateShapes(): Promise<void> {
        this.status = 5;
        const collectionName: string = this.selectedCountry.countryCode;
        // Regions is a WIP project
        // const collectionName: string = (this.selectedRegion ? this.selectedRegion.key : this.selectedCountry.countryCode);

        console.log('Generating', collectionName, this.seedObjects.length);
        const clearChangesLogPromise = this.trailAreaService.createShapes(collectionName, this.seedObjects,
            this.bounds.north, this.bounds.south, this.bounds.east, this.bounds.west, this.landShapes)
            .then(() => this.trailAreaService.clearChangesLog(collectionName));

        return clearChangesLogPromise
            .then(() => this.updateTrailAreaShapeImages());
    }

    private initMap(): void {
        if (this.countryBoundsMap === null) {
            this.countryBoundsMap = new google.maps.Map(this.countryBoundsMapElement.nativeElement, DefaultMapProp);

            // Draw country square on map
            this.countryRectangle = new google.maps.Rectangle({
                clickable: false,
                draggable: false,
                zIndex: 10000
            });

            this.countryRectangle.addListener('bounds_changed', () => {
                this.bounds = this.countryRectangle.getBounds().toJSON();
            });

            // Info window
            this.infoWindow = new google.maps.InfoWindow({
                disableAutoPan: false,
                zIndex: 10001,
                content: 'blank'
            });
        }
        this.status = 1;
        this.drawOnMap();
    }

    private drawOnMap() {
        if (this.status < 3) {
            this.countryRectangle.setBounds(this.bounds);
            this.countryRectangle.setMap(this.countryBoundsMap);
            this.countryRectangle.setEditable(true);
        } else {
            this.countryRectangle.setMap(null);
        }
        this.countryBoundsMap.fitBounds(this.bounds, 1);

        this.mapElements.forEach((mapElement) => mapElement.setMap(null));
        this.mapElements = [];


        if (this.status < 4) {
            if (Array.isArray(this.landShapes)) {
                this.landShapes.forEach((landShape) => {
                    const landShapeArray: google.maps.LatLngLiteral[] = [];
                    landShape.forEach((shapePoint) => landShapeArray.push({lat: shapePoint[1], lng: shapePoint[0]}));
                    const polygon = new google.maps.Polygon({
                        clickable: false,
                        editable: false,
                        draggable: false,
                        strokeWeight: 1,
                        fillColor: '#00FF17',
                        fillOpacity: 0.7,
                        paths: landShapeArray,
                        map: this.countryBoundsMap
                    });
                    this.mapElements.push(polygon);
                });
            }

            if (Array.isArray(this.seedObjects)) {
                this.seedObjects.forEach((seedObject) => {
                    const areaMarker = new google.maps.Marker({
                        position: {
                            lat: seedObject.seedPoint[0],
                            lng: seedObject.seedPoint[1]
                        },
                        map: this.countryBoundsMap,
                        clickable: true,
                        draggable: false
                    });
                    areaMarker.addListener('click', () => {
                        let preContent = '';
                        if (this.trailAreasShapes && this.trailAreasShapes[seedObject.key]) {
                            preContent = '<div>Name: <strong>' + this.trailAreasShapes[seedObject.key].trailArea.name + '</strong></div>' +
                                '<div>Shapes: ' + this.trailAreasShapes[seedObject.key].shapes.length + '</div>';
                        }
                        this.infoWindow.setContent(preContent + '<div>Key: ' + seedObject.key + '</div>' +
                            '<div>Type:' + seedObject.type + '</div>' +
                            '<div>Pos: (' + seedObject.seedPoint[0] + ', ' + seedObject.seedPoint[1] + ')</div>');
                        this.infoWindow.open({
                            anchor: areaMarker
                        });
                    });
                    this.mapElements.push(areaMarker);
                });
            }
        } else {
            if (typeof this.trailAreasShapes === 'object' && this.trailAreasShapes !== null) {
                Object.values(this.trailAreasShapes).forEach((mappedTrailAreaShape) => {
                    const trailAreaAnchor = new google.maps.Marker({
                        map: this.countryBoundsMap,
                        position: {
                            lat: (mappedTrailAreaShape.trailArea.boundsNorth + mappedTrailAreaShape.trailArea.boundsSouth) / 2,
                            lng: (mappedTrailAreaShape.trailArea.boundsEast + mappedTrailAreaShape.trailArea.boundsWest) / 2
                        },
                        visible: false
                    });
                    const trailAreaPolygon = new google.maps.Polygon({
                        clickable: true,
                        map: this.countryBoundsMap,
                        draggable: false,
                        strokeWeight: 1,
                        fillColor: '#00FF17',
                        fillOpacity: 0.5,
                        paths: mappedTrailAreaShape.shapes,
                        editable: false,
                        strokeColor: '#FFFFFF',
                        zIndex: 1000
                    });
                    trailAreaPolygon.addListener('mouseover', () => {
                        this.infoWindow.setContent('<div>Name: <strong>' + mappedTrailAreaShape.trailArea.name + '</strong></div>' +
                            '<div>Shapes: ' + mappedTrailAreaShape.shapes.length + '</div>' +
                            '<div>Key: ' + mappedTrailAreaShape.trailArea.key + '</div>' +
                            '<img src="https://storage.googleapis.com/' + environment.firebase.storageBucket + '/areaConquered/' +
                            this.selectedCountry.countryCode + '/' + mappedTrailAreaShape.trailArea.key + '.png?u=' +
                            this.collectionUpdatedTimestamp.toString() +
                            '" alt="' + mappedTrailAreaShape.trailArea.name + '" width="160" height="120" />');
                        this.infoWindow.open({
                            anchor: trailAreaAnchor
                        });
                    });
                    trailAreaPolygon.addListener('mouseout', () => this.infoWindow.close());
                    this.mapElements.push(trailAreaAnchor);
                    this.mapElements.push(trailAreaPolygon);
                });
            }
        }
    }

    private parseGeoJSON(geoJSON: string): LatLngArray[] {
        const fileContents: { [key: string]: object } = JSON.parse(geoJSON);
        return this.getPolygons(fileContents);
    }

    private getPolygons(printObject: { [key: string]: any }, pre: string = ''): LatLngArray[] {
        const countryParts: LatLngArray[] = [];
        Object.keys(printObject).forEach((key) => {
            if (typeof printObject[key] === 'object') {
                if (key === 'coordinates') {
                    let polygons: LatLngArray[][];
                    if (printObject['type'] === this.GEOMETRIES_TYPE_MULTI_POLYGON) {
                        polygons = printObject[key];
                    } else if (printObject['type'] === this.GEOMETRIES_TYPE_POLYGON) {
                        polygons = [printObject[key]];
                    }

                    const bounds: LatLngArray = [
                        [this.bounds.east, this.bounds.north],
                        [this.bounds.west, this.bounds.north],
                        [this.bounds.west, this.bounds.south],
                        [this.bounds.east, this.bounds.south]
                    ];

                    polygons.forEach((polygonParts) => {
                        polygonParts.forEach((polygon) => {
                            const polygonInBounds = PolygonTools.polygon.intersection(polygon, bounds);
                            countryParts.push(...polygonInBounds);
                        });
                    });
                    console.log(pre + key, 'size', polygons.length, countryParts.length);
                } else {
                    console.log(pre + key + ':');
                    countryParts.push(...this.getPolygons(printObject[key], ' ' + pre + key + '-'));
                }
            } else {
                console.log(pre + key, '=', printObject[key]);
            }
        });
        return countryParts;
    }

    private loadTrailAreaShapes(): Observable<MappedTrailAreaShapes> {
        return this.trailAreaService.loadTrailAreaShapes(this.selectedCountry.countryCode)
            .pipe(
                take(1),
                switchMap((trailAreasShapes) => {
                    return combineLatest([of(trailAreasShapes), this.trailAreaService.getTrailAreas(Object.keys(trailAreasShapes))]);
                }),
                map(([trailAreasShapes, trailAreas]) => {
                    const trailAreaKeys: string[] = Object.keys(trailAreasShapes);
                    if (trailAreaKeys.length === 0) {
                        return null;
                    }
                    const mapData: MappedTrailAreaShapes = {};
                    trailAreaKeys.forEach((trailAreaKey) => {
                        for (const trailArea of trailAreas) {
                            if (trailArea.key === trailAreaKey) {
                                mapData[trailAreaKey] = {trailArea: trailArea, shapes: trailAreasShapes[trailAreaKey]};
                                break;
                            }
                        }
                    });
                    return mapData;
                })
            );
    }

    private updateTrailAreaShapeImages() {
        if (this.seedObjects.length > 0) {
            this.afFunctions.httpsCallable('updateTrailAreaShapeImages')({collectionKey: this.selectedCountry.countryCode})
                .pipe(take(1))
                .subscribe(() => this.onSelectCountry());
        } else {
            this.onSelectCountry();
        }
    }

}
