import { Component, AfterViewChecked } from '@angular/core';
import { environment } from '../../environments/environment';

// Services
import { AuthService } from '../core/auth.service';
import { LayoutService } from '../core/layout.service';

declare var $: any;

@Component({
    selector: 'app-main-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements AfterViewChecked {
    version: string = environment.version;

    removeTimeout: number;
    relaxing = 400;

    constructor(
        public authService: AuthService,
        public layout: LayoutService
    ) {
    }

    ngAfterViewChecked() {
        window.clearTimeout(this.removeTimeout);
        this.removeTimeout = window.setTimeout(() => {
            this.relaxing *= 2;
            $('body').Layout('fixLayoutHeight');
        }, this.relaxing);
    }

}
