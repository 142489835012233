export interface Meetup {
    key: string;
    meetupId: string;
    name: string;
    description: string;
    public: boolean;
    timestamp: number;
    imageUrl: string;
    groupKey: string;
    trailAreaKey: string;
    latitude: number;
    longitude: number;
    participants: string[];
    year: number;
    week: number;
}

export interface MeetupDash {
    key: string;
    timestamp: number;
    public: boolean;
    year: number;
    week: number;
}

export interface MeetUpStat {
    year: number;
    week: number;
    yearWeekLabel: string;
    publicCount: number;
    privateCount: number;
}

export interface MeetupFeedEntry {
    participants: string[];
}

export interface CalendarMeetup {
    forKey: string;
    forType: string;
    periodFrom: number;
    periodTo: number;
    filter: string;
    includeInPlanning: boolean;
}

export interface MeetupParticipant {
    name: string;
    imageUrl: string;
    role: MeetupParticipantRoles;
    state: MeetupParticipantStates;
    application?: boolean;
    profileKey?: string;
}

export interface MeetupParticipantsAction {
    meetupKey: string;
    action: MeetupParticipantActions;
    whoKeys: string[];
}

export enum MeetupParticipantStates {
    STATE_JOINED = 'joined',
    STATE_INVITED = 'invited'
}

export enum MeetupParticipantRoles {
    ROLE_OWNER = 'owner',
    ROLE_MEMBER = 'member',
    ROLE_OUTSIDER = 'outsider'
}

export enum MeetupParticipantActions {
    ACTION_INVITE = 'invite',
    ACTION_JOIN = 'join',
    ACTION_LEAVE = 'leave'
}
