import { Injectable } from '@angular/core';
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database/';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

// Interfaces
import { LastWeekTotalKpi } from '../interfaces/general';
import { KioskOrder, ORDER_STATUS_SUCCESS, PaymentType } from '../interfaces/kiosk';

import firebase from 'firebase/compat';

const APPLE_SANDBOX_ORDER_START_LENGTH = 6;
const APPLE_PRODUCTION_ORDER_MIN = 8;
const GOOGLE_TESTCARD_TIME_LIMIT = 1000000000;

@Injectable({
    providedIn: 'root'
})
export class KioskOrderService {

    kioskOrderRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase
    ) {
        this.kioskOrderRef = this.db.database.ref('kioskOrder');
    }

    private static isOrderARealIncome(kioskOrder: KioskOrder): boolean {
        // Order not finished
        if (kioskOrder.status !== ORDER_STATUS_SUCCESS) {
            return false;
        }

        // Paid with activation code or a giveaway
        if (kioskOrder.paymentType === PaymentType.ACTIVATION_CODE || kioskOrder.paymentType === PaymentType.GIVE_AWAY) {
            return false;
        }

        // Paid with Apple Sandbox
        if (
            kioskOrder.paymentType === PaymentType.IAP &&
            typeof kioskOrder.transactionID === 'string' &&
            (kioskOrder.transactionID.length < 10 ||
                (
                    kioskOrder.transactionID.indexOf('1'.padEnd(APPLE_SANDBOX_ORDER_START_LENGTH, '0')) === 0 &&
                    parseInt(kioskOrder.transactionID.substr(APPLE_SANDBOX_ORDER_START_LENGTH, 1), 10) < APPLE_PRODUCTION_ORDER_MIN
                )
            )
        ) {
            return false;
        }

        // Paid with Google Test-card
        if (
            kioskOrder.paymentType === PaymentType.IAP &&
            typeof kioskOrder.extra !== 'undefined' &&
            typeof kioskOrder.extra.expiryTimeMillis === 'string' &&
            typeof kioskOrder.extra.startTimeMillis === 'string' &&
            ((parseInt(kioskOrder.extra.expiryTimeMillis, 10) - parseInt(kioskOrder.extra.startTimeMillis, 10))
                < GOOGLE_TESTCARD_TIME_LIMIT)
        ) {
            return false;
        }

        // Zero order
        if (typeof kioskOrder.amount !== 'number' || kioskOrder.amount <= 0) {
            return false;
        }

        // Development order
        if (typeof kioskOrder.orderID === 'undefined') {
            console.error(kioskOrder);
            return false;
        }
        return true;
    }

    private static getKioskOrderFromSnap(kioskOrderSnap: AngularFireAction<DatabaseSnapshot<{}>>): KioskOrder {
        const kioskOrder: KioskOrder = <KioskOrder>kioskOrderSnap.payload.val();
        kioskOrder.key = kioskOrderSnap.key;
        if (typeof kioskOrder.kioskCustomer !== 'object' || kioskOrder.kioskCustomer === null) {
            kioskOrder.kioskCustomer = {
                name: '<NA>',
                street: '<NA>',
                city: '<NA>',
                zip: '<NA>',
                country: '<NA>'
            };
        }
        kioskOrder.realIncome = KioskOrderService.isOrderARealIncome(kioskOrder);
        return kioskOrder;
    }

    getKioskOrders(): Observable<KioskOrder[]> {
        return this.db.list<KioskOrder>(this.kioskOrderRef.ref).snapshotChanges()
            .pipe(
                take(1),
                map((kioskOrderSnaps) => {
                    const kioskOrders: KioskOrder[] = [];
                    kioskOrderSnaps.forEach((kioskOrderSnap) => {
                        kioskOrders.push(KioskOrderService.getKioskOrderFromSnap(kioskOrderSnap));
                    });
                    return kioskOrders;
                })
            );
    }

    getKioskKpis(): Observable<LastWeekTotalKpi> {
        return this.db.list<KioskOrder>(this.kioskOrderRef.ref, ref => ref.orderByChild('status').equalTo(ORDER_STATUS_SUCCESS))
            .snapshotChanges()
            .pipe(
                take(1),
                map((kioskOrderSnaps) => {
                    const lastWeekThreshold = Date.now() - 7 * 24 * 60 * 60 * 1000;
                    const kpi: LastWeekTotalKpi = {
                        total: 0,
                        lastWeek: 0
                    };
                    kioskOrderSnaps.forEach((kioskOrderSnap) => {
                        const kioskOrder: KioskOrder = KioskOrderService.getKioskOrderFromSnap(kioskOrderSnap);
                        if (kioskOrder.realIncome) {
                            kpi.total++;
                            if (kioskOrder.successTimestamp > lastWeekThreshold) {
                                kpi.lastWeek++;
                            }
                        }
                    });
                    return kpi;
                })
            );
    }

    getPremiumOrderStatsFromLastWeek(): Observable<{ new: number, renewals: number }> {
        const lastWeekThreshold = Date.now() - 7 * 24 * 60 * 60 * 1000;
        return this.db.list<KioskOrder>(this.kioskOrderRef.ref, ref => ref.orderByChild('successTimestamp').startAt(lastWeekThreshold))
            .snapshotChanges()
            .pipe(
                take(1),
                map((snaps) => {
                    let newPremium = 0;
                    let renewals = 0;
                    snaps.forEach((snap) => {
                        const kioskOrder: KioskOrder = KioskOrderService.getKioskOrderFromSnap(snap);
                        if (kioskOrder.realIncome) {
                            if (kioskOrder.kioskProduct.key === 'premium') {
                                if (kioskOrder.orderText.indexOf('(auto-renew)') !== -1 ||
                                    kioskOrder.orderText.indexOf('(recovered)') !== -1) {
                                    renewals++;
                                } else {
                                    newPremium++;
                                }
                            }
                        }
                    });
                    return {
                        new: newPremium,
                        renewals: renewals
                    };
                })
            );
    }
}
