<div class="content-wrapper">
    <app-content-header-section *ngIf="destination" contentPageId="destination"
                                [pageParams]="{ destinationName: destination.name, destinationKey: destination.key }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid" *ngIf="destination">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-12">

                    <div class="info-box">
                        <span class="info-box-icon bg-blue"><i class="fas fa-map"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Trail Areas</span>
                            <span class="info-box-number">{{ trailAreaCount }}</span>
                            <a routerLink="/destination/{{ destination.key }}/trail-areas">Go to trail areas</a>
                        </div>
                    </div>

                </div>
<!--                <div class="col-lg-3 col-md-6 col-12">-->

<!--                    <div class="info-box">-->
<!--                        <span class="info-box-icon bg-green"><i class="far fa-compass"></i></span>-->
<!--                        <div *ngIf="!destination.eventsEnabled" class="info-box-content">-->
<!--                            <span class="info-box-text">Events</span>-->
<!--                            <span class="info-box-number">Not enabled</span>-->
<!--                            <i class="fas fa-phone"></i> Please contact us for more information.-->
<!--                        </div>-->
<!--                        <div *ngIf="destination.eventsEnabled" class="info-box-content">-->
<!--                            <span class="info-box-text">Events</span>-->
<!--                            <span class="info-box-number">{{ eventCount }}</span>-->
<!--                            <a routerLink="/destination/{{ destination.key }}/events">Go to events</a>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </div>-->
            </div>
            <div class="row">
                <div class="col-md-4">

                    <app-translatable-texts textsName="Destination" colorTheme="maroon" infoTrigger="{{ textsChangedInfo }}" [textModel]="textModel"
                                            [textObject]="destination" (alteredTextObject)="onAlteredTextObject($event)"></app-translatable-texts>

                </div>
                <div class="col-md-4" *ngIf="destination.boundsNorth && trailAreas && trailAreas.length > 0">

                    <div class="card card-outline card-primary">
                        <div class="card-header">
                            <h3 class="card-title"><i class="far fa-map mr-2"></i> Overview of {{ destination.name }}</h3>
                        </div>
                        <div class="card-body p-0">
                            <div #gmap id="gmap"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
