import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

// Component using this guard
import { PublishableComponent } from '../base/publishable/publishable.component';

@Injectable({
    providedIn: 'root'
})
export class SavedAndPublishedGuard implements CanDeactivate<unknown> {
    canDeactivate(
        component: PublishableComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return component.canDeactivate();
    }

}
