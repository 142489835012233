import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from "rxjs";
import {GroupMember} from "../../interfaces/group";
import {GroupService} from "../../firebase-services/group.service";

declare var $: any;

@Component({
    selector: 'app-group-member-picker',
    templateUrl: './group-member-picker.component.html',
    styleUrls: ['./group-member-picker.component.css']
})
export class GroupMemberPickerComponent implements OnDestroy, OnChanges {
    @Input() modalId: string;
    @Input() modalTitle: string;
    @Input() btnClassType: string;
    @Input() groupKey: string;
    @Input() filterList: string[] = [];
    @Output() membersSelected = new EventEmitter<string[]>();
    destroy$: Subject<boolean> = new Subject<boolean>();
    private allMembers: GroupMember[] = [];
    uninvitedMembers: GroupMember[] = [];
    selectedUserKeys: Set<string> = new Set<string>();

    constructor(
        private groupService: GroupService
    ) {
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.groupKey && changes.groupKey.currentValue) {
            this.fetchGroupMembers();
        }
        if (changes.filterList) {
            this.applyFilter();
        }
    }

    fetchGroupMembers(): void {
        if (this.groupKey) {
            this.groupService.getGroupMembers(this.groupKey).pipe(takeUntil(this.destroy$))
                .subscribe((members) => {
                    this.allMembers = members;
                    this.applyFilter();
                });
        }
    }

    private applyFilter(): void {
        if (this.filterList) {
            this.uninvitedMembers = this.allMembers.filter(user => !this.filterList.includes(user.profileKey));
        }
    }

    submitSelection(): void {
        this.membersSelected.emit(Array.from(this.selectedUserKeys));
        $('#groupMemberPickerModal_' + this.modalId).modal('hide');
    }

    toggleSelection(userKey: string): void {
        if (this.selectedUserKeys.has(userKey)) {
            this.selectedUserKeys.delete(userKey);
        } else {
            this.selectedUserKeys.add(userKey);
        }
    }
}
