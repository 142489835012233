<div class="content-wrapper">

    <section class="content-header">
        <h1>
            <i class="fa fa-street-view"></i> Ride
            <small>details</small>
        </h1>
        <ol class="breadcrumb">
            <li><a routerLink="/root/dashboard"><i class="fas fa-user-secret"></i> Root</a></li>
            <li><a routerLink="/root/rides"><i class="fas fa-bicycle"></i> Rides</a></li>
            <li class="active"><i class="fa fa-street-view"></i> Ride</li>
        </ol>
    </section>

    <section class="content">
        <div class="container-fluid">
            <div *ngIf="ride" class="row">
                <div class="col-12">

                    <div class="card card-outline card-maroon">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fa fa-street-view"></i> Map of ride</h3>
                        </div>
                        <div class="card-body">
                            <agm-map [fitBounds]="true" [zoom]="12">
                                <agm-marker [visible]="false" [latitude]="ride.boundsNorth" [longitude]="ride.boundsEast"
                                            [agmFitBounds]="true"></agm-marker>
                                <agm-marker [visible]="false" [latitude]="ride.boundsSouth" [longitude]="ride.boundsWest"
                                            [agmFitBounds]="true"></agm-marker>
                                <agm-polyline *ngFor="let trail of trails" [strokeColor]="trail.color" strokeWeight="4">
                                    <agm-icon-sequence [strokeColor]="trail.color" strokeOpacity="0.6" strokeWeight="1" [fixedRotation]="false"
                                                       [path]="'FORWARD_OPEN_ARROW'" [repeat]="'150px'"></agm-icon-sequence>
                                    <agm-polyline-point *ngFor="let point of trailPaths[trail.key]" [latitude]="point[1]"
                                                        [longitude]="point[0]"></agm-polyline-point>
                                </agm-polyline>
                                <agm-polyline *ngIf="ridePath" [strokeColor]="ridePath.color" strokeWeight="7" strokeOpacity="0.8">
                                    <agm-icon-sequence [strokeColor]="ridePath.color" strokeOpacity="0.8" strokeWeight="1" [fixedRotation]="false"
                                                       [path]="'FORWARD_OPEN_ARROW'" [repeat]="'100px'"></agm-icon-sequence>
                                    <agm-polyline-point *ngFor="let point of ridePath.path" [latitude]="point[1]"
                                                        [longitude]="point[0]"></agm-polyline-point>
                                </agm-polyline>
                                <agm-polyline *ngFor="let chapter of profileRide.story" [strokeColor]="chapter.color" strokeWeight="1">
                                    <agm-polyline-point *ngFor="let point of chapter.path" [latitude]="point[1]"
                                                        [longitude]="point[0]"></agm-polyline-point>
                                </agm-polyline>
                            </agm-map>
                            <div class="form-group">
                                <label>Legacy validation</label>
                                <div>
                                    <i [ngClass]="'fa fa-' + (ride.valid ? 'check' : 'times')"></i>
                                    <code>{{ ride.backendMessage }}</code>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="eventKey">Event Key</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <i class="input-group-text far fa-compass"></i>
                                    </div>
                                    <input id="eventKey" type="text" class="form-control" [(ngModel)]="eventKey">
                                    <span class="input-group-append" *ngIf="!ride.eventKey">
                                        <button (click)="connectEventAndRide()" class="btn btn-success float-right"
                                                [disabled]="eventKey === ''">Set</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button (click)="recalculate()" class="btn btn-info">Recalculate</button>
                            <button (click)="legacyValidation()" class="btn btn-info">Legacy validation</button>
                            <button (click)="downloadFile()" class="btn btn-default">Download GPX</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</div>
