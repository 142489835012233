import {Injectable} from '@angular/core';
import firebase from "firebase/compat";
import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from "@angular/fire/compat/database";
import {forkJoin, Observable, of} from "rxjs";
import {map, switchMap, take} from "rxjs/operators";
import {DestinationManager, DestinationManagers} from "../../../interfaces/destination-manager";
import {UserService} from "../../../firebase-services/user.service";
import {Roles} from "../../../interfaces/role";
import {Destination} from "../../../interfaces/destination";
import {DestinationService} from "../../../firebase-services/destination.service";

@Injectable({
    providedIn: 'root'
})
export class DestinationManagersService {
    roles = Roles;
    destinationManagersRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private userService: UserService,
        private destinationService: DestinationService,
    ) {
        this.destinationManagersRef = this.db.database.ref('managers/destinationManagers');
    }

    getDestinationsManagers(): Observable<DestinationManagers> {
        return this.db.list<DestinationManager>(this.destinationManagersRef).snapshotChanges()
            .pipe(
                switchMap(snapshots => {
                    const destinationManagerObservables = snapshots.map(snapshot => {
                        const destinationManager = this.destinationManagerFromSnap(snapshot);
                        return this.userService.getUser(destinationManager.userKey).pipe(
                            take(1),
                            map(user => ({
                                user,
                                destinations: destinationManager.destinations
                            }))
                        );
                    });
                    return forkJoin(destinationManagerObservables).pipe(
                        map(destinationManagers => {
                            const result: DestinationManagers = {};
                            destinationManagers.forEach(dm => {
                                dm.destinations.forEach(destinationKey => {
                                    if (!result[destinationKey]) {
                                        result[destinationKey] = [];
                                    }
                                    result[destinationKey].push(dm.user);
                                });
                            });
                            return result;
                        })
                    );
                })
            );
    }

    getDestinationManagerDestinations(userKey: string): Observable<Destination[]> {
        return this.db.list<string>(this.destinationManagersRef.child(userKey).child('destinations').ref)
            .snapshotChanges().pipe(
                map(dKeys => dKeys.map(dKey => dKey.key)),
                switchMap(destinationKeys => destinationKeys.length
                    ? this.destinationService.getDestinations(destinationKeys)
                    : of(null)
                )
            );
    }

    addDestinationManager(userKey: string, destinationKey: string): Promise<void> {
        return this.destinationManagersRef.child(userKey).child('destinations').update({[destinationKey]: destinationKey});
    }

    removeDestinationManager(userKey: string, destinationKey: string): Promise<void> {
        return this.destinationManagersRef.child(userKey).child('destinations').child(destinationKey).remove();
    }

    private destinationManagerFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<DestinationManager>>): DestinationManager {
        const destinationManager: DestinationManager = snapshot.payload.val();
        destinationManager.userKey = snapshot.key;
        destinationManager.destinations = Object.values(destinationManager.destinations ?? []);
        return destinationManager;
    }

}
