import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

// Services
import { AngularFireDatabase } from '@angular/fire/compat/database/';

// Interfaces
import firebase from 'firebase/compat';

@Injectable({
    providedIn: 'root'
})
export class ProfileDataService {
    profileDataRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase
    ) {
        this.profileDataRef = this.db.database.ref('profileData');
    }

    getVisitedTrailAreaKeys(profileKey: string): Observable<string[]> {
        return this.db.list<any>(this.profileDataRef.ref.child(profileKey).child('trailAreas')).snapshotChanges()
            .pipe(
                take(1),
                map((visitedTrailAreasSnapshot) => {
                    const visitedTrailAreaKeys: string[] = [];
                    visitedTrailAreasSnapshot.forEach((snap) => {
                        visitedTrailAreaKeys.push(snap.key);
                    });
                    return visitedTrailAreaKeys;
                })
            );
    }
}
