<div class="content-wrapper">
    <app-content-header-section contentPageId="destinationManagers" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">

                    <div class="card card-outline card-maroon">
                        <div class="card-header">
                            <h3 class="card-title">Destinations</h3>
                        </div>
                        <div class="card-body p-0">
                            <table *ngIf="destinations" class="table">
                                <thead>
                                <tr>
                                    <th>Promoted</th>
                                    <th>Name</th>
                                    <th colspan="3"># Managers</th>
                                    <th># Trail Areas</th>
                                    <th>Adventures enabled</th>
                                    <th># Adventures</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let destination of destinations" id="tr_{{ destination.key }}">
                                    <td>
                                            <span *ngIf="onlineIsManager[destination.key]">
                                                <a href="/destination/{{destination.key}}/settings"><i class="fas fa-cogs"></i>&nbsp;</a>
                                            </span>
                                        <span *ngIf="displayTrash[destination.key]">
                                                <a onclick="return false"><i class="far fa-trash-alt"
                                                                             (click)="deleteDestination(destination.key)"></i></a>&nbsp;
                                            </span>
                                        {{ destination.promoted }}
                                    </td>
                                    <td>
                                        {{ destination.name }} &nbsp;
                                        <span class="badge badge-default">{{destination.key}}</span>
                                    </td>
                                    <td class="td-min-width">
                                        <div role="group" class="btn-group" *ngIf="destinationManagers[destination.key]">
                                            <button type="button" class="btn btn-xs btn-default dropdown-toggle" data-toggle="dropdown"
                                                    aria-expanded="false">
                                                {{ numberOfManagers[destination.key] }}
                                                <span class="caret"></span>
                                            </button>
                                            <ul class="todo-list dropdown-menu dropdown-menu-right">
                                                <li *ngFor="let destinationManager of destinationManagers[destination.key]"
                                                    [id]="destination.key + destinationManager.userId">
                                                    <div class="tools">
                                                        <button class="btn btn-xs btn-danger"
                                                                (click)="removeManager(destinationManager.userID, destination.key)">Remove
                                                        </button>
                                                    </div>
                                                    <img class="img-circle img-sm" src="{{ destinationManager.userPicture }}" alt="">
                                                    <div class="img-push">
                                                        <div class="text">{{ destinationManager.name }}</div>
                                                        <small class="badge badge-danger"
                                                               title="Country info">{{ destinationManager.deviceCountry }}</small>
                                                        <small class="badge badge-warning"
                                                               title="Last login time">{{ destinationManager.lastLoginTime | date:'dd/MM/yyy' }}</small>
                                                        <small class="badge badge-default"
                                                               title="Unique profile key">{{ destinationManager.userID }}</small>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="overlay" *ngIf="!numberOfManagers[destination.key] && !(numberOfManagers[destination.key] === 0)">
                                            <i class="fas fa-sync-alt fa-spin"></i>
                                        </div>
                                    </td>
                                    <td class="td-min-width">
                                        <button *ngIf="numberOfManagers[destination.key] >= 0 && !onlineIsManager[destination.key]"
                                                (click)="addManager(authService.user.userID, destination.key)"
                                                class="btn btn-xs btn-default addOnlineUserAsManagerButton" [style]="setOnlineBackgroundImage()"
                                                title="Add yourself as manager">+ &nbsp;
                                        </button>
                                    </td>
                                    <td>
                                            <span>
                                                <app-find-user *ngIf="numberOfManagers[destination.key] >= 0" modalId="{{ destination.key }}"
                                                               modalTitle="Find Manager for {{ destination.name }}" btnClassType="xs btn-default"
                                                               (userSelected)="addManager($event, destination.key)">+<i class="fa fa-user"
                                                                                                                        title="Add manager to {{ destination.name }}"></i></app-find-user>
                                            </span>
                                    </td>
                                    <td><span *ngIf="numberOfTrailAreas[destination.key]">{{ numberOfTrailAreas[destination.key] }}</span></td>
                                    <td>{{destination.adventuresEnabled}}</td>
                                    <td><span *ngIf="numberOfAdventures[destination.key]">{{ numberOfAdventures[destination.key] }}</span></td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="overlay" *ngIf="!destinations">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</div>
