import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

// Extends
import { PublishableComponent } from '../../../base/publishable/publishable.component';

// Services
import { AuthService } from '../../../core/auth.service';
import { LayoutService } from '../../../core/layout.service';
import { FirebaseObjectService } from '../../../services/firebase-object.service';
import { MapWorkerService } from '../../../services/map-worker.service';
import { MediaLibraryService } from '../../../firebase-services/media-library.service';
import { PoiService } from '../../../firebase-services/poi.service';
import { PoiCategoryService } from '../../../firebase-services/poi-category.service';
import { PoiStateService } from '../../../services/poi-state.service';
import { TrailAreaService } from '../../../firebase-services/trail-area.service';

// Interfaces
import { PublishedStates } from '../../../interfaces/general';
import { DefaultMapProp, TrailAreaOnMap } from '../../../interfaces/map';
import { DraftPoi, CategoryForPoi, PublicPoi } from '../../../interfaces/poi';
import { PoiCategoriesAccessLevels, PoiCategory, PublicPoiCategory } from '../../../interfaces/poi-category';
import { TextModelItemType } from '../../../interfaces/text-model';
import { TrailArea } from '../../../interfaces/trailArea';
import {Roles} from "../../../interfaces/role";

@Component({
    selector: 'app-poi-edit',
    templateUrl: './trail-area-poi-edit.component.html',
    styleUrls: ['./trail-area-poi-edit.component.css']
})
export class TrailAreaPoiEditComponent extends PublishableComponent implements OnInit, AfterViewChecked {
    @ViewChild('gmap') gmapElement: ElementRef;

    poiKey: string;
    trailAreaKey: string;
    draftPoi: DraftPoi;
    publicPoi: PublicPoi;
    trailArea: TrailArea;
    poiCategories: { [label: string]: PoiCategory } = null;
    publicPoiCategories: { [label: string]: PublicPoiCategory } = null;

    isAdmin = false;

    selectedCategories: { [label: string]: boolean } = {};
    selectedIcons: { [label: string]: boolean } = {};
    roles = Roles;

    private publicPoiCategories$: Observable<{ [label: string]: PublicPoiCategory }>;
    private poiCategories$: Observable<{ [label: string]: PoiCategory }>;
    private trailArea$: Observable<TrailArea>;
    private map: google.maps.Map;
    private trailAreaOnMap: TrailAreaOnMap;

    constructor(
        public layout: LayoutService,
        private route: ActivatedRoute,
        private afFunctions: AngularFireFunctions,
        private authService: AuthService,
        private mapWorkerService: MapWorkerService,
        private mediaLibraryService: MediaLibraryService,
        private poiService: PoiService,
        private poiCategoryService: PoiCategoryService,
        private stateService: PoiStateService,
        private trailAreaService: TrailAreaService
    ) {
        super();
        this.publicPoiCategories$ = this.poiCategoryService.getPublicPoiCategories()
            .pipe(takeUntil(this.destroy$));
        this.poiCategories$ = this.poiCategoryService.getPoiCategories()
            .pipe(takeUntil(this.destroy$));
        this.initTextModel();
    }

    ngOnInit() {
        this.poiKey = this.route.snapshot.paramMap.get('poiKey');
        if (this.route.snapshot.url[0].path === 'trail-area') {
            this.contentPageId = 'trailAreaPoiEdit';
            this.trailAreaKey = this.route.snapshot.paramMap.get('areaKey');
        } else {
            delete this.trailAreaKey;
            this.contentPageId = 'editPoi';
        }

        this.draftPoi = null;
        this.publicPoi = null;
        this.trailArea = null;
        this.poiCategories = null;
        this.publicPoiCategories = null;
        this.map = null;
        this.trailAreaOnMap = null;

        this.isAdmin = this.authService.isUser(this.roles.ADMIN);

        if (this.trailAreaKey) {
            this.trailArea$ = this.trailAreaService.getTrailArea(this.trailAreaKey)
                .pipe(takeUntil(this.destroy$));
        } else {
            this.trailArea$ = of(<TrailArea>{
                name: 'Unknown',
                key: 'nn',
                trailKeys: {}
            });
        }

        combineLatest([this.publicPoiCategories$, this.poiCategories$, this.trailArea$])
            .subscribe(([publicCategories, categories, trailArea]) => {
                this.publicPoiCategories = publicCategories;
                this.poiCategories = categories;
                this.trailArea = trailArea;
                this.loadPoi();
            });
    }

    ngAfterViewChecked() {
        this.initMap();
    }

    saveDraft(): Promise<any> {
        if (this.newImageReady) {
            // Uploads image in image handler. This then triggers the onUploadedImageUrl function, which calls this function again.
            this.doUploadImage++;
            return Promise.resolve();
        }
        return this.poiService.savePoiDraft(this.draftPoi, this.state.isPublished)
            .catch((err) => console.error('Error saving POI:', err.message));
    }

    onUploadedImageUrl(uploadedImageUrl: string): Promise<any> {
        this.draftPoi.imageUrl = uploadedImageUrl;
        this.newImageReady = false;
        return this.saveDraft();
    }

    onImageSelected(success: boolean) {
        this.newImageReady = success;
        this.handlePoiAltered();
    }

    publish(): Promise<any> {
        this.afFunctions.httpsCallable('publishPoi')({poiKey: this.poiKey})
            .pipe(take(1))
            .subscribe({
                next: () => this.poiService.setActive(this.poiKey, true),
                error: (err) => window.alert(this.poiKey + ': ' + JSON.parse(err.message).message)
            });
        return Promise.resolve();
    }

    unpublish(): Promise<any> {
        this.afFunctions.httpsCallable('unpublishPoi')({poiKey: this.poiKey})
            .pipe(take(1))
            .subscribe({
                next: () => this.poiService.setActive(this.poiKey, false),
                error: (err) => window.alert(this.poiKey + ': ' + JSON.parse(err.message).message)
            });
        return Promise.resolve();
    }

    disableCategory(label: string): boolean {
        return this.poiCategories[label].accessLevel !== PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN &&
            (!this.isAdmin || this.poiCategories[label].accessLevel !== PoiCategoriesAccessLevels.ROOT);
    }

    disableSubCategory(label: string, subLabel: string): boolean {
        return this.poiCategories[label].subCategories[subLabel].accessLevel !== PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN &&
            (!this.isAdmin || this.poiCategories[label].subCategories[subLabel].accessLevel !== PoiCategoriesAccessLevels.ROOT);
    }

    handlePoiAltered() {
        this.state.altered = this.newImageReady ||
            JSON.stringify(FirebaseObjectService.prepareSetObject(this.draftPoi)) !== this.originalAsString;
        PoiStateService.updateState<DraftPoi, PublicPoi>(this.draftPoi, this.publicPoi, this.state, this.publicPoiCategories);
    }

    updatePoiCategories() {
        this.selectedIcons = {};
        this.draftPoi.categories = [];
        Object.keys(this.selectedCategories).forEach((selectedLabel) => {
            if (!this.selectedCategories[selectedLabel]) {
                return;
            }

            let categoryForPoi: CategoryForPoi;
            if (this.publicPoiCategories[selectedLabel]) {
                this.selectedIcons[selectedLabel] = true;
                categoryForPoi = {
                    label: selectedLabel,
                    sortOrder: this.publicPoiCategories[selectedLabel].sortOrder
                };
            } else { // It may be a sub category
                for (const label in this.publicPoiCategories) {
                    if (this.publicPoiCategories[label].subCategories) {
                        for (const subLabel in this.publicPoiCategories[label].subCategories) {
                            if (selectedLabel === subLabel) {
                                this.selectedIcons[label] = true;
                                categoryForPoi = {
                                    label: label,
                                    sortOrder: this.publicPoiCategories[label].sortOrder,
                                    subLabel: selectedLabel,
                                    subSortOrder: this.publicPoiCategories[label].subCategories[selectedLabel].sortOrder
                                };
                            }
                        }
                    }
                }
            }
            this.draftPoi.categories.push(categoryForPoi);
        });
        this.draftPoi.categories.sort((a, b) => a.sortOrder - b.sortOrder);

        this.onIconChanged();
    }

    onIconChanged() {
        // Clear map for existing markers
        if (typeof this.trailAreaOnMap.poiMarkerClusters === 'object') {
            if (this.trailAreaOnMap.poiMarkerClusters[this.poiKey]) {
                this.trailAreaOnMap.poiMarkerClusters[this.poiKey].forEach((marker) => {
                    marker.setMap(null);
                    marker.unbindAll();
                });
                this.trailAreaOnMap.poiMarkerClusters[this.poiKey] = null;
            }
        } else {
            this.trailAreaOnMap.poiMarkerClusters = {};
        }

        if (this.draftPoi.categories.length > 0) {
            let poiIconValid = false;
            Object.keys(this.selectedIcons).forEach((selectedLabel) => {
                if (this.publicPoiCategories[selectedLabel].iconUrl === this.draftPoi.iconUrl) {
                    poiIconValid = true;
                }
            });
            if (!poiIconValid) {
                this.draftPoi.iconUrl = this.publicPoiCategories[this.draftPoi.categories[0].label].iconUrl;
            }
            this.mediaLibraryService.getMediaIconKeyFromIconUrl(this.draftPoi.iconUrl)
                .pipe(take(1))
                .subscribe((mediaIconKey) => {
                    this.draftPoi.mediaIconKey = mediaIconKey;
                    this.updateIconCluster();
                    this.handlePoiAltered();
                });
        } else {
            this.handlePoiAltered();
        }
    }

    categoriesOutOfSync(): boolean {
        return PoiStateService.categoriesOutOfSync(this.draftPoi, this.publicPoi, this.publicPoiCategories);
    }

    private initTextModel(): void {
        this.textModel.items.push({
            name: 'Name',
            varName: 'name',
            help: 'The name of this point of interest.',
            placeholder: 'POI name',
            type: TextModelItemType.INPUT,
            required: true
        });
        this.textModel.items.push({
            name: 'POI description',
            varName: 'description',
            help: 'A longer description of the POI.',
            placeholder: 'A short description of the POI',
            type: TextModelItemType.TEXT_AREA
        });
        this.textModel.items.push({
            name: 'Web address',
            varName: 'www',
            publicVarName: 'websiteUrl',
            help: 'A web address for the point of interest.',
            placeholder: 'https://www.my-poi.com',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Facebook link',
            varName: 'facebookLink',
            publicVarName: 'facebookUrl',
            help: 'A link to the Facebook page of the point of interest.',
            placeholder: 'https://www.facebook.com/mountainbikeunited',
            type: TextModelItemType.INPUT
        });
    }

    private initMap() {
        if (this.map !== null || typeof this.gmapElement === 'undefined') {
            return;
        }
        const mapProp: google.maps.MapOptions = Object.assign({}, DefaultMapProp);
        mapProp.zoom = 17;
        mapProp.center = {
            lat: this.draftPoi.latitude,
            lng: this.draftPoi.longitude
        };
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

        this.mapWorkerService.trailAreaToMap(this.trailArea, this.map, this.poiKey)
            .subscribe((trailAreaOnMap) => {
                this.trailAreaOnMap = trailAreaOnMap;
                this.updatePoiCategories();
            });
    }

    private updateIconCluster() {
        const markerCluster = MapWorkerService.poiToMap(this.draftPoi, this.map, this.publicPoiCategories, true);
        this.trailAreaOnMap.poiMarkerClusters[this.poiKey] = markerCluster;
        markerCluster.map((marker) => {
            marker.setDraggable(true);
            marker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
                this.draftPoi.latitude = event.latLng.lat();
                this.draftPoi.longitude = event.latLng.lng();
                this.handlePoiAltered();
            });
        });
    }

    private loadPoi() {
        const poi$ = this.poiService.getPoi(this.poiKey)
            .pipe(takeUntil(this.destroy$));

        const publicPoi$ = this.poiService.getPublicPoi(this.poiKey)
            .pipe(takeUntil(this.destroy$));

        combineLatest([poi$, publicPoi$])
            .subscribe(([poi, publicPoi]) => {
                this.originalAsString = JSON.stringify(poi);

                // Find out which categories this POI has.
                Object.keys(this.publicPoiCategories).forEach((label) => {
                    let selected = false;
                    if (poi.categories) {
                        for (const categoryForPoi of poi.categories) {
                            if (categoryForPoi.label === label && !categoryForPoi.subLabel) {
                                selected = true;
                                break;
                            }
                        }
                    }
                    this.selectedCategories[label] = selected;

                    if (this.publicPoiCategories[label].subCategories) {
                        Object.keys(this.publicPoiCategories[label].subCategories).forEach((subLabel) => {
                            let subSelected = false;
                            if (poi.categories) {
                                for (const categoryForPoi of poi.categories) {
                                    if (categoryForPoi.subLabel === subLabel) {
                                        subSelected = true;
                                        break;
                                    }
                                }
                            }
                            this.selectedCategories[subLabel] = subSelected;
                        });
                    }
                });

                this.draftPoi = poi;
                this.publicPoi = publicPoi;

                // Set state
                this.state = {
                    altered: false,
                    canPublish: false,
                    icon: 'fas fa-sync-alt fa-spin',
                    isPublished: false,
                    outOfSync: false,
                    progress: PublishedStates.DRAFT_SAVED,
                    theme: 'warning'
                };
                PoiStateService.updateState<DraftPoi, PublicPoi>(this.draftPoi, this.publicPoi, this.state, this.publicPoiCategories);
            });
    }

}
