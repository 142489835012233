import { AfterContentChecked, Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

// Services
import { EventService } from '../../firebase-services/event.service';

// Interfaces
import { EVENT_ITEM_TIME_FORMAT, EventItem } from '../../interfaces/event';
import { TextModel, TextModelItemType, TextObject } from '../../interfaces/text-model';

import * as moment from 'moment';

declare var $: any;

@Component({
    selector: 'app-event-item-edit',
    templateUrl: './event-item-edit.component.html',
    styleUrls: ['./event-item-edit.component.css']
})
export class EventItemEditComponent implements AfterContentChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    textModel: TextModel = {items: []};

    @Input() routerBack: string;

    @Input() set eventItem(eventItem: EventItem) {
        this._eventItem = eventItem;
        this.init();
    }

    _eventItem: EventItem;

    originalName = '';
    disableSaveItem = true;
    alertTimeout: number;
    pin1 = 0;
    pin2 = 0;
    pin3 = 0;
    pin4 = 0;
    eventItemIsTimeBoxed = false;
    timeboxIsLoaded = false;

    settingsChangedInfo = 0;
    textsChangedInfo = 0;

    constructor(
        private router: Router,
        private eventService: EventService
    ) {
    }

    ngAfterContentChecked() {
        const eventItemEditComponent = this;
        if (this.eventItemIsTimeBoxed && !this.timeboxIsLoaded && $('#timebox').length > 0) {
            $(function () {
                const daterangepickeroptions = {
                    timePicker: true,
                    timePicker24Hour: true,
                    timePickerIncrement: 30,
                    locale: {
                        format: EVENT_ITEM_TIME_FORMAT
                    },
                    startDate: moment(eventItemEditComponent._eventItem.timeboxStart),
                    endDate: moment(eventItemEditComponent._eventItem.timeboxEnd)
                };
                $('#timebox').daterangepicker(
                    daterangepickeroptions,
                    function (start: moment.Moment, end: moment.Moment) {
                        eventItemEditComponent._eventItem.timeboxStart = start.valueOf();
                        eventItemEditComponent._eventItem.timeboxEnd = end.valueOf();
                        const timeBox = $('#timebox');
                        timeBox.data('daterangepicker').setStartDate(start);
                        timeBox.data('daterangepicker').setEndDate(end);
                        eventItemEditComponent.disableSaveItem = false;
                    }
                );
            });
            this.timeboxIsLoaded = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    init() {
        this.initTextModel();
        this.eventService.getEventElementOriginalName(this._eventItem)
            .pipe(takeUntil(this.destroy$))
            .subscribe((name) => this.originalName = name);
        if (this._eventItem.voucherPin) {
            this.pin1 = parseInt(this._eventItem.voucherPin.charAt(0), 10);
            this.pin2 = parseInt(this._eventItem.voucherPin.charAt(1), 10);
            this.pin3 = parseInt(this._eventItem.voucherPin.charAt(2), 10);
            this.pin4 = parseInt(this._eventItem.voucherPin.charAt(3), 10);
        }
        if (this._eventItem.timeboxStart && this._eventItem.timeboxEnd) {
            this.eventItemIsTimeBoxed = true;
        }
    }

    private initTextModel(): void {
        this.textModel = {items: []};
        this.textModel.items.push({
            name: 'Name',
            varName: 'name',
            help: 'The name of the event element.',
            placeholder: 'Event element name',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Header',
            varName: 'header',
            help: 'A subheader for the event element.',
            placeholder: 'Header text',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Description',
            varName: 'description',
            help: 'Describe the purpose of the event item.',
            placeholder: 'Event element description',
            type: TextModelItemType.TEXT_AREA
        });
    }

    /**
     * Sets state-variable when event item is changed
     */
    itemChanged(): void {
        this.disableSaveItem = false;
    }

    /**
     * Save event item
     */
    saveItem(goBack: boolean): void {
        this.disableSaveItem = true;
        if (this._eventItem.voucherPin) {
            this._eventItem.voucherPin = '' + this.pin1 + this.pin2 + this.pin3 + this.pin4;
        }
        if (!this.eventItemIsTimeBoxed) {
            this._eventItem.timeboxStart = this._eventItem.timeboxEnd = null;
        }
        this.eventService.updateEventItemSettings(this._eventItem)
            .then(() => {
                if (goBack) {
                    this.router.navigate([this.routerBack]);
                } else {
                    this.settingsChangedInfo++;
                }
            })
            .catch(err =>
                console.log('About-Update error occurred:', err.message)
            );
    }

    onAlteredTextObject(alteredTextObject: TextObject): void {
        this.eventService.updateEventItemTexts(<EventItem>alteredTextObject)
            .then(() => this.textsChangedInfo++)
            .catch((err) => console.error('Text-Update error occurred:', err.message));
    }
}
