import packageInfo from '../../package.json';

export const environment = {
    version: packageInfo.version,
    production: false,
    www: 'https://admin-staging.mtbutd.com',
    firebase: {
      apiKey: "AIzaSyBSKCadaEmxSMVZLPQwSTKP4hq-kJ-DtLA",
      authDomain: "singletracker-beta.firebaseapp.com",
      databaseURL: "https://singletracker-beta.firebaseio.com",
      projectId: "singletracker-beta",
      storageBucket: "singletracker-beta.appspot.com",
      messagingSenderId: "1002392552602",
      appId: "1:1002392552602:web:99dccca25c0e39a549cb23",
      measurementId: "G-LXRQHTERFF"
    },
    geocoding: {
        apiKey: 'AIzaSyCDjf30ywuHsfLVuSDRk7DVyXDa_aAX_Ys'
    },
    maps: {
        apiKey: 'AIzaSyA75QC5-th96haC66Z7j3EWTva1SKv46tk'
    },
    timezone: {
      apiKey: 'AIzaSyAKFyOLX6wi7H5ZsDNKSoixXXLMw4Ky1mE'
    },
    elasticsearch: {
      host: 'https://7698a6e92b144675aa9a7183865190e1.us-central1.gcp.cloud.es.io',
        auth: {
          apiKey: 'UzNEX3ZaRUJ1X2k5NlBxZ2hWYTI6a1BvX3hQTUxULXVaTnkyNjF2bUhSUQ=='
        }
    }
};
