import { AfterViewChecked, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, Observable, Subject, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';
import polyTool from '@pirxpilot/google-polyline';

// Services
import { AuthService } from '../../core/auth.service';
import { GeoService } from '../../services/geo.service';
import { ModelAssistantService } from '../../services/model-assistant.service';
import { TrailService } from '../../firebase-services/trail.service';
import { TrailAreaService } from '../../firebase-services/trail-area.service';

// Interfaces
import { TrailArea } from '../../interfaces/trailArea';
import { GPSPoint, Trail, TrailColors, TrailGradingWorld, TrailTypes } from '../../interfaces/trail';


declare var $: any;

@Component({
    selector: 'app-trails',
    templateUrl: './trails.component.html',
    styleUrls: ['./trails.component.css']
})
export class TrailsComponent implements AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    area: TrailArea = null;
    trails: Trail[] = null;

    unlinkedTrails: Trail[] = null;
    unlinkedTrailsLoaded = false;
    select2: any = null;

    newTrailName: string = null;

    // The key of the selected trail.
    selectedTrailKey: string = null;

    removeTrailCandidate: Trail = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public authService: AuthService,
        private trailService: TrailService,
        private trailAreaService: TrailAreaService,
        private modelAssistant: ModelAssistantService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initialise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngOnDestroy() {
        this.destroySelect2();
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    ngAfterViewChecked() {
        this.createSelect2();
    }

    addTrailToTrailArea() {
        const trailUpdatedPromise = this.trailService.setTrailAreaKey(this.selectedTrailKey, this.area.key);
        const trailAreaUpdatedPromise = this.trailAreaService.addTrail(this.selectedTrailKey, this.area);
        return Promise.all([trailUpdatedPromise, trailAreaUpdatedPromise])
            .then(() => this.reInit())
            .catch((err) => {
                console.error(err);
                alert('An error happened. Check the console');
            });
    }

    setRemoveTrailCandidate(trail: Trail) {
        this.removeTrailCandidate = trail;
        $('#modal-remove').modal('show');
    }

    removeTrailFromTrailArea(trailKey: string): Promise<void> {
        const removedFromTrailAreaPromise = this.trailAreaService.removeTrail(trailKey, this.area);
        const removedFromTrailPromise = this.trailService.removeTrailAreaKey(trailKey);
        return Promise.all([removedFromTrailAreaPromise, removedFromTrailPromise])
            .then(() => {
                $('#modal-remove').modal('hide');
                this.reInit();
            });
    }

    setSelectedTrail(key: string): void {
        this.selectedTrailKey = key;
    }

    createNewTrail(): Promise<boolean> {
        if (this.newTrailName === '') {
            alert('Please give the new trail a name');
            return;
        }
        const latLongs: GPSPoint[] = [];
        if (typeof this.area.boundsNorth === 'number' && this.area.boundsNorth > -90) {
            latLongs.push({
                latitude: (this.area.boundsNorth + this.area.boundsSouth) / 2,
                longitude: (this.area.boundsEast + this.area.boundsWest) / 2
            });
            latLongs.push({
                latitude: (this.area.boundsNorth + this.area.boundsSouth) / 2,
                longitude: (this.area.boundsEast + this.area.boundsWest) / 2 + 0.001
            });
        } else {
            const userPos1: google.maps.LatLngLiteral = GeoService.geohashToLatLngLiteral(this.authService.user.geohash.substr(0, 5) + '1');
            const userPos2: google.maps.LatLngLiteral = GeoService.geohashToLatLngLiteral(this.authService.user.geohash.substr(0, 5) + 'z');
            latLongs.push({latitude: userPos1.lat, longitude: userPos1.lng});
            latLongs.push({latitude: userPos1.lat, longitude: ((userPos1.lng + userPos2.lng) / 2)});
        }

        const newTrail: Trail = {
            name: this.newTrailName,
            active: false,
            countrie: this.area.country,
            dateCreated: moment().format('YYYY-MM-DD'),
            color: TrailColors[0].value,
            iconUrl: TrailGradingWorld[0].iconURL,
            trackType: TrailTypes.COMMON_TRAIL,
            encodedPolyline: polyTool.encode(latLongs, {
                factor: 1e6,
                mapFn: (p: GPSPoint, _i: number, _ps: GPSPoint[]) => [[p.longitude], [p.latitude]]
            }),
            startPoint: latLongs[0],
            stopPoint: latLongs[1],
            trailAreaKey: this.area.key
        };
        const newKey = this.trailService.createNewTrail(newTrail);
        const addedToTrailAreaPromise = this.trailAreaService.addTrail(newKey, this.area);
        return addedToTrailAreaPromise
            .then(() => this.router.navigate(['/trail-area/' + this.area.key + '/trails/' + newKey]));
    }

    private createSelect2() {
        if (this.unlinkedTrailsLoaded && (this.select2 === null || this.select2.length === 0)) {
            // Enable search in dropdown.
            this.select2 = $('#selectTrail');

            this.select2.select2({
                theme: 'bootstrap4',
                placeholder: 'Select an existing trail'
            });

            // Set the selected value in the select correctly.
            this.select2.on('select2:select', (event: any) => this.setSelectedTrail(event.params.data.id));
            // Select nothing to display placeholder
            this.select2.val(null).trigger('change');
        }
    }

    /**
     * Load data for the current trail area
     */
    private init() {
        this.reInit();
        this.area = null;
        this.newTrailName = null;
        this.loadTrailArea(this.route.snapshot.paramMap.get('key'));
        this.loadTrails(this.route.snapshot.paramMap.get('key'));
        if (this.authService.user.isRoot) {
            this.loadUnlinkedTrails();
        }
    }

    private reInit() {
        this.destroySelect2();
        this.removeTrailCandidate = null;
        this.selectedTrailKey = null;
    }

    private destroySelect2() {
        if (this.select2 !== null) {
            this.select2.select2('destroy');
            this.select2.off('select2:select');
            this.select2 = null;
            this.unlinkedTrails = null;
            this.unlinkedTrailsLoaded = false;
        }
    }

    private loadUnlinkedTrails() {
        this.trailService.getUnlinkedTrailKeys()
            .pipe(
                takeUntil(this.destroy$),
                switchMap((trailKeys) => {
                    const trailObservables: Observable<Trail>[] = [];
                    trailKeys.forEach((trailKey) => {
                        trailObservables.push(this.modelAssistant.getTrail(trailKey));
                    });
                    return combineLatest(trailObservables);
                })
            )
            .subscribe((unlinkedTrails) => {
                this.unlinkedTrails = unlinkedTrails;
                this.selectedTrailKey = null;
                this.unlinkedTrailsLoaded = true;
                this.createSelect2();
            });
    }

    private loadTrailArea(trailAreaKey: string): void {
        this.trailAreaService.getTrailArea(trailAreaKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((trailArea) => this.area = trailArea);
    }

    private loadTrails(trailAreaKey): void {
        this.trailService.getTrailsOnTrailArea(trailAreaKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((trails) => this.trails = trails);
    }
}
