import { TranslatableObject } from './general';

export const MINIMUM_TIME_SLOT_MINUTES = 15;
export const MINIMUM_TIME_SLOT_MILLISECONDS = MINIMUM_TIME_SLOT_MINUTES * 60000;

export interface Treat extends TranslatableObject {
    key?: string;
    alias?: string;
    topOfMindTitle: string;
    topOfMindSubHeader: string;
    link: string;
    activeFrom: number;
    activeTo: number;
    imageURL?: string;
    targetMyTreats: number;
    targetTopOfMind: number;
    myTreatsExposures?: number;
    topOfMindExposures?: number;
    myTreatsHits?: number;
    topOfMindHits?: number;
}

export enum ExposureType {
    MYTREATS = 'myTreats',
    TOPOFMIND = 'topOfMind'
}

export interface TreatExposure {
    key: string;
    exposureType: ExposureType;
    type: ExposureType;
}

export interface TreatHit {
    treatExposureKey: string;
}

export interface TreatsTabTexts extends TranslatableObject {
    title: string;
    description: string;
}
