import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';

@Component({
    selector: 'app-map-picker',
    templateUrl: './map-picker.component.html',
    styleUrls: ['./map-picker.component.css']
})
export class MapPickerComponent implements AfterViewInit, OnChanges {
    @Input() latitude: number;
    @Input() longitude: number;
    @Output() locationSelected = new EventEmitter<{ lat: number; lng: number }>();

    private map: google.maps.Map | null = null;
    private marker: google.maps.Marker | null = null;

    constructor(
        private cdr: ChangeDetectorRef
    ) {
    }

    ngAfterViewInit() {
        this.initializeMap(this.latitude || 0, this.longitude || 0);
        this.initializeSearchBox();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.marker && this.map) {
            const newPosition = {
                lat: changes.latitude?.currentValue || this.latitude,
                lng: changes.longitude?.currentValue || this.longitude
            };
            this.marker.setPosition(newPosition);
            this.map.setCenter(newPosition);
        }
    }

    private initializeMap(lat: number, lng: number) {
        const mapElement = document.getElementById('map') as HTMLElement;

        this.map = new google.maps.Map(mapElement, {
            center: {lat, lng},
            zoom: 12,
        });

        this.marker = new google.maps.Marker({
            position: {lat, lng},
            map: this.map,
            draggable: true,
        });

        this.marker.addListener('dragend', () => {
            const position = this.marker?.getPosition();
            if (position) {
                this.latitude = position.lat();
                this.longitude = position.lng();
                this.locationSelected.emit({lat: this.latitude, lng: this.longitude});
                this.cdr.detectChanges();
            }
        });

        this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
            if (event.latLng) {
                const clickedLocation = event.latLng;
                this.marker?.setPosition(clickedLocation);
                this.latitude = clickedLocation.lat();
                this.longitude = clickedLocation.lng();
                this.locationSelected.emit({lat: this.latitude, lng: this.longitude});
                this.cdr.detectChanges();
            }
        });
    }

    private initializeSearchBox() {
        // Link the search box to the UI element.
        const input = document.getElementById('pac-input') as HTMLInputElement;
        const searchBox = new google.maps.places.SearchBox(input);

        // Bias the SearchBox results towards the current map's viewport.
        if (this.map) {
            this.map.addListener('bounds_changed', () => {
                searchBox.setBounds(this.map!.getBounds());
            });
        }

        // Listen for the event fired when the user selects a prediction.
        searchBox.addListener('places_changed', () => {
            const places = searchBox.getPlaces();
            if (!places || places.length === 0) {
                return;
            }

            // Get the first place from the results.
            const place = places[0];
            if (!place.geometry || !place.geometry.location) {
                console.log('Selected place has no geometry');
                return;
            }

            // Center the map on the selected location and reposition the marker.
            if (this.map) {
                this.map.setCenter(place.geometry.location);
                this.map.setZoom(15);
            }

            if (this.marker) {
                this.marker.setPosition(place.geometry.location);
            } else if (this.map) {
                this.marker = new google.maps.Marker({
                    position: place.geometry.location,
                    map: this.map,
                    draggable: true,
                });
            }

            // Emit the selected location.
            this.locationSelected.emit({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            });
            this.cdr.detectChanges();
        });
    }
}
