import {AfterViewChecked, Component, OnDestroy} from '@angular/core';
import {Meetup} from '../../interfaces/meetup';
import {Subject} from 'rxjs';
import {switchMap, take, takeUntil} from 'rxjs/operators';
import {PressCampService} from '../press-camp.service';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../../core/auth.service';
import {FollowUserService} from '../../firebase-services/follow-user.service';
import {User} from '../../interfaces/user';
import * as moment from 'moment-timezone';
import {TrailAreaService} from '../../firebase-services/trail-area.service';
import {TimeZoneService} from '../../services/time-zone.service';

declare var $: any;

@Component({
    selector: 'app-press-camp-meetup-edit',
    templateUrl: './press-camp-meetup-edit.component.html',
    styleUrls: ['./press-camp-meetup-edit.component.css']
})
export class PressCampMeetupEditComponent implements OnDestroy, AfterViewChecked {
    destroy$: Subject<boolean> = new Subject<boolean>();
    pressCampMeetup: Meetup;
    pressCampMeetupParticipants: User[];
    pressCampMeetupPendingInvites: User[];
    saveMeetupSettingsDisabled = false;
    savingSettings = false;
    meetupSettingsSaved = 0;
    groupKey: string;
    filterList: string[] = [];
    invitingParticipants = false;
    meetupParticipantsInvited = 0;

    dateTimeIsLoaded = false;
    dateTimeName = '#dateTime';
    timeFormat = 'DD-MM-YYYY HH:mm';

    selectedTrailAreaName: string;

    isMapPickerOpen = false;
    meetingPlaceName: string = '';

    private wallClockTime: string = '';

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private followUserService: FollowUserService,
        private pressCampService: PressCampService,
        private trailAreaService: TrailAreaService,
        private timeZoneService: TimeZoneService
    ) {
        const meetupKey = this.route.snapshot.paramMap.get('meetupKey');
        this.pressCampService
            .getPressCampMeetup(meetupKey)
            .pipe(
                takeUntil(this.destroy$),
                switchMap((pressCampMeetup) => {
                    this.pressCampMeetup = pressCampMeetup;
                    this.pressCampMeetup.meetupId = meetupKey;
                    if (pressCampMeetup.trailAreaKey) {
                        this.onAreaSelected(pressCampMeetup.trailAreaKey);
                    } else if (pressCampMeetup.latitude && pressCampMeetup.longitude) {
                        this.onLocationSelected({lat: pressCampMeetup.latitude, lng: pressCampMeetup.longitude});
                    }
                    return this.pressCampService.getPressCampMeetupParticipants(this.pressCampMeetup.participants);
                })
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe((participants) => {
                this.pressCampMeetupParticipants = participants;
                const userKeysSet = new Set<string>();
                this.pressCampMeetupParticipants.forEach((participant) => {
                    userKeysSet.add(participant.userID);
                });
                this.filterList = Array.from(userKeysSet);
                this.pressCampService
                    .getPressCampMeetupPendingInvites(meetupKey)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((pendingInvites) => {
                        this.pressCampMeetupPendingInvites = pendingInvites.filter((pendingInvite) => {
                            return !this.pressCampMeetupParticipants.some(
                                (participant) => participant.userID === pendingInvite.userID
                            );
                        });
                        this.pressCampMeetupPendingInvites.forEach((pendingInvite) => {
                            userKeysSet.add(pendingInvite.userID);
                        });
                        this.filterList = Array.from(userKeysSet);
                    });
            });
    }

    ngAfterViewChecked() {
        if (
            this.pressCampMeetup !== null &&
            !this.dateTimeIsLoaded &&
            $(this.dateTimeName).length > 0
        ) {
            if (this.pressCampMeetup.latitude != null && this.pressCampMeetup.longitude != null) {
                this.initializeDateTimePicker();
            } else {
                this.initializeDateTimePicker('UTC');
            }
            this.dateTimeIsLoaded = true;
        }
    }

    initializeDateTimePicker(forcedTimezone?: string): void {
        if (forcedTimezone) {
            this.setupDatePicker(forcedTimezone);
        } else {
            if (this.pressCampMeetup.latitude != null && this.pressCampMeetup.longitude != null) {
                this.timeZoneService.getTimeZone(this.pressCampMeetup.latitude, this.pressCampMeetup.longitude)
                    .subscribe({
                        next: (tzData) => {
                            const meetupTimezone = tzData.timeZoneId;
                            this.setupDatePicker(meetupTimezone);
                        },
                        error: (error) => {
                            console.error('Error fetching timezone:', error);
                            this.setupDatePicker('UTC');
                        }
                    });
            } else {
                this.setupDatePicker('UTC');
            }
        }
    }

    setupDatePicker(timezone: string): void {
        if ($(this.dateTimeName).data('daterangepicker')) {
            $(this.dateTimeName).data('daterangepicker').remove();
        }
        let timeStr: string;
        const currentVal = $(this.dateTimeName).val();
        if (this.wallClockTime && this.wallClockTime.trim().length > 0) {
            timeStr = this.wallClockTime;
        } else if (currentVal && currentVal.trim().length > 0) {
            timeStr = currentVal;
        } else {
            timeStr = moment.tz(this.pressCampMeetup.timestamp, timezone).format(this.timeFormat);
        }
        const startMoment = moment.tz(timeStr, this.timeFormat, timezone);
        this.wallClockTime = startMoment.format(this.timeFormat);
        const pressCampMeetupEditComponent = this;
        $(this.dateTimeName).daterangepicker(
            {
                singleDatePicker: true,
                timePicker: true,
                timePicker24Hour: true,
                startDate: startMoment,
                locale: {
                    format: this.timeFormat
                }
            },
            function (startDate: moment.Moment) {
                pressCampMeetupEditComponent.pressCampMeetup.timestamp = startDate.valueOf();
                pressCampMeetupEditComponent.wallClockTime = startDate.format(pressCampMeetupEditComponent.timeFormat);
            }
        );
    }

    onLocationSelected(event: { lat: number; lng: number }): void {
        this.pressCampMeetup.latitude = event.lat;
        this.pressCampMeetup.longitude = event.lng;
        if (this.pressCampMeetup.trailAreaKey) {
            this.meetingPlaceName = 'Trail area head';
        } else {
            this.meetingPlaceName = `Lat: ${event.lat}, Lng: ${event.lng}`;
        }
        if (this.dateTimeIsLoaded) {
            this.timeZoneService.getTimeZone(event.lat, event.lng).subscribe({
                next: (tzData) => {
                    const newTimezone = tzData.timeZoneId;
                    const currentInput = $(this.dateTimeName).val();
                    if (currentInput && currentInput.trim().length > 0) {
                        const newMoment = moment.tz(currentInput, this.timeFormat, newTimezone);
                        this.pressCampMeetup.timestamp = newMoment.valueOf();

                        if ($(this.dateTimeName).data('daterangepicker')) {
                            $(this.dateTimeName).data('daterangepicker').setStartDate(newMoment);
                        }

                    }
                },
                error: (error) => {
                    console.error('Error fetching timezone on location change:', error);
                }
            });
        }
    }

    onAreaSelected(areaKey: string) {
        this.pressCampMeetup.trailAreaKey = areaKey;
        this.trailAreaService
            .getTrailAreaMapPointsWorld(areaKey)
            .pipe(take(1))
            .subscribe((area) => {
                this.selectedTrailAreaName = area.title;
                this.onLocationSelected({lat: area.latitude, lng: area.longitude});
            });
    }

    saveSettings(): void {
        this.savingSettings = true;
        this.pressCampService.savePressCampMeetupSettings(this.pressCampMeetup).then((success) => {
            if (success) {
                this.savingSettings = false;
                this.meetupSettingsSaved++;
            } else {
                window.alert('An error occurred while updating meetup. Please try again.');
                this.savingSettings = false;
            }
        });
    }

    onUploadedImageUrl(uploadedImageUrl: string): void {
        this.pressCampMeetup.imageUrl = uploadedImageUrl;
        this.pressCampService.savePressCampMeetupSettings(this.pressCampMeetup).then((success) => {
            if (!success) {
                window.alert('An error occurred while updating meetup cover image. Please try again.');
            }
        });
    }

    onGroupMembersSelected(inviteList: string[]): void {
        this.invitingParticipants = true;
        this.pressCampService
            .invitePressCampMeetupParticipants(this.pressCampMeetup.meetupId, inviteList)
            .then((success) => {
                if (success) {
                    this.invitingParticipants = false;
                    this.meetupParticipantsInvited++;
                } else {
                    this.invitingParticipants = false;
                    window.alert('An error occurred while inviting meetup participants. Please try again.');
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    openMapPicker() {
        this.isMapPickerOpen = true;
    }

    closeMapPicker() {
        this.isMapPickerOpen = false;
    }
}
