import {Injectable} from '@angular/core';
import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from '@angular/fire/compat/database';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';

import {Delaunay} from 'd3-delaunay';

// Services
import {FileService} from '../services/file.service';
import {GoogleGeocodeService} from '../services/google-geocode.service';

// Interfaces
import {Trail} from '../interfaces/trail';
import {
    BoundingBox,
    DeprecatedEventReference,
    DeprecatedTrailReference,
    LatLng,
    LatLngArray,
    SEED_TYPE_TRAIL_AREA,
    SeedObject,
    TrailArea,
    TrailAreaListEntry,
    TrailAreaMapPointsWorldModel,
    TrailAreaShapes
} from '../interfaces/trailArea';

import firebase from 'firebase/compat';
import * as PolygonTools from 'polygon-tools';
import polylineTool from '@pirxpilot/google-polyline';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class TrailAreaService {

    TRAIL_AREA_LOOKUP_DELAY: 513;

    trailAreaRef: firebase.database.Reference;
    trailAreaShapesRef: firebase.database.Reference;
    generalMapOverlaysRef: firebase.database.Reference;
    centerChangeLogRef: firebase.database.Reference;
    mapPointsWorldRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private fileService: FileService,
        private geocodeService: GoogleGeocodeService
    ) {
        this.trailAreaRef = this.db.database.ref('trackGroups');
        this.trailAreaShapesRef = this.db.database.ref('trailAreaShapes');
        this.generalMapOverlaysRef = this.db.database.ref('mapOverlays').child('general');
        this.centerChangeLogRef = this.db.database.ref('updates').child('changed');
        this.mapPointsWorldRef = this.db.database.ref("mapPointsWorld");
    }

    private static createSeedObjectsFromTrailAreas(trailAreas: TrailArea[]): SeedObject[] {
        const seeds: SeedObject[] = [];
        for (let i = 0; i < trailAreas.length; i++) {
            const candidateTrailArea = trailAreas[i];
            if (candidateTrailArea.boundsNorth && candidateTrailArea.boundsNorth > -90) {
                const point: LatLng = [
                    (candidateTrailArea.boundsNorth + candidateTrailArea.boundsSouth) / 2,
                    (candidateTrailArea.boundsEast + candidateTrailArea.boundsWest) / 2
                ];
                seeds.push({type: SEED_TYPE_TRAIL_AREA, key: candidateTrailArea.key, seedPoint: point});
            }
        }
        return seeds;
    }

    private static areasOverlap(areaOne: BoundingBox, areaTwo: BoundingBox): boolean {
        return ((areaOne.boundsEast > areaTwo.boundsWest) &&
            (areaOne.boundsWest < areaTwo.boundsEast) &&
            (areaOne.boundsNorth > areaTwo.boundsSouth) &&
            (areaOne.boundsSouth < areaTwo.boundsNorth));
    }

    doesTrailAreaHaveMapPoint(trailAreaKey: string): Observable<boolean> {
        return this.db.object<TrailAreaListEntry>(this.mapPointsWorldRef.child(trailAreaKey).ref)
            .snapshotChanges()
            .pipe(
                take(1),
                map((snap) => {
                    return snap.payload.exists();
                })
            );
    }

    getTrailAreaMapPointsWorld(trailAreaKey: string): Observable<TrailAreaMapPointsWorldModel> {
        return this.db.object<TrailAreaMapPointsWorldModel>(this.mapPointsWorldRef.child(trailAreaKey).ref)
            .snapshotChanges()
            .pipe(
                take(1),
                map((snap) => {
                    return snap.payload.val();
                })
            );
    }

    getFreeTrailAreas(): Observable<TrailArea[]> {
        return this.db.list<TrailArea>(this.trailAreaRef.ref, ref => ref.orderByChild('destinationKey').equalTo(null)).snapshotChanges()
            .pipe(map((trailAreaSnapshots) => trailAreaSnapshots.map((trailAreaSnapshot) => this.trailAreaFromSnap(trailAreaSnapshot))));
    }

    /**
     * Gets imported trail areas
     * @returns {Observable<TrailArea[]>} An array of all trail areas with the importKey defined.
     */
    getImportedTrailAreas(): Observable<TrailArea[]> {
        return this.db.list<TrailArea>(this.trailAreaRef.ref, ref => ref.orderByChild('importKey').startAt('!')).snapshotChanges()
            .pipe(
                take(1),
                map((trailAreaSnaps) => {
                    const trailAreas: TrailArea[] = [];
                    let delayCounter = 0;
                    trailAreaSnaps.forEach((trailAreaSnap) => {
                        const trailArea = this.trailAreaFromSnap(trailAreaSnap);
                        if (!trailArea.region) {
                            this.applyGeoMetaToTrailArea(trailArea, delayCounter++, false);
                        }
                        trailAreas.push(trailArea);
                    });
                    return trailAreas;
                })
            );
    }

    getTrailArea(trailAreaKey: string): Observable<TrailArea> {
        return this.db.object<TrailArea>(this.trailAreaRef.child(trailAreaKey).ref).snapshotChanges()
            .pipe(
                switchMap((trailAreaSnapshot) => {
                    const trailArea = this.trailAreaFromSnap(trailAreaSnapshot);
                    // Legacy handling
                    return this.handleLegacyOnTrailLoad(trailArea)
                        .then(() => trailArea);
                })
            );
    }

    getTrailAreas(trailAreaKeys: string[]): Observable<TrailArea[]> {
        return this.db.list<TrailArea>(this.trailAreaRef.ref).snapshotChanges()
            .pipe(
                take(1),
                map((trailAreaSnapshots) => {
                    const returnTrailAreas: TrailArea[] = [];
                    trailAreaKeys.forEach((trailAreaKey) => {
                        for (const trailAreaSnapshot of trailAreaSnapshots) {
                            if (trailAreaKey === trailAreaSnapshot.key) {
                                returnTrailAreas.push(this.trailAreaFromSnap(trailAreaSnapshot));
                                return;
                            }
                        }
                    });
                    return returnTrailAreas;
                })
            );
    }

    getTrailAreasForCountry(countryCode: string): Observable<TrailArea[]> {
        this.handleTrailAreasWithoutCountry();
        return this.db.list<TrailArea>(this.trailAreaRef.ref, ref => ref.orderByChild('country').equalTo(countryCode)).snapshotChanges()
            .pipe(
                take(1),
                map((trailAreaSnapshots) => trailAreaSnapshots.map((trailAreaSnapshot) => this.trailAreaFromSnap(trailAreaSnapshot)))
            );
    }

    getTrailAreaFromTrail(trail: Trail): Observable<TrailArea> {
        if (trail.trailAreaKey) {
            return this.getTrailArea(trail.trailAreaKey);
        }
        return null;
    }

    applyGeoMetaToTrailArea(trailArea: TrailArea, delayCounter: number, force: boolean): void {
        setTimeout(() => {
            if (trailArea.boundsNorth === -90 && !force) {
                return;
            }
            const latLng: string = (trailArea.boundsNorth + trailArea.boundsSouth) / 2 + ',' +
                (trailArea.boundsEast + trailArea.boundsWest) / 2;
            const languages: Set<string> = new Set();
            languages.add('da');
            Object.keys(trailArea.lang).forEach((languageCode) => languages.add(languageCode));
            const lookupObservable = this.geocodeService.lookupLatLng(latLng, 'en-GB', languages);
            return lookupObservable
                .subscribe((lookup) => {
                    const lang: { [languageCode: string]: { [textKey: string]: string } } = trailArea.lang ?? {};
                    let country: string = trailArea.country ?? null;
                    let countryName: string = trailArea.countryName ?? null;
                    let region: string = trailArea.region ?? null;
                    if (lookup === null) {
                        console.log(trailArea.name, trailArea.key, region, countryName, country);
                        region = null;
                        Object.keys(trailArea.lang).forEach((languageCode: string) => lang[languageCode]['region'] = null);
                    } else {
                        country = lookup.countryCode ?? country;
                        countryName = lookup.countryName ?? countryName;
                        region = lookup.regionName ?? region;
                        Object.keys(lookup.lang).forEach((languageCode: string) => {
                            if (!lang[languageCode]) {
                                lang[languageCode] = {};
                            }
                            lang[languageCode]['region'] = lookup.lang[languageCode].regionName ?? null;
                            lang[languageCode]['countryName'] = lookup.lang[languageCode].countryName ?? null;
                        });
                    }
                    const updateTrailArea: TrailArea = <TrailArea>{
                        lang: lang,
                        country: country,
                        countryName: countryName,
                        region: region
                    };
                    return this.trailAreaRef.child(trailArea.key).update(updateTrailArea);
                });
        }, this.TRAIL_AREA_LOOKUP_DELAY * delayCounter + 17);
    }

    addLanguage(trailArea: TrailArea, languageCode: string): Observable<TrailArea> {
        if (trailArea.boundsNorth === -90) {
            if (!trailArea.lang) {
                trailArea.lang = {};
            }
            if (!trailArea.lang[languageCode]) {
                trailArea.lang[languageCode] = {};
            }
            return of(trailArea);
        }
        const latLng: string = (trailArea.boundsNorth + trailArea.boundsSouth) / 2 + ',' +
            (trailArea.boundsEast + trailArea.boundsWest) / 2;
        const lookupObservable = this.geocodeService.lookupLatLng(latLng, languageCode);
        return lookupObservable
            .pipe(
                map((lookup) => {
                    if (lookup === null) {
                        return;
                    }
                    if (!trailArea.lang) {
                        trailArea.lang = {};
                    }
                    if (!trailArea.lang[languageCode]) {
                        trailArea.lang[languageCode] = {};
                    }
                    trailArea.lang[languageCode]['region'] = lookup.regionName ?? trailArea.lang[languageCode]['region'] ?? null;
                    return trailArea;
                })
            );
    }

    updateTexts(trailArea: TrailArea): Promise<void> {
        // Delete texts that are blank
        for (const lang in trailArea.lang) {
            if (typeof trailArea.lang[lang] === 'object') {
                for (const textKey in trailArea.lang[lang]) {
                    if (trailArea.lang[lang].hasOwnProperty(textKey) &&
                        (typeof trailArea.lang[lang][textKey] !== 'string' || trailArea.lang[lang][textKey] === '') ||
                        !trailArea.lang[lang].hasOwnProperty(textKey)) {
                        trailArea.lang[lang][textKey] = null;
                    }
                }
            }
        }

        console.log(trailArea)
        return this.trailAreaRef.child(trailArea.key).update({
            name: trailArea.name || "",
            donateMotivator: trailArea.donateMotivator || "",
            articleBody: trailArea.articleBody || "",
            warningText: trailArea.warningText || "",
            lang: trailArea.lang
        });
    }

    updateAbout(trailArea: TrailArea): Promise<void> {
        const updateObject: TrailArea = <TrailArea>{
            dateCreated: trailArea.dateCreated,
            hashtag: trailArea.hashtag,
            isAccessibleForFree: (typeof trailArea.isAccessibleForFree === 'boolean') ? trailArea.isAccessibleForFree : null,
            maintainers: trailArea.maintainers,
            owner: trailArea.owner,
            sponsor: trailArea.sponsor,
            openingHours: trailArea.openingHours
        };
        return this.trailAreaRef.child(trailArea.key).update(updateObject);
    }

    updateImage(trailArea: TrailArea, newImageUrl: string): Promise<any> {
        let deleteOnlinePromise: Promise<void>;
        if (trailArea.imageUrl !== null) {
            deleteOnlinePromise = this.fileService.deleteStorageFile(trailArea.imageUrl)
                .catch((err) => console.error('Couldn\'t delete old file' + trailArea.imageUrl, err));
        } else {
            deleteOnlinePromise = Promise.resolve();
        }

        const setImageUrlPromise = this.trailAreaRef.child(trailArea.key).child('imageUrl').set(newImageUrl);
        return Promise.all([deleteOnlinePromise, setImageUrlPromise]);
    }

    updateHqMap(trailArea: TrailArea): Promise<any> {
        const updateElement: TrailArea = <TrailArea>{
            hqMapMinimumZ: trailArea.hqMapMinimumZ,
            hqMapMaximumZ: trailArea.hqMapMaximumZ,
            hqMapUrlTemplate: trailArea.hqMapUrlTemplate,
            hqMapIsGeneral: trailArea.hqMapIsGeneral
        };
        return this.trailAreaRef.child(trailArea.key).update(updateElement)
            .then(() => {
                if (updateElement.hqMapIsGeneral) {
                    const oneYearFromNow = moment().add(1, 'y').valueOf();
                    const overlayUpdateElement = {
                        boundsEast: trailArea.boundsEast,
                        boundsNorth: trailArea.boundsNorth,
                        boundsSouth: trailArea.boundsSouth,
                        boundsWest: trailArea.boundsWest,
                        expires: oneYearFromNow,
                        maximumZ: trailArea.hqMapMaximumZ,
                        minimumZ: trailArea.hqMapMinimumZ,
                        urlTemplate: trailArea.hqMapUrlTemplate
                    };
                    return this.generalMapOverlaysRef.child(trailArea.key).update(overlayUpdateElement);
                }
            });
    }

    /**
     * Adds a trail to a trail area
     * @param {string} trailKey The key of the trail to add.
     * @param {TrailArea} trailArea The trail area to add to.
     * @returns {Promise<boolean>} A promise when the trail has been added to the trail area.
     */
    addTrail(trailKey: string, trailArea: TrailArea): Promise<any> {
        return this.trailAreaRef.child(trailArea.key).child('trailKeys').child(trailKey).set(true);
    }

    /**
     * Removes a trail from a trail area
     * @param {string} trailKey The key of the trail to remove.
     * @param {TrailArea} trailArea The trail area to remove from.
     * @returns {Promise<void>} A promise when the trail has been remove from the trail area.
     */
    removeTrail(trailKey: string, trailArea: TrailArea): Promise<void> {
        console.warn('Removing trail from trail area', trailKey, trailArea);
        return this.trailAreaRef.child(trailArea.key).child('trailKeys').child(trailKey).remove();
    }

    /**
     * Creates a trail area using name alone.
     * @param {string} name Name of the new trail area.
     * @param {string} countryCode
     * @returns {string} Return the key of the new trail area.
     */
    createTrailArea(name: string, countryCode: string): firebase.database.ThenableReference {
        const dateCreated = new Date();
        const newTrailArea: TrailArea = {
            name: name,
            country: countryCode,
            dateCreated: dateCreated.getFullYear() + '-' +
                (dateCreated.getMonth() + 1).toString().padStart(2, '0') + '-' +
                dateCreated.getDate().toString().padStart(2, '0')
        };
        return this.trailAreaRef.push(newTrailArea);
    }

    // updateHqMapPayment(trailAreaKey: string, kioskProductKey: string): Promise<any> {
    //     return this.trailAreaRef.child(trailAreaKey).child('hqMapProductKey').set(kioskProductKey);
    // }

    /**
     * Will create a trail area from a (handmade) draft.
     * @param {TrailArea} areaDraft A (handmade) trail area draft.
     * @returns {string} The created trail area key.
     */
    createTrailAreaFromDraft(areaDraft: TrailArea): string {
        const trailAreaCreatedThenableReference = this.trailAreaRef.push(areaDraft);
        return trailAreaCreatedThenableReference.key;
    }

    /**
     * Deletes a trail area.
     * @param {string} key
     * @returns {Promise<void>} A promise, which is resolved when the trail area has been deleted.
     */
    deleteTrailArea(key: string): Promise<void> {
        return this.trailAreaRef.child(key).remove();
    }

    /**
     * Adds an event to a trail area
     * @param {string} trailAreaKey
     * @param {string} eventKey
     * @returns {Promise<void>}
     */
    addEventToTrailArea(trailAreaKey: string, eventKey: string): Promise<void> {
        return this.trailAreaRef.child(trailAreaKey).child('eventKeys').child(eventKey).set(true);
    }

    removeEventFromTrailArea(trailAreaKey: string, eventKey: string): Promise<void> {
        return this.trailAreaRef.child(trailAreaKey).child('eventKeys').child(eventKey).remove();
    }

    disableEvents(trailAreaKey: string): Promise<void> {
        return this.trailAreaRef.child(trailAreaKey).child('eventsEnabled').remove();
    }

    enableEvents(trailAreaKey: string): Promise<void> {
        return this.trailAreaRef.child(trailAreaKey).child('eventsEnabled').set(true);
    }

    addedToDestination(trailArea: TrailArea, destinationKey: string): Promise<void> {
        if (typeof trailArea.destinationKey === 'string') {
            if (trailArea.destinationKey === destinationKey) {
                return Promise.resolve();
            }
            return Promise.reject('Trail area already connected to destination');
        }
        return this.trailAreaRef.child(trailArea.key).child('destinationKey').set(destinationKey);
    }

    removedFromDestination(trailAreaKey: string): Promise<void> {
        return this.trailAreaRef.child(trailAreaKey).child('destinationKey').remove();
    }

    getCountrySeedObjects(countryCode: string): Observable<SeedObject[]> {
        return this.db.list<TrailArea>(this.trailAreaRef.ref, (ref) => ref.orderByChild('country').equalTo(countryCode)).snapshotChanges()
            .pipe(
                take(1),
                map((trailAreasSnap) => trailAreasSnap.map((t) => this.trailAreaFromSnap(t))),
                map((countryTrailAreas) => TrailAreaService.createSeedObjectsFromTrailAreas(countryTrailAreas))
            );
    }

    loadTrailAreaShapes(collectionName: string): Observable<TrailAreaShapes> {
        return this.db.list<{ [indexKey: string]: string }>(this.trailAreaShapesRef.child(collectionName).ref).snapshotChanges()
            .pipe(
                take(1),
                map((trailAreasEplsSnaps) => {
                    const trailAreasShapes: TrailAreaShapes = {};
                    trailAreasEplsSnaps.forEach((trailAreaEplsSnap) => {
                        const trailAreaShapes: google.maps.LatLngLiteral[][] = [];
                        Object.values(trailAreaEplsSnap.payload.val()).forEach((epl) => {
                            const polyline: LatLngArray = polylineTool.decode(epl);
                            const trailAreaShape: google.maps.LatLngLiteral[] = [];
                            polyline.forEach((coordinate) => {
                                trailAreaShape.push({lat: coordinate[1], lng: coordinate[0]});
                            });
                            trailAreaShapes.push(trailAreaShape);
                        });
                        trailAreasShapes[trailAreaEplsSnap.key] = trailAreaShapes;
                    });
                    return trailAreasShapes;
                })
            );
    }

    createShapes(collectionName: string, seedObjects: SeedObject[],
                 north: number, south: number, east: number, west: number,
                 landShapes: LatLngArray[]): Promise<any> {
        const shapeBoundingBoxes: BoundingBox[] = [];
        for (let l = 0; l < landShapes.length; l++) {
            shapeBoundingBoxes[l] = this.getBoundingBox(landShapes[l]);
        }
        const seeds = [];
        for (let s = 0; s < seedObjects.length; s++) {
            const point = [
                seedObjects[s].seedPoint[1], // longitude
                seedObjects[s].seedPoint[0] // latitude
            ];
            seeds.push(point);
        }
        const delaunay = Delaunay.from(seeds);
        const voronoi = delaunay.voronoi([west, south, east, north]);

        const encodedPolygons: { [key: string]: string[] } = {};
        for (let i = 0; i < seedObjects.length; i++) {
            const voronoiCell: LatLngArray = <LatLngArray>voronoi.cellPolygon(i);
            if (voronoiCell === null) {
                console.error(i, 'Skipping bad cell', seedObjects[i].type, seedObjects[i].key);
                continue;
            }
            const cellBoundingBox: BoundingBox = this.getBoundingBox(voronoiCell);
            const shapeEpls = [];
            for (let j = 0; j < landShapes.length; j++) {
                if (!TrailAreaService.areasOverlap(cellBoundingBox, shapeBoundingBoxes[j])) {
                    continue;
                }
                const intersection: LatLngArray = PolygonTools.polygon.intersection(voronoiCell, landShapes[j]);
                if (intersection.length > 0) {
                    for (let k = 0; k < intersection.length; k++) {
                        const epl = polylineTool.encode(intersection[k]);
                        shapeEpls.push(epl);
                    }
                    console.log(`Trail area ${(i + 1)}/${seedObjects.length}, land shape ${(j + 1)}/${landShapes.length} has ${intersection.length} intersections. Progress: ${(Math.round((i * landShapes.length + j + 1) / (seedObjects.length * landShapes.length) * 10000) / 100)}%`);
                }
            }
            encodedPolygons[seedObjects[i].key] = shapeEpls;
        }
        console.log('Done - saving');
        return this.savePolygons(encodedPolygons, this.trailAreaShapesRef.child(collectionName));
    }

    clearChangesLog(countryCode): Promise<void> {
        return this.centerChangeLogRef.child(countryCode).remove();
    }

    getCountryCodeNeedingVoronoiUpdate(): Observable<string[]> {
        return this.db.list(this.centerChangeLogRef.ref).snapshotChanges()
            .pipe(
                map((changesSnap) => {
                    const countryCodes: string[] = [];
                    changesSnap.forEach((changeSnap) => countryCodes.push(changeSnap.key));
                    return countryCodes;
                })
            );
    }

    getAllTrailAreas(): Observable<TrailArea[]> {
        return this.db.list<TrailArea>(this.trailAreaRef.ref).snapshotChanges()
            .pipe(
                take(1),
                map((trailAreaSnapshots) => trailAreaSnapshots.map((trailAreaSnapshot) => this.trailAreaFromSnap(trailAreaSnapshot)))
            );
    }

    private handleTrailAreasWithoutCountry(): void {
        this.db.list<TrailArea>(this.trailAreaRef.ref, ref => ref.orderByChild('country').equalTo(null)).snapshotChanges()
            .pipe(
                take(1),
                map((trailAreaSnapshots) => {
                    let delayCounter = 0;
                    trailAreaSnapshots.forEach((trailAreaSnapshot) => {
                        const trailArea: TrailArea = this.trailAreaFromSnap(trailAreaSnapshot);
                        if (trailArea.boundsNorth === -90) {
                            console.warn('Bounds not set for trail area', trailArea);
                            return this.handleLegacyOnTrailLoad(trailArea);
                        }
                        this.applyGeoMetaToTrailArea(trailArea, delayCounter++, false);
                    });
                    return;
                })
            )
            .subscribe(() => null);
    }

    private handleLegacyOnTrailLoad(trailArea: TrailArea): Promise<any> {
        if (!trailArea.region) {
            this.applyGeoMetaToTrailArea(trailArea, 0, true);
        }
        return Promise.resolve();
    }

    private trailAreaFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<TrailArea>>): TrailArea {
        const trailArea: TrailArea = snapshot.payload.val();
        trailArea.key = snapshot.key;

        trailArea.country = trailArea.country || null;
        trailArea.region = trailArea.region || null;
        trailArea.destinationKey = trailArea.destinationKey ?? null;

        trailArea.name = trailArea.name || trailArea.groupName || 'New Trail Area';
        trailArea.articleBody = trailArea.articleBody || null;
        trailArea.lang = trailArea.lang || {};
        trailArea.imageUrl = trailArea.imageUrl || null;

        // Deprecated
        trailArea.tracks = trailArea.tracks ? Object.values(trailArea.tracks) : [];
        // Handling a better trail key structure
        trailArea.trailKeys = this.getTrailKeys(trailArea.trailKeys, trailArea.tracks);

        trailArea.events = trailArea.events ? Object.values(trailArea.events) : [];
        trailArea.eventKeys = this.getEventKeys(trailArea.eventKeys, trailArea.events);
        trailArea.eventsEnabled = trailArea.eventsEnabled ?? false;

        trailArea.dateCreated = trailArea.dateCreated ?? null;

        trailArea.hashtag = trailArea.hashtag ?? null;
        trailArea.isAccessibleForFree = (typeof (trailArea.isAccessibleForFree) === 'boolean') ? trailArea.isAccessibleForFree : null;
        trailArea.maintainers = trailArea.maintainers ?? null;
        trailArea.owner = trailArea.owner ?? null;
        trailArea.sponsor = trailArea.sponsor ?? null;
        trailArea.openingHours = trailArea.openingHours ?? null;

        trailArea.boundsNorth = trailArea.boundsNorth ?? -90;
        trailArea.boundsSouth = trailArea.boundsSouth ?? 90;
        trailArea.boundsEast = trailArea.boundsEast ?? -180;
        trailArea.boundsWest = trailArea.boundsWest ?? 180;
        return trailArea;
    }

    searchByName(searchTerm: string): Observable<TrailArea[]> {
        return this.db.list<TrailArea>(`trackGroups`, ref => ref.orderByChild('name').startAt(searchTerm).endAt(searchTerm + '\uf8ff'))
            .snapshotChanges()
            .pipe(
                map(actions =>
                    actions.map(a => {
                        const data = a.payload.val() as TrailArea;
                        const key = a.key;
                        return {...data, key};
                    })
                )
            );
    }


    private getTrailKeys(trailKeys: { [trailKey: string]: boolean }, tracks: DeprecatedTrailReference[]): { [trailKey: string]: boolean } {
        if (typeof trailKeys !== 'object' || trailKeys === null) {
            trailKeys = {};
        }
        tracks.forEach((deprecatedTrailRef) => {
            trailKeys[deprecatedTrailRef.trackId] = true;
        });
        return trailKeys;
    }

    private getEventKeys(eventKeys: { [eventKey: string]: boolean },
                         events: DeprecatedEventReference[]): { [trailKey: string]: boolean } {
        if (typeof eventKeys !== 'object' || eventKeys === null) {
            eventKeys = {};
        }
        events.forEach((deprecatedEventRef) => {
            eventKeys[deprecatedEventRef.eventID] = true;
        });
        return eventKeys;
    }

    private savePolygons(encodedPolygons: { [key: string]: string[] }, collectionRef: firebase.database.Reference): Promise<any> {
        const deletePromise = collectionRef.ref.remove();

        // Push new/updated encoded polygons
        return deletePromise
            .then(() => {
                const promises: Promise<any>[] = [];
                Object.entries(encodedPolygons).forEach(([key, epls]) => {
                    promises.push(collectionRef.child(key).set(epls));
                });
                return Promise.all(promises);
            });
    }

    private getBoundingBox(latLngArray: LatLngArray): BoundingBox {
        let north = -90;
        let south = 90;
        let east = -180;
        let west = 180;
        latLngArray.forEach((latLng) => {
            if (latLng[1] > north) {
                north = latLng[1];
            }
            if (latLng[1] < south) {
                south = latLng[1];
            }
            if (latLng[0] > east) {
                east = latLng[0];
            }
            if (latLng[0] < west) {
                west = latLng[0];
            }
        });
        return {boundsNorth: north, boundsSouth: south, boundsEast: east, boundsWest: west};
    }
}
