<div class="content-wrapper">
    <app-content-header-section contentPageId="rootDashboard" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-12">

                    <div class="info-box">
                        <span class="info-box-icon bg-black"><i class="fas fa-hand-holding-usd"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Donations</span>
                            <span class="info-box-number" *ngIf="donationKpi">Beneficiaries: {{ donationKpi.beneficiaries }}
                                | Donors: {{ donationKpi.donors }}</span>
                            <span class="info-box-number" *ngIf="donationKpi">Potential: {{ donationKpi.amount }} DKK</span>
                        </div>
                        <div class="overlay" *ngIf="!donationKpi">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-lg-3 col-md-6 col-12">

                    <div class="info-box">
                        <span class="info-box-icon bg-maroon"><i class="fas fa-gem"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Premium</span>
                            <span class="info-box-number" *ngIf="premiumKpi">All time: {{ premiumKpi.allTimeMembers }}
                                | Now: {{ premiumKpi.currentMembers }}</span>
                            <span class="info-box-number" *ngIf="premiumKpi">Last week: {{ premiumKpi.lastWeekNew }}
                                new | {{ premiumKpi.lastWeekRenewals }} renewals</span>
                        </div>
                        <div class="overlay" *ngIf="!premiumKpi">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-lg-3 col-md-6 col-12">

                    <div class="info-box">
                        <span class="info-box-icon bg-red"><i class="fas fa-cash-register"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Kiosk Orders</span>
                            <span class="info-box-number" *ngIf="kioskKpi">Total: {{ kioskKpi.total }}</span>
                            <span class="info-box-number" *ngIf="kioskKpi">Last week: {{ kioskKpi.lastWeek }}</span>
                        </div>
                        <div class="overlay" *ngIf="!kioskKpi">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-lg-3 col-md-6 col-12">

                    <div class="info-box">
                        <span class="info-box-icon bg-info"><i class="fas fa-star-half-alt"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Reviews</span>
                            <span class="info-box-number" *ngIf="reviewKpi">Total: {{ reviewKpi.total }}</span>
                            <span class="info-box-number" *ngIf="reviewKpi">Last week: {{ reviewKpi.lastWeek }}</span>
                        </div>
                        <div class="overlay" *ngIf="!reviewKpi">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-6">

                    <div class="card card-outline card-orange">
                        <div class="card-header">
                            <h3 class="card-title">New Users</h3>
                        </div>
                        <div class="card-body">
                            <canvas #kpiNewUsers></canvas>
                        </div>
                        <div class="overlay" *ngIf="!newUsersChart">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-xl-6 content">

                    <div class="card card-outline card-strava">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i [ngClass]="layout.getPluralIcon('ride')"></i> Ride stats - total, from-platform and to-Strava-synced
                            </h3>
                        </div>
                        <div class="card-body">
                            <canvas #stravaSyncedRides></canvas>
                        </div>
                        <div class="overlay" *ngIf="!noSyncedRidesChart">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-md-12 col-xl-6 content">

                    <div class="card card-outline card-strava">
                        <div class="card-header">
                            <h3 class="card-title">Strava Sync Enabled Performance</h3>
                        </div>
                        <div class="card-body">
                            <canvas #stravaSyncButton></canvas>
                        </div>
                        <div class="overlay" *ngIf="!stravaSyncButtonChart">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-xl-6">

                    <div class="card card-outline card-maroon">
                        <div class="card-header">
                            <h3 class="card-title">Number of MeetUps</h3>
                        </div>
                        <div class="card-body">
                            <canvas #kpiNoMeetUps></canvas>
                        </div>
                        <div class="overlay" *ngIf="!noMeetUpsChart">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-md-4">

                    <div class="card card-outline card-dark">
                        <div class="card-header">
                            <h3 class="card-title">The Team <small>of Root Users</small></h3>
                        </div>
                        <div class="card-body p-0">
                            <table class="table" *ngIf="rootUsers">
                                <tbody>
                                <tr *ngFor="let rootUser of rootUsers">
                                    <td><i class="fas fa-user-secret"></i> {{ rootUser.name }} <span
                                            class="badge badge-default">{{ rootUser.userID }}</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="overlay" *ngIf="!rootUsers">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-md-4">

                    <div class="card card-outline card-navy">
                        <div class="card-header">
                            <h3 class="card-title">Links</h3>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li><a href="https://blog.mtbutd.com/wp-admin/" target="admin_blog">Admin til Blog og Shop</a></li>
                                <li><a href="https://ontrail.dk/wp-admin" target="admin_ontrail">Admin: On Trail</a></li>
                                <li><a href="https://222cycles.dk/" target="222cycles">222Cycles</a></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>
