<h3 *ngIf="authService.user.isRoot && handlerTitle"><i class="fas fa-cash-register"></i> {{ handlerTitle }}</h3>

<div *ngIf="authService.user.isRoot && kioskProductExists === false" class="row">
    <div class="col-md-6 col-lg-4">

        <div *ngIf="currencies" [ngClass]="'card card-outline card-'+colorTheme">
            <div class="card-header">
                <h3 class="card-title">+ <i class="fas fa-cash-register"></i> Add payment</h3>
            </div>
            <div class="card-body">

                <div class="form-group">
                    <label class="control-label" for="currencySelector"><i class="fas fa-cash-register mr-2"></i> Default currency</label>
                    <div class="input-group">
                        <select [(ngModel)]="defaultCurrency" class="form-control" id="currencySelector" aria-describedby="currencySelectorHelp">
                            <option *ngFor="let currency of currencies" [ngValue]="currency">{{ currency.name }}</option>
                        </select>
                    </div>
                    <small id="currencySelectorHelp" class="form-text text-muted">Select default currency.</small>
                </div>

            </div>
            <div class="card-footer">
                <button (click)="createKioskProduct()" class="btn btn-danger">Setup payment</button>
            </div>
        </div>

    </div>
</div>
<div class="row" *ngIf="authService.user.isRoot && kioskProductExists === true">
    <div class="col-md-6 col-lg-4">

        <app-translatable-texts textsName="Kiosk Product" [colorTheme]="colorTheme" [infoTrigger]="textsChangedInfo" [textModel]="textModel"
                                [textObject]="kioskProduct" (alteredTextObject)="onAlteredTextObject($event)"></app-translatable-texts>

    </div>
    <div class="col-md-6 col-lg-4">

        <div [ngClass]="'card card-outline card-tabs card-' + colorTheme" *ngIf="currencies && countryInitialised">
            <div class="card-header border-bottom-0">
                <h3 class="card-title"><i class="fas fa-tags"></i> Prices</h3>
            </div>

            <div class="card-header p-0 pt-1 border-bottom-0">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <a href="#tab_default" aria-controls="#tab_default" aria-selected="true" data-toggle="pill" role="tab"
                           class="nav-link active">Default</a>
                    </li>
                    <li *ngFor="let country of usedCountries" class="nav-item">
                        <a [id]="'tab_'+country+'_link'" [href]="'#tab_'+country" [attr.aria-controls]="'#tab_'+country" aria-selected="false"
                           data-toggle="pill" role="tab" class="nav-link">{{ country | uppercase }}</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a id="country_dropdown" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false" role="button"
                           aria-haspopup="true">
                            <i class="far fa-flag"></i>+
                        </a>
                        <div aria-labelledby="country_dropdown" class="dropdown-menu">
                            <span class="dropdown-header">Add prices to country</span>
                            <a *ngFor="let country of unusedCountries" class="dropdown-item"
                               (click)="addCountry(country)">Add {{ countryNames[country] }}</a>
                        </div>
                    </li>
                </ul>
            </div>


            <div class="card-body">
                <app-alert-info-box infoTrigger="{{ priceTrigger }}">
                    <h4>Prices saved successfully</h4>
                </app-alert-info-box>

                <div class="tab-content">

                    <div id="tab_default" class="tab-pane fade active show" role="tabpanel">
                        <div class="form-group form-group-sm">
                            <label for="currency" class="control-label">Default currency:</label>
                            <select [(ngModel)]="kioskProduct.defaultPriceStructure.currency" (change)="updateCurrency(null)" id="currency"
                                    aria-describedby="currencyHelp" class="form-control">
                                <option *ngFor="let currency of currencies" [value]="currency.currency">{{ currency.name }}</option>
                            </select>
                            <small id="currencyHelp" class="form-text text-muted">Select default currency.</small>
                        </div>

                        <div class="form-group form-group-sm">
                            <label class="control-label">Default prices:</label>
                            <ng-container *ngFor="let productVariant of productVariants">
                                <div *ngIf="productVariant.variantType !== FOLLOW_MEMBERSHIP" class="input-group input-group-sm pb-1">
                                    <span class="input-group-prepend"><span class="input-group-text">{{ productVariant.name }}</span></span>
                                    <input type="number" min="0" step="0.05"
                                           [(ngModel)]="kioskProduct.defaultPriceStructure.variants[productVariant.key].amount"
                                           (change)="priceChanged()" (keyup)="priceChanged()" class="form-control text-right" placeholder="100.00">
                                    <span class="input-group-append"><span
                                            class="input-group-text">{{ kioskProduct.defaultPriceStructure.currency }}</span></span>
                                </div>
                            </ng-container>
                            <small class="form-text text-muted">Enter default price per variant.</small>
                        </div>

                        <div class="form-group form-group-sm">
                            <label class="control-label">Sale prices:</label>
                            <ng-container *ngFor="let productVariant of productVariants">
                                <div *ngIf="productVariant.variantType !== FOLLOW_MEMBERSHIP" class="input-group input-group-sm pb-1">
                                    <span class="input-group-prepend"><span class="input-group-text">{{ productVariant.name }}</span></span>
                                    <input type="number" min="0" step="0.05"
                                           [disabled]="!kioskProduct.defaultPriceStructure.variants[productVariant.key].amount"
                                           [(ngModel)]="kioskProduct.defaultPriceStructure.variants[productVariant.key].saleAmount"
                                           (change)="priceChanged()" (keyup)="priceChanged()" class="form-control text-right" placeholder="100.00">
                                    <span class="input-group-append"><span
                                            class="input-group-text">{{ kioskProduct.defaultPriceStructure.currency }}</span></span>
                                </div>
                            </ng-container>
                            <small class="form-text text-muted">Enter sale price per variant.</small>
                        </div>

                        <div *ngIf="organisations">
                            <hr>
                            <h4><i class="fa fa-university"></i> Organisation sale prices:</h4>
                            <div *ngFor="let organisation of organisations" class="form-group form-group-sm">
                                <label class="control-label">{{ organisation.name }}:</label>
                                <div *ngFor="let productVariant of productVariants" class="input-group input-group-sm pb-1">
                                    <span class="input-group-prepend"><span class="input-group-text">{{ productVariant.name }}</span></span>
                                    <input type="number" min="0" step="0.05"
                                           [(ngModel)]="kioskProduct.defaultPriceStructure.variants[productVariant.key].organisations[organisation.key]"
                                           (change)="priceChanged()" (keyup)="priceChanged()" class="form-control text-right" placeholder="100.00">
                                    <span class="input-group-append"><span
                                            class="input-group-text">{{ kioskProduct.defaultPriceStructure.currency }}</span></span>
                                </div>
                                <small class="form-text text-muted">Enter {{ organisation.name }} price per variant.</small>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let country of usedCountries" class="tab-pane" id="tab_{{ country }}">
                        <div class="form-group form-group-sm">
                            <label [for]="'currency' + country" class="control-label">Default currency ({{ countryNames[country] }}):</label>
                            <select [(ngModel)]="kioskProduct.countryPriceStructures[country].currency" (change)="updateCurrency(country)"
                                    [id]="'currency' + country" [attr.aria-describedby]="'currencyHelp' + country" class="form-control">
                                <option *ngFor="let currency of currencies" [value]="currency.currency">{{ currency.name }}</option>
                            </select>
                            <small [id]="'currencyHelp' + country" class="form-text text-muted">Select default currency
                                for {{ countryNames[country] }}.</small>
                        </div>

                        <div class="form-group form-group-sm">
                            <label class="control-label">Default prices ({{ countryNames[country] }}):</label>
                            <ng-container *ngFor="let productVariant of productVariants">
                                <div *ngIf="productVariant.variantType !== FOLLOW_MEMBERSHIP" class="input-group input-group-sm pb-1">
                                    <span class="input-group-prepend"><span class="input-group-text">{{ productVariant.name }}</span></span>
                                    <input type="number" min="0" step="0.05"
                                           [(ngModel)]="kioskProduct.countryPriceStructures[country].variants[productVariant.key].amount"
                                           (change)="priceChanged()" (keyup)="priceChanged()" class="form-control text-right" placeholder="100.00">
                                    <span class="input-group-append"><span
                                            class="input-group-text">{{ kioskProduct.countryPriceStructures[country].currency }}</span></span>
                                </div>
                            </ng-container>
                            <small class="form-text text-muted">Enter default price per variant for {{ countryNames[country] }}.</small>
                        </div>

                        <div class="form-group form-group-sm">
                            <label class="control-label">Sale prices ({{ countryNames[country] }}):</label>
                            <ng-container *ngFor="let productVariant of productVariants">
                                <div *ngIf="productVariant.variantType !== FOLLOW_MEMBERSHIP" class="input-group input-group-sm pb-1">
                                    <span class="input-group-prepend"><span class="input-group-text">{{ productVariant.name }}</span></span>
                                    <input type="number" min="0" step="0.05"
                                           [disabled]="!kioskProduct.countryPriceStructures[country].variants[productVariant.key].amount"
                                           [(ngModel)]="kioskProduct.countryPriceStructures[country].variants[productVariant.key].saleAmount"
                                           (change)="priceChanged()" (keyup)="priceChanged()" class="form-control text-right" placeholder="100.00">
                                    <span class="input-group-append"><span
                                            class="input-group-text">{{ kioskProduct.countryPriceStructures[country].currency }}</span></span>
                                </div>
                            </ng-container>
                            <small class="form-text text-muted">Enter sale price per variant for {{ countryNames[country] }}.</small>
                        </div>

                        <div *ngIf="organisations">
                            <hr>
                            <h4><i class="fa fa-university"></i> Organisation sale prices ({{ countryNames[country] }}):</h4>
                            <div *ngFor="let organisation of organisations" class="form-group form-group-sm">
                                <label class="control-label">{{ organisation.name }} ({{ countryNames[country] }}):</label>
                                <div *ngFor="let productVariant of productVariants" class="input-group input-group-sm pb-1">
                                    <span class="input-group-prepend"><span class="input-group-text">{{ productVariant.name }}</span></span>
                                    <input type="number" min="0" step="0.05"
                                           [(ngModel)]="kioskProduct.countryPriceStructures[country].variants[productVariant.key].organisations[organisation.key]"
                                           (change)="priceChanged()" (keyup)="priceChanged()" class="form-control text-right" placeholder="100.00">
                                    <span class="input-group-append"><span
                                            class="input-group-text">{{ kioskProduct.countryPriceStructures[country].currency }}</span></span>
                                </div>
                                <small class="form-text text-muted">Enter {{ organisation.name }} price per variant in {{ countryNames[country] }}
                                    .</small>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="card-footer">
                <button [disabled]="disableSavePrices" (click)="savePrices()" class="btn btn-{{ colorTheme }}">Save prices</button>
            </div>
        </div>

    </div>
    <div class="col-md-6 col-lg-4">

        <div [ngClass]="'card card-outline card-'+colorTheme" *ngIf="enableActivationCodes && kioskProductExists">
            <div class="card-header">
                <h3 class="card-title">+ <i class="fa fa-ticket"></i> Generate activation code<span *ngIf="activationCodeCount>1">s</span></h3>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <label class="control-label" for="productVariantSelector">Product variant to activate</label>
                    <div class="input-group">
                        <select [(ngModel)]="activationCodeVariant" class="form-control" id="productVariantSelector"
                                aria-describedby="productVariantSelectorHelp">
                            <option *ngFor="let productVariant of productVariants" [ngValue]="productVariant">{{ productVariant.name }}</option>
                        </select>
                    </div>
                    <small id="productVariantSelectorHelp" class="form-text text-muted">What to activate with the generated activation codes.</small>
                </div>
                <div class="form-group">
                    <label class="control-label" for="codeCountInput">Number of activation codes to create</label>
                    <div class="input-group">
                        <input [(ngModel)]="activationCodeCount" class="form-control" type="number" name="activationCodeCount" min="1" max="100"
                               id="codeCountInput" aria-describedby="codeCountInputHelp">
                        <span class="input-group-append"><span class="input-group-text">activation code<span
                                *ngIf="activationCodeCount>1">s</span></span></span>
                    </div>
                    <small id="codeCountInputHelp" class="form-text text-muted">Max 100 at a time.</small>
                </div>
                <div class="form-group">
                    <label class="control-label" for="codeLengthInput">Activation code length</label>
                    <div class="input-group">
                        <input [(ngModel)]="activationCodeLength" class="form-control" type="number" name="activationCodeLength" min="6" max="10"
                               id="codeLengthInput" aria-describedby="codeLengthInputHelp">
                        <span class="input-group-append"><span class="input-group-text">characters</span></span>
                    </div>
                    <small id="codeLengthInputHelp" class="form-text text-muted">Choose between six and ten characters.</small>
                </div>

                <div class="form-group form-check" *ngIf="_bundleWith">
                    <div *ngFor="let bundleWithItem of _bundleWith; index as i">
                        <input type="checkbox" class="form-check-input" [id]="'bundleWith' + i" [(ngModel)]="selectedBundles[i]">
                        <label class="form-check-label" [for]="'bundleWith' + i">{{ bundleWithItem.name }}</label>
                    </div>
                </div>

            </div>
            <div class="card-footer">
                <button data-toggle="modal" data-target="#activationCodesModal" (click)="createActivationCodes()" [ngClass]="'btn btn-'+colorTheme"
                        [disabled]="!activationCodeVariant">Create activation code<span *ngIf="activationCodeCount>1">s</span></button>
            </div>
        </div>

        <div [ngClass]="'card card-outline card-tabs card-' + colorTheme" *ngIf="enableUpsell && upsellProductCandidates">
            <div class="card-header border-bottom-0">
                <h3 class="card-title"><i class="fas fa-cart-plus"></i> Up Sale</h3>
            </div>
            <div class="card-header p-0 pt-1 border-bottom-0">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <a href="#tab_upsell_default" aria-controls="#tab_upsell_default" aria-selected="true" data-toggle="pill" role="tab"
                           class="nav-link active">Default</a>
                    </li>
                    <li *ngFor="let country of usedCountries" class="nav-item">
                        <a [id]="'tab_upsell_'+country+'_link'" [href]="'#tab_upsell_'+country" [attr.aria-controls]="'#tab_upsell_'+country"
                           aria-selected="false" data-toggle="pill" role="tab" class="nav-link">{{ country | uppercase }}</a>
                    </li>
                </ul>
            </div>

            <div class="card-body">
                <app-alert-info-box infoTrigger="{{ upsellTrigger }}">
                    <h4>Up sale saved successfully</h4>
                </app-alert-info-box>

                <div class="tab-content">

                    <div id="tab_upsell_default" class="tab-pane fade active show" role="tabpanel">
                        <div class="form-group">
                            <label for="upsell" class="control-label">Default up sale product:</label>
                            <select id="upsell" aria-describedby="upsellHelp" [(ngModel)]="kioskProduct.defaultUpSaleProduct.referenceKey"
                                    (change)="updateUpsell(null)" class="form-control">
                                <option *ngFor="let upsellProduct of upsellProductCandidates"
                                        [value]="upsellProduct.referenceKey">{{ upsellProduct.name }}</option>
                            </select>
                            <small id="upsellHelp" class="form-text text-muted">Select default up sale product.</small>
                        </div>
                    </div>

                    <div *ngFor="let country of usedCountries" [id]="'tab_upsell_'+country" class="tab-pane fade" role="tabpanel">
                        <div class="form-group">
                            <label [for]="'upsell_' + country" class="control-label">Up sale product in {{ countryNames[country] }}:</label>
                            <select [(ngModel)]="kioskProduct.countryUpSaleProducts[country].referenceKey" (change)="updateUpsell(country)"
                                    [id]="'upsell_' + country" [attr.aria-describedby]="'upsellHelp' + country" class="form-control">
                                <option *ngFor="let upsellProduct of upsellProductCandidates"
                                        [value]="upsellProduct.referenceKey">{{ upsellProduct.name }}</option>
                            </select>
                            <small [id]="'upsellHelp' + country" class="form-text text-muted">Select up sale product in {{ countryNames[country] }}
                                .</small>
                        </div>
                    </div>

                </div>
            </div>

            <div class="card-footer">
                <button [disabled]="disableSaveUpsell" (click)="saveUpsell()" [ngClass]="'btn btn-'+colorTheme">Save Up Sale</button>
            </div>
        </div>

    </div>
</div>

<div class="modal fade" id="activationCodesModal" tabindex="-1" role="dialog" aria-labelledby="activationCodesModalLabel" data-backdrop="static"
     data-keyboard="false">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content" *ngIf="newCodesText">
            <div class="modal-header">
                <button type="button" class="close" (click)="closeModal()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="activationCodesModalLabel"><i class="fa fa-ticket"></i> Activation Codes
                    <small>for {{ kioskProduct.name }}</small></h4>
            </div>
            <div class="modal-body">
                <div>
                    These are your generated activation codes.
                </div>
                <div>
                    <strong>Important:</strong> Please copy the codes now - you will not be able to find these afterwards.
                </div>
                <textarea id="newCodesTextArea" [rows]="newCodes.length" cols="15" readonly="readonly">{{ newCodesText }}</textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="closeModal()">Close</button>
                <button class="btn btn-warning" (click)="copyActivationCodes()"><i class="far fa-copy"></i> Copy activation codes</button>
            </div>
        </div>
    </div>
</div>
