import {Component, OnDestroy} from '@angular/core';
import {Subject, tap} from "rxjs";
import {AuthService} from "../../core/auth.service";
import {LayoutService} from "../../core/layout.service";
import {takeUntil} from "rxjs/operators";
import {EventManagerService} from "../../root/role-manager/event-managers/event-manager.service";
import {EventEntities} from "../../interfaces/event-manager";

@Component({
    selector: 'event-manager-dashboard',
    templateUrl: './event-manager-dashboard.component.html',
    styleUrls: ['./event-manager-dashboard.component.css']
})
export class EventManagerDashboardComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    eventEntities: EventEntities;

    constructor(
        private eventManagerService: EventManagerService,
        public layout: LayoutService,
        private authService: AuthService
    ) {
        this.eventManagerService.getEventManagerEntities(this.authService.user.userID)
            .pipe(
                takeUntil(this.destroy$)
                )
            .subscribe((e) => {
                this.eventEntities = e
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
