import {Component} from '@angular/core';
import {Router} from '@angular/router';

// Services
import {AuthService} from '../../core/auth.service';
import {DestinationService} from '../../firebase-services/destination.service';
import {DestinationManagersService} from "../role-manager/destination-managers/destination-managers.service";
import {Roles} from "../../interfaces/role";
import {RoleService} from "../../core/role.service";

// Interfaces

@Component({
    selector: 'app-new-destination',
    templateUrl: './new-destination.component.html',
    styleUrls: ['./new-destination.component.css']
})
export class NewDestinationComponent {

    roles = Roles;
    destinationName: string = '';
    destinationPromoted: boolean = false;
    destinationEventsEnabled: boolean = false;

    disableCreate: boolean = true;

    constructor(
        private authService: AuthService,
        private router: Router,
        private destinationService: DestinationService,
        private destinationManagersService: DestinationManagersService,
        private roleService: RoleService
    ) {
    }

    valueChanged() {
        this.disableCreate = !(this.destinationName && this.destinationName.length);
    }

    createDestination() {
        this.disableCreate = true;
        const newDestinationThenableReference = this.destinationService.createDestination(this.destinationName, this.destinationPromoted, this.destinationEventsEnabled);

        const addManagerPromise = this.roleService.assignUserRole(this.authService.user.userID, this.roles.DESTINATION_MANAGER, this.authService.user.userID).then(() => {
            return this.destinationManagersService.addDestinationManager(this.authService.user.userID, newDestinationThenableReference.key);
        });

        Promise.all([newDestinationThenableReference, addManagerPromise])
            .then(() => this.router.navigate(['/destination/' + newDestinationThenableReference.key + '/settings']))
            .catch(err => alert(err));
    }
}
