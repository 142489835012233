import { AfterViewChecked, Component, EventEmitter, Input, Output } from '@angular/core';

// Services
import { ElasticSearchService } from '../../services/elastic-search.service';
import { CountryService } from '../../firebase-services/country.service';

// Interfaces
import { Country } from '../../interfaces/countries';

declare var $: any;

const timeOptions = {
    autoUpdateInput: false,
    singleDatePicker: true,
    minDate: '15-02-2021',
    locale: {
        format: 'DD-MM-YYYY',
        cancelLabel: 'Clear'
    }
};

@Component({
    selector: 'app-find-profile-segment',
    templateUrl: './find-profile-segment.component.html',
    styleUrls: ['./find-profile-segment.component.css']
})
export class FindProfileSegmentComponent implements AfterViewChecked {

    @Input() modalId: string;
    @Input() modalTitle: string;
    @Input() btnClassType: string;
    @Input() minimumAppVersions: { [platform: string]: string };
    @Output() profileSegment = new EventEmitter<string[]>();

    disableSearch = false;
    reach = 0;

    osPlatform = '';

    countries: Country[] = null;
    countryCode = '';

    timeInputsLoaded = false;
    seenFrom = 0;
    seenTo = 0;

    constructor(
        private elasticSearch: ElasticSearchService,
        private countryService: CountryService
    ) {
        this.loadCountries();
    }

    ngAfterViewChecked(): void {
        const seenFromElement = $('#seenFrom' + this.modalId);
        const seenToElement = $('#seenTo' + this.modalId);
        if (!this.timeInputsLoaded && seenFromElement.length > 0 && seenToElement.length > 0) {
            // Seen from
            seenFromElement.daterangepicker(timeOptions);
            seenFromElement.on('apply.daterangepicker', (ev, picker) => {
                seenFromElement.val(picker.startDate.format(timeOptions.locale.format));
                this.seenFrom = picker.startDate.valueOf();
                this.searchForSegment();
            });
            seenFromElement.on('cancel.daterangepicker', () => {
                seenFromElement.val('');
                this.seenFrom = 0;
                this.searchForSegment();
            });

            // Seen to
            seenToElement.daterangepicker(timeOptions);
            seenToElement.on('apply.daterangepicker', (ev, picker) => {
                seenToElement.val(picker.startDate.format(timeOptions.locale.format));
                this.seenTo = picker.startDate.valueOf() + 24 * 60 * 60 * 1000 - 1;
                this.searchForSegment();
            });
            seenToElement.on('cancel.daterangepicker', () => {
                seenToElement.val('');
                this.seenTo = 0;
                this.searchForSegment();
            });
            this.timeInputsLoaded = true;
        }
    }

    searchForSegment(): void {
        if (this.disableSearch) {
            return;
        }
        if (this.seenFrom > 0 && this.seenTo > 0 && this.seenFrom >= this.seenTo) {
            window.alert('Problem with selected dates - please correct dates');
            return;
        }
        this.disableSearch = true;
        console.log(this.osPlatform, this.minimumAppVersions[this.osPlatform], this.countryCode.toUpperCase(), this.seenFrom, this.seenTo);
        this.elasticSearch.getProfileSegmentCount(
            this.osPlatform, this.minimumAppVersions[this.osPlatform], this.countryCode.toUpperCase(), this.seenFrom, this.seenTo)
            .subscribe((count) => {
                this.reach = count;
                this.disableSearch = false;
            });

    }

    select(): void {
        this.disableSearch = true;
        this.elasticSearch.getProfileSegmentKeys(
            this.osPlatform, this.minimumAppVersions[this.osPlatform], this.countryCode.toUpperCase(), this.seenFrom, this.seenTo)
            .then((profileKeys) => {
                this.profileSegment.emit(profileKeys);
                this.disableSearch = false;
                $('#findProfileSegmentModal_' + this.modalId).modal('hide');
            });
    }

    private loadCountries() {
        const countrySubscription = this.countryService.getAllCountries()
            .subscribe((countries) => {
                this.countries = countries;
                countrySubscription.unsubscribe();
            });
    }

}
