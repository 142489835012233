import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {Subject} from 'rxjs';
import {map, switchMap, take, takeUntil} from 'rxjs/operators';

// Services
import {AuthService} from '../../core/auth.service';
import {CountryService} from '../../firebase-services/country.service';
import {DestinationService} from '../../firebase-services/destination.service';
import {TrailAreaService} from '../../firebase-services/trail-area.service';
import {UserService} from '../../firebase-services/user.service';

// Interfaces
import {User} from '../../interfaces/user';
import {Country} from '../../interfaces/countries';
import {Destination} from '../../interfaces/destination';
import {
    TrailArea,
    TrailAreaGroup,
    TrailAreaGroupCandidate,
    TrailAreaGroupCandidateDestination
} from '../../interfaces/trailArea';
import {TrailAreaManagerService} from "../../root/role-manager/trail-area-managers/trail-area-manager.service";
import {RoleService} from "../../core/role.service";
import {Roles} from "../../interfaces/role";
import {DestinationManagers} from "../../interfaces/destination-manager";
import {TrailAreaManagers} from "../../interfaces/trail-area-manager";
// import { KioskReferenceType, ProductCategory } from '../../interfaces/kiosk';

declare var $: any;

@Component({
    selector: 'app-country-trail-areas-admin',
    templateUrl: './country-trail-areas-admin.component.html',
    styleUrls: ['./country-trail-areas-admin.component.css']
})
export class CountryTrailAreasAdminComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    country: Country = null;
    private roles = Roles;

    trailAreaGrouping: TrailAreaGroup[] = null;
    rawTrailAreas: { [trailAreaKey: string]: TrailArea } = {};
    trailAreaManagers: { [trailAreaKey: string]: User[] } = {};
    onlineIsManager: { [trailAreaKey: string]: boolean } = {};
    numberOfTrails: { [trailAreaKey: string]: number } = {};
    numberOfPois: { [trailAreaKey: string]: number } = {};
    numberOfAdventures: { [trailAreaKey: string]: number } = {};
    numberOfManagers: { [trailAreaKey: string]: number } = {};

    displayTrash: { [trailAreaKey: string]: boolean } = {};
    hasHqMap: { [trailAreaKey: string]: boolean } = {};
    hqMapTrailArea: TrailArea = null;
    hqMapSave = false;

    hqMapSaving = false;
    // kioskProductKey: string = null;
    // productCategory: ProductCategory = ProductCategory.HQ_MAPS;
    // referenceType: KioskReferenceType = null;
    // referenceKey: string = null;
    // defaultName: string = null;

    constructor(
        public authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private countryService: CountryService,
        private destinationService: DestinationService,
        private trailAreaService: TrailAreaService,
        private userService: UserService,
        private domSanitizer: DomSanitizer,
        private trailAreaManagerService: TrailAreaManagerService,
        private roleService: RoleService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initialise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    init() {
        this.rawTrailAreas = {};
        this.country = null;
        this.trailAreaGrouping = null;
        const countryCode = this.route.snapshot.paramMap.get('countryCode');
        this.countryService.getCountry(countryCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe((country) => this.loadCountry(country));
    }

    loadCountry(country: Country): void {
        this.trailAreaService.getTrailAreasForCountry(country.countryCode)
            .pipe(
                take(1),
                map((trailAreas) => this.getTrailAreasDetail(trailAreas)),
                map((trailAreas) => this.splitTrailAreas(trailAreas))
            )
            .subscribe();
        this.country = country;
    }

    private splitTrailAreas(trailAreas: TrailArea[]) {
        const regionSet: Set<string> = new Set();
        const destinationKeySet: Set<string> = new Set();
        trailAreas.forEach((trailArea) => {
            regionSet.add(trailArea.region);
            destinationKeySet.add(trailArea.destinationKey);
        });
        const trailAreaPreGrouping: TrailAreaGroupCandidate[] = [];
        [...regionSet].forEach((regionName) => {
            const destinations: TrailAreaGroupCandidateDestination[] = [];
            [...destinationKeySet].forEach((destinationKey) => {
                const destinationTrailAreas: TrailArea[] = [];
                trailAreas.forEach((trailArea) => {
                    if (trailArea.region === regionName && trailArea.destinationKey === destinationKey) {
                        destinationTrailAreas.push(trailArea);
                    }

                });
                if (destinationTrailAreas.length !== 0) {
                    const destination: TrailAreaGroupCandidateDestination = {
                        destinationKey: destinationKey,
                        trailAreas: destinationTrailAreas
                    };
                    destinations.push(destination);
                }
            });
            const trailAreaPreGroup: TrailAreaGroupCandidate = {
                regionName: regionName,
                destinations: destinations
            };
            trailAreaPreGrouping.push(trailAreaPreGroup);
        });
        destinationKeySet.delete(null);
        this.getDestinations([...destinationKeySet], trailAreaPreGrouping);
    }

    private getDestinations(destinationKeys: string[], trailAreaPreGrouping: TrailAreaGroupCandidate[]) {
        if (destinationKeys.length === 0) {
            this.generateTrailAreaGrouping(trailAreaPreGrouping, []);
        } else {
            this.destinationService.getDestinations(destinationKeys)
                .pipe(take(1))
                .subscribe((destinations) => {
                    this.generateTrailAreaGrouping(trailAreaPreGrouping, destinations);
                });
        }
    }

    private generateTrailAreaGrouping(trailAreaPreGrouping: TrailAreaGroupCandidate[], destinations: Destination[]) {
        const trailAreaGrouping: TrailAreaGroup[] = [];
        for (const trailAreaPreGroup of trailAreaPreGrouping) {
            const regionDestinations: { destination: Destination, trailAreas: TrailArea[] }[] = [];
            let nullDestination: { destination: Destination, trailAreas: TrailArea[] } = null;
            for (const groupDestination of trailAreaPreGroup.destinations) {
                let destination: Destination = null;
                if (groupDestination.destinationKey !== null) {
                    for (const candidateDestination of destinations) {
                        if (groupDestination.destinationKey === candidateDestination.key) {
                            destination = candidateDestination;
                            break;
                        }
                    }
                }
                const regionDestination: { destination: Destination, trailAreas: TrailArea[] } = {
                    destination: destination,
                    trailAreas: groupDestination.trailAreas
                };
                if (destination === null) { // Delay inserting into array till the end.
                    nullDestination = regionDestination;
                } else {
                    regionDestinations.push(regionDestination);
                }
            }
            if (nullDestination !== null) {
                regionDestinations.push(nullDestination);
            }
            const region: TrailAreaGroup = {
                regionName: trailAreaPreGroup.regionName,
                destinations: regionDestinations
            };
            trailAreaGrouping.push(region);
        }
        this.trailAreaGrouping = trailAreaGrouping;
    }

    private getTrailAreasDetail(trailAreas: TrailArea[]):
        TrailArea    [] {
        this.loadTrailAreaManagers(trailAreas);
        for (const trailArea of trailAreas) {
            this.rawTrailAreas[trailArea.key] = trailArea;

            // Number of Trails
            if (trailArea.trailKeys) {
                this.numberOfTrails[trailArea.key] = Object.keys(trailArea.trailKeys).length;
            }

            // Number of Adventures
            if (trailArea.adventureKeys) {
                this.numberOfAdventures[trailArea.key] = Object.keys(trailArea.adventureKeys).length;
            }
            // Number of POIs
            // POIs should be refactored like adventures and trails.

            // HQ Maps
            this.hasHqMap[trailArea.key] = this.trailAreaHasHqMap(trailArea);
            // Online is Manager?
            this.onlineIsManager[trailArea.key] = false;
            this.authService.trailAreas.forEach((onlineUserTrailArea) => {
                if (onlineUserTrailArea.key === trailArea.key) {
                    this.onlineIsManager[trailArea.key] = true;
                }
            });
        }
        return trailAreas.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
    }

    private loadTrailAreaManagers(trailAreas: TrailArea[]): void {
        this.trailAreaManagerService.getTrailAreasManagers(trailAreas)
            .pipe(takeUntil(this.destroy$)).subscribe((dmList) => {
            this.trailAreaManagers = dmList;
            this.populateNumberOfManagers(dmList);
        });
    }

    populateNumberOfManagers(areaManagers: TrailAreaManagers) {
        for (const areaKey in areaManagers) {
            if (areaManagers.hasOwnProperty(areaKey)) {
                this.numberOfManagers[areaKey] = areaManagers[areaKey].length;
            }
        }
    }

    addManager(userKey: string, areaKey: string): void {
        this.roleService.assignUserRole(userKey, this.roles.TRAIL_AREA_MANAGER, this.authService.user.userID)
            .then(() => {
                this.trailAreaManagerService.addTrailAreaToTrailAreaManager(userKey, areaKey)
                    .then(() => this.updateAddManager(userKey, areaKey));
            })
            .catch((err) => console.error('Sorry, an error occurred: ', err.message));
    }

    removeManager(userKey: string, areaKey: string): void {
        $('#' + areaKey + userKey).remove();
        this.trailAreaManagerService.getTrailAreaManagerTrailAreas(userKey)
            .pipe(
                take(1),
                switchMap((areas) => {
                    if (areas.length === 1) {
                        return this.trailAreaManagerService.removeTrailAreaFromTrailAreaManager(userKey, areaKey).then(() => {
                            return this.roleService.revokeUserRole(userKey, this.roles.TRAIL_AREA_MANAGER)
                                .then(() => {
                                    this.updateRemoveManager(userKey, areaKey);
                                })
                                .catch((err) => console.error('Sorry, an error occurred: ', err.message));
                        })
                            .catch((err) => console.error('Sorry, an error occurred: ', err.message));
                    } else {
                        return this.trailAreaManagerService.removeTrailAreaFromTrailAreaManager(userKey, areaKey)
                            .then(() => {
                                this.updateRemoveManager(userKey, areaKey);
                            })
                            .catch((err) => console.error('Sorry, an error occurred: ', err.message));
                    }
                })
            )
    }

    updateRemoveManager(userKey: string, areaKey: string) {
        if (userKey === this.authService.user.userID) {
            this.onlineIsManager[areaKey] = false;
        }
        this.loadTrailAreaManagers([this.rawTrailAreas[areaKey]]);
    }

    updateAddManager(userKey: string, areaKey: string) {
        if (userKey === this.authService.user.userID) {
            this.onlineIsManager[areaKey] = true;
        }
        this.loadTrailAreaManagers([this.rawTrailAreas[areaKey]]);
    }

    deleteTrailArea(key: string): Promise<void> {
        let found = false;
        for (const trailAreaGroup of this.trailAreaGrouping) {
            for (const destination of trailAreaGroup.destinations) {
                for (const k in destination.trailAreas) {
                    if (destination.trailAreas[k].key === key) {
                        destination.trailAreas.splice(parseInt(k, 10), 1);
                        found = true;
                        break;
                    }
                }
                if (found) {
                    break;
                }
            }
            if (found) {
                break;
            }
        }
        return this.trailAreaService.deleteTrailArea(key);
    }

    setOnlineBackgroundImage() {
        return this.domSanitizer.bypassSecurityTrustStyle('background-image: url(' + this.authService.user.userPicture + ')');
    }

// High Quality Maps
    trailAreaHasHqMap(trailArea: TrailArea): boolean {
        return (
            trailArea.hqMapMinimumZ > 10 &&
            trailArea.hqMapMaximumZ <= 21 &&
            trailArea.hqMapMinimumZ <= trailArea.hqMapMaximumZ &&
            (typeof trailArea.hqMapUrlTemplate === 'string' &&
                trailArea.hqMapUrlTemplate.match('^https:\/\/.*\/\{z\}\/\{x\}\/\{y\}\.png') !== null)
        );
    }

    onSetupHqMapClick(trailArea: TrailArea) {
        this.hqMapSave = false;
        this.hqMapSaving = false;
        this.hqMapTrailArea = trailArea;
    }

    hqMapChanged() {
        this.hqMapSave = this.trailAreaHasHqMap(this.hqMapTrailArea);
    }

    saveHqMap() {
        this.hqMapSaving = true;
        this.hqMapSave = false;
        this.trailAreaService.updateHqMap(this.hqMapTrailArea)
            .then(() => {
                $('#hqMapModal').modal('hide');
                this.hasHqMap[this.hqMapTrailArea.key] = true;
                this.hqMapTrailArea = null;
            });
    }

// Kiosk Product for maps // Trail Area
//     onSetupTrailAreaMapProductClick(trailArea: TrailArea) {
//         this.kioskProductKey = trailArea.hqMapProductKey;
//         this.referenceType = KioskReferenceType.TRAIL_AREA;
//         this.defaultName = 'HQ-Map for ' + trailArea.name;
//         this.referenceKey = trailArea.key;
//     }
//
//     onTrailAreaMapProductCreated(kioskProductKey: string): void {
//         this.trailAreaService.updateHqMapPayment(this.referenceKey, kioskProductKey)
//             .then(() => this.init());
//     }

    updateLocationTexts(regionIndex: number): void {
        this.trailAreaGrouping[regionIndex].destinations.forEach((destination) => {
            destination.trailAreas.forEach((trailArea) => {
                this.trailAreaService.applyGeoMetaToTrailArea(trailArea, 0, true);
            });
        });
        document.getElementById('location-texts-button-' + regionIndex).hidden = true;
    }
}
