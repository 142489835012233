import { environment } from '../../../../environments/environment';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { AuthService } from '../../../core/auth.service';
import { AwardsService } from '../../../firebase-services/awards.service';
import { OnTrailService } from '../../../firebase-services/on-trail.service';
import { TrailAreaService } from '../../../firebase-services/trail-area.service';
import { UserService } from '../../../firebase-services/user.service';

// Interfaces
import {
    StatusOptions,
    StatusOptionRejected,
    StatusOptionApplied,
    StatusOptionBeingProcessed,
    StatusOptionApproved,
    OnTrailApplication,
    ON_TRAIL_AWARD_KEY
} from '../../../interfaces/on-trail';
import { Award, AwardLog, AwardCategory } from '../../../interfaces/award';
import { TrailArea } from '../../../interfaces/trailArea';

import * as moment from 'moment';
import {TrailAreaManagerService} from "../../../root/role-manager/trail-area-managers/trail-area-manager.service";
import {CountryManagersService} from "../../../root/role-manager/country-managers/country-managers.service";
import {RoleService} from "../../../core/role.service";
import {Roles} from "../../../interfaces/role";

declare var $: any;

@Component({
    selector: 'app-on-trail-application',
    templateUrl: './on-trail-application.component.html',
    styleUrls: ['./on-trail-application.component.css']
})
export class OnTrailApplicationComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    webBaseUrl: string = environment.www;

    statusOptions = StatusOptions;
    rejected = StatusOptionRejected;
    applied = StatusOptionApplied;
    beingProcessed = StatusOptionBeingProcessed;
    approved = StatusOptionApproved;

    onTrailAward: Award;
    awardLog: AwardLog;
    awardCategories: AwardCategory[] = [];
    application: OnTrailApplication;

    trailArea: TrailArea;
    adminOnTrailArea = false;
    countryManager = false;

    formattedDate: string;
    awardChanged = false;

    roles = Roles;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        private onTrailService: OnTrailService,
        private awardsService: AwardsService,
        private trailAreaService: TrailAreaService,
        private trailAreaManagerService: TrailAreaManagerService,
        private countryManagerService: CountryManagersService,
        private roleService: RoleService
    ) {
    }

    ngOnInit() {
        this.awardsService.getAwardFromKey(ON_TRAIL_AWARD_KEY)
            .pipe(takeUntil(this.destroy$))
            .subscribe((award) => {
                const categoryEntries = <[string, AwardCategory][]>Object.entries(award.categories);
                for (const [categoryKey, category] of categoryEntries) {
                    category.key = categoryKey;
                    this.awardCategories.push(category);
                }
                this.onTrailAward = award;
                this.isUserCountryManager();
            });
        this.onTrailService.getOnTrailApplication(this.route.snapshot.paramMap.get('applicationKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((onTrailApplication) => {
                this.trailArea = null;
                if (onTrailApplication.trackId) {
                    this.getApplicationWithTrail(onTrailApplication);
                } else {
                    this.application = onTrailApplication;
                }
            });
        this.isUserCountryManager();
        if (this.application && this.application.trail) { // In case this is slower than expected
            this.getTrailArea();
            this.getAwardsLog();
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    private getApplicationWithTrail(onTrailApplication: OnTrailApplication) {
        this.onTrailService.loadTrailForApplication(onTrailApplication)
            .pipe(takeUntil(this.destroy$))
            .subscribe((onTrailApplicationWithTrail) => {
                this.application = onTrailApplicationWithTrail;
                this.getTrailArea();
                this.getAwardsLog();
            });
    }

    private getTrailArea() {
        if (!this.application.trail) {
            return;
        }
        this.trailAreaService.getTrailAreaFromTrail(this.application.trail)
            .pipe(takeUntil(this.destroy$))
            .subscribe((trailArea) => {
                if (trailArea) {
                    this.trailArea = trailArea;
                    this.isUserTrailAreaManager(trailArea.key);
                } else {
                    this.adminOnTrailArea = false;
                }
            });
    }

    private isUserTrailAreaManager(trailAreaKey: string) {
        this.trailAreaManagerService.isUserTrailAreaManager(this.authService.user.userID, trailAreaKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((isManager) => this.adminOnTrailArea = isManager);
    }

    private isUserCountryManager() {
        if (!this.onTrailAward) {
            return;
        }
        this.countryManagerService.isUserCountryManager(this.authService.user.userID, this.onTrailAward.countryCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe((isCountryManager) => this.countryManager = isCountryManager);
    }

    private getAwardsLog() {
        if (!(this.application && this.application.trail)) {
            return;
        }
        if (this.application.trail.awards && this.application.trail.awards[ON_TRAIL_AWARD_KEY]) {
            this.awardsService.loadTrailAwardLog(ON_TRAIL_AWARD_KEY, this.application.trail.key)
                .pipe(takeUntil(this.destroy$))
                .subscribe((awardLog) => {
                    if (!awardLog) {
                        return;
                    }
                    this.awardLog = awardLog;
                    this.awardLog.trailKey = this.application.trail.key;
                    this.awardLog.awardedByKey = this.authService.user.userID;
                    this.awardLog.awardedByName = this.authService.user.name;
                    this.awardLog.awardedByImageUrl = this.authService.user.userPicture;
                    this.showAwardDateInput();
                });
        } else {
            this.awardLog = {
                awardKey: ON_TRAIL_AWARD_KEY,
                categoryKey: null,
                trailKey: this.application.trail.key,
                awardTime: moment.now(),
                awardedByKey: this.authService.user.userID,
                awardedByName: this.authService.user.name,
                awardedByImageUrl: this.authService.user.userPicture
            };
            this.showAwardDateInput();
        }
    }

    private showAwardDateInput() {
        this.formattedDate = moment.utc(this.awardLog.awardTime).format('YYYY-MM-DD');
        window.setTimeout(() => {
            $('#datepicker')
                .datepicker({
                    autoclose: true,
                    format: 'yyyy-mm-dd',
                    startDate: '2017-01-01',
                    endDate: moment().format('YYYY-MM-DD'),
                    weekStart: 1
                })
                .on('hide', () => {
                    this.formattedDate = $('#datepicker').val();
                    this.awardLog.awardTime = moment.utc(this.formattedDate).unix() * 1000;
                    this.awardChanged = true;
                });
        }, 0);
    }

    setTrailOnOnTrailApplication(trailKey: string, onTrailApplicationKey: string): Promise<void> {
        return this.onTrailService.setTrailOnApplication(onTrailApplicationKey, trailKey);
    }

    unlinkTrailFromOnTrailApplication(): Promise<void> {
        if (confirm('Are you sure you want to unlink this trail from the application?')) {
            return this.onTrailService.unlinkTrailFromApplication(this.application);
        }
        return Promise.resolve();
    }

    updateStatus(application: OnTrailApplication, newStatus: number): Promise<void> {
        application.status = newStatus;
        return this.onTrailService.updateOnTrailApplicationStatus(application);
    }

    deleteOnTrailApplication(application: OnTrailApplication): Promise<boolean> {
        if (confirm('Are you sure you want to delete this application? (' + application.trackName + ')')) {
            return this.onTrailService.deleteOnTrailApplication(application)
                .then(() => this.router.navigate(['/on-trail/status']));
        }
        return Promise.resolve(false);
    }

    promoteToManager(): void {
        this.roleService.assignUserRole(this.authService.user.userID, this.roles.TRAIL_AREA_MANAGER, this.authService.user.userID)
            .then(() => {
                return this.trailAreaManagerService.addTrailAreaToTrailAreaManager(this.authService.user.userID, this.trailArea.key)
            })
            .catch((err) => console.error('Sorry, an error occurred: ', err.message));
    }

    updateAwardCategory(category: AwardCategory) {
        this.awardLog.categoryKey = category.key;
        this.awardChanged = true;
    }

    setAward():Promise<any> {
        this.awardChanged = false;
        return this.awardsService.setAward(this.awardLog, this.onTrailAward.categories[this.awardLog.categoryKey]);
    }

}
