import {Injectable, OnDestroy} from '@angular/core';
import firebase from "firebase/compat";
import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from "@angular/fire/compat/database";
import {Group, GroupMember, GroupMembersAction} from "../interfaces/group";
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {Observable, Subject} from "rxjs";
import {map, take, takeUntil} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class GroupService {
    groupsRef: firebase.database.Reference;
    groupMembersRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private aFireFunctions: AngularFireFunctions
    ) {
        this.groupsRef = db.database.ref('groups');
        this.groupMembersRef = db.database.ref('groupMembers');
    }

    getGroup(groupKey: string): Observable<Group> {
        return this.db.object<Group>(this.groupsRef.child(groupKey).ref).snapshotChanges()
            .pipe(take(1), map((groupSnapshot) => groupSnapshot.payload.val() as Group));
    }

    getGroupMembers(groupKey: string): Observable<GroupMember[]> {
        return this.db.list<GroupMember>(this.groupMembersRef.child(groupKey).ref).snapshotChanges()
            .pipe(map((groupMembersSnapshot) => {
                return groupMembersSnapshot.map(snapshot => {
                    const key = snapshot.key;
                    const data = snapshot.payload.val();
                    return { key, ...data } as GroupMember;
                });
            }));
    }

    createNewGroup(group: Group): Observable<string> {
        group.groupKey = this.groupsRef.push().key;
        return this.aFireFunctions.httpsCallable('createGroup')(group).pipe(take(1));
    }

    updateGroup(group: Group): Observable<boolean> {
        return this.aFireFunctions.httpsCallable('updateGroup')(group).pipe(take(1));
    }

    deleteGroup(group: Group): Observable<boolean> {
        return this.aFireFunctions.httpsCallable('deleteGroup')(group).pipe(take(1));
    }

    inviteGroupMembers(inviteList: GroupMembersAction): Observable<boolean> {
        return this.aFireFunctions.httpsCallable('groupMembershipAction')(inviteList).pipe(take(1));
    }

    searchByName(searchTerm: string): Observable<Group[]> {
        return this.db.list<Group>(`groups`, ref => ref.orderByChild('name').startAt(searchTerm).endAt(searchTerm + '\uf8ff'))
            .snapshotChanges()
            .pipe(
                map(actions =>
                    actions.map(a => {
                        const data = a.payload.val() as Group;
                        const key = a.key;
                        return {...data, key};
                    })
                )
            );
    }

}
