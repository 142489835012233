import { AreaObject, TranslatableObject } from './general';
import { Destination } from './destination';

export const SEED_TYPE_TRAIL_AREA = 'trailArea';

export interface LatLng extends Array<number> {
    0: number; // longitude
    1: number; // latitude
}

export interface LatLngArray extends Array<number[]> {
    [index: number]: LatLng;
}

export interface BoundingBox {
    boundsNorth: number;
    boundsSouth: number;
    boundsEast: number;
    boundsWest: number;
}

export interface SeedObject {
    type: string;
    key: string;
    seedPoint: LatLng;
}

export interface TrailAreaListEntry {
    trackGroupId: string;
}

export interface TrailAreaMapPointsWorldModel {
    refKey: string;
    title: string;
    latitude: number;
    longitude: number;
}

export interface DeprecatedTrailReference {
    trackId: string;
}

export interface DeprecatedEventReference {
    eventID: string;
}

export interface TrailArea extends TranslatableObject, AreaObject {
    key?: string;

    donateMotivator?: string;
    name: string;
    articleBody?: string;
    imageUrl?: string;
    warningText?: string;

    country: string;
    countryName?: string;
    region?: string;
    destinationKey?: string;

    dateCreated: string;

    hashtag?: string;
    isAccessibleForFree?: boolean;
    maintainers?: string;
    owner?: string;
    sponsor?: string;
    openingHours?: string;

    importKey?: string;

    /**
     * @deprecated
     * @todo Replace with e.g. trailKeys in db
     * See destination trailAreaKeys
     */
    tracks?: DeprecatedTrailReference[];
    trailKeys?: { [trailKey: string]: boolean };
    eventsEnabled?: boolean;
    /**
     * @deprecated
     * @todo Replace with e.g. eventKeys in db
     * See destination trailAreaKeys
     */
    events?: DeprecatedEventReference[];
    eventKeys?: { [eventKey: string]: boolean };

    hqMapMinimumZ?: number;
    hqMapMaximumZ?: number;
    hqMapUrlTemplate?: string;
    hqMapProductKey?: string;
    hqMapIsGeneral?: boolean;

    /**
     * @deprecated
     */
    datePublished?: string;
    /**
     * @deprecated
     */
    dateModified?: string;
    /**
     * @deprecated
     */
    version?: any;
    /**
     * @deprecated
     */
    promoted?: boolean;
    /**
     * @deprecated
     */
    groupName?: string;
}

export interface TrailAreaShapes {
    [trailAreaKey: string]: google.maps.LatLngLiteral[][];
}

export interface TrailAreaGroup {
    regionName: string;
    destinations: TrailAreaGroupDestination[];
}

export interface TrailAreaGroupDestination {
    destination: Destination;
    trailAreas: TrailArea[];
}

export interface TrailAreaGroupCandidate {
    regionName: string;
    destinations: TrailAreaGroupCandidateDestination[];
}

export interface TrailAreaGroupCandidateDestination {
    destinationKey: string;
    trailAreas: TrailArea[];
}

export interface MappedTrailAreaShape {
    trailArea: TrailArea;
    shapes: google.maps.LatLngLiteral[][];
}

export interface MappedTrailAreaShapes {
    [trailAreaKey: string]: MappedTrailAreaShape;
}
