import {Injectable, OnDestroy} from '@angular/core';
import {AuthService} from "../../../core/auth.service";
import {combineLatest, Observable, of, Subject, switchMap} from "rxjs";
import firebase from "firebase/compat";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {Roles} from "../../../interfaces/role";
import {Group} from "../../../interfaces/group";
import {map, takeUntil} from "rxjs/operators";
import {GroupService} from "../../../firebase-services/group.service";

@Injectable({
    providedIn: 'root'
})
export class PressCampManagerService implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    pressCampManagersRef: firebase.database.Reference;
    roles = Roles;

    constructor(
        private db: AngularFireDatabase,
        private authService: AuthService,
        private groupService: GroupService
    ) {
        this.pressCampManagersRef = db.database.ref('managers/pressCampManagers');
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getPressCampManagerGroups(userKey: string): Observable<Group[]> {
        return this.db.list<string>(this.pressCampManagersRef.child(userKey).child('pressCampGroups').ref).snapshotChanges()
            .pipe(
                takeUntil(this.destroy$),
                switchMap(groupKeys =>
                    groupKeys.length
                        ? combineLatest(
                            groupKeys.map(gKey =>
                                this.groupService.getGroup(gKey.key).pipe(
                                    map(group => {
                                        return {...group, groupKey: gKey.key};
                                    })
                                )
                            )
                        )
                        : of([])
                )
            );
    }

    addPressCampGroupToManager(userKey: string, groupKey: string): Promise<void> {
        return this.pressCampManagersRef.child(userKey).child('pressCampGroups').update({[groupKey]: groupKey});
    }

    removePressCampGroupFromManager(userKey: string, groupKey: string): Promise<void> {
        return this.pressCampManagersRef.child(userKey).child('pressCampGroups').child(groupKey).remove();
    }
}
