import {Injectable} from '@angular/core';
import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from "@angular/fire/compat/database";
import {combineLatest, forkJoin, Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {UserService} from "../../../firebase-services/user.service";
import {TrailAreaService} from "../../../firebase-services/trail-area.service";
import firebase from "firebase/compat";
import {DmoManager, DmoManagerListItem} from "../../../interfaces/dmo-manager";
import {TrailArea} from "../../../interfaces/trailArea";

@Injectable({
    providedIn: 'root'
})
export class DmoManagerService {
    private dmoManagersRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private userService: UserService,
        private trailAreaService: TrailAreaService
    ) {
        this.dmoManagersRef = this.db.database.ref('managers/dmoManagers')
    }

    getDmoManagersListItems(): Observable<DmoManagerListItem[]> {
        return this.db.list<DmoManager>(this.dmoManagersRef.ref).snapshotChanges()
            .pipe(
                switchMap(dmoSnapshots => forkJoin(
                    dmoSnapshots.map(dmoSnapshot => {
                        const dmoManager: DmoManager = this.DmoManagerFromSnap(dmoSnapshot);
                        return this.userService.getUser(dmoManager.userKey).pipe(
                            take(1),
                            switchMap(dmoUser => dmoManager.trailAreas
                                ? this.trailAreaService.getTrailAreas(dmoManager.trailAreas).pipe(
                                    take(1),
                                    map(dmoTrailAreas => ({
                                            user: dmoUser,
                                            trailAreas: dmoTrailAreas.sort((a, b) => a.country.localeCompare(b.country))
                                        })
                                    )
                                )
                                : of({user: dmoUser, trailAreas: []})
                            )
                        );
                    })
                ))
            );
    }


    public getDmoManagerTrailAreas(userKey: string): Observable<TrailArea[]> {
        return this.db.list<string>(this.dmoManagersRef.child(userKey).child('trailAreas').ref).snapshotChanges()
            .pipe(
                map(taKeys => taKeys.map(taKey => taKey.key)),
                switchMap(trailAreaKeys =>
                    trailAreaKeys.length
                        ? combineLatest(trailAreaKeys.map(key => this.trailAreaService.getTrailArea(key)))
                        : of([])
                )
            );
    }

    public addTrailAreaToDmoManager(userKey: string, areaKey: string): Promise<void> {
        return this.dmoManagersRef.child(userKey).child('trailAreas').update({[areaKey]: areaKey});
    }

    public removeTrailAreaFromDmoManager(userKey: string, areaKey: string): Promise<void> {
        return this.dmoManagersRef.child(userKey).child('trailAreas').child(areaKey).remove();
    }

    private DmoManagerFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<DmoManager>>): DmoManager {
        const dmoManager: DmoManager = snapshot.payload.val();
        dmoManager.userKey = snapshot.key;
        dmoManager.trailAreas = Object.values(dmoManager.trailAreas ?? []);
        return dmoManager;
    }
}