<div class="content-wrapper">
    <app-content-header-section contentPageId="eventManagerDashboard" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4" *ngIf="eventEntities.countries && eventEntities.countries.length">
                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-flag"></i> Go to events on country</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <ul class="todo-list">
                                    <li class="primaryListItem"
                                        *ngFor="let country of eventEntities.countries"
                                        [routerLink]="layout.getUrl('countryEvents', { countryCode: country.countryCode })">
                                        <span class="text">{{ country.name }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="eventEntities.destinations && eventEntities.destinations.length">
                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-heart"></i> Go to events on destination</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <ul class="todo-list">
                                    <li class="primaryListItem"
                                        *ngFor="let destination of eventEntities.destinations"
                                        [routerLink]="layout.getUrl('destinationEvents', { destinationKey: destination.key })">
                                        <span class="text">{{ destination.name }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="eventEntities.trailAreas && eventEntities.trailAreas.length">
                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-route"></i> Go to events on trail area</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <ul class="todo-list">
                                    <li class="primaryListItem"
                                        *ngFor="let area of eventEntities.trailAreas"
                                        [routerLink]="layout.getUrl('trailAreaEvents', { trailAreaName: area.name, trailAreaKey: area.key })">
                                        <span class="text">{{ area.name }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
