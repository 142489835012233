<div class="map-container">
    <input
            id="pac-input"
            class="controls"
            type="text"
            placeholder="Search for places"
    />
    <div id="map"></div>
    <div class="coordinates mt-2">
        <p><strong>Selected Location:</strong></p>
        <p>Latitude: {{ latitude }} <br>
            Longitude: {{ longitude }}</p>
    </div>
</div>