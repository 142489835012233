import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '../../core/layout.service';
import { DestinationService } from '../../firebase-services/destination.service';
import { EventService } from '../../firebase-services/event.service';

// Interfaces
import { Destination } from '../../interfaces/destination';
import { Event, EVENT_TINT_COLOR } from '../../interfaces/event';
import { CreateOnMap } from '../../interfaces/map';
import {AuthService} from "../../core/auth.service";

@Component({
    selector: 'app-destination-events',
    templateUrl: './destination-events.component.html',
    styleUrls: ['./destination-events.component.css']
})
export class DestinationEventsComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    destination: Destination = null;
    events: Event[] = null;
    routerRoot: string;

    constructor(
        public layout: LayoutService,
        private route: ActivatedRoute,
        private router: Router,
        private destinationService: DestinationService,
        private eventService: EventService,
        private authService: AuthService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initalise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    /**
     * Load data for the current trail area
     */
    init() {
        this.destination = null;
        this.events = null;
        this.destinationService.getDestination(this.route.snapshot.paramMap.get('destinationKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((destination) => {
                this.getEvents(destination);
                this.routerRoot = '/destination/' + destination.key;
                this.destination = destination;
            });
    }

    /**
     * Loads the events for the destination
     */
    private getEvents(destination: Destination): void {
        if (!destination.eventsEnabled || Object.keys(destination.eventKeys).length === 0) {
            this.events = null;
            return;
        }
        this.eventService.getEvents(Object.keys(destination.eventKeys))
            .pipe(takeUntil(this.destroy$))
            .subscribe((events) => this.events = events);
    }

    createNewEvent(newEventOnMap: CreateOnMap): void {
        var newEvent = <Event>{
            name: newEventOnMap.name,
            latitude: newEventOnMap.lat,
            longitude: newEventOnMap.lng,
            geohash: newEventOnMap.geohash,
            tintColor: EVENT_TINT_COLOR,
            creatorKey: this.authService.user.userID
        };
        var newKey = this.eventService.createNewEvent(newEvent);
        // Fire and forget
        this.destinationService.addEventToDestination(this.destination.key, newKey);

        this.router.navigate([this.routerRoot + '/events/' + newKey]);
    }
}
