import {Injectable, OnDestroy} from '@angular/core';
import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from "@angular/fire/compat/database";
import {combineLatest, forkJoin, Observable, of, Subject} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {UserService} from "../../../firebase-services/user.service";
import {TrailAreaService} from "../../../firebase-services/trail-area.service";
import firebase from "firebase/compat";
import {EventEntities, EventManager, EventManagerListItem} from "../../../interfaces/event-manager";
import {CountryService} from "../../../firebase-services/country.service";
import {DestinationService} from "../../../firebase-services/destination.service";

@Injectable({
    providedIn: 'root'
})
export class EventManagerService implements OnDestroy {
    private destroy$: Subject<boolean> = new Subject<boolean>();
    private eventManagersRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private userService: UserService,
        private countryService: CountryService,
        private destinationService: DestinationService,
        private trailAreaService: TrailAreaService
    ) {
        this.eventManagersRef = this.db.database.ref('managers/eventManagers')
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getEventManagersListItems(): Observable<EventManagerListItem[]> {
        return this.db.list<EventManager>(this.eventManagersRef.ref).snapshotChanges()
            .pipe(
                switchMap(emSnapshots => forkJoin(
                    emSnapshots.map(emSnapshot => {
                        const eventManager: EventManager = this.EventManagerFromSnap(emSnapshot);
                        return this.userService.getUser(eventManager.userKey).pipe(
                            take(1),
                            switchMap(emUser => forkJoin({
                                countries: eventManager.countries.length
                                    ? this.countryService.getCountries(eventManager.countries).pipe(take(1))
                                    : of([]),
                                destinations: eventManager.destinations.length
                                    ? this.destinationService.getDestinations(eventManager.destinations).pipe(take(1))
                                    : of([]),
                                trailAreas: eventManager.trailAreas.length
                                    ? this.trailAreaService.getTrailAreas(eventManager.trailAreas).pipe(take(1))
                                    : of([])
                            }).pipe(
                                map(({ countries, destinations, trailAreas }) => ({
                                    user: emUser,
                                    countries: countries.sort((a, b) => a.countryCode.localeCompare(b.countryCode)),
                                    destinations: destinations.sort((a, b) => a.name.localeCompare(b.name)),
                                    trailAreas: trailAreas.sort((a, b) => a.name.localeCompare(b.name))
                                }))
                            ))
                        );
                    })
                ))
            );
    }

    public getEventManagerEntities(userKey: string): Observable<EventEntities> {
        const countries$ = this.db.list<string>(this.eventManagersRef.child(userKey).child('countries').ref).snapshotChanges()
            .pipe(
                map(cKeys => cKeys.map(cKey => cKey.key)),
                switchMap(countryCodes =>
                    countryCodes.length
                        ? combineLatest(countryCodes.map(key => this.countryService.getCountry(key)))
                        // should this be null?
                        : of([])
                )
            );

        const destinations$ = this.db.list<string>(this.eventManagersRef.child(userKey).child('destinations').ref).snapshotChanges()
            .pipe(
                map(dKeys => dKeys.map(dKey => dKey.key)),
                switchMap(destinationKeys =>
                    destinationKeys.length
                        ? combineLatest(destinationKeys.map(key => this.destinationService.getDestination(key)))
                        : of([])
                )
            );

        const trailAreas$ = this.db.list<string>(this.eventManagersRef.child(userKey).child('trailAreas').ref).snapshotChanges()
            .pipe(
                map(taKeys => taKeys.map(taKey => taKey.key)),
                switchMap(trailAreaKeys =>
                    trailAreaKeys.length
                        ? combineLatest(trailAreaKeys.map(key => this.trailAreaService.getTrailArea(key)))
                        : of([])
                )
            );

        return combineLatest([countries$, destinations$, trailAreas$]).pipe(
            map(([countries, destinations, trailAreas]) => ({
                countries,
                destinations,
                trailAreas
            }))
        );

    }

    private EventManagerFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<EventManager>>): EventManager {
        const eventManager: EventManager = snapshot.payload.val();
        eventManager.userKey = snapshot.key;
        eventManager.countries = Object.values(eventManager.countries ?? []);
        eventManager.destinations = Object.values(eventManager.destinations ?? []);
        eventManager.trailAreas = Object.values(eventManager.trailAreas ?? []);
        return eventManager;
    }

    public addCountryToEventManager(userKey: string, countryCode: string): Promise<void> {
        return this.eventManagersRef.child(userKey).child('countries').update({[countryCode]: countryCode});
    }

    public removeCountryFromEventManager(userKey: string, countryCode: string): Promise<void> {
        return this.eventManagersRef.child(userKey).child('countries').child(countryCode).remove();
    }

    public addDestinationToEventManager(userKey: string, destinationKey: string): Promise<void> {
        return this.eventManagersRef.child(userKey).child('destinations').update({[destinationKey]: destinationKey});
    }

    public removeDestinationFromEventManager(userKey: string, destinationKey: string): Promise<void> {
        return this.eventManagersRef.child(userKey).child('destinations').child(destinationKey).remove();
    }

    public addTrailAreaToEventManager(userKey: string, areaKey: string): Promise<void> {
        return this.eventManagersRef.child(userKey).child('trailAreas').update({[areaKey]: areaKey});
    }

    public removeTrailAreaFromEventManager(userKey: string, areaKey: string): Promise<void> {
        return this.eventManagersRef.child(userKey).child('trailAreas').child(areaKey).remove();
    }

}