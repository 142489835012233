import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { CountryService } from '../../../firebase-services/country.service';
import { EventService } from '../../../firebase-services/event.service';
import { TrailAreaService } from '../../../firebase-services/trail-area.service';

// Interfaces
import { Country } from '../../../interfaces/countries';
import { TrailArea } from '../../../interfaces/trailArea';
import { Event } from '../../../interfaces/event';

@Component({
    selector: 'app-country-event-edit',
    templateUrl: './country-event-edit.component.html',
    styleUrls: ['./country-event-edit.component.css']
})
export class CountryEventEditComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    country: Country = null;
    event: Event = null;
    trailAreas: TrailArea[] = null;
    routerRoot: string;

    constructor(
        private route: ActivatedRoute,
        private countryService: CountryService,
        private eventService: EventService,
        private trailAreaService: TrailAreaService
    ) {
    }

    ngOnInit() {
        this.countryService.getCountry(this.route.snapshot.paramMap.get('countryCode'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((country) => {
                this.trailAreaService.getTrailAreasForCountry(country.countryCode)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((trailAreas) => this.trailAreas = trailAreas);
                this.routerRoot = '/country-manager/' + country.countryCode;
                this.country = country;
            });
        this.eventService.getEvent(this.route.snapshot.paramMap.get('eventKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((event) => this.event = event);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
