import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AngularFireDatabase } from '@angular/fire/compat/database/';

import firebase from 'firebase/compat';

// Interfaces
import { Currency } from '../interfaces/kiosk';

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {

    currencyRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase
    ) {
        this.currencyRef = this.db.database.ref('arrays/currency');
    }

    getCurrencies(): Observable<Currency[]> {
        return this.db.list<Currency>(this.currencyRef.ref).snapshotChanges().pipe(
            take(1),
            map((currenciesSnap) => {
                const currencies: Currency[] = [];
                currenciesSnap.forEach((currencySnap) => {
                    currencies.push(currencySnap.payload.val());
                });
                return currencies;
            })
        );
    }
}
