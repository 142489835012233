<div class="content-wrapper">
    <app-content-header-section contentPageId="poiManagerDashboard" [pageParams]="{ }"></app-content-header-section>

    <section class="content" *ngIf="poiEntities">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4" *ngIf="poiEntities.countries">
                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-flag"></i> Go to POIs on country</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <ul class="todo-list">
                                    <li class="primaryListItem"
                                        *ngFor="let country of poiEntities.countries"
                                        [routerLink]="layout.getUrl('countryPois', { countryName: country.name, countryCode: country.countryCode })">
                                        <span class="text">{{ country.name }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="poiEntities.destinations">
                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-heart"></i> Go to POIs on destination</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <ul class="todo-list">
                                    <li class="primaryListItem"
                                        *ngFor="let destination of poiEntities.destinations"
                                        [routerLink]="layout.getUrl('destinationPois', { destinationName: destination.name, destinationKey: destination.key })">
                                        <span class="text">{{ destination.name }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="poiEntities.trailAreas">
                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-route"></i> Go to POIs on trail area</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <ul class="todo-list">
                                    <li class="primaryListItem"
                                        *ngFor="let area of poiEntities.trailAreas"
                                        [routerLink]="layout.getUrl('trailAreaPois', { trailAreaName: area.name, trailAreaKey: area.key })">
                                        <span class="text">{{ area.name }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
