<div class="content-wrapper">
    <app-content-header-section *ngIf="organisation" [pageParams]="{organisationKey: organisation.key, organisationName: organisation.name}"
                                contentPageId="organisationMembers"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">

                    <div class="card card-outline card-maroon">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fa fa-users"></i> Members</h3>
                        </div>
                        <div *ngIf="!members" class="overlay">
                            <i class="fas fa-spinner fa-3x fa-spin"></i>
                        </div>
                        <div class="card-body px-0">
                            <table *ngIf="members" class="table row-bordered table-striped" id="membersDataTable">
                                <thead>
                                <tr>
                                    <th>Expires</th>
                                    <th>Name</th>
                                    <th>Street</th>
                                    <th>Postal code</th>
                                    <th>City</th>
                                    <th>Country</th>
                                    <ng-container *ngIf="organisation && organisation.extraFields">
                                        <th *ngFor="let extraField of organisation.extraFields">{{ extraField.name }}</th>
                                    </ng-container>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let member of members">
                                    <td [attr.data-order]="member.expires">
                                        {{ member.expires | date:'dd/MM/yyy HH:mm:ss' }}
                                    </td>
                                    <td [attr.data-order]="member.name">
                                        {{ member.name }}
                                    </td>
                                    <td [attr.data-order]="member.street">
                                        {{ member.street }}
                                    </td>
                                    <td [attr.data-order]="member.zip">
                                        {{ member.zip }}
                                    </td>
                                    <td [attr.data-order]="member.city">
                                        {{ member.city }}
                                    </td>
                                    <td [attr.data-order]="member.country">
                                        {{ member.country }}
                                    </td>
                                    <ng-container *ngIf="organisation.extraFields">
                                        <td *ngFor="let extraField of organisation.extraFields">{{ member[extraField.fieldName] }}</td>
                                    </ng-container>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</div>
