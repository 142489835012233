import {Component, OnDestroy} from '@angular/core';
import {Group, GroupMember} from "../../interfaces/group";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {PressCampService} from "../press-camp.service";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../core/auth.service";
import {FollowUserService} from "../../firebase-services/follow-user.service";

@Component({
    selector: 'app-press-camp-group-edit',
    templateUrl: './press-camp-group-edit.component.html',
    styleUrl: './press-camp-group-edit.component.css'
})
export class PressCampGroupEditComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    pressCampGroup: Group;
    saveGroupSettingsDisabled = false;
    savingSettings = false;
    groupSettingsSaved = 0;
    members: GroupMember[];
    filterList: string[];
    invitingMembers = false;
    groupMembersInvited = 0;

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private followUserService: FollowUserService,
        private pressCampService: PressCampService
    ) {
        const pressCampKey = this.route.snapshot.paramMap.get('pressCampKey');
        this.pressCampService.getPressCampGroup(pressCampKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((pressCampGroup) => {
                this.pressCampGroup = pressCampGroup;
                this.pressCampGroup.groupKey = pressCampKey;
            });
        this.pressCampService.getPressCampGroupMembers(pressCampKey)
            .pipe(takeUntil(this.destroy$)).subscribe((membersList) => {
            this.members = membersList;
            this.filterList = this.members.map(member => member.profileKey);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    settingsChanged(): void {
        this.saveGroupSettingsDisabled = false;
    }

    onUploadedImageUrl(uploadedImageUrl: string): void {
        this.pressCampGroup.coverImgUrl = uploadedImageUrl;
        this.pressCampService.savePressCampGroupSettings(this.pressCampGroup).then((success) => {
            if (!success) {
                window.alert('An error occurred while updating group cover image. Please try again.');
            }
        });
    }

    saveSettings(): void {
        this.savingSettings = true;
        this.pressCampService.savePressCampGroupSettings(this.pressCampGroup).then((success) => {
            if (success) {
                this.savingSettings = false;
                this.groupSettingsSaved++;
            } else {
                window.alert('An error occurred while updating group. Please try again.');
                this.savingSettings = false;
            }
        });
    }

    inviteUser(userId: string): void {
        if (!this.members.some((m) => m.profileKey === userId)) {
            this.invitingMembers = true;
            this.pressCampService.invitePressCampGroupMembers(this.pressCampGroup.groupKey, [userId]).then((success) => {
                if (success) {
                    this.invitingMembers = false;
                    this.groupMembersInvited++;
                } else {
                    this.invitingMembers = false;
                    window.alert('An error occurred while inviting group members. Please try again.');
                }
            });
        } else window.alert('User is already a member.');

    }

    onFollowPeopleSelected(inviteList: string[]): void {
        this.invitingMembers = true;
        this.pressCampService.invitePressCampGroupMembers(this.pressCampGroup.groupKey, inviteList).then((success) => {
            if (success) {
                this.invitingMembers = false;
                this.groupMembersInvited++;
            } else {
                this.invitingMembers = false;
                window.alert('An error occurred while inviting group members. Please try again.');
            }
        });
    }

    onGroupSelected(inviteGroupKey: string): void {
        this.invitingMembers = true;
        this.pressCampService.getPressCampGroupMembers(inviteGroupKey)
            .pipe(takeUntil(this.destroy$)).subscribe((membersList) => {
            const inviteList = membersList
                .filter((member) => !this.members.some(existingMember => existingMember.profileKey === member.profileKey))
                .map((member) => member.profileKey);
            this.pressCampService.invitePressCampGroupMembers(this.pressCampGroup.groupKey, inviteList).then((success) => {
                if (success) {
                    this.invitingMembers = false;
                    this.groupMembersInvited++;
                } else {
                    this.invitingMembers = false;
                    window.alert('An error occurred while inviting group members. Please try again.');
                }
            });
        });
    }
}
