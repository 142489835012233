import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {Roles} from "../interfaces/role";

@Injectable({providedIn: 'root'})
class PermService {
    constructor(
        private authService: AuthService
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.uid$
            .pipe(
                map(() => (this.authService.user !== null))
            );
    }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    return inject(PermService).canActivate(next, state);
}

export const adminGuard: CanActivateFn = (route, state) => {
    const roles = Roles;
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                const {user, authRoles} = authService;

                if (user && authRoles.includes(roles.ADMIN)) return true;

                router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                return false;
            })
        );
};

export const eventManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const roles = Roles;
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                const {user, authRoles, eventTrailAreas} = authService;
                const path = route.url.map(segment => segment.path).join('/');

                if (user && authRoles.includes(roles.ADMIN)) return true;

                const hasEventManagerAccess = eventTrailAreas.some(area => path.includes(area.key) && user && authRoles.includes(roles.EVENT_MANAGER));
                if (hasEventManagerAccess) return true;

                router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                return false;
            })
        );
};

export const dmoManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const roles = Roles;
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                const {user, authRoles, dmoTrailAreas} = authService;
                const path = route.url.map(segment => segment.path).join('/');

                if (user && authRoles.includes(roles.ADMIN)) return true;

                const hasDmoAreaAccess = dmoTrailAreas.some(area => path.includes(area.key) && user && authRoles.includes(roles.DMO_MANAGER));
                if (hasDmoAreaAccess) return true;

                router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                return false;
            })
        );
};

export const countryManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const roles = Roles;
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                const {user, authRoles, countries} = authService;
                const path = route.url.map(segment => segment.path).join('/');

                if (user && authRoles.includes(roles.ADMIN)) return true;

                const hasCountryAccess = countries.some(entry => path.includes(entry.countryCode) && user && authRoles.includes(roles.COUNTRY_MANAGER));
                if (hasCountryAccess) return true;

                router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                return false;
            })
        );
};

export const destinationManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const roles = Roles;
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                const {user, authRoles, destinations} = authService;
                const path = route.url.map(segment => segment.path).join('/');

                if (user && authRoles.includes(roles.ADMIN)) return true;

                const hasDestinationAccess = destinations.some(entry => path.includes(entry.key) && user && authRoles.includes(roles.DESTINATION_MANAGER));
                if (hasDestinationAccess) return true;

                router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                return false;
            })
        );
};

export const trailAreaManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const roles = Roles;
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);

    return authService.uid$.pipe(
        map(() => {
            const {user, authRoles, trailAreas, dmoTrailAreas} = authService;
            const path = route.url.map(segment => segment.path).join('/');

            if (user && authRoles.includes(roles.ADMIN)) return true;

            const hasTrailAreaAccess = trailAreas.some(area => path.includes(area.key) && user && authRoles.includes(roles.TRAIL_AREA_MANAGER));
            if (hasTrailAreaAccess) return true;

            const hasDmoAreaAccess = dmoTrailAreas.some(area => path.includes(area.key) && user && authRoles.includes(roles.DMO_MANAGER));
            if (hasDmoAreaAccess) return true;

            router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
            return false;
        })
    );
};

export const organisationManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const roles = Roles;
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                const {user, authRoles, organisations} = authService;
                const path = route.url.map(segment => segment.path).join('/');

                if (user && authRoles.includes(roles.ADMIN)) return true;

                const hasOrganisationAccess = organisations.some(entry => path.includes(entry.key) && user && authRoles.includes(roles.ORGANISATION_MANAGER));
                if (hasOrganisationAccess) return true;

                router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                return false;
            })
        );
};

export const trailCenterManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const roles = Roles;
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                const {user, authRoles} = authService;

                if (user && authRoles.includes(roles.ADMIN)) return true;

                if (user && authRoles.includes(roles.TRAIL_CENTER_MANAGER)) return true;

                router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                return false;
            })
        );
};

export const onTrailManagerGuard: CanActivateFn = (route, state): Observable<boolean> => {
    const roles = Roles;
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.uid$
        .pipe(
            map(() => {
                const {user, authRoles} = authService;

                if (user && authRoles.includes(roles.ADMIN)) return true;

                if (user && authRoles.includes(roles.ON_TRAIL_MANAGER)) return true;

                router.navigate(['/dashboard']).then(() => window.alert('You do not have permission to see that page anymore'));
                return false;
            })
        );
};