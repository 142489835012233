import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, of, zip } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import firebase from 'firebase/compat';

// Services
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database/';

// Interfaces
import { FeedEntryContent, NewsStory, NewsStoryList, NewsStoryListItem, NewsStoryOnList, ProfileFeedEntry } from '../interfaces/news-story';
import { User } from '../interfaces/user';

import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class NewsStoryService {

    newsStoryRef: firebase.database.Reference;
    newsStoryListRef: firebase.database.Reference;
    profileFeedEntriesRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase
    ) {
        this.newsStoryRef = this.db.database.ref('newsStories');
        this.newsStoryListRef = this.db.database.ref('newsStoryLists');
        this.profileFeedEntriesRef = this.db.database.ref('profileFeedEntries');
    }

    private static getNewsStoryFromSnap(newsStorySnap: AngularFireAction<DatabaseSnapshot<{}>>): NewsStory {
        const newsStory: NewsStory = <NewsStory>newsStorySnap.payload.val();
        newsStory.key = newsStorySnap.key;
        if (typeof newsStory.lang !== 'object') {
            newsStory.lang = {};
        }
        if (typeof newsStory.content === 'object') {
            newsStory.content = Object.values(newsStory.content);
        } else {
            newsStory.content = [];
        }
        return newsStory;
    }

    getNewsStoryFromKey(newsRoomKey: string, newsStoryKey: string): Observable<NewsStory> {
        return this.db.object<NewsStory>(this.newsStoryRef.child(newsRoomKey).child(newsStoryKey).ref).snapshotChanges().pipe(
            map((newsStorySnap) => NewsStoryService.getNewsStoryFromSnap(newsStorySnap))
        );
    }

    getNewsStoriesIn(roomKey: string, useForWelcome: boolean = false): Observable<NewsStory[]> {
        return this.db.list<NewsStory>(this.newsStoryRef.child(roomKey).ref).snapshotChanges()
            .pipe(
                map((newsStorySnapshots) => {
                    const newsStories: NewsStory[] = [];
                    for (const i in newsStorySnapshots) {
                        if (!useForWelcome ||
                            (typeof newsStorySnapshots[i].payload.val().archivedAt !== 'number' &&
                                typeof newsStorySnapshots[i].payload.val().publishedAt === 'number' &&
                                newsStorySnapshots[i].payload.val().disableInteractions === true)) {
                            newsStories.push(NewsStoryService.getNewsStoryFromSnap(newsStorySnapshots[i]));
                        }
                    }
                    return newsStories;
                }),
            );
    }

    createNewsStory(newsStory: NewsStory, createIn: string, creator: User): string {
        newsStory.createdAt = moment.now();
        newsStory.creatorKey = creator.userID;
        newsStory.creatorName = creator.name;
        const thenableReference = this.newsStoryRef.child(createIn).push(newsStory);
        return thenableReference.key;
    }

    updateSettings(roomKey: string, newsStory: NewsStory): Promise<void> {
        const disableInteractions: boolean = newsStory.disableInteractions === true;
        return this.newsStoryRef.child(roomKey).child(newsStory.key).child('disableInteractions').set(disableInteractions);
    }

    /**
     * Updates the texts of the News Story
     */
    updateTexts(roomKey: string, newsStory: NewsStory): Promise<void> {
        // Delete texts that are blank
        for (const lang in newsStory.lang) {
            if (typeof newsStory.lang[lang] === 'object' && newsStory.lang[lang] !== null) {
                for (const textKey in newsStory.lang[lang]) {
                    if (newsStory.lang[lang].hasOwnProperty(textKey) &&
                        typeof newsStory.lang[lang][textKey] !== 'string' || newsStory.lang[lang][textKey] === 'undefined') {
                        newsStory.lang[lang][textKey] = null;
                    }
                }
            }
        }
        return this.newsStoryRef.child(roomKey).child(newsStory.key).update(<NewsStory>{
            title: newsStory.title,
            description: newsStory.description || null,
            lang: newsStory.lang
        });
    }

    uploadedImage(newsStory: NewsStory, roomKey: string, index: number, uploadedImageUrl: string): Promise<NewsStory> {
        const contentEntry: FeedEntryContent = {
            type: 'image',
            url: uploadedImageUrl
        };
        newsStory.content[index] = contentEntry;
        return this.newsStoryRef.child(roomKey).child(newsStory.key).child('content').child(index.toString()).update(contentEntry)
            .then(() => newsStory);
    }

    setTrails(newsStory: NewsStory, trailAreaKeys: string[]): Promise<NewsStory> {
        newsStory.trails = trailAreaKeys;
        if (trailAreaKeys === null) {
            return this.newsStoryRef.child(newsStory.trailAreaKey).child(newsStory.key).child('trails').remove()
                .then(() => newsStory);
        }
        return this.newsStoryRef.child(newsStory.trailAreaKey).child(newsStory.key).child('trails').update(trailAreaKeys)
            .then(() => newsStory);
    }

    deleteDraft(newsStory: NewsStory, roomKey: string): Promise<any> {
        if (newsStory.publishedAt) {
            return Promise.reject('Cannot delete published story');
        }
        return this.newsStoryRef.child(roomKey).child(newsStory.key).remove();
    }

    publish(newsStory: NewsStory, roomKey: string, publisher: User): Promise<NewsStory> {
        if (newsStory.publishedAt) {
            return Promise.resolve(newsStory);
        }
        newsStory.publishedAt = Date.now();
        newsStory.publisherKey = publisher.userID;
        newsStory.publisherName = publisher.name;
        const publishUpdate = {
            publishedAt: newsStory.publishedAt,
            publisherKey: publisher.userID,
            publisherName: publisher.name
        };
        return this.newsStoryRef.child(roomKey).child(newsStory.key).update(publishUpdate)
            .then(() => newsStory);
    }

    archiveStory(newsStory: NewsStory, roomKey: string, archivist: User): Promise<NewsStory> {
        if (newsStory.archivedAt) {
            return Promise.resolve(newsStory);
        }
        const isNewsStoryInList$ = this.isNewsStoryInList(newsStory.key).pipe(take(1));
        const isNewsStoryInListPromise = lastValueFrom(isNewsStoryInList$);

        return isNewsStoryInListPromise
            .then((isNewsStoryInList) => {
                if (isNewsStoryInList) {
                    return Promise.reject('News story cannot be archived. It is used in a welcome story!');
                }
                newsStory.archivedAt = moment.now();
                newsStory.archivistKey = archivist.userID;
                newsStory.archivistName = archivist.name;
                const archiveUpdate = {
                    archivedAt: newsStory.archivedAt,
                    archivistKey: archivist.userID,
                    archivistName: archivist.name
                };

                return this.newsStoryRef.child(roomKey).child(newsStory.key).update(archiveUpdate)
                    .then(() => newsStory);
            });
    }

    getNewsStoryOnListFromListItem(newsStoryListItem: NewsStoryListItem, listItemKey: string): Observable<NewsStoryOnList> {
        return this.getNewsStoryFromKey(newsStoryListItem.newsRoomKey, newsStoryListItem.newsStoryKey)
            .pipe(
                switchMap((newsStory) => {
                    const newsStoryOnList: NewsStoryOnList = <NewsStoryOnList>newsStory;
                    newsStoryOnList.listItemKey = listItemKey;
                    return of(newsStoryOnList);
                })
            );
    }

    isNewsStoryInList(newsStoryKey: string): Observable<boolean> {
        return this.db.list<NewsStoryList>(this.newsStoryListRef.ref).valueChanges()
            .pipe(
                map((newsStoryLists) => {
                    for (const newsStoryList of newsStoryLists) {
                        for (const newStoryListItemKey in newsStoryList) {
                            if (newsStoryList.hasOwnProperty(newStoryListItemKey) &&
                                newsStoryList[newStoryListItemKey].newsStoryKey === newsStoryKey) {
                                return true;
                            }
                        }
                    }
                    return false;
                })
            );
    }

    getNewsStoriesFromList(listKey: string): Observable<NewsStoryOnList[]> {
        return this.db.list<NewsStoryListItem>(this.newsStoryListRef.child(listKey).ref).snapshotChanges()
            .pipe(
                switchMap((newsStoryListItemSnapshots) => {
                    if (newsStoryListItemSnapshots.length === 0) {
                        return of([]);
                    }
                    const newsStoryObservables: Observable<NewsStoryOnList>[] = [];
                    newsStoryListItemSnapshots.forEach((newsStoryListItemSnapshot) => {
                        const newsStoryListItem: NewsStoryListItem = newsStoryListItemSnapshot.payload.val();
                        newsStoryObservables.push(this.getNewsStoryOnListFromListItem(newsStoryListItem, newsStoryListItemSnapshot.key));
                    });
                    return zip(...newsStoryObservables);
                })
            );
    }

    addWelcomeStoryToList(storyKey: string, listKey: string, feedEntryKey: string): string {
        const listItem: NewsStoryListItem = {
            newsRoomKey: 'news-room',
            newsStoryKey: storyKey,
            feedEntryKey: feedEntryKey
        };
        const listItemThenableReference = this.newsStoryListRef.child(listKey).push(listItem);
        return listItemThenableReference.key;
    }

    removeWelcomeStoryFromList(listKey: string, newsStoryOnList: NewsStoryOnList): Promise<void> {
        return this.newsStoryListRef.child(listKey).child(newsStoryOnList.listItemKey).remove();
    }

    addReachToList(listStories: NewsStoryOnList[], sortAndFilter: boolean): Observable<NewsStoryOnList[]> {
        console.log(listStories.length, sortAndFilter);
        return of(listStories); // Do not add reach - not like this

        // const usedFeedEntryKeys: string[] = [];
        // listStories.forEach((listStory) => {
        //     usedFeedEntryKeys.push(listStory.feedEntryKey);
        //     listStory.reach = 0;
        // });
        //
        // // This is too much for production.
        // // @todo - set reach per story differently (reach is created from published or through welcome stories)
        // return this.db.list<ProfileFeedEntries>(this.profileFeedEntriesRef.ref).valueChanges()
        //     .pipe(
        //         map((profilesFeedEntries) => {
        //             profilesFeedEntries.forEach((profileFeedEntries) => {
        //                 for (const feedEntryKey in profileFeedEntries) {
        //                     if (usedFeedEntryKeys.includes(feedEntryKey)) {
        //                         for (const listStoryIndex in listStories) {
        //                             if (feedEntryKey === listStories[listStoryIndex].feedEntryKey) {
        //                                 listStories[listStoryIndex].reach++;
        //                                 break;
        //                             }
        //                         }
        //                     }
        //                 }
        //             });
        //             return (sortAndFilter ? listStories.filter((s) => s.reach > 0).sort((a, b) => b.reach - a.reach) : listStories);
        //         })
        //     );

    }

    addStoryToFeedsList(feedEntryKey: string, profileKeys: string[]): Promise<any> {
        if (typeof feedEntryKey !== 'string' || feedEntryKey.length === 0) {
            console.error('AN ERROR OCCURRED with THE FEED ENTRY KEY - Notify Frederik NOW!', feedEntryKey);
            window.alert('AN ERROR OCCURRED with THE FEED ENTRY KEY - Notify Frederik NOW!');
            return Promise.reject(feedEntryKey);
        }
        const updateElement: ProfileFeedEntry = {
            time: moment.now(),
            type: 'story'
        };

        // Write the update element simultaneously in the feed entry lists of the selected users.
        const updates: { [path: string]: object } = {};
        profileKeys.forEach((profileKey) => {
            if (typeof profileKey !== 'string' || profileKey.length === 0) {
                console.error('AN ERROR OCCURRED WITH A PROFILE KEY - NOTIFY Frederik NOW!', feedEntryKey, profileKey);
                window.alert('AN ERROR OCCURRED WITH A PROFILE KEY - NOTIFY Frederik NOW!');
                return Promise.reject(profileKey);
            }
            updates[profileKey + '/' + feedEntryKey] = updateElement;
        });
        return this.profileFeedEntriesRef.update(updates);
    }

    addStoryToFeedList(feedEntryKey: string, profileKey: string): Promise<any> {
        const updateElement: ProfileFeedEntry = {
            time: moment.now(),
            type: 'story'
        };
        return this.profileFeedEntriesRef.child(profileKey).child(feedEntryKey).set(updateElement);
    }
}
