import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

// Services
import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from '@angular/fire/compat/database';

// Interfaces
import {MINIMUM_TIME_SLOT_MILLISECONDS, Sponsor, SponsorTargetType} from '../interfaces/sponsor';

import firebase from 'firebase/compat';
import * as moment from 'moment';
import {FileService} from "../services/file.service";

@Injectable({
    providedIn: 'root'
})
export class SponsorService {
    sponsorRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private fileService: FileService
    ) {
        this.sponsorRef = this.db.database.ref('sponsor');
    }

    private static sponsorSnapshotToSponsor(snapshot: AngularFireAction<DatabaseSnapshot<Sponsor>>): Sponsor {
        const sponsor = {
            key: snapshot.key,
            ...snapshot.payload.val()
        };
        return sponsor;
    }

    /**
     * Gets a specific sponsor from key
     */
    getSponsor(sponsorKey: string): Observable<Sponsor> {
        return this.db.object<Sponsor>(`sponsor/${sponsorKey}`).snapshotChanges().pipe(
            map((rawTreat) => SponsorService.sponsorSnapshotToSponsor(rawTreat))
        );
    }

    /**
     * Gets all sponsors without statistics
     */
    getSponsors(): Observable<Sponsor[]> {
        return this.db.list<Sponsor>(`sponsor`).snapshotChanges().pipe(
            map((rawTreats) => rawTreats.map((rawTreat) => SponsorService.sponsorSnapshotToSponsor(rawTreat)))
        );
    }

    /**
     * deletes a specific sponsor from key
     */
    removeSponsor(sponsor: Sponsor): Promise<void> {
        const deleteImagePromises: Promise<void>[] = [];
        if (sponsor.imageURL) {
            deleteImagePromises.push(this.fileService.deleteStorageFile(sponsor.imageURL));
        }
        return Promise.all(deleteImagePromises).then(() => this.sponsorRef.child(sponsor.key).remove()).catch(error => {
            console.error('Error deleting images or sponsor:', error);
            throw error;
        });
    }

    /**
     * Updates the settings of the sponsor
     */
    updateSettings(sponsor: Sponsor): Promise<void> {
        return this.sponsorRef.child(sponsor.key).update(<Sponsor>{
            key: sponsor.key || null,
            activeFrom: sponsor.activeFrom || 0,
            activeTo: sponsor.activeTo || (sponsor.activeFrom + 900000) || 900000,
            alias: sponsor.alias || null,
            link: sponsor.link || null,
        });
    }

    /**
     * Updates the type of the sponsor
     */
    updateType(sponsor: Sponsor): Promise<void> {
        return this.sponsorRef.child(sponsor.key).update(<Sponsor>{
            key: sponsor.key || null,
            sponsorType: sponsor.sponsorType || SponsorTargetType.NO_SPONSOR_TARGET_TYPE_SELECTED,
            autoRenewable: sponsor.autoRenewable || null,
            longitude: sponsor.longitude || 0,
            latitude: sponsor.latitude || 0,
            radius: sponsor.radius || 0,
            country: sponsor.country || null,
            trailTargetKeys: sponsor.trailTargetKeys,
            countryFilter: sponsor.countryFilter || null,
            isMainSponsor: sponsor.isMainSponsor
        });
    }

    /**
     * Updates the image of the sponsor
     */
    uploadedImage(sponsor: Sponsor): Promise<void> {
        return this.sponsorRef.child(sponsor.key).update(<Sponsor>{
            imageURL: sponsor.imageURL
        });
    }

    /**
     * Updates the image of the sponsor
     */
    uploadedImageSize(sponsor: Sponsor): Promise<void> {
        return this.sponsorRef.child(sponsor.key).update(<Sponsor>{
            imageHeight: sponsor.imageHeight,
            imageWidth: sponsor.imageWidth
        });
    }

    /**
     * Copy sponsor
     * @todo: Copy image
     */
    copySponsor(sponsor: Sponsor): Promise<string> {
        const activeFrom: number = Math.ceil(Math.max(sponsor.activeTo, moment.now()) / MINIMUM_TIME_SLOT_MILLISECONDS)
            * MINIMUM_TIME_SLOT_MILLISECONDS;
        const activeTo: number = Math.ceil(
            (activeFrom + Math.max(Math.abs(sponsor.activeTo - sponsor.activeFrom), MINIMUM_TIME_SLOT_MILLISECONDS))
            / MINIMUM_TIME_SLOT_MILLISECONDS) * MINIMUM_TIME_SLOT_MILLISECONDS;
        const copy: Sponsor = {
            alias: sponsor.alias + ' copy',
            link: sponsor.link,
            activeFrom: activeFrom,
            activeTo: activeTo,
            imageURL: '',
            sponsorType: sponsor.sponsorType,
            longitude: sponsor.longitude,
            latitude: sponsor.latitude,
            radius: sponsor.radius,
            country: sponsor.country,
            trailTargetKeys: sponsor.trailTargetKeys,
            countryFilter: sponsor.countryFilter,
            imageWidth: sponsor.imageWidth,
            imageHeight: sponsor.imageHeight

            //autoRenewable: undefined
        };
        const thenablePromise = this.sponsorRef.push(copy);
        return thenablePromise
            .then(() => thenablePromise.key);
    }

    newSponsor(newCampaignAlias: string): Promise<string> {
        const thenablePromise = this.sponsorRef.push(<Sponsor>{
            alias: newCampaignAlias,
            sponsorType: SponsorTargetType.NO_SPONSOR_TARGET_TYPE_SELECTED
        });
        return thenablePromise
            .then(() => thenablePromise.key);
    }
}
