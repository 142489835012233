import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { TrailAreaService } from '../../../firebase-services/trail-area.service';
import { EventService } from '../../../firebase-services/event.service';

// Interfaces
import { Event } from '../../../interfaces/event';
import { TrailArea } from '../../../interfaces/trailArea';

@Component({
    selector: 'app-trail-area-explorers',
    templateUrl: './trail-area-explorers.component.html',
    styleUrls: ['./trail-area-explorers.component.css']
})
export class TrailAreaExplorersComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    area: TrailArea = null;
    event: Event = null;

    constructor(
        private route: ActivatedRoute,
        private trailAreaService: TrailAreaService,
        private eventService: EventService
    ) {
    }

    ngOnInit() {
        this.trailAreaService.getTrailArea(this.route.snapshot.paramMap.get('areaKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((area) => this.area = area);
        this.eventService.getEvent(this.route.snapshot.paramMap.get('eventKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((event) => this.event = event);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
