import {LOCALE_ID, NgModule} from '@angular/core';

// Imports
import {BrowserModule} from '@angular/platform-browser';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {FormsModule} from '@angular/forms'; // <-- NgModel lives here
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {AngularFireFunctionsModule} from '@angular/fire/compat/functions';
import {AngularFireRemoteConfigModule, DEFAULTS, SETTINGS} from '@angular/fire/compat/remote-config';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';

import {environment} from '../environments/environment';
import {CoreModule} from './core/core.module';
import {AppRoutingModule} from './app-routing.module';

// Component declarations
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {HeaderComponent} from './header/header.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {FooterComponent} from './footer/footer.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
// Dashboard
import {DashboardComponent} from './dashboard/dashboard.component';
// Country
import {CountryTrailAreasAdminComponent} from './country/country-trail-areas-admin/country-trail-areas-admin.component';
import {CountryNewTrailAreaComponent} from './country/country-new-trail-area/country-new-trail-area.component';
import {CountryEventsComponent} from './country/country-events/country-events.component';
import {CountryEventEditComponent} from './country/country-events/country-event-edit/country-event-edit.component';
import {CountryExplorersComponent} from './country/country-events/country-explorers/country-explorers.component';
import {
    CountryEventItemEditComponent
} from './country/country-events/country-event-edit/country-event-item-edit/country-event-item-edit.component';
import {
    CountryContactResponsibleComponent
} from './country/country-contact-responsible/country-contact-responsible.component';
// Destination
import {DestinationComponent} from './destination/destination.component';
import {DestinationTrailAreasComponent} from './destination/destination-trail-areas/destination-trail-areas.component';
import {DestinationEventsComponent} from './destination/destination-events/destination-events.component';
import {
    DestinationEventEditComponent
} from './destination/destination-events/destination-event-edit/destination-event-edit.component';
import {
    DestinationEventExplorersComponent
} from './destination/destination-events/destination-event-explorers/destination-event-explorers.component';
import {
    DestinationEventItemEditComponent
} from './destination/destination-events/destination-event-item-edit/destination-event-item-edit.component';
// Trail Area
import {TrailAreaComponent} from './trail-area/trail-area.component';
import {TrailsComponent} from './trail-area/trails/trails.component';
import {TrailEditComponent} from './trail-area/trails/trail-edit/trail-edit.component';
import {TrailAreaPoisComponent} from './trail-area/trail-area-pois/trail-area-pois.component';
import {
    TrailAreaPoiEditComponent
} from './trail-area/trail-area-pois/trail-area-poi-edit/trail-area-poi-edit.component';
import {TrailAreaNewsComponent} from './trail-area/trail-area-news/trail-area-news.component';
import {
    TrailAreaEditDraftComponent
} from './trail-area/trail-area-news/trail-area-edit-draft/trail-area-edit-draft.component';
import {
    TrailAreaModifyStoryComponent
} from './trail-area/trail-area-news/trail-area-modify-story/trail-area-modify-story.component';
import {TrailAreaDraftsComponent} from './trail-area/trail-area-news/trail-area-drafts/trail-area-drafts.component';
import {TrailAreaStoriesComponent} from './trail-area/trail-area-news/trail-area-stories/trail-area-stories.component';
import {
    TrailAreaStoryArchiveComponent
} from './trail-area/trail-area-news/trail-area-story-archive/trail-area-story-archive.component';
import {TrailAreaEventsComponent} from './trail-area/trail-area-events/trail-area-events.component';
import {
    TrailAreaEventEditComponent
} from './trail-area/trail-area-events/trail-area-event-edit/trail-area-event-edit.component';
import {
    TrailAreaExplorersComponent
} from './trail-area/trail-area-events/trail-area-explorers/trail-area-explorers.component';
import {
    TrailAreaEventItemEditComponent
} from './trail-area/trail-area-events/trail-area-event-edit/trail-area-event-item-edit/trail-area-event-item-edit.component';
import {AreaManagersComponent} from './trail-area/area-managers/area-managers.component';
import {RideStatisticsComponent} from './trail-area/ride-statistics/ride-statistics.component';
// Organisation
import {MembershipComponent} from './organisation/membership/membership.component';
import {MembersComponent} from './organisation/members/members.component';
// OnTrail
import {OnTrailStatusComponent} from './on-trail/on-trail-status/on-trail-status.component';
import {
    OnTrailApplicationComponent
} from './on-trail/on-trail-status/on-trail-application/on-trail-application.component';
import {OnTrailContactsComponent} from './on-trail/on-trail-contacts/on-trail-contacts.component';
// Root
import {RootDashboardComponent} from './root/root-dashboard/root-dashboard.component';
import {KioskOrdersComponent} from './root/kiosk-orders/kiosk-orders.component';
import {NewsRoomComponent} from './root/news-room/news-room.component';
import {NewsRoomEditDraftComponent} from './root/news-room/news-room-edit-draft/news-room-edit-draft.component';
import {NewsRoomModifyStoryComponent} from './root/news-room/news-room-modify-story/news-room-modify-story.component';
import {NewsRoomDraftsComponent} from './root/news-room/news-room-drafts/news-room-drafts.component';
import {NewsRoomStoriesComponent} from './root/news-room/news-room-stories/news-room-stories.component';
import {NewsRoomArchiveComponent} from './root/news-room/news-room-archive/news-room-archive.component';
import {NewsRoomWelcomeComponent} from './root/news-room/news-room-welcome/news-room-welcome.component';
import {PremiumProductComponent} from './root/premium/premium-product/premium-product.component';
import {PremiumMembersComponent} from './root/premium/premium-members/premium-members.component';
import {VoronoiComponent} from './root/voronoi/voronoi.component';
import {ShapefileImportComponent} from './root/trail-manager/shapefile-import/shapefile-import.component';
import {ExportFacilitydbComponent} from './root/trail-manager/export-facilitydb/export-facilitydb.component';
import {TrailsImportComponent} from './root/trail-manager/trails-import/trails-import.component';
import {NewDestinationComponent} from './root/new-destination/new-destination.component';
import {RtwEventsComponent} from './root/rtw/rtw-events/rtw-events.component';
import {RtwEventParticipantsComponent} from './root/rtw/rtw-event-participants/rtw-event-participants.component';
import {EditRtwEventComponent} from './root/rtw/edit-rtw-event/edit-rtw-event.component';
import {CreateRtwEventComponent} from './root/rtw/create-rtw-event/create-rtw-event.component';
import {RidesComponent} from './root/rides/rides.component';
import {RideComponent} from './root/rides/ride/ride.component';
import {PoiCategoriesComponent} from './root/poiManager/poi-categories/poi-categories.component';
import {EditPoiCategoryComponent} from './root/poiManager/poi-categories/edit-poi-category/edit-poi-category.component';
import {PoiValidatorComponent} from './root/poiManager/poi-validator/poi-validator.component';
import {PoiImportComponent} from './root/poiManager/poi-import/poi-import.component';
import {MediaLibraryComponent} from './root/media-library/media-library.component';
import {EmailExtractComponent} from './root/email-extract/email-extract.component';
import {TreatsComponent} from './root/treats/treats.component';
import {TreatEditComponent} from './root/treats/treat-edit/treat-edit.component';
import {SponsorComponent} from './root/sponsor/sponsor.component';
import {SponsorEditComponent} from './root/sponsor/sponsor-edit/sponsor-edit.component';
import {RoleManagerComponent} from "./root/role-manager/role-manager.component";
import {EventManagersComponent} from "./root/role-manager/event-managers/event-managers.component";

// event
import {EventManagerDashboardComponent} from "./event/event-manager-dashboard/event-manager-dashboard.component";

// trail-center
import {TrailCenterDashboardComponent} from "./trail-center/trail-center-dashboard/trail-center-dashboard.component"


// Pipes
import {KeyValueBasePipe} from './pipes/key-value-base.pipe';
import {KeyValuePoiCategoryPipe} from './pipes/key-value-poi-category.pipe';
import {KeyValuePoiSubCategoryPipe} from './pipes/key-value-poi-sub-category.pipe';
import {KeyValuePublicPoiCategoryPipe} from './pipes/key-value-public-poi-category.pipe';
import {KeyValuePublicPoiSubCategoryPipe} from './pipes/key-value-public-poi-sub-category.pipe';
import {DatePipe, registerLocaleData} from '@angular/common'

// Sub-components
import {AddMediaOfTypeComponent} from './ui/add-media-of-type/add-media-of-type.component';
import {EventListComponent} from './ui/event-list/event-list.component';
import {EventEditComponent} from './ui/event-edit/event-edit.component';
import {EventItemEditComponent} from './ui/event-item-edit/event-item-edit.component';
import {AlertInfoBoxComponent} from './ui/alert-info-box/alert-info-box.component';
import {ConfirmDialogComponent} from './ui/confirm-dialog/confirm-dialog.component';
import {ContentHeaderSectionComponent} from './ui/content-header-section/content-header-section.component';
import {CreateOnMapComponent} from './ui/create-on-map/create-on-map.component';
import {ExplorersComponent} from './ui/explorers/explorers.component';
import {FindUserComponent} from './ui/find-user/find-user.component';
import {FindTrailComponent} from './ui/find-trail/find-trail.component';
import {ImageHandlerComponent} from './ui/image-handler/image-handler.component';
import {KioskProductHandlerComponent} from './ui/kiosk-product-handler/kiosk-product-handler.component';
import {NewsRoomListComponent} from './ui/news-room-list/news-room-list.component';
import {NewsStoryAddBoxComponent} from './ui/news-story-add-box/news-story-add-box.component';
import {NewsEditComponent} from './ui/news-edit/news-edit.component';
import {PublishedIndicatorComponent} from './ui/published-indicator/published-indicator.component';
import {TranslatableTextsComponent} from './ui/translatable-texts/translatable-texts.component';
import {TranslatableTextEditComponent} from './ui/translatable-text-edit/translatable-text-edit.component';

import {FindProfileSegmentComponent} from './ui/find-profile-segment/find-profile-segment.component';
import localeDa from '@angular/common/locales/da';
// Directives
import {ClickPreventDefaultDirective} from './directives/click-prevent-default.directive';
import {ClickStopPropagationDirective} from './directives/click-stop-propagation.directive';
import {FindTrailAreaComponent} from "./ui/find-trail-area/find-trail-area.component";
import {EditRoleDescriptionComponent} from "./ui/edit-role-description/edit-role-description.component";
import {DevToolsComponent} from "./root/dev-tools/dev-tools.component";
import {DmoManagersComponent} from "./root/role-manager/dmo-managers/dmo-managers.component";
import {CountryManagersComponent} from "./root/role-manager/country-managers/country-managers.component";
import {DestinationManagersComponent} from "./root/role-manager/destination-managers/destination-managers.component";
import {TrailAreaManagersComponent} from "./root/role-manager/trail-area-managers/trail-area-managers.component";
import {SponsorManagersComponent} from "./root/role-manager/sponsor-managers/sponsor-managers.component";
import {TreatManagersComponent} from "./root/role-manager/treat-managers/treat-managers.component";
import {OrganisationManagersComponent} from "./root/role-manager/organisation-managers/organisation-managers.component";
import {NewsRoomManagersComponent} from "./root/role-manager/news-room-managers/news-room-managers.component";
import {FindCountryComponent} from "./ui/find-country/find-country.component";
import {FindDestinationComponent} from "./ui/find-destination/find-destination.component";
import {PoiManagersComponent} from "./root/role-manager/poi-managers/poi-managers.component";
import {PoiManagerDashboardComponent} from "./poi/poi-manager-dashboard/poi-manager-dashboard.component";
import {DestinationPoisComponent} from "./destination/destination-pois/destination-pois.component";
import {
    DestinationPoiEditComponent
} from "./destination/destination-pois/destination-poi-edit/destination-poi-edit.component";
import {CountryPoisComponent} from "./country/country-pois/country-pois.component";
import {CountryPoiEditComponent} from "./country/country-pois/country-poi-edit/country-poi-edit.component";
import {PressCampManagersComponent} from "./root/role-manager/press-camp-managers/press-camp-managers.component";
import {
    PressCampManagerDashboardComponent
} from "./press-camp/press-camp-manager-dashboard/press-camp-manager-dashboard.component";
import {PressCampGroupEditComponent} from "./press-camp/press-camp-group-edit/press-camp-group-edit.component";
import {PressCampMeetupEditComponent} from "./press-camp/press-camp-meetup-edit/press-camp-meetup-edit.component";
import {PressCampOverviewComponent} from "./press-camp/press-camp-overview/press-camp-overview.component";
import {FindGroupComponent} from "./ui/find-group/find-group.component";
import {FollowsPeopleComponent} from "./ui/follows-people/follows-people.component";
import {MapPickerComponent} from "./ui/map-picker/map-picker.component";
import {GroupMemberPickerComponent} from "./ui/group-member-picker/group-member-picker.component";

registerLocaleData(localeDa);

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HeaderComponent,
        SidebarComponent,
        FooterComponent,
        UserProfileComponent,
        DashboardComponent,
        CountryTrailAreasAdminComponent,
        CountryNewTrailAreaComponent,
        CountryEventsComponent,
        CountryEventEditComponent,
        CountryExplorersComponent,
        CountryEventItemEditComponent,
        CountryContactResponsibleComponent,
        CountryPoisComponent,
        CountryPoiEditComponent,
        DestinationComponent,
        DestinationTrailAreasComponent,
        DestinationEventsComponent,
        DestinationEventEditComponent,
        DestinationEventExplorersComponent,
        DestinationEventItemEditComponent,
        DestinationPoisComponent,
        DestinationPoiEditComponent,
        TrailAreaComponent,
        TrailsComponent,
        TrailEditComponent,
        TrailAreaPoisComponent,
        TrailAreaPoiEditComponent,
        TrailAreaNewsComponent,
        TrailAreaEditDraftComponent,
        TrailAreaModifyStoryComponent,
        TrailAreaDraftsComponent,
        TrailAreaStoriesComponent,
        TrailAreaStoryArchiveComponent,
        TrailAreaEventsComponent,
        TrailAreaEventEditComponent,
        TrailAreaExplorersComponent,
        TrailAreaEventItemEditComponent,
        AreaManagersComponent,
        RideStatisticsComponent,
        MembershipComponent,
        MembersComponent,
        OnTrailStatusComponent,
        OnTrailApplicationComponent,
        OnTrailContactsComponent,
        RootDashboardComponent,
        KioskOrdersComponent,
        NewsRoomComponent,
        NewsRoomEditDraftComponent,
        NewsRoomModifyStoryComponent,
        NewsRoomDraftsComponent,
        NewsRoomStoriesComponent,
        NewsRoomArchiveComponent,
        NewsRoomWelcomeComponent,
        PremiumProductComponent,
        PremiumMembersComponent,
        VoronoiComponent,
        NewDestinationComponent,
        ShapefileImportComponent,
        ExportFacilitydbComponent,
        TrailsImportComponent,
        PoiCategoriesComponent,
        EditPoiCategoryComponent,
        PoiValidatorComponent,
        PoiImportComponent,
        CreateRtwEventComponent,
        RtwEventsComponent,
        RtwEventParticipantsComponent,
        EditRtwEventComponent,
        RidesComponent,
        RideComponent,
        MediaLibraryComponent,
        EmailExtractComponent,
        RoleManagerComponent,
        TreatsComponent,
        TreatEditComponent,
        KeyValueBasePipe,
        KeyValuePoiCategoryPipe,
        KeyValuePoiSubCategoryPipe,
        KeyValuePublicPoiCategoryPipe,
        KeyValuePublicPoiSubCategoryPipe,
        AddMediaOfTypeComponent,
        EventListComponent,
        EventEditComponent,
        EventEditComponent,
        EventItemEditComponent,
        AlertInfoBoxComponent,
        ConfirmDialogComponent,
        ContentHeaderSectionComponent,
        CreateOnMapComponent,
        ExplorersComponent,
        FindUserComponent,
        FindCountryComponent,
        FindDestinationComponent,
        FindTrailComponent,
        ImageHandlerComponent,
        KioskProductHandlerComponent,
        NewsRoomListComponent,
        NewsStoryAddBoxComponent,
        NewsEditComponent,
        PublishedIndicatorComponent,
        TranslatableTextsComponent,
        TranslatableTextEditComponent,
        FindProfileSegmentComponent,
        ClickPreventDefaultDirective,
        ClickStopPropagationDirective,
        SponsorComponent,
        SponsorEditComponent,
        EventManagerDashboardComponent,
        PoiManagerDashboardComponent,
        TrailCenterDashboardComponent,
        FindTrailAreaComponent,
        FindGroupComponent,
        FollowsPeopleComponent,
        EditRoleDescriptionComponent,
        DevToolsComponent,
        EventManagersComponent,
        DmoManagersComponent,
        CountryManagersComponent,
        DestinationManagersComponent,
        TrailAreaManagersComponent,
        SponsorManagersComponent,
        TreatManagersComponent,
        OrganisationManagersComponent,
        NewsRoomManagersComponent,
        PoiManagersComponent,
        PressCampManagersComponent,
        PressCampManagerDashboardComponent,
        PressCampOverviewComponent,
        PressCampGroupEditComponent,
        PressCampMeetupEditComponent,
        MapPickerComponent,
        GroupMemberPickerComponent
    ],
    bootstrap: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything
        CoreModule, // <-- add core module
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFireFunctionsModule,
        AngularFireRemoteConfigModule,
        AppRoutingModule,
        CKEditorModule
    ],
    providers: [
        DatePipe,
        {provide: LOCALE_ID, useValue: 'da-DA'},
        {provide: DEFAULTS, useValue: {enableAwesome: true}},
        {
            provide: SETTINGS,
            useFactory: () => environment.production ? {} : {minimumFetchIntervalMillis: 10000}
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {
}
