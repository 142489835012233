import { AfterViewChecked, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { lastValueFrom, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

// Services
import { TrailAreaService } from '../../firebase-services/trail-area.service';
import { UserService } from '../../firebase-services/user.service';
import { CountryService } from '../../firebase-services/country.service';
import { OnTrailService } from '../../firebase-services/on-trail.service';

// Interfaces
import { TrailArea } from '../../interfaces/trailArea';
import { Country } from '../../interfaces/countries';
import { User } from '../../interfaces/user';
import {TrailAreaManagerService} from "../../root/role-manager/trail-area-managers/trail-area-manager.service";

declare var $: any;

@Component({
    selector: 'app-country-contact-responsible',
    templateUrl: './country-contact-responsible.component.html',
    styleUrls: ['./country-contact-responsible.component.css']
})
export class CountryContactResponsibleComponent implements AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    country: Country = null;
    countryTrailAreas: TrailArea[];
    trailAreaManagers: { [trailAreaKey: string]: User[] };
    emails: { [profileKey: string]: string };
    contacts: { [trailAreaKey: string]: string[] };

    // Datatable
    dataTableName = '#contactDataTable';
    dataTable: any = null;
    dataTableCreated = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private trailAreaService: TrailAreaService,
        private trailAreaManagerService: TrailAreaManagerService,
        private userService: UserService,
        private countryService: CountryService,
        private onTrailService: OnTrailService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initialise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && ($(this.dataTableName + '>tbody>tr').length > 0)) {
            this.dataTable = $(this.dataTableName).DataTable({
                dom: 'Blfrtip',
                buttons: [
                    'csvHtml5',
                    'excelHtml5'
                ],
                'order': [[0, 'asc']]
            });
            this.dataTableCreated = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    init() {
        this.countryTrailAreas = null;
        this.contacts = null;
        this.emails = {};
        this.trailAreaManagers = null;
        const countryCode = this.route.snapshot.paramMap.get('countryCode');
        this.getCountry(countryCode);
        this.getCountryTrailAreas(countryCode);
    }

    private getCountry(countryCode: string) {
        this.countryService.getCountry(countryCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe((country) => this.country = country);
    }

    private getCountryTrailAreas(countryCode: string) {
        this.trailAreaService.getTrailAreasForCountry(countryCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe((trailAreas) => {
                const managerPromises: Promise<{ trailAreaKey: string, managers: User[] }>[] = [];
                this.getOnTrail(trailAreas);
                for (const trailArea of trailAreas) {
                    managerPromises.push(this.getTrailAreaManagers(trailArea.key));
                }
                Promise.all(managerPromises)
                    .then((managerLists) => {
                        this.handleManagers(managerLists)
                            .then(() => this.setCountryTrailAreas(trailAreas));
                    });
            });
    }

    private getOnTrail(trailAreas: TrailArea[]): void {
        this.onTrailService.getOnTrailApplications()
            .pipe(takeUntil(this.destroy$))
            .subscribe((allApplications) => {
                const contacts: { [trailAreaKey: string]: string[] } = {};
                trailAreas.forEach((trailArea) => {
                    allApplications.forEach((application) => {
                        if (trailArea.trailKeys.hasOwnProperty(application.trackId)) {
                            const contact = application.name + ' <' + application.email + '>';
                            if (!contacts[trailArea.key]) {
                                contacts[trailArea.key] = [];
                            }
                            if (contacts[trailArea.key].indexOf(contact) === -1) {
                                contacts[trailArea.key].push(contact);
                            }
                        }
                    });
                });
                this.contacts = contacts;
                this.setCountryTrailAreas(trailAreas);
            });
    }

    private getTrailAreaManagers(trailAreaKey: string): Promise<{ trailAreaKey: string, managers: User[] }> {
        const getManagers$ = this.trailAreaManagerService.getManagersForTrailArea(trailAreaKey)
            .pipe(take(1));
        return lastValueFrom(getManagers$)
            .then((managers) => {
                return {
                    trailAreaKey: trailAreaKey,
                    managers: managers.filter((m) => m)
                };
            });
    }

    private handleManagers(managerElementList: { trailAreaKey: string, managers: User[] }[]): Promise<any> {
        const trailAreaManagers: { [trailAreaKey: string]: User[] } = {};
        const managerSet: Set<string> = new Set();
        managerElementList.forEach((managerElement) => {
            if (managerElement.managers.length > 0) {
                managerElement.managers.forEach((user) => {
                    if (!user.userID) {
                        console.error('Invalid user was found', user);
                    } else {
                        managerSet.add(user.userID);
                    }
                });
                trailAreaManagers[managerElement.trailAreaKey] = managerElement.managers;
            } else {
                trailAreaManagers[managerElement.trailAreaKey] = [];
            }
        });
        const emailPromises: Promise<any>[] = [];
        managerSet.forEach((profileKey) => {
            const email$ = this.userService.getProfileEmailFromKey(profileKey)
                .pipe(take(1));
            const emailPromise = lastValueFrom(email$)
                .then((email) => {
                    this.emails[profileKey] = email;
                });
            emailPromises.push(emailPromise);
        });
        return Promise.all(emailPromises)
            .then(() => this.trailAreaManagers = trailAreaManagers);
    }

    private setCountryTrailAreas(countryTrailAreas: TrailArea[]) {
//        console.log('Ready?', (this.contacts !== null), typeof this.contacts, (this.trailAreaManagers !== null),
//        typeof this.trailAreaManagers);
        if (this.contacts && this.trailAreaManagers) {
            // console.log('Set TAs', Object.keys(this.contacts).length, Object.keys(this.trailAreaManagers).length);
            this.countryTrailAreas = countryTrailAreas;
        }
    }
}
