import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {FollowsUser} from "../../interfaces/follow-user";
import {Subject} from "rxjs";
import {FollowUserService} from "../../firebase-services/follow-user.service";

declare var $: any;

@Component({
    selector: 'app-follows-people',
    templateUrl: './follows-people.component.html',
    styleUrls: ['./follows-people.component.css']
})
export class FollowsPeopleComponent implements OnDestroy, OnChanges {
    @Input() modalId: string;
    @Input() modalTitle: string;
    @Input() btnClassType: string;
    @Input() filterList: string[] = [];
    @Output() peopleSelected = new EventEmitter<string[]>();
    destroy$: Subject<boolean> = new Subject<boolean>();
    foundPeople: FollowsUser[] = [];
    private allPeople: FollowsUser[] = [];
    selectedUserKeys: Set<string> = new Set<string>();

    constructor(
        private followUserService: FollowUserService
    ) {
        this.followUserService.getUserFollowsPeople()
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe((followsList) => {
                this.allPeople = followsList;
                this.applyFilter();
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterList) {
            this.applyFilter();
        }
    }

    private applyFilter(): void {
        this.foundPeople = this.allPeople.filter(user => !this.filterList.includes(user.key));
    }

    submitSelection(): void {
        this.peopleSelected.emit(Array.from(this.selectedUserKeys));
        $('#followsPeopleModal_' + this.modalId).modal('hide');
    }

    toggleSelection(userKey: string): void {
        if (this.selectedUserKeys.has(userKey)) {
            this.selectedUserKeys.delete(userKey);
        } else {
            this.selectedUserKeys.add(userKey);
        }
    }
}
