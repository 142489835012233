import { Component, ViewChild, ElementRef, Input, EventEmitter, Output, AfterViewChecked } from '@angular/core';

// Services
import { AuthService } from '../../core/auth.service';
import { GeoService } from '../../services/geo.service';
import { MapWorkerService } from '../../services/map-worker.service';

// Interfaces
import { Country } from '../../interfaces/countries';
import { Destination } from '../../interfaces/destination';
import { CreateOnMap, DefaultMapProp, LOOSE_PADDING, ZoomRestrictionSetting } from '../../interfaces/map';
import { TrailArea } from '../../interfaces/trailArea';
import {take} from "rxjs/operators";
import {TrailAreaService} from "../../firebase-services/trail-area.service";

@Component({
    selector: 'app-create-on-map',
    templateUrl: './create-on-map.component.html',
    styleUrls: ['./create-on-map.component.css']
})
export class CreateOnMapComponent implements AfterViewChecked {
    @ViewChild('gmap') gmapElement: ElementRef;

    @Input() themeColor: string;
    @Input() themeIcon: string;
    @Input() typeName: string;
    @Input() zoomRestrictions: string = ZoomRestrictionSetting.MEDIUM;
    @Input() areaObject: Country | Destination | TrailArea;

    @Output() createdOnMap = new EventEmitter<CreateOnMap>();

    private map: google.maps.Map = null;
    private markerLatLngLiteral: google.maps.LatLngLiteral = null;

    newName = '';

    constructor(
        private authService: AuthService,
        private mapWorkerService: MapWorkerService,
        private trailAreaService: TrailAreaService
    ) {
    }

    private static isTrailArea(obj: any): obj is TrailArea {
        return (typeof obj.trailKeys === 'object');
    }

    private static isDestination(obj: any): obj is Destination {
        return (typeof obj.trailAreaKeys === 'object');
    }

    ngAfterViewChecked() {
        if (this.map !== null) {
            return;
        }
        if (typeof this.areaObject.boundsNorth !== 'number') {
            console.error('Supplied area is not ok', this.areaObject);
            return;
        }

        const mapProp: google.maps.MapOptions = Object.assign({}, DefaultMapProp);
        mapProp.fullscreenControl = false;

        const mapBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
        if (this.areaObject.boundsSouth < this.areaObject.boundsNorth) {
            if ('lat' in this.areaObject && 'lng' in this.areaObject &&
                typeof this.areaObject['lat'] === 'number' && typeof this.areaObject['lng'] === 'number') {
                this.markerLatLngLiteral = {lat: this.areaObject['lat'], lng: this.areaObject['lng']};
            } else {
                this.markerLatLngLiteral = {
                    lat: (this.areaObject.boundsNorth + this.areaObject.boundsSouth) / 2,
                    lng: (this.areaObject.boundsEast + this.areaObject.boundsWest) / 2
                };
            }

            const loosePadding = (this.zoomRestrictions === ZoomRestrictionSetting.LOOSE ? LOOSE_PADDING : 0);
            mapProp.restriction = {
                latLngBounds: {
                    north: this.areaObject.boundsNorth + loosePadding,
                    south: this.areaObject.boundsSouth - loosePadding,
                    east: this.areaObject.boundsEast + loosePadding,
                    west: this.areaObject.boundsWest - loosePadding
                },
                strictBounds: (this.zoomRestrictions === ZoomRestrictionSetting.STRICT)
            };
            mapBounds.extend({lat: this.areaObject.boundsNorth, lng: this.areaObject.boundsEast});
            mapBounds.extend({lat: this.areaObject.boundsSouth, lng: this.areaObject.boundsWest});
        } else {
            const userPos1: google.maps.LatLngLiteral = GeoService.geohashToLatLngLiteral(this.authService.user.geohash.substr(0, 5) + '1');
            const userPos2: google.maps.LatLngLiteral = GeoService.geohashToLatLngLiteral(this.authService.user.geohash.substr(0, 5) + 'z');

            this.markerLatLngLiteral = {lat: (userPos1.lat + userPos2.lat) / 2, lng: (userPos1.lng + userPos2.lng) / 2};

            mapBounds.extend(userPos1);
            mapBounds.extend(userPos2);
        }

        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
        this.map.fitBounds(mapBounds, {bottom: 1, left: 1, right: 1, top: 1});

        const marker = new google.maps.Marker({
            position: this.markerLatLngLiteral,
            draggable: true,
            clickable: false,
            map: this.map,
            zIndex: 404
        });
        marker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
            this.markerLatLngLiteral = {lat: event.latLng.lat(), lng: event.latLng.lng()};
        });

        if (CreateOnMapComponent.isTrailArea(this.areaObject)) {
            this.mapWorkerService.trailAreaToMap(this.areaObject, this.map)
                .subscribe((trailAreaOnMap) => {
                    trailAreaOnMap.centerMarker.setMap(null);
                });
        }

        if (CreateOnMapComponent.isDestination(this.areaObject)) {
            Object.keys(this.areaObject.trailAreaKeys).forEach((key) => {
                this.trailAreaService.getTrailArea(key).pipe(take(1)).subscribe((area) => {
                    this.mapWorkerService.trailAreaToMap(area, this.map)
                        .subscribe();
                });
            });
        }
    }

    createNew(): void {
        if (this.newName === '') {
            alert('Please give the new ' + this.typeName + ' a name');
            return;
        }
        const createdItem: CreateOnMap = {
            name: this.newName,
            lat: this.markerLatLngLiteral.lat,
            lng: this.markerLatLngLiteral.lng,
            geohash: GeoService.latLngLiteralToGeohash(this.markerLatLngLiteral)
        };

        this.createdOnMap.emit(createdItem);
    }

}
