import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database/';

// Interfaces
import { KioskCustomer } from '../interfaces/kiosk';

import firebase from 'firebase/compat';

const NOT_SET = 'Not set';

@Injectable({
    providedIn: 'root'
})
export class KioskCustomerService {

    kioskCustomersRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase
    ) {
        this.kioskCustomersRef = this.db.database.ref('kioskCustomer');
    }

    private static getKioskCustomerFromSnap(snap: AngularFireAction<DatabaseSnapshot<KioskCustomer>>): KioskCustomer {
        if (!snap.payload.exists()) {
            return {
                city: NOT_SET,
                country: NOT_SET,
                name: null,
                street: NOT_SET,
                zip: NOT_SET
            };
        }
        return snap.payload.val();
    }

    getKioskCustomer(profileKey: string): Observable<KioskCustomer> {
        return this.db.object<KioskCustomer>(this.kioskCustomersRef.child(profileKey).ref).snapshotChanges()
            .pipe(
                map((customerSnap) => {
                    return KioskCustomerService.getKioskCustomerFromSnap(customerSnap);
                })
            );
    }
}
