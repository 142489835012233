<div class="content-wrapper">
    <app-content-header-section contentPageId="treatManagers" [pageParams]="{ }"></app-content-header-section>

    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6">
                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-user-tie"></i> Treat managers</h3>
                        </div>
                        <div class="card-body px-0">
                            <table *ngIf="allIsLoaded" id="treatManagersDataTable" class="table">
                                <thead>
                                <tr>
                                    <th>Manager</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let manager of treatManagers">
                                    <td>
                                        <img class="img-fluid img-circle img-sm"
                                             src="{{ manager.userPicture }}" alt="">&nbsp;
                                        <strong>{{ manager.name }}</strong>
                                        <div class="mt-3" *ngIf="authService.isUser(roles.ADMIN)">
                                            <button class="btn btn-xs btn-danger"
                                                    (click)="revokeTreatManager(manager.userID)">
                                                <i class="fas fa-trash"></i> Revoke manager
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <app-find-user modalId="assignTreatRole"
                                           modalTitle="Find user to assign as treat manager" id="selectTreatRoleUser"
                                           (userSelected)="assignTreatManager($event)" btnClassType="success">Add
                                Manager
                            </app-find-user>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>