import { NgModule } from '@angular/core';

// Imports
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms'; // <-- NgModel lives here
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database/';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions/';
import { AngularFireRemoteConfigModule, DEFAULTS, SETTINGS } from '@angular/fire/compat/remote-config/';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';

// Component declarations
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
// Dashboard
import { DashboardComponent } from './dashboard/dashboard.component';
// Country
import { CountryTrailAreasAdminComponent } from './country/country-trail-areas-admin/country-trail-areas-admin.component';
import { CountryNewTrailAreaComponent } from './country/country-new-trail-area/country-new-trail-area.component';
import { CountryAdventuresComponent } from './country/country-adventures/country-adventures.component';
import { CountryAdventureEditComponent } from './country/country-adventures/country-adventure-edit/country-adventure-edit.component';
import { CountryExplorersComponent } from './country/country-adventures/country-explorers/country-explorers.component';
import { CountryAdventureItemEditComponent } from './country/country-adventures/country-adventure-edit/country-adventure-item-edit/country-adventure-item-edit.component';
import { CountryContactResponsibleComponent } from './country/country-contact-responsible/country-contact-responsible.component';
// Destination
import { DestinationComponent } from './destination/destination.component';
import { DestinationTrailAreasComponent } from './destination/destination-trail-areas/destination-trail-areas.component';
import { DestinationAdventuresComponent } from './destination/destination-adventures/destination-adventures.component';
import { DestinationAdventureEditComponent } from './destination/destination-adventures/destination-adventure-edit/destination-adventure-edit.component';
import { DestinationAdventureExplorersComponent } from './destination/destination-adventures/destination-adventure-explorers/destination-adventure-explorers.component';
import { DestinationAdventureItemEditComponent } from './destination/destination-adventures/destination-adventure-item-edit/destination-adventure-item-edit.component';
// Trail Area
import { TrailAreaComponent } from './trail-area/trail-area.component';
import { TrailsComponent } from './trail-area/trails/trails.component';
import { TrailEditComponent } from './trail-area/trails/trail-edit/trail-edit.component';
import { PoisComponent } from './trail-area/pois/pois.component';
import { PoiEditComponent } from './trail-area/pois/poi-edit/poi-edit.component';
import { TrailAreaNewsComponent } from './trail-area/trail-area-news/trail-area-news.component';
import { TrailAreaEditDraftComponent } from './trail-area/trail-area-news/trail-area-edit-draft/trail-area-edit-draft.component';
import { TrailAreaModifyStoryComponent } from './trail-area/trail-area-news/trail-area-modify-story/trail-area-modify-story.component';
import { TrailAreaDraftsComponent } from './trail-area/trail-area-news/trail-area-drafts/trail-area-drafts.component';
import { TrailAreaStoriesComponent } from './trail-area/trail-area-news/trail-area-stories/trail-area-stories.component';
import { TrailAreaStoryArchiveComponent } from './trail-area/trail-area-news/trail-area-story-archive/trail-area-story-archive.component';
import { TrailAreaAdventuresComponent } from './trail-area/trail-area-adventures/trail-area-adventures.component';
import { TrailAreaAdventureEditComponent } from './trail-area/trail-area-adventures/trail-area-adventure-edit/trail-area-adventure-edit.component';
import { TrailAreaExplorersComponent } from './trail-area/trail-area-adventures/trail-area-explorers/trail-area-explorers.component';
import { TrailAreaAdventureItemEditComponent } from './trail-area/trail-area-adventures/trail-area-adventure-edit/trail-area-adventure-item-edit/trail-area-adventure-item-edit.component';
import { AreaManagersComponent } from './trail-area/area-managers/area-managers.component';
import { RideStatisticsComponent } from './trail-area/ride-statistics/ride-statistics.component';
// Organisation
import { MembershipComponent } from './organisation/membership/membership.component';
import { MembersComponent } from './organisation/members/members.component';
// OnTrail
import { OnTrailStatusComponent } from './on-trail/on-trail-status/on-trail-status.component';
import { OnTrailApplicationComponent } from './on-trail/on-trail-status/on-trail-application/on-trail-application.component';
import { OnTrailContactsComponent } from './on-trail/on-trail-contacts/on-trail-contacts.component';
// Root
import { RootDashboardComponent } from './root/root-dashboard/root-dashboard.component';
import { KioskOrdersComponent } from './root/kiosk-orders/kiosk-orders.component';
import { NewsRoomComponent } from './root/news-room/news-room.component';
import { NewsRoomEditDraftComponent } from './root/news-room/news-room-edit-draft/news-room-edit-draft.component';
import { NewsRoomModifyStoryComponent } from './root/news-room/news-room-modify-story/news-room-modify-story.component';
import { NewsRoomDraftsComponent } from './root/news-room/news-room-drafts/news-room-drafts.component';
import { NewsRoomStoriesComponent } from './root/news-room/news-room-stories/news-room-stories.component';
import { NewsRoomArchiveComponent } from './root/news-room/news-room-archive/news-room-archive.component';
import { NewsRoomWelcomeComponent } from './root/news-room/news-room-welcome/news-room-welcome.component';
import { PremiumProductComponent } from './root/premium/premium-product/premium-product.component';
import { PremiumMembersComponent } from './root/premium/premium-members/premium-members.component';
import { CountryAdministratorsComponent } from './root/country-administrators/country-administrators.component';
import { VoronoiComponent } from './root/voronoi/voronoi.component';
import { ShapefileImportComponent } from './root/trail-manager/shapefile-import/shapefile-import.component';
import { ExportFacilitydbComponent } from './root/trail-manager/export-facilitydb/export-facilitydb.component';
import { TrailsImportComponent } from './root/trail-manager/trails-import/trails-import.component';
import { DestinationAdministratorsComponent } from './root/destination-administrators/destination-administrators.component';
import { NewDestinationComponent } from './root/new-destination/new-destination.component';
import { RtwEventsComponent } from './root/rtw/rtw-events/rtw-events.component';
import { RtwEventParticipantsComponent } from './root/rtw/rtw-event-participants/rtw-event-participants.component';
import { EditRtwEventComponent } from './root/rtw/edit-rtw-event/edit-rtw-event.component';
import { CreateRtwEventComponent } from './root/rtw/create-rtw-event/create-rtw-event.component';
import { RidesComponent } from './root/rides/rides.component';
import { RideComponent } from './root/rides/ride/ride.component';
import { PoiCategoriesComponent } from './root/poiManager/poi-categories/poi-categories.component';
import { EditPoiCategoryComponent } from './root/poiManager/poi-categories/edit-poi-category/edit-poi-category.component';
import { PoiValidatorComponent } from './root/poiManager/poi-validator/poi-validator.component';
import { PoiImportComponent } from './root/poiManager/poi-import/poi-import.component';
import { MediaLibraryComponent } from './root/media-library/media-library.component';
import { EmailExtractComponent } from './root/email-extract/email-extract.component';
import { TreatsComponent } from './root/treats/treats.component';
import { TreatEditComponent } from './root/treats/treat-edit/treat-edit.component';
import { SponsorComponent } from './root/sponsor/sponsor.component';
import { SponsorEditComponent } from './root/sponsor/sponsor-edit/sponsor-edit.component';


// Pipes
import { KeyValueBasePipe } from './pipes/key-value-base.pipe';
import { KeyValuePoiCategoryPipe } from './pipes/key-value-poi-category.pipe';
import { KeyValuePoiSubCategoryPipe } from './pipes/key-value-poi-sub-category.pipe';
import { KeyValuePublicPoiCategoryPipe } from './pipes/key-value-public-poi-category.pipe';
import { KeyValuePublicPoiSubCategoryPipe } from './pipes/key-value-public-poi-sub-category.pipe';
import { DatePipe } from '@angular/common'

// Sub-components
import { AddMediaOfTypeComponent } from './ui/add-media-of-type/add-media-of-type.component';
import { AdventureListComponent } from './ui/adventure-list/adventure-list.component';
import { AdventureEditComponent } from './ui/adventure-edit/adventure-edit.component';
import { AdventureItemEditComponent } from './ui/adventure-item-edit/adventure-item-edit.component';
import { AlertInfoBoxComponent } from './ui/alert-info-box/alert-info-box.component';
import { ConfirmDialogComponent } from './ui/confirm-dialog/confirm-dialog.component';
import { ContentHeaderSectionComponent } from './ui/content-header-section/content-header-section.component';
import { CreateOnMapComponent } from './ui/create-on-map/create-on-map.component';
import { ExplorersComponent } from './ui/explorers/explorers.component';
import { FindUserComponent } from './ui/find-user/find-user.component';
import { FindTrailComponent } from './ui/find-trail/find-trail.component';
import { ImageHandlerComponent } from './ui/image-handler/image-handler.component';
import { KioskProductHandlerComponent } from './ui/kiosk-product-handler/kiosk-product-handler.component';
import { NewsRoomListComponent } from './ui/news-room-list/news-room-list.component';
import { NewsStoryAddBoxComponent } from './ui/news-story-add-box/news-story-add-box.component';
import { NewsEditComponent } from './ui/news-edit/news-edit.component';
import { PublishedIndicatorComponent } from './ui/published-indicator/published-indicator.component';
import { TranslatableTextsComponent } from './ui/translatable-texts/translatable-texts.component';
import { TranslatableTextEditComponent } from './ui/translatable-text-edit/translatable-text-edit.component';

// Providers
import { AuthGuard } from './core/auth.guard';
import { RootAuthGuard } from './core/root-auth.guard';
import { FindProfileSegmentComponent } from './ui/find-profile-segment/find-profile-segment.component';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
registerLocaleData(localeDa);

// Directives
import { ClickPreventDefaultDirective } from './directives/click-prevent-default.directive';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything
        CoreModule, // <-- add core module
        AngularFireAuthModule,
        provideStorage(() => getStorage()),  // imports firebase/storage only needed for storage features
        AngularFireDatabaseModule,
        AngularFireFunctionsModule,
        AngularFireRemoteConfigModule,
        AppRoutingModule,
        CKEditorModule
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        HeaderComponent,
        SidebarComponent,
        FooterComponent,
        UserProfileComponent,
        DashboardComponent,
        CountryTrailAreasAdminComponent,
        CountryNewTrailAreaComponent,
        CountryAdventuresComponent,
        CountryAdventureEditComponent,
        CountryExplorersComponent,
        CountryAdventureItemEditComponent,
        CountryContactResponsibleComponent,
        DestinationComponent,
        DestinationTrailAreasComponent,
        DestinationAdventuresComponent,
        DestinationAdventureEditComponent,
        DestinationAdventureExplorersComponent,
        DestinationAdventureItemEditComponent,
        TrailAreaComponent,
        TrailsComponent,
        TrailEditComponent,
        PoisComponent,
        PoiEditComponent,
        TrailAreaNewsComponent,
        TrailAreaEditDraftComponent,
        TrailAreaModifyStoryComponent,
        TrailAreaDraftsComponent,
        TrailAreaStoriesComponent,
        TrailAreaStoryArchiveComponent,
        TrailAreaAdventuresComponent,
        TrailAreaAdventureEditComponent,
        TrailAreaExplorersComponent,
        TrailAreaAdventureItemEditComponent,
        AreaManagersComponent,
        RideStatisticsComponent,
        MembershipComponent,
        MembersComponent,
        OnTrailStatusComponent,
        OnTrailApplicationComponent,
        OnTrailContactsComponent,
        RootDashboardComponent,
        KioskOrdersComponent,
        NewsRoomComponent,
        NewsRoomEditDraftComponent,
        NewsRoomModifyStoryComponent,
        NewsRoomDraftsComponent,
        NewsRoomStoriesComponent,
        NewsRoomArchiveComponent,
        NewsRoomWelcomeComponent,
        PremiumProductComponent,
        PremiumMembersComponent,
        CountryAdministratorsComponent,
        VoronoiComponent,
        DestinationAdministratorsComponent,
        NewDestinationComponent,
        ShapefileImportComponent,
        ExportFacilitydbComponent,
        TrailsImportComponent,
        PoiCategoriesComponent,
        EditPoiCategoryComponent,
        PoiValidatorComponent,
        PoiImportComponent,
        CreateRtwEventComponent,
        RtwEventsComponent,
        RtwEventParticipantsComponent,
        EditRtwEventComponent,
        RidesComponent,
        RideComponent,
        MediaLibraryComponent,
        EmailExtractComponent,
        TreatsComponent,
        TreatEditComponent,
        KeyValueBasePipe,
        KeyValuePoiCategoryPipe,
        KeyValuePoiSubCategoryPipe,
        KeyValuePublicPoiCategoryPipe,
        KeyValuePublicPoiSubCategoryPipe,
        AddMediaOfTypeComponent,
        AdventureListComponent,
        AdventureEditComponent,
        AdventureItemEditComponent,
        AlertInfoBoxComponent,
        ConfirmDialogComponent,
        ContentHeaderSectionComponent,
        CreateOnMapComponent,
        ExplorersComponent,
        FindUserComponent,
        FindTrailComponent,
        ImageHandlerComponent,
        KioskProductHandlerComponent,
        NewsRoomListComponent,
        NewsStoryAddBoxComponent,
        NewsEditComponent,
        PublishedIndicatorComponent,
        TranslatableTextsComponent,
        TranslatableTextEditComponent,
        FindProfileSegmentComponent,
        ClickPreventDefaultDirective,
        ClickStopPropagationDirective,
        SponsorComponent,
        SponsorEditComponent
    ],
    providers: [
        DatePipe,
        { provide: LOCALE_ID, useValue: 'da-DA'},
        AuthGuard,
        RootAuthGuard,
        {provide: DEFAULTS, useValue: {enableAwesome: true}},
        {
            provide: SETTINGS,
            useFactory: () => environment.production ? {} : {minimumFetchIntervalMillis: 10_000}
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
