import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FirebaseObjectService {

    constructor() {
    }

    // @todo: There may be some problems with regular arrays here!
    static prepareUpdateObject(input: {}): { [path: string]: any } {
        const output: { [path: string]: any } = {};
        Object.keys(input).forEach((propertyName) => {
            switch (typeof input[propertyName]) {
                case 'object':
                    if (input[propertyName] === null) {
                        output[propertyName] = null;
                    } else {
                        const nestedOutput = FirebaseObjectService.prepareUpdateObject(input[propertyName]);
                        Object.entries(nestedOutput).forEach(([key, val]) => {
                            output[propertyName + '/' + key] = val;
                        });
                    }
                    break;

                case 'undefined':
                    output[propertyName] = null;
                    break;

                case 'string':
                    output[propertyName] = (input[propertyName].trim() === '') ? null : input[propertyName].trim();
                    break;

                default:
                    output[propertyName] = input[propertyName];
            }
        });
        return output;
    }

    static prepareSetObject(input: any[] | {}): any[] | {} {
        const output = (Array.isArray(input) ? [] : {});
        Object.keys(input).forEach((propertyName) => {
            let subOutput;
            switch (typeof input[propertyName]) {
                case 'object':
                    if (input[propertyName] !== null) {
                        subOutput = FirebaseObjectService.prepareSetObject(input[propertyName]);
                        if (subOutput === {} || subOutput === []) {
                            subOutput = null;
                        }
                    } else {
                        subOutput = null;
                    }
                    break;

                case 'string':
                    subOutput = input[propertyName].trim();
                    if (subOutput === '') {
                        subOutput = null;
                    }
                    break;

                case 'undefined':
                    subOutput = null;
                    break;

                default:
                    subOutput = input[propertyName];
            }

            if (subOutput !== null) {
                if (Array.isArray(output)) {
                    output.push(subOutput);
                } else {
                    output[propertyName] = subOutput;
                }
            }
        });
        return output;
    }

}
