<div class="content-wrapper">
    <app-content-header-section contentPageId="countryManagers" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">

                    <div class="alert alert-info alert-dismissible">
                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                        <h4><i class="icon fa fa-info"></i> Countries</h4>
                        If you are missing a country, please send an e-mail to <a href="mailto:frederik@mtbutd.com">frederik@mtbutd.com</a>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4" *ngFor="let country of countries">

                    <div class="card card-outline card-navy " id="box_{{ country.countryCode }}">
                        <div class="card-header">
                            <h3 class="card-title">{{ country.name }} <small *ngIf="countryManagers[country.countryCode]?.length">(<i
                                    class="icon fas fa-users-cog"></i> {{ countryManagers[country.countryCode]?.length }})</small></h3>
                        </div>

                        <div class="card-body">
                            <ul class="todo-list">
                                <li *ngFor="let manager of countryManagers[country.countryCode]">
                                    <span class="text">{{ manager.name }}</span>
                                    <small class="badge badge-default">{{ manager.userID }}</small>
                                    <div class="tools">
                                        <button class="btn btn-xs btn-success" (click)="revokeCountryManager(manager.userID, country.countryCode)">
                                            Revoke access
                                        </button> &nbsp;
                                        <i (click)="copyText(manager.userID)" class="far fa-copy black-todolist-item" title="Copy profile key"></i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="card-footer">
                            <app-find-user modalId="{{ country.countryCode }}" modalTitle="Find Manager for {{ country.name }}"
                                           (userSelected)="appointCountryManager($event, country.countryCode)" btnClassType="success">Add manager
                                to {{ country.name }}</app-find-user>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </section>

</div>
