import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {combineLatest, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {AngularFireAuth} from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';

import {UserService} from '../firebase-services/user.service';
import {TrailAreaService} from '../firebase-services/trail-area.service';

import {AdminUser} from '../interfaces/user';
import {Country} from '../interfaces/countries';
import {Destination} from '../interfaces/destination';
import {TrailArea} from '../interfaces/trailArea';
import {Organisation} from '../interfaces/organisation';
import {RoleService} from "./role.service";

@Injectable()
export class AuthService {

    user: AdminUser = null;
    countries: Country[];
    destinations: Destination[];
    trailAreas: TrailArea[];
    organisations: Organisation[];
    authRoles: string[];

    uid$: Observable<string>;
    private allLoaded$: Observable<string> = null;

    constructor(
        private afAuth: AngularFireAuth,
        private userService: UserService,
        private areaService: TrailAreaService,
        private roleService: RoleService,
        private router: Router
    ) {
        this.uid$ = this.afAuth.authState
            .pipe(
                switchMap((oauthUser) => {
                    if (oauthUser) {
                        if (this.user !== null && this.user.userID === oauthUser.uid) {
                            return of(this.user.userID);
                        }
                        if (this.allLoaded$ !== null) {
                            return this.allLoaded$;
                        }
                        const userObservable = this.userService.getAdminUser(oauthUser.uid);
                        const countriesObservable = this.userService.getUserAdminCountries(oauthUser.uid);
                        const destinationsObservable = this.userService.getUserAdminDestinations(oauthUser.uid);
                        const trailAreasObservable = this.areaService.getUserTrailAreas(oauthUser.uid);
                        const organisationsObservable = this.userService.getUserAdminOrganisations(oauthUser.uid);
                        const rolesObservable = this.roleService.getAuthRoles(oauthUser.uid);
                        this.allLoaded$ = combineLatest([
                            userObservable,
                            countriesObservable,
                            destinationsObservable,
                            trailAreasObservable,
                            organisationsObservable,
                            rolesObservable,
                        ]).pipe(
                            map(([user, countries, destinations, trailAreas, organisations, roles]) => {
                                if (!user) {
                                    console.error('Illegal', oauthUser.uid);
                                    return null;
                                }
                                this.user = user;
                                this.countries = countries;
                                this.destinations = destinations;
                                this.trailAreas = trailAreas;
                                this.organisations = organisations;
                                this.authRoles = roles;
                                return user.userID;
                            })
                        );
                        return this.allLoaded$;
                    }
                    return of(null);
                })
            );
    }

    public isUser(role: string): boolean {
        if (this.user) {
            return this.authRoles.includes(role);
        }
        return false;
    }

    googleLogin(): Promise<firebase.auth.UserCredential> {
        const provider = new firebase.auth.GoogleAuthProvider();
        return this.oAuthLogin(provider);
    }

    facebookLogin(): Promise<firebase.auth.UserCredential> {
        const provider = new firebase.auth.FacebookAuthProvider();
        return this.oAuthLogin(provider);
    }

    appleLogin(): Promise<firebase.auth.UserCredential> {
        const provider = new firebase.auth.OAuthProvider('apple.com');
        return this.oAuthLogin(provider);
    }

    emailLogin(userEmail: string, userPassword: string): Promise<firebase.auth.UserCredential> {
        return this.afAuth.signInWithEmailAndPassword(userEmail, userPassword);
    }

    private oAuthLogin(provider: firebase.auth.AuthProvider): Promise<firebase.auth.UserCredential> {
        return this.afAuth.signInWithPopup(provider);
    }

    signOut() {
        this.afAuth.signOut()
            .then(() => {
                this.user = null;
                this.countries = null;
                this.destinations = null;
                this.trailAreas = null;
                this.organisations = null;
                this.authRoles = null;
                return this.router.navigate(['/login']);
            });
    }

    getTrailArea(trailAreaKey: string): TrailArea {
        for (const i in this.trailAreas) {
            if (this.trailAreas[i].key === trailAreaKey) {
                return this.trailAreas[i];
            }
        }
    }

}
