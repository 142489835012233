import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {combineLatest, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import {FacebookAuthProvider, GoogleAuthProvider, OAuthProvider} from '@angular/fire/auth';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {UserService} from '../firebase-services/user.service';
import {Country} from '../interfaces/countries';
import {Destination} from '../interfaces/destination';
import {TrailArea} from '../interfaces/trailArea';
import {Organisation} from '../interfaces/organisation';
import {RoleService} from "./role.service";
import {DmoManagerService} from "../root/role-manager/dmo-managers/dmo-manager.service";
import {DestinationManagersService} from "../root/role-manager/destination-managers/destination-managers.service";
import {CountryManagersService} from "../root/role-manager/country-managers/country-managers.service";
import {User} from "../interfaces/user";
import {TrailAreaManagerService} from "../root/role-manager/trail-area-managers/trail-area-manager.service";
import {OrganisationManagerService} from "../root/role-manager/organisation-managers/organisation-manager.service";
import {EventManagerService} from "../root/role-manager/event-managers/event-manager.service";
import {EventEntities} from "../interfaces/event-manager";
import {PoiEntities} from "../interfaces/poi-manager";
import {PoiManagerService} from "../root/role-manager/poi-managers/poi-manager.service";

@Injectable()
export class AuthService {

    user: User = null;
    countries: Country[];
    destinations: Destination[];
    trailAreas: TrailArea[];
    dmoTrailAreas: TrailArea[];
    organisations: Organisation[];
    eventEntities: EventEntities;
    poiEntities: PoiEntities;
    authRoles: string[];

    uid$: Observable<string>;
    private allLoaded$: Observable<string> = null;

    constructor(
        private afAuth: AngularFireAuth,
        private roleService: RoleService,
        private userService: UserService,
        private countryManagersService: CountryManagersService,
        private destinationManagersService: DestinationManagersService,
        private organisationManagerService: OrganisationManagerService,
        private dmoManagerService: DmoManagerService,
        private trailAreaManagerService: TrailAreaManagerService,
        private eventManagerService: EventManagerService,
        private poiManagerService: PoiManagerService,
        private router: Router
    ) {
        this.uid$ = this.afAuth.authState
            .pipe(
                switchMap((oauthUser) => {
                    if (oauthUser) {
                        if (this.user !== null && this.user.userID === oauthUser.uid) {
                            return of(this.user.userID);
                        }
                        if (this.allLoaded$ !== null) {
                            return this.allLoaded$;
                        }
                        const userObservable = this.userService.getUser(oauthUser.uid);
                        const countriesObservable = this.countryManagersService.getCountryManagerCountries(oauthUser.uid);
                        const destinationsObservable = this.destinationManagersService.getDestinationManagerDestinations(oauthUser.uid);
                        const trailAreasObservable = this.trailAreaManagerService.getTrailAreaManagerTrailAreas(oauthUser.uid);
                        const dmoTrailAreasObservable = this.dmoManagerService.getDmoManagerTrailAreas(oauthUser.uid);
                        const organisationsObservable = this.organisationManagerService.getOrganisationManagerOrganisations(oauthUser.uid);
                        const eventEntitiesObservable = this.eventManagerService.getEventManagerEntities(oauthUser.uid);
                        const poiEntitiesObservable = this.poiManagerService.getPoiManagerEntities(oauthUser.uid);
                        const rolesObservable = this.roleService.getAuthRoles(oauthUser.uid);
                        this.allLoaded$ = combineLatest([
                            userObservable,
                            countriesObservable,
                            destinationsObservable,
                            trailAreasObservable,
                            dmoTrailAreasObservable,
                            organisationsObservable,
                            eventEntitiesObservable,
                            poiEntitiesObservable,
                            rolesObservable,
                        ]).pipe(
                            map(([user, countries, destinations, trailAreas, dmoTrailAreas, organisations, eventEntities, poiEntities, roles]) => {
                                if (!user) {
                                    console.error('Illegal', oauthUser.uid);
                                    return null;
                                }
                                this.user = user;
                                this.countries = countries;
                                this.destinations = destinations;
                                this.trailAreas = trailAreas;
                                this.dmoTrailAreas = dmoTrailAreas;
                                this.organisations = organisations;
                                this.eventEntities = eventEntities;
                                this.poiEntities = poiEntities;
                                this.authRoles = roles;
                                return user.userID;
                            })
                        );
                        return this.allLoaded$;
                    }
                    return of(null);
                })
            );
    }

    public isUser(role: string): boolean {
        if (this.user) {
            return this.authRoles.includes(role);
        }
        return false;
    }

    googleLogin(): Promise<firebase.auth.UserCredential> {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account'
        });
        return this.oAuthLogin(provider);
    }

    facebookLogin(): Promise<firebase.auth.UserCredential> {
        const provider = new FacebookAuthProvider();
        return this.oAuthLogin(provider);
    }

    appleLogin(): Promise<firebase.auth.UserCredential> {
        const provider = new OAuthProvider('apple.com');
        return this.oAuthLogin(provider);
    }

    emailLogin(userEmail: string, userPassword: string): Promise<firebase.auth.UserCredential> {
        return this.afAuth.signInWithEmailAndPassword(userEmail, userPassword);
    }

    private oAuthLogin(provider: firebase.auth.AuthProvider): Promise<firebase.auth.UserCredential> {
        return this.afAuth.signInWithPopup(provider);
    }

    signOut() {
        this.afAuth.signOut()
            .then(() => {
                this.user = null;
                this.countries = null;
                this.destinations = null;
                this.trailAreas = null;
                this.dmoTrailAreas = null;
                this.organisations = null;
                this.authRoles = null;
                return this.router.navigate(['/login']);
            });
    }

    getTrailArea(trailAreaKey: string): TrailArea {
        for (const i in this.trailAreas) {
            if (this.trailAreas[i].key === trailAreaKey) {
                return this.trailAreas[i];
            }
        }
    }

}
