/**
 * These are the currently supported auth roles for reference. To update list, check DB/roles.
 */
export enum Roles {
    ADMIN = 'admin',
    COUNTRY_MANAGER = 'countryManager',
    DESTINATION_MANAGER = 'destinationManager',
    ORGANISATION_MANAGER = 'organisationManager',
    TRAIL_AREA_MANAGER = 'trailAreaManager',
    TRAIL_MANAGER = 'trailManager',
    EVENT_MANAGER = 'eventManager',
    TRAIL_CENTER_MANAGER = 'trailCenterManager',
    BRAND_MANAGER = 'brandManager',
    DMO_MANAGER = 'dmoManager',
    PRESS_CAMP_MANAGER = 'pressCampManager',
    TOUR_OPERATOR = 'tourOperator',
    NEWS_ROOM_MANAGER = 'newsRoomManager',
    TREAT_MANAGER = 'treatManager',
    SPONSOR_MANAGER = 'sponsorManager',
    POI_MANAGER = 'poiManager',
    ON_TRAIL_MANAGER = 'onTrailManager'
}

export interface Role {
    id: string;
    at: number;
    by: string;
}

export interface AssignableRole {
    id: string;
    description: string;
    name: string;
}

export type UserRoles = {
    [key: string]: Role[];
}

export interface AssignRoleEntry {
    at: number;
    by: string;
}

export interface SaveRoleEntry {
    name: string;
    description: string;
}

