<aside class="main-sidebar sidebar-dark-olive elevation-4">

    <div class="brand-link logo-switch navbar-white">
        <img src="assets/images/logo.png" alt="Mountainbike United Administration" class="brand-image-xl logo-xs">
        <img src="assets/images/menu_logo_full.png" alt="Mountainbike United Administration"
             class="brand-image-xs logo-xl">
    </div>

    <div class="sidebar">
        <nav class="pt-2">
            <ul class="nav nav-pills nav-sidebar flex-column nav-compact nav-child-indent text-sm"
                data-widget="treeview" role="menu">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">
                        <i class="nav-icon fas fa-tachometer-alt"></i>
                        <p>Dashboard</p>
                    </a>
                </li>

                <li *ngIf="authService.countries as countries" class="nav-item has-treeview"
                    routerLinkActive="menu-open">
                    <a class="nav-link">
                        <i class="nav-icon fas fa-flag"></i>
                        <p>Countries <i class="right fas fa-angle-left"></i></p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li *ngFor="let country of countries" class="nav-item has-treeview"
                            routerLinkActive="menu-open active">
                            <a class="nav-link">
                                <i class="nav-icon far fa-flag"></i>
                                <p class="second-level-text">{{ country.name }} <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <a routerLink="/country-manager/{{ country.countryCode }}/trail-areas-admin"
                                       class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-map"></i>
                                        <p>Trail Areas Manager</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/country-manager/{{ country.countryCode }}/new-trail-area"
                                       class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="fas fa-plus"></i><i class="nav-icon far fa-map"></i>
                                        <p>Add new Trail Area</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/country-manager/{{ country.countryCode }}/contact-responsible"
                                       class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-address-book"></i>
                                        <p>Contact Responsible</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li *ngIf="authService.destinations as destinations" class="nav-item has-treeview"
                    routerLinkActive="menu-open">
                    <a class="nav-link">
                        <i class="nav-icon fas fa-heart"></i>
                        <p>Destinations <i class="right fas fa-angle-left"></i></p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li *ngFor="let destination of destinations" class="nav-item has-treeview"
                            routerLinkActive="menu-open active">
                            <a class="nav-link">
                                <i class="nav-icon far fa-heart"></i>
                                <p class="second-level-text">{{ destination.name }} <i
                                        class="right fas fa-angle-left"></i></p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <a routerLink="/destination/{{ destination.key }}/settings" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-cogs"></i>
                                        <p>Settings</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/destination/{{ destination.key }}/trail-areas" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-map"></i>
                                        <p>Trail Areas</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li *ngIf="authService.trailAreas as areas" class="nav-item has-treeview" routerLinkActive="menu-open">
                    <a class="nav-link">
                        <i class="nav-icon fas fa-map"></i>
                        <p>Trail Areas <i class="right fas fa-angle-left"></i></p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li *ngFor="let trailArea of areas" class="nav-item has-treeview"
                            routerLinkActive="menu-open active">
                            <a class="nav-link">
                                <i class="nav-icon far fa-map"></i>
                                <p class="second-level-text">{{ trailArea.name }} <i
                                        class="right fas fa-angle-left"></i></p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <a routerLink="/trail-area/{{trailArea.key}}/settings" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-cogs"></i>
                                        <p>Settings</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/trail-area/{{trailArea.key}}/trails" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-route"></i>
                                        <p>Trails</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/trail-area/{{trailArea.key}}/area-managers" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-users-cog"></i>
                                        <p>Area Managers</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/trail-area/{{trailArea.key}}/ride-statistics" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-chart-pie"></i>
                                        <p>Statistics</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <ng-container *ngIf="authService.organisations as organisations">
                    <li *ngFor="let organisation of organisations" class="nav-item has-treeview"
                        routerLinkActive="menu-open">
                        <a class="nav-link">
                            <i class="nav-icon fas fa-university"></i>
                            <p>{{ organisation.name }} <i class="right fas fa-angle-left"></i></p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <a routerLink="/organisation/{{ organisation.key }}/membership" class="nav-link"
                                   routerLinkActive="active-link">
                                    <i [ngClass]="'nav-icon ' + layout.getIcon('membership')"></i>
                                    <p>Membership</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/organisation/{{ organisation.key }}/members" class="nav-link"
                                   routerLinkActive="active-link">
                                    <i class="nav-icon fas fa-users"></i>
                                    <p>Members</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ng-container>

                <li *ngIf="authService.isUser(roles.ON_TRAIL_MANAGER)" class="nav-item has-treeview"
                    routerLinkActive="menu-open">
                    <a class="nav-link">
                        <i class="nav-icon fas fa-certificate"></i>
                        <p>OnTrail <i class="fas fa-angle-left right"></i></p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/on-trail/status" class="nav-link" routerLinkActive="active-link">
                                <i class="nav-icon fas fa-sign"></i>
                                <p>Status</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/on-trail/contacts" class="nav-link" routerLinkActive="active-link">
                                <i class="nav-icon fas fa-address-book"></i>
                                <p>Contacts</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="authService.dmoTrailAreas as dmoAreas"
                    class="nav-item has-treeview"
                    routerLinkActive="menu-open active">
                    <a class="nav-link">
                        <i [ngClass]="'nav-icon ' + layout.getPluralIcon('dmo')"></i>
                        <p class="second-level-text">{{ layout.getLinkText('dmoManager', {}) }}<i
                                class="right fas fa-angle-left"></i></p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li *ngFor="let dmoArea of dmoAreas" class="nav-item has-treeview"
                            routerLinkActive="menu-open active">
                            <a class="nav-link">
                                <i class="nav-icon far fa-map"></i>
                                <p class="second-level-text">{{ dmoArea.name }} <i
                                        class="right fas fa-angle-left"></i></p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <a routerLink="/trail-area/{{dmoArea.key}}/settings" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-cogs"></i>
                                        <p>Settings</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/trail-area/{{dmoArea.key}}/trails" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-route"></i>
                                        <p>Trails</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/trail-area/{{dmoArea.key}}/pois" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-map-marker-alt"></i>
                                        <p>POIs</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/trail-area/{{dmoArea.key}}/area-managers" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-users-cog"></i>
                                        <p>Area Managers</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/trail-area/{{dmoArea.key}}/ride-statistics" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-chart-pie"></i>
                                        <p>Statistics</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li *ngIf="authService.isUser(roles.EVENT_MANAGER)" class="nav-item has-treeview"
                    routerLinkActive="menu-open active">
                    <a class="nav-link">
                        <i [ngClass]="'nav-icon ' + layout.getPluralIcon('event')"></i>
                        <p class="second-level-text">{{ layout.getLinkText('eventManager', {}) }}<i
                                class="right fas fa-angle-left"></i></p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/event-manager-dashboard" class="nav-link" routerLinkActive="active-link">
                                <i [ngClass]="'nav-icon ' + layout.getIcon('eventManager')"></i>
                                <p>{{ layout.getLinkText('eventManagerDashboard', {}) }}</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="authService.isUser(roles.TRAIL_CENTER_MANAGER)" class="nav-item has-treeview"
                    routerLinkActive="menu-open active">
                    <a class="nav-link">
                        <i [ngClass]="'nav-icon ' + layout.getIcon('trailCenter')"></i>
                        <p class="second-level-text">{{ layout.getLinkText('trailCenter', {}) }}<i
                                class="right fas fa-angle-left"></i></p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/trail-center/trail-center-dashboard" class="nav-link"
                               routerLinkActive="active-link">
                                <i [ngClass]="'nav-icon ' + layout.getIcon('trailCenterDashboard')"></i>
                                <p>{{ layout.getLinkText('trailCenterDashboard', {}) }}</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="authService.isUser(roles.ADMIN)" class="nav-item has-treeview"
                    routerLinkActive="menu-open active">
                    <a class="nav-link">
                        <i [ngClass]="'nav-icon ' + layout.getPluralIcon('admin')"></i>
                        <p class="second-level-text">{{ layout.getLinkText('admin', {}) }}<i
                                class="right fas fa-angle-left"></i></p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/root/dashboard" class="nav-link" routerLinkActive="active-link">
                                <i class="nav-icon fas fa-chart-line"></i>
                                <p>Admin Dashboard</p>
                            </a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/root/kiosk-orders" class="nav-link" routerLinkActive="active-link">
                                <i class="nav-icon fas fa-shopping-basket"></i>
                                <p>Kiosk Orders</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/root/news-room" class="nav-link" routerLinkActive="active-link">
                                <i class="nav-icon far fa-newspaper"></i>
                                <p>News Room</p>
                            </a>
                        </li>
                        <li class="nav-item has-treeview"
                            routerLinkActive="menu-open active">
                            <a class="nav-link">
                                <i class="nav-icon fas fa-gem"></i>
                                <p class="second-level-text">Premium <i class="right fas fa-angle-left"></i></p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <a routerLink="/root/premium/product" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i [ngClass]="'nav-icon ' + layout.getIcon('membership')"></i>
                                        <p>Membership</p>
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/root/premium/members" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-users"></i>
                                        <p>Members</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item has-treeview"
                            routerLinkActive="menu-open active">
                            <a class="nav-link">
                                <i class="nav-icon fas fa-flag"></i>
                                <p class="second-level-text">Countries Manager <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <a routerLink="root/countries-manager/voronoi" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-globe"></i>
                                        <p>Voronoi</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item has-treeview"
                            routerLinkActive="menu-open active">
                            <a class="nav-link">
                                <i class="nav-icon fas fa-heart"></i>
                                <p class="second-level-text">Destinations Manager <i
                                        class="right fas fa-angle-left"></i></p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <a routerLink="/root/destinations-manager/new-destination" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="nav-icon fas fa-plus"></i>
                                        <p>Add Destination</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item has-treeview"
                            routerLinkActive="menu-open active">
                            <a class="nav-link">
                                <i [ngClass]="'nav-icon ' + layout.getPluralIcon('trail')"></i>
                                <p class="second-level-text">Trail Manager <i class="right fas fa-angle-left"></i></p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <a [routerLink]="layout.getUrl('shapefileImport', {})" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i [ngClass]="'nav-icon ' + layout.getContentPageIcon('shapefileImport')"></i>
                                        <p>{{ layout.getLinkText('shapefileImport', {}) }}</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="layout.getUrl('exportFacilitydb', {})" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i [ngClass]="'nav-icon ' + layout.getContentPageIcon('exportFacilitydb')"></i>
                                        <p>{{ layout.getLinkText('exportFacilitydb', {}) }}</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="layout.getUrl('trailsImport', {})" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i [ngClass]="'nav-icon ' + layout.getContentPageIcon('trailsImport')"></i>
                                        <p>{{ layout.getLinkText('trailsImport', {}) }}</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item has-treeview"
                            routerLinkActive="menu-open active">
                            <a class="nav-link">
                                <i [ngClass]="'nav-icon ' + layout.getPluralIcon('poi')"></i>
                                <p class="second-level-text">{{ layout.getLinkText('poiManager', {}) }} <i
                                        class="right fas fa-angle-left"></i></p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <a [routerLink]="layout.getUrl('poiCategories', {})" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i [ngClass]="'nav-icon ' + layout.getPluralIcon('category')"></i>
                                        <p>{{ layout.getLinkText('poiCategories', {}) }}</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="layout.getUrl('poiValidator', {})" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i [ngClass]="'nav-icon ' + layout.getPluralIcon('validator')"></i>
                                        <p>{{ layout.getLinkText('poiValidator', {}) }}</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="layout.getUrl('poiImport', {})" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i class="fas fa-plus"></i><i
                                            [ngClass]="'nav-icon ' + layout.getPluralIcon('poi')"></i>
                                        <p>{{ layout.getLinkText('poiImport', {}) }}</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item has-treeview"
                            routerLinkActive="menu-open active">
                            <a class="nav-link">
                                <i [ngClass]="'nav-icon ' + layout.getPluralIcon('rtw')"></i>
                                <p class="second-level-text">Ride The World <i class="right fas fa-angle-left"></i></p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <a routerLink="/root/rtw/events/list" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i [ngClass]="'nav-icon ' + layout.getPluralIcon('rtwEvent')"></i>
                                        <p>{{ layout.getLinkText('rtwEvents', {}) }}</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/root/rtw/events/create" class="nav-link"
                                       routerLinkActive="active-link">
                                        <i [ngClass]="'nav-icon ' + layout.getIcon('rtwEvent')"></i>
                                        <p>{{ layout.getLinkText('createRtwEvent', {}) }}</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="layout.getUrl('rides', {})" class="nav-link"
                               routerLinkActive="active-link">
                                <i [ngClass]="'nav-icon ' + layout.getPluralIcon('ride')"></i>
                                <p>{{ layout.getLinkText('rides', {}) }}</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="layout.getUrl('mediaLibrary', {})" class="nav-link"
                               routerLinkActive="active-link">
                                <i [ngClass]="'nav-icon ' + layout.getPluralIcon('mediaLibrary')"></i>
                                <p>{{ layout.getLinkText('mediaLibrary', {}) }}</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/root/emailExtract" class="nav-link" routerLinkActive="active-link">
                                <i class="nav-icon fas fa-mail-bulk"></i>
                                <p>Email Extract</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/root/treats" class="nav-link" routerLinkActive="active-link">
                                <i class="nav-icon fas fa-coffee"></i>
                                <p>Treats</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/root/sponsor" class="nav-link" routerLinkActive="active-link">
                                <i class="nav-icon fa fa-gift"></i>
                                <p>Sponsor</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/root/role-manager" class="nav-link" routerLinkActive="active-link">
                                <i [ngClass]="'nav-icon ' + layout.getPluralIcon('manager')"></i>
                                <p>Role Manager</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/root/dev-tools" class="nav-link" routerLinkActive="active-link">
                                <i [ngClass]="'nav-icon ' + layout.getPluralIcon('developer')"></i>
                                <p>Developer Tools</p>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>

    <div class="text-center text-secondary">
        <small><strong>Version</strong> {{ version }}</small>
    </div>

</aside>