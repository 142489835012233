<div class="content-wrapper">
    <app-content-header-section contentPageId="treats" [pageParams]="{ }"></app-content-header-section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-5">
                    <div class="card card-outline card-teal">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-cogs"></i> Create New Treat</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="newTreatAlias">Alias</label>
                                <div class="input-group">
                                    <input [(ngModel)]="newTreatAlias"
                                           (change)="newTreatAliasChanged()"
                                           (keyup)="newTreatAliasChanged()" id="newTreatAlias"
                                           aria-describedby="newTreatHelp" class="form-control"
                                           placeholder="Mtb Shop Treat">
                                </div>
                                <small class="form-text text-muted" id="newTreatHelp">Choose an alias for your new
                                    treat. This is an internal name for the treat, and is not used
                                    publicly.
                                </small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="disableCreateTreat" (click)="createTreat()" class="btn btn-success">
                                Create treat
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <app-translatable-texts *ngIf="textObject" textsName="Treats" colorTheme="teal"
                                            infoTrigger="{{ textsChangedInfo }}"
                                            [textModel]="textModel" [textObject]="textObject"
                                            (alteredTextObject)="onAlteredTextObject($event)">
                    </app-translatable-texts>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-outline card-teal">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-coffee"></i> Treats</h3>
                        </div>
                        <div class="card-body px-0">
                            <table *ngIf="allIsLoaded" id="treatsDataTable" class="table row-bordered table-striped">
                                <thead>
                                <tr>
                                    <th>Treat</th>
                                    <th>Texts and link</th>
                                    <th>Active</th>
                                    <th>Top of Mind</th>
                                    <th>My Treats</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let treat of treats">
                                    <td>
                                        <div>
                                            <strong>{{ treat.alias || treat.topOfMindTitle }}</strong>
                                        </div>
                                        <img src="{{ treat.imageURL }}" width="50"
                                             [alt]="treat.alias || treat.topOfMindTitle"/>
                                        <div>
                                            <button class="btn btn-xs btn-success"
                                                    routerLink="/root/treats/{{ treat.key }}"><i
                                                    class="far fa-edit"></i> Edit
                                            </button>&nbsp;
                                            <button class="btn btn-xs btn-info" (click)="copyTreat(treat)"><i
                                                    class="far fa-copy"></i> Copy
                                            </button>&nbsp;
                                            <button class="btn btn-xs btn-danger" (click)="deleteTreat(treat)"><i
                                                    class="fas fa-trash"></i> Delete
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="badge badge-en">en</span>&nbsp;{{ treat.topOfMindTitle }}&nbsp;<small
                                            title="{{ treat.topOfMindSubHeader }}">&lt;sub header&gt;</small>&nbsp;<a
                                            href="{{ treat.link }}"
                                            target="treatLink">Link</a>
                                        <div *ngIf="treat.lang">
                                            <div *ngFor="let lang of objectKeys(treat.lang)">
                                                <span class="badge badge-{{ lang }}">{{ lang }}</span>&nbsp;<span
                                                    *ngIf="treat.lang[lang].topOfMindTitle">{{ treat.lang[lang].topOfMindTitle }}</span>&nbsp;<small
                                                    *ngIf="treat.lang[lang].topOfMindSubHeader"
                                                    title="{{ treat.lang[lang].topOfMindSubHeader }}">&lt;sub
                                                header&gt;</small>&nbsp;<a *ngIf="treat.lang[lang].link"
                                                                           href="{{ treat.lang[lang].link }}"
                                                                           target="treatLink">Link</a>
                                            </div>
                                        </div>
                                    </td>
                                    <td [attr.data-order]="treat.activeTo">
                                        From {{ treat.activeFrom | date:'dd/MM/yyy HH:mm' }}<br/>
                                        To {{ treat.activeTo | date:'dd/MM/yyy HH:mm' }}
                                        <div>
                                            <span *ngIf="now < treat.activeFrom" class="badge badge-warning">Planned in the future</span>
                                            <div *ngIf="now > treat.activeFrom && now < treat.activeTo"
                                                 class="progress">
                                                <div class="progress-bar progress-bar-green" role="progressbar"
                                                     [style]="setProgressWidth(treat)">
                                                    <!-- aria-valuenow="{{}}" aria-valuemin="0" aria-valuemax="{{treat.activeTo - treat.activeFrom}}"-->
                                                    <span class="sr-only">40% Complete (success)</span>
                                                </div>
                                            </div>
                                            <span *ngIf="now > treat.activeTo"
                                                  class="badge badge-danger">Treat is expired</span>
                                        </div>
                                    </td>
                                    <td [attr.data-order]="treat.targetTopOfMind">
                                        Weight: {{ treat.targetTopOfMind }}
                                        <div *ngIf="treat.topOfMindExposures > 0">
                                            Exposures: {{ treat.topOfMindExposures }}<br/>
                                            Hits: {{ treat.topOfMindHits }}<br/>
                                            <span class="badge badge-warning">Hit-rate: {{ 100 * treat.topOfMindHits / treat.topOfMindExposures | number:'1.2-2' }}</span>
                                        </div>
                                        <div *ngIf="!treat.topOfMindExposures">
                                            <button class="btn btn-gray" (click)="loadStatsForTreat(treat)">Load stats
                                            </button>
                                        </div>
                                    </td>
                                    <td [attr.data-order]="treat.targetMyTreats">
                                        Weight: {{ treat.targetMyTreats }}
                                        <div *ngIf="treat.myTreatsExposures > 0">
                                            Exposures: {{ treat.myTreatsExposures}}<br/>
                                            Hits: {{ treat.myTreatsHits}}<br/>
                                            <span class="badge badge-primary">Hit-rate: {{ 100 * treat.myTreatsHits / treat.myTreatsExposures | number:'1.2-2' }}</span>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</div>
