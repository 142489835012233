import {Component, OnDestroy, OnInit} from '@angular/core'
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

// Services
import {AuthService} from "../../core/auth.service";
import {RoleService} from "../../core/role.service";
// interfaces
import {AssignableRole, Roles} from "../../interfaces/role";
import {User} from "../../interfaces/user";

@Component({
    selector: 'role-manager',
    templateUrl: './role-manager.component.html',
    styleUrls: ['./role-manager.component.css']
})
export class RoleManagerComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    roles = Roles;
    assignableRoles: AssignableRole[];
    adminUsers: User[];
    stateCreateRole = 0;
    saveNewRoleDisabled = true;
    newRoleName: string;
    newRoleDescription: string;

    constructor(
        public authService: AuthService,
        public roleService: RoleService,
    ) {
    }

    ngOnInit() {
        this.populateAssignableRoles();
        this.populateAdminUsers();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public onNewRoleNameChanged() {
        this.saveNewRoleDisabled = !(this.newRoleDescription && this.newRoleDescription !== '' && this.newRoleName && this.newRoleName !== '');
    }

    public onNewRoleDescriptionChanged() {
        this.saveNewRoleDisabled = !(this.newRoleName && this.newRoleName !== '' && this.newRoleDescription && this.newRoleDescription !== '');
    }

    public resetCreateRole() {
        this.saveNewRoleDisabled = true;
        this.newRoleName = '';
        this.newRoleDescription = '';
    }

    public assignAdminUser(userKey: string): void {
        this.roleService.assignUserRole(userKey, this.roles.ADMIN, this.authService.user.userID).then(() => {
        }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
    }

    public revokeAdminUser(userKey: string): Promise<void> {
        return this.roleService.revokeUserRole(userKey, this.roles.ADMIN);
    }

    public saveNewRole(): void {
        this.roleService.saveNewRole(this.newRoleName, this.newRoleDescription).then(() => {
            this.stateCreateRole++;
            this.resetCreateRole();
        }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
    }

    private populateAssignableRoles() {
        this.roleService.getAssignableRoles().pipe(takeUntil(this.destroy$)).subscribe((roles) => {
                this.assignableRoles = roles;
            }
        );
    }

    private populateAdminUsers() {
        this.roleService.getRoleUsers(this.roles.ADMIN).pipe(takeUntil(this.destroy$)).subscribe((admins) => {
            this.adminUsers = admins;
        });
    }

    getManagersRouterLink(id: string): string {
        switch (id) {
            case 'countryManager': {
                return '/root/role-manager/country-managers';
            }
            case 'destinationManager': {
                return '/root/role-manager/destination-managers';
            }
            case 'organisationManager': {
                return '/root/role-manager/organisation-managers';
            }
            case 'eventManager': {
                return '/root/role-manager/event-managers';
            }
            case 'dmoManager': {
                return '/root/role-manager/dmo-managers';
            }
            case 'trailAreaManager': {
                return '/root/role-manager/trail-area-managers';
            }
            case 'sponsorManager': {
                return '/root/role-manager/sponsor-managers';
            }
            case 'treatManager': {
                return '/root/role-manager/treat-managers';
            }
            case 'newsRoomManager': {
                return '/root/role-manager/news-room-managers';
            }
            case 'poiManager': {
                return '/root/role-manager/poi-managers';
            }
            case 'pressCampManager': {
                return '/root/role-manager/press-camp-managers';
            }
            default: {
                return '/root/dashboard';
            }
        }
    }

    updateRoleDescription(role: string, desc: string): Promise<void> {
        return this.roleService.updateRoleDescription(role, desc);
    }
}
