import {Injectable} from '@angular/core';
import {AuthService} from "../core/auth.service";
import firebase from "firebase/compat";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {Observable} from "rxjs";
import {FollowsUser} from "../interfaces/follow-user";
import {map, take} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class FollowUserService {
    followsRef: firebase.database.Reference;

    constructor(
        private authService: AuthService,
        private db: AngularFireDatabase
    ) {
        this.followsRef = this.db.database.ref('follow/profile');
    }

    getUserFollowsPeople(): Observable<FollowsUser[]> {
        return this.db.list<FollowsUser>(
            this.followsRef
                .child(this.authService.user.userID)
                .child('/following/profile').ref)
            .snapshotChanges().pipe(take(1), map((l) => {
                return l.map(snapshot => {
                    const key = snapshot.key;
                    const data = snapshot.payload.val();
                    return { key, ...data } as FollowsUser;
                });
            }));
    }
}
