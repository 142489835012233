import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
// services
import {CountryService} from "../../firebase-services/country.service";
import {FileService} from "../../services/file.service";
// interfaces
import {CountriesImportCandidate, Country} from "../../interfaces/countries";
import {UserAdminRights} from "../../interfaces/user";
import {UserService} from "../../firebase-services/user.service";
import {RoleService} from "../../core/role.service";
import {Roles} from "../../interfaces/role";
import {AuthService} from "../../core/auth.service";
import {CountryManagersService} from "../role-manager/country-managers/country-managers.service";
import {DestinationManagersService} from "../role-manager/destination-managers/destination-managers.service";
import {TrailAreaManagerService} from "../role-manager/trail-area-managers/trail-area-manager.service";
import {OrganisationManagerService} from "../role-manager/organisation-managers/organisation-manager.service";
import {GoogleGeocodeService} from "../../services/google-geocode.service";
import {AddressLookup} from "../../interfaces/address-lookup";

@Component({
    selector: 'dev-tools',
    templateUrl: './dev-tools.component.html',
    styleUrls: ['./dev-tools.component.css']
})
export class DevToolsComponent implements OnDestroy, OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    roles = Roles;
    countriesInDb: Country[];
    private selectedCountriesFile: File = null;
    countriesImportDataCandidates: CountriesImportCandidate[];
    dataReadyForImport = false;
    managersInDb: { key: string; data: UserAdminRights }[];

    constructor(
        private fileService: FileService,
        private countryService: CountryService,
        private geocodeService: GoogleGeocodeService,
        private userService: UserService,
        private roleService: RoleService,
        private authService: AuthService,
        private countryManagersService: CountryManagersService,
        private destinationManagersService: DestinationManagersService,
        private trailAreaManagerService: TrailAreaManagerService,
        private organisationManagerService: OrganisationManagerService
    ) {
    }

    ngOnInit(): void {
        this.getAllCountries();
        this.getAllManagers();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getAllCountries(): void {
        this.countryService.getAllCountries().pipe(takeUntil(this.destroy$)).subscribe((countries) => {
            this.countriesInDb = countries;
            console.log('Countries already in database: ');
            console.log(this.countriesInDb);
        });
    }

    getAllManagers(): void {
        this.userService.getAllManagers().pipe(takeUntil(this.destroy$)).subscribe((managers) => {
            this.managersInDb = managers;
            console.log('Managers in userGroups/admin node: ');
            console.log(managers);
        });
    }

    onCountriesFileSelected(files: FileList): void {
        this.selectedCountriesFile = this.fileService.onFileSelected(files, 'text/csv');
        if (this.selectedCountriesFile !== null) {
            $('#filenameCountries').val(this.selectedCountriesFile.name);
            const reader = new FileReader();
            reader.onload = () => {
                let text = reader.result;
                const countriesImportDataJson = this.csvJSON(text);
                this.countriesImportDataCandidates = JSON.parse(countriesImportDataJson);
                console.log('Countries import data candidates: ');
                console.log(this.countriesImportDataCandidates);
                if (this.countriesImportDataCandidates) this.dataReadyForImport = true;
            };
            reader.readAsText(this.selectedCountriesFile);
        } else {
            $('#filenameCountries').val('');
        }
    }

    importAllCountries(): void {
        console.log('*** Beginning of import ***');
        for (let country of this.countriesImportDataCandidates) {
            let countryExists = false;
            for (let existingCountry of this.countriesInDb) {
                if (country.country.toLowerCase() === existingCountry.countryCode) {
                    console.log('Duplicate country found: ' + country.country);
                    countryExists = true;
                    break;
                }
            }
            if (!countryExists) {
                if (country.country && country.name) {
                    let cl: Observable<AddressLookup> = this.geocodeService.lookupCountry(country.name.replace(/"/g, ''));
                    cl.subscribe((al) => {
                        if (al !== null) {
                            const newCountry: Country = {
                                countryCode: country.country.toLowerCase(),
                                name: country.name.replace(/"/g, ''),
                                lat: al.lat,
                                lng: al.lng,
                                events: [],
                                boundsNorth: al.boundsNorth,
                                boundsSouth: al.boundsSouth,
                                boundsEast: al.boundsEast,
                                boundsWest: al.boundsWest
                            }
                            this.countryService.addNewCountry(newCountry).then(() => {
                                console.log('Country added: ' + country.country);
                            }).catch((e) => {
                                console.log('An error occurred while adding country: ' + country.country + '\n' + e);
                            });
                        } else {
                            console.log('Look up country failed: ' + country.name.replace(/"/g, ''));
                        }
                    });
                } else {
                    console.log('Country was excluded for missing data: ' + country.country);
                }
            }
        }
    }

    public csvJSON(csv) {
        const lines = csv.split("\n");
        const result = [];
        const headers = lines[0].split(",");
        for (let i = 1; i < lines.length; i++) {
            const obj = {};
            const currentLine = lines[i].split(",");
            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentLine[j];
            }
            result.push(obj);
        }
        return JSON.stringify(result);
    }

    migrateManagers(): void {
        console.log('*** Beginning of migration ***');
        for (let manager of this.managersInDb) {
            if (manager.data.countries) {
                this.roleService.assignUserRole(manager.key, this.roles.COUNTRY_MANAGER, this.authService.user.userID).then(() => {
                        for (let country in manager.data.countries) {
                            this.countryManagersService.addCountryManager(manager.key, country).then(() => {
                                    console.log(manager.key + ' added as country manager for ' + country);
                                }
                            ).catch((err) => console.log('Error adding ' + manager.key + ' as country manager for ' + country + '\n' + err));
                        }
                    }
                );
            }
            if (manager.data.destinations) {
                this.roleService.assignUserRole(manager.key, this.roles.DESTINATION_MANAGER, this.authService.user.userID).then(() => {
                        for (let destination in manager.data.destinations) {
                            this.destinationManagersService.addDestinationManager(manager.key, destination).then(() => {
                                    console.log(manager.key + ' added as destination manager for ' + destination);
                                }
                            ).catch((err) => console.log('Error adding ' + manager.key + ' as destination manager for ' + destination + '\n' + err));
                        }
                    }
                );
            }
            if (manager.data.trackGroups) {
                this.roleService.assignUserRole(manager.key, this.roles.TRAIL_AREA_MANAGER, this.authService.user.userID).then(() => {
                        for (let area in manager.data.trackGroups) {
                            this.trailAreaManagerService.addTrailAreaToTrailAreaManager(manager.key, area).then(() => {
                                    console.log(manager.key + ' added as trail area manager for ' + area);
                                }
                            ).catch((err) => console.log('Error adding ' + manager.key + ' as trail area manager for ' + area + '\n' + err));
                        }
                    }
                );
            }
            if (manager.data.organisations) {
                this.roleService.assignUserRole(manager.key, this.roles.ORGANISATION_MANAGER, this.authService.user.userID).then(() => {
                        for (let org in manager.data.organisations) {
                            this.organisationManagerService.addOrganisationToOrganisationManager(manager.key, org).then(() => {
                                    console.log(manager.key + ' added as organisation manager for ' + org);
                                }
                            ).catch((err) => console.log('Error adding ' + manager.key + ' as organisation manager for ' + org + '\n' + err));
                        }
                    }
                );
            }
            if (manager.data.ontrail) {
                this.roleService.assignUserRole(manager.key, this.roles.ON_TRAIL_MANAGER, this.authService.user.userID).then(() => {
                        console.log(manager.key + ' added as on trail manager')
                    }
                ).catch((err) => console.log('Error adding ' + manager.key + ' as onTrail manager.' + '\n' + err));
            }
        }
    }
}