<button type="button" class="btn btn-{{ btnClassType }}" data-toggle="modal"
        [attr.data-target]="'#followsPeopleModal_'+modalId">
    <ng-content></ng-content>
</button>
<div class="modal fade" id="followsPeopleModal_{{modalId}}" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ modalTitle }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <div class="table-responsive">
                    <ul class="todo-list">
                        <li *ngFor="let user of foundPeople">
                            <div class="d-flex align-items-center">
                                <img class="img-fluid img-circle img-sm" src="{{ user.imageURL }}" alt="">
                                <span class="text">{{ user.name }}</span>
                                <small class="badge badge-default" title="Unique group key">{{ user.key }}</small>
                                <input class="ml-auto" type="checkbox" [checked]="selectedUserKeys.has(user.key)"
                                       (change)="toggleSelection(user.key)"/>&nbsp;&nbsp;
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="submitSelection()">Submit</button>
                <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
