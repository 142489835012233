import {AfterViewChecked, Component, OnDestroy} from "@angular/core";
import {Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {Observable, of, Subject} from "rxjs";
import {map, take, takeUntil} from "rxjs/operators";

// Services
import {SponsorService} from "src/app/firebase-services/sponsor.service";

// Interfaces
import {Sponsor, SponsorTargetType, SponsorTargetType2LabelMapping,} from "src/app/interfaces/sponsor";
import {TextModel, TextObject,} from "../../interfaces/text-model";
import {forEach} from "jszip";
import {TrailAreaService} from "src/app/firebase-services/trail-area.service";

declare var $: any;

@Component({
    selector: "app-sponsor",
    templateUrl: "./sponsor.component.html",
    styleUrls: ["./sponsor.component.css"],
})
export class SponsorComponent implements OnDestroy, AfterViewChecked {
    destroy$: Subject<boolean> = new Subject<boolean>();

    treatsDataTableName = "#sponsorDataTable";
    now: number = Date.now();

    public sponsorTargetType2LabelMapping = SponsorTargetType2LabelMapping;

    sponsors: Sponsor[] = null;
    allIsLoaded = false;
    dataTableCreated = false;
    dataTable: any = null;

    textModel: TextModel = {items: []};
    textObject: TextObject;
    textsChangedInfo = 0;
    newCampaignAlias: string;
    disableCreateCampaign = true;

    constructor(
        private router: Router,
        private sponsorService: SponsorService,
        private trailAreaService: TrailAreaService,
        private domSanitizer: DomSanitizer
    ) {
        this.sponsorService
            .getSponsors()
            .pipe(takeUntil(this.destroy$))
            .subscribe((sponsors) => {
                sponsors.forEach((sponsor) => {
                    if (sponsor.trailTargetKeys && sponsor.trailTargetKeys.length > 0) {
                        this.trailAreaService.getTrailAreas(sponsor.trailTargetKeys).pipe(takeUntil(this.destroy$)).subscribe((list) => {
                            sponsor.areas = list;
                        })
                    }
                });
                this.sponsors = sponsors;
                this.allIsLoaded = true;
            });
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && $("#sponsorDataTable>tbody>tr").length > 0) {
            this.dataTable = $(this.treatsDataTableName).DataTable({
                order: [[2, "desc"]],
                columnDefs: [{orderable: false, targets: 0}],
            });
            this.dataTableCreated = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getSponsorTargetSpec(sponsor: Sponsor): string {
        let str;
        let textString: string = "<ul>";
        switch (sponsor.sponsorType) {
            case SponsorTargetType.NO_SPONSOR_TARGET_TYPE_SELECTED:
                const value = "No target selected";
                textString.concat("<li>" + value.toString() + "</li>");
                str = "</ul>";
                return textString.concat(str.toString());
            case SponsorTargetType.TRAIL_AREA_SPECIFIC_SPONSOR:
                if (sponsor.areas) {
                    sponsor.areas.forEach(function (value) {
                        textString = textString.concat("<li>" + value.name.toString() + "</li>");
                    });
                    str = "</ul>";
                    return textString.concat(str.toString());
                }
            case SponsorTargetType.AREA_SPECIFIC_SPONSOR:
                if (sponsor.latitude && sponsor.longitude && sponsor.radius) {
                    textString = textString.concat(
                        "<li>" + "latitude: " + sponsor.latitude.toString() + "</li>"
                    );
                    textString = textString.concat(
                        "<li>" + "longitude: " + sponsor.longitude.toString() + "</li>"
                    );
                    textString = textString.concat(
                        "<li>" + "radius: " + sponsor.radius.toString() + "</li>"
                    );
                }
                str = "</ul>";
                return textString.concat(str.toString());
            case SponsorTargetType.COUNTRY_SPECIFIC_SPONSOR:
                if (sponsor.country) {
                    textString = textString.concat(
                        "<li>" + sponsor.country.toString() + "</li>"
                    );
                } else {
                    str = "No country chosen";
                    textString = textString.concat(str.toString());
                }
                str = "</ul>";
                return textString.concat(str.toString());
        }
    }

    setProgressWidth(sponsor: Sponsor) {
        const width = Math.round(
            (100 * (this.now - sponsor.activeFrom)) /
            (sponsor.activeTo - sponsor.activeFrom)
        );
        return this.domSanitizer.bypassSecurityTrustStyle("width: " + width + "%");
    }

    copyCampaign(sponsor: Sponsor): Promise<any> {
        return this.sponsorService
            .copySponsor(sponsor)
            .then((newSponsorKey) =>
                this.router.navigate(["/root/sponsor/" + newSponsorKey])
            );
    }

    createNewCampaign(): Promise<void> {
        return this.sponsorService.newSponsor(this.newCampaignAlias).then((newSponsorKey) => {
            this.router.navigate(["/root/sponsor/" + newSponsorKey]);
        });
    }

    deleteCampaign(sponsor: Sponsor): Promise<void> {
        this.allIsLoaded = false;
        this.dataTable.destroy();
        this.dataTableCreated = false;
        return this.sponsorService.removeSponsor(sponsor);
    }

    newCampaignAliasChanged() {
        this.disableCreateCampaign = !(this.newCampaignAlias && this.newCampaignAlias !== '');
    }
}
