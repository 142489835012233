<div class="content-wrapper">
    <app-content-header-section *ngIf="country && event && eventItem" contentPageId="countryEventEdit"
                                [pageParams]="{ countryName: country.name, countryCode: country.countryCode, eventName: event.name, eventKey: event.key, eventItemName: eventItem.name, eventItemKey: eventItem.key }"></app-content-header-section>

    <section *ngIf="eventItem && event && country" class="content-header">
        <h1>
            <i class="fa fa-puzzle-piece"></i> {{ eventItem.name }}
            <small>Edit</small>
        </h1>
        <ol class="breadcrumb">
            <li><a routerLink="/dashboard"><i class="fas fa-flag"></i> Countries</a></li>
            <li><a routerLink="/country-manager/{{ country.countryCode }}/trail-areas-admin"><i class="far fa-map"></i> Trail Areas
                in {{ country.name }}</a></li>
            <li><a routerLink="/country-manager/{{ country.countryCode }}/events"><i class="far fa-compass"></i> Events in {{ country.name }}
            </a></li>
            <li><a routerLink="/country-manager/{{ country.countryCode }}/events/{{ event.key }}"><i
                    class="far fa-compass"></i> {{ event.name }}</a></li>
            <li class="active"><i class="fa fa-puzzle-piece"></i> {{ eventItem.name }}</li>
        </ol>
    </section>

    <section class="content">
        <app-event-item-edit *ngIf="eventItem && routerRoot && routerBack" [eventItem]="eventItem"
                                 [routerBack]="routerRoot + routerBack"></app-event-item-edit>
    </section>
</div>
