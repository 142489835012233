export interface UserAdminRightCountryManagerEntry {
    countryCode: string;
    at: number;
    by: string;
}

export interface UserAdminRightDestinationEntry {
    destinationKey: string;
    at: number;
    by: string;
}

export interface UserAdminRightTrailAreaEntry {
    at: number;
    by: string;
    /**
     * @deprecated
     */
    trackGroupId: string;
}

export interface UserAdminRightOrganisationEntry {
    at: number;
    by: string;
    organisationKey: string;
}

export interface UserAdminRights {
    countries: UserAdminRightCountryManagerEntry[];
    destinations: UserAdminRightDestinationEntry[];
    trackGroups: UserAdminRightTrailAreaEntry[];
    organisations: UserAdminRightOrganisationEntry[];
    ontrail: boolean;
}

export interface User {
    createdTime: number;
    deviceAppVersion: string;
    deviceCountry: string;
    deviceLocale: string;
    deviceOS: string;
    deviceOSVersion: string;
    email?: string;
    geohash: string;
    isOnTrail?: boolean;
    isRoot?: boolean;
    lastLoginTime?: number;
    name: string;
    userID: string;
    userPicture?: string;
}

export interface AdminUser extends User {
    adminForTrailAreas?: string[];
    //    adminForTrailAreas: TrailArea[];
}

export interface RootUser extends User {
    root_at: number;
    root_by: string;
    root_active: boolean;
}

export interface RootDatabaseEntry {
    at: number;
    by: string;
    root: boolean;
}

export interface Email {
    userID: string;
    email: string;
}

export interface UserReview {
    description: string;
    rating: number;
    time: number;
    trackId: string;
    userId: string;
}

enum ElementType {
    POI = 1,
    TRAIL = 2
}

export interface ExplorerItemStatus {
    type?: ElementType;
    percentageCompleted?: number;
    startTime?: number;
    rides?: {};
    adventureElementKey: string;
}

export interface Explorer extends User {
    itemStatuses: ExplorerItemStatus[];
    score: number;
    percentageScore: number;
}

export interface EmailExtractUser extends User {
    email: string;
}

export interface EmailExtractUserAddress {
  email: string;
  name: string;
}
