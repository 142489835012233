<button type="button" class="btn btn-{{ btnClassType }}" data-toggle="modal" [attr.data-target]="'#findGroupModal_'+modalId"
        (click)="focusInSearch()">
    <ng-content></ng-content>
</button>
<div class="modal fade" id="findGroupModal_{{modalId}}" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ modalTitle }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <strong><i class="fas fa-search mr-2"></i> <label [for]="'searchForGroups'+modalId">Search for groups by name, starting
                        with</label></strong>
                    <input (change)="searchForGroups()" (keyup)="searchForGroups()" [disabled]="disableSearch" [id]="'searchForGroups'+modalId"
                           [attr.aria-describedby]="'searchForGroupsHelp' + modalId" class="form-control" placeholder="Enter group name here...">
                    <small class="form-text text-muted" [id]="'searchForGroupsHelp' + modalId">Enter at least 3 characters. Searches group names
                        starting with your search term.</small>
                    <small class="form-text text-red" [id]="'searchForGroupsWarning' + modalId"><i class="fas fa-exclamation-triangle"></i> The search is case sensitive.</small>
                </div>
                <div class="table-responsive">
                    <ul class="todo-list">
                        <li *ngFor="let group of foundGroups">
                            <div class="tools">
                                <button class="btn btn-xs btn-success" (click)="selectGroup(group.key)">Select</button> &nbsp;
                                <i (click)="copyText(group.key)" class="far fa-copy black-todolist-item" title="Copy group key"></i>
                            </div>
                            <div class="img-push">
                                <span class="text">{{ group.name }}</span>
                                <small class="badge badge-default" title="Unique group key">{{ group.key }}</small>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Cancel</button>
            </div>
            <div class="overlay" *ngIf="disableSearch">
                <i class="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
        </div>
    </div>
</div>
