import { Injectable } from '@angular/core';
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database/';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import firebase from 'firebase/compat';

// Services
import { TrailAreaService } from './trail-area.service';

// Interfaces
import { Destination } from '../interfaces/destination';
import { TrailArea } from '../interfaces/trailArea';

@Injectable({
    providedIn: 'root'
})
export class DestinationService {
    destinationRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private trailAreaService: TrailAreaService
    ) {
        this.destinationRef = this.db.database.ref('destinations');
    }

    private static destinationFromSnap(destinationSnapshot: AngularFireAction<DatabaseSnapshot<Destination>>): Destination {
        const destination: Destination = destinationSnapshot.payload.val();
        destination.key = destinationSnapshot.key;
        destination.boundsNorth = destination.boundsNorth || -90;
        destination.boundsSouth = destination.boundsSouth || 90;
        destination.boundsEast = destination.boundsEast || -180;
        destination.boundsWest = destination.boundsWest || 180;
        if (typeof destination.adventureKeys === 'undefined') {
            destination.adventureKeys = {};
        }
        if (typeof destination.trailAreaKeys === 'undefined') {
            destination.trailAreaKeys = {};
        }
        destination.lang = destination.lang || {};
        return destination;
    }

    getDestination(destinationKey: string): Observable<Destination> {
        return this.db.object<Destination>(this.destinationRef.child(destinationKey).ref).snapshotChanges()
            .pipe(map((destinationSnapshot) => DestinationService.destinationFromSnap(destinationSnapshot)));
    }

    getDestinations(destinationKeys: string[]): Observable<Destination[]> {
        return combineLatest(destinationKeys.map((destinationKey) => this.getDestination(destinationKey)));
    }

    createDestination(name: string, promoted: boolean, adventuresEnabled: boolean): firebase.database.ThenableReference {
        const newDestination: Destination = {
            name: name,
            promoted: promoted,
            adventuresEnabled: adventuresEnabled
        };
        return this.destinationRef.push(newDestination);
    }

    deleteDestination(key: string): Promise<void> {
        return this.destinationRef.child(key).remove();
    }

    getAllDestinations(): Observable<Destination[]> {
        return this.db.list<Destination>(this.destinationRef.ref).snapshotChanges()
            .pipe(
                take(1),
                map((destinationSnapshots) => destinationSnapshots.map((destinationSnapshot) => {
                    return DestinationService.destinationFromSnap(destinationSnapshot);
                }))
            );
    }

    updateTexts(destination: Destination): Promise<void> {
        // Delete texts that are blank
        for (const lang of Object.keys(destination.lang)) {
            for (const text of Object.keys(destination.lang[lang])) {
                if (!destination.lang[lang][text] || destination.lang[lang][text] === 'undefined') {
                    destination.lang[lang][text] = null;
                }
            }
        }
        const updateElement = {
            name: destination.name,
            lang: destination.lang
        };
        return this.destinationRef.child(destination.key).update(updateElement);
    }

    addTrailAreaToDestination(trailArea: TrailArea, destination: Destination): Promise<any> {
        destination.boundsNorth = Math.max(destination.boundsNorth, trailArea.boundsNorth);
        destination.boundsSouth = Math.min(destination.boundsSouth, trailArea.boundsSouth);
        destination.boundsEast = Math.max(destination.boundsEast, trailArea.boundsEast);
        destination.boundsWest = Math.min(destination.boundsWest, trailArea.boundsWest);
        const updateElement: Destination = <Destination>{
            boundsNorth: destination.boundsNorth,
            boundsSouth: destination.boundsSouth,
            boundsEast: destination.boundsEast,
            boundsWest: destination.boundsWest
        };
        const destinationUpdatePromise = this.destinationRef.child(destination.key).child('trailAreaKeys').child(trailArea.key).set(true);
        const boundsUpdatePromise = destinationUpdatePromise
            .then(() => this.destinationRef.child(destination.key).update(updateElement));
        const trailAreaUpdatePromise = this.trailAreaService.addedToDestination(trailArea, destination.key);
        return Promise.all([boundsUpdatePromise, trailAreaUpdatePromise]);
    }

    removeTrailAreaFromDestination(trailAreaKey: string, destination: Destination, trailAreasLeft: TrailArea[]): Promise<any> {
        const updateElement: Destination = <Destination>{
            boundsNorth: -90,
            boundsSouth: 90,
            boundsEast: -180,
            boundsWest: 180
        };
        trailAreasLeft.forEach((trailArea) => {
            updateElement.boundsNorth = Math.max(updateElement.boundsNorth, trailArea.boundsNorth);
            updateElement.boundsSouth = Math.min(updateElement.boundsSouth, trailArea.boundsSouth);
            updateElement.boundsEast = Math.max(updateElement.boundsEast, trailArea.boundsEast);
            updateElement.boundsWest = Math.min(updateElement.boundsWest, trailArea.boundsWest);
        });
        if (updateElement.boundsNorth === -90) {
            updateElement.boundsNorth = null;
            updateElement.boundsSouth = null;
            updateElement.boundsEast = null;
            updateElement.boundsWest = null;
        }

        const destinationUpdatePromise = this.destinationRef.child(destination.key).child('trailAreaKeys').child(trailAreaKey).remove();
        const boundsUpdatePromise = destinationUpdatePromise
            .then(() => this.destinationRef.child(destination.key).update(updateElement));
        const trailAreaUpdatePromise = this.trailAreaService.removedFromDestination(trailAreaKey);
        return Promise.all([boundsUpdatePromise, trailAreaUpdatePromise]);
    }

    /**
     * Adds an adventure to a destination
     * @param {string} destinationKey
     * @param {string} adventureKey
     * @returns {Promise<void>} The key of the adventureKey reference.
     */
    addAdventureToDestination(destinationKey: string, adventureKey: string): Promise<void> {
        return this.destinationRef.child(destinationKey).child('adventureKeys').child(adventureKey).set(true);
    }

}
