import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { DestinationService } from '../../../firebase-services/destination.service';
import { EventService } from '../../../firebase-services/event.service';

// Interfaces
import { Destination } from '../../../interfaces/destination';
import { Event, EventItem } from '../../../interfaces/event';

@Component({
    selector: 'app-destination-event-item-edit',
    templateUrl: './destination-event-item-edit.component.html',
    styleUrls: ['./destination-event-item-edit.component.css']
})
export class DestinationEventItemEditComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    destination: Destination = null;
    event: Event = null;
    eventItem: EventItem = null;
    routerRoot: string;
    routerBack: string;

    constructor(
        private route: ActivatedRoute,
        private destinationService: DestinationService,
        private eventService: EventService
    ) {
    }

    ngOnInit() {
        this.destinationService.getDestination(this.route.snapshot.paramMap.get('destinationKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((destination) => {
                this.routerRoot = '/destination/' + destination.key;
                this.destination = destination;
            });
        this.eventService.getEvent(this.route.snapshot.paramMap.get('eventKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((event) => {
                this.routerBack = '/events/' + event.key;
                this.event = event;
            });
        this.eventService.getEventItem(this.route.snapshot.paramMap.get('eventItemKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((eventItem) => this.eventItem = eventItem);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
