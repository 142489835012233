import {AfterViewChecked, Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

// Services
import {AuthService} from '../../core/auth.service';
import {UserService} from '../../firebase-services/user.service';
import {CountryService} from '../../firebase-services/country.service';
import {DestinationService} from '../../firebase-services/destination.service';
import {TrailAreaService} from '../../firebase-services/trail-area.service';

// Interfaces
import {Country} from '../../interfaces/countries';
import {Destination} from '../../interfaces/destination';
import {TrailArea} from '../../interfaces/trailArea';
import {Roles} from "../../interfaces/role";
import {TrailAreaManagerService} from "../../root/role-manager/trail-area-managers/trail-area-manager.service";
import {RoleService} from "../../core/role.service";

declare var $: any;

@Component({
    selector: 'app-destination-trail-areas',
    templateUrl: './destination-trail-areas.component.html',
    styleUrls: ['./destination-trail-areas.component.css']
})
export class DestinationTrailAreasComponent implements AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    countries: Country[] = null;

    destination: Destination = null;
    destinationTrailAreas: TrailArea[] = null;
    isTrailAreaAdmin: { [trailAreaKey: string]: boolean } = {};

    newTrailAreaName: string = null;
    newTrailAreaCountryCode: string = null;

    freeTrailAreas: TrailArea[] = null;
    selectedFreeTrailAreaIndex: number = null;
    freeSelect2: any = null;
    adventureTrailArea: TrailArea = null;
    roles = Roles;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public authService: AuthService,
        private userService: UserService,
        private countryService: CountryService,
        private destinationService: DestinationService,
        private trailAreaService: TrailAreaService,
        private trailAreaManagerService: TrailAreaManagerService,
        private roleService: RoleService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => (e instanceof NavigationEnd) ? this.init() : null);
        this.loadCountries();
    }

    ngAfterViewChecked() {
        this.createSelect2();
    }

    ngOnDestroy() {
        this.destroySelect2();
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    private init() {
        this.destination = null;
        this.destinationTrailAreas = null;
        this.isTrailAreaAdmin = {};
        this.newTrailAreaName = null;
        this.newTrailAreaCountryCode = null;
        this.selectedFreeTrailAreaIndex = null;
        if (this.authService.isUser(this.roles.ADMIN)) {
            this.destroySelect2();
            this.loadFreeTrailAreas();
        }
        this.getDestination(this.route.snapshot.paramMap.get('destinationKey'));
    }

    private createSelect2() {
        if (this.freeTrailAreas !== null && this.freeSelect2 === null) {
            // Enable search in dropdown.
            this.freeSelect2 = $('#selectTrailArea').select2({
                theme: 'bootstrap4',
                placeholder: 'Select an existing trail area'
            });
            // Set the selected value in the select correctly.
            this.freeSelect2.on('select2:select', (e: any) => this.selectedFreeTrailAreaIndex = e.params.data.id);
            // Select nothing to display placeholder
            this.freeSelect2.val(null).trigger('change');
        }
    }

    private destroySelect2() {
        if (this.freeSelect2 !== null) {
            this.freeSelect2.select2('destroy');
            this.freeSelect2.off('select2:select');
            this.freeSelect2 = null;
            this.freeTrailAreas = null;
        }
    }

    private loadCountries() {
        this.countryService.getCountries()
            .pipe(take(1))
            .subscribe((countries) => this.countries = countries);
    }

    private getDestination(destinationKey: string): void {
        this.destinationService.getDestination(destinationKey)
            .pipe(take(1))
            .subscribe((destination) => {
                this.destination = destination;
                this.getDestinationTrailAreas();
            });
    }

    private getDestinationTrailAreas(): void {
        this.trailAreaService.getTrailAreas(Object.keys(this.destination.trailAreaKeys))
            .pipe(take(1))
            .subscribe((trailAreas) => {
                trailAreas.forEach((trailArea) => {
                    this.trailAreaManagerService.isUserTrailAreaManager(this.authService.user.userID, trailArea.key)
                        .subscribe((isManager) => this.isTrailAreaAdmin[trailArea.key] = isManager);
                });
                this.destinationTrailAreas = trailAreas;
            });
    }

    private loadFreeTrailAreas() {
        this.trailAreaService.getFreeTrailAreas()
            .pipe(take(1))
            .subscribe((freeTrailAreas) => this.freeTrailAreas = freeTrailAreas);
    }

    private addTrailAreaToDestination(trailArea: TrailArea): Promise<any> {
        return this.destinationService.addTrailAreaToDestination(trailArea, this.destination)
            .then(() => {
                if (Object.keys(trailArea.adventureKeys).length > 0) {
                    this.adventureTrailArea = trailArea;
                    $('#modal-adventures').modal('show');
                } else if (trailArea.adventuresEnabled === true) {
                    this.trailAreaService.disableAdventures(trailArea.key)
                        .then(() => this.init());
                }
                return this.init();
            });
    }

    createNewTrailArea(): void {
        if (this.newTrailAreaName === '') {
            alert('Please give the new trail area a name');
            return;
        }
        if (!this.newTrailAreaCountryCode) {
            alert('Please select a country for the new trail');
            return;
        }
        const trailAreaThenable = this.trailAreaService.createTrailArea(this.newTrailAreaName, this.newTrailAreaCountryCode);
        const profileKey = this.authService.user.userID;
        const adminPromise = this.roleService.assignUserRole(profileKey, this.roles.TRAIL_AREA_MANAGER, profileKey).then(() => {
            return this.trailAreaManagerService.addTrailAreaToTrailAreaManager(profileKey, trailAreaThenable.key);
        });
        Promise.all([trailAreaThenable, adminPromise])
            .then(() => {
                this.trailAreaService.getTrailArea(trailAreaThenable.key)
                    .subscribe((trailArea) => this.addTrailAreaToDestination(trailArea));
            });
    }

    addToDestination() {
        return this.addTrailAreaToDestination(this.freeTrailAreas[this.selectedFreeTrailAreaIndex]);
    }

    handleAdventures() {
        $('#modal-adventures').modal('hide');
        if ($('#adventureAction').prop('checked') === true) {
            const promises: Promise<void>[] = [];
            Object.keys(this.adventureTrailArea.adventureKeys).forEach((adventureKey) => {
                promises.push(this.destinationService.addAdventureToDestination(this.destination.key, adventureKey));
                promises.push(this.trailAreaService.removeAdventureFromTrailArea(this.adventureTrailArea.key, adventureKey));
            });
            promises.push(this.trailAreaService.disableAdventures(this.adventureTrailArea.key));
            Promise.all(promises)
                .then(() => this.init());
        } else {
            this.init();
        }
    }

    removeTrailAreaFromDestination(trailAreaKey: string) {
        for (let i = 0; i < this.destinationTrailAreas.length; i++) {
            if (this.destinationTrailAreas[i].key === trailAreaKey) {
                this.destinationTrailAreas.splice(i, 1);
                break;
            }
        }
        this.destinationService.removeTrailAreaFromDestination(trailAreaKey, this.destination, this.destinationTrailAreas)
            .then(() => this.init());
    }
}
