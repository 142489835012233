<div class="content-wrapper">
    <app-content-header-section *ngIf="treat" contentPageId="treatsEdit"
                                [pageParams]="{ treatKey:treat.key, treatName:treat.alias || treat.topOfMindTitle}"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 col-lg-4">

                    <div *ngIf="treat" class="card card-outline card-teal">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-cogs"></i> Treat settings - {{ treat.alias || treat.topOfMindTitle }}</h3>
                        </div>
                        <div class="card-body" *ngIf="treat">
                            <app-alert-info-box infoTrigger="{{ settingsChangedInfo }}">
                                <h4>Treat saved successfully</h4>
                            </app-alert-info-box>
                            <div id="" class="alert alert-warning alert-dismissible d-none" role="alert">
                                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                            </div>

                            <div class="form-group">
                                <label for="activePeriod">Active period</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <i class="input-group-text far fa-clock"></i>
                                    </div>
                                    <input type="text" class="form-control" id="activePeriod" aria-describedby="activePeriodHelp">
                                </div>
                            </div>
                            <div>
                                <small class="form-text text-muted" id="activePeriodHelp">When the treat will show up in the apps.</small>
                            </div>

                            <div class="form-group">
                                <div>
                                    <label for="alias">Alias</label>
                                    <input [(ngModel)]="treat.alias" (change)="settingsChanged()" (keyup)="settingsChanged()" id="alias"
                                           aria-describedby="aliasHelp" class="form-control">
                                </div>
                                <small class="form-text text-muted" id="aliasHelp">Internal name for the treat. Not used publicly.</small>
                            </div>

                            <div class="form-group">
                                <div>
                                    <label for="targetTopOfMind">Top of Mind Coefficient</label>
                                    <input [(ngModel)]="treat.targetTopOfMind" id="targetTopOfMind" aria-describedby="targetTopOfMindHelp"
                                           class="form-control" type="number" min="0" max="20" (change)="settingsChanged()"
                                           (keyup)="settingsChanged()">
                                </div>
                                <small class="form-text text-muted" id="targetTopOfMindHelp">Coefficient for selecting this treat as top of
                                    mind.</small>
                            </div>

                            <div class="form-group">
                                <div>
                                    <label for="targetMyTreats">My Treats Coefficient</label>
                                    <input [(ngModel)]="treat.targetMyTreats" id="targetMyTreats" aria-describedby="targetMyTreatsHelp"
                                           class="form-control" type="number" min="0" max="20" (change)="settingsChanged()"
                                           (keyup)="settingsChanged()">
                                </div>
                                <small class="form-text text-muted" id="targetMyTreatsHelp">Coefficient for sorting this treat among my
                                    treats.</small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="disableSaveSettings" (click)="saveSettings()" class="btn btn-primary">Save settings</button>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 col-lg-4">
                    <app-translatable-texts *ngIf="treat" textsName="Treat" colorTheme="teal" infoTrigger="{{ textsChangedInfo }}"
                                            [textModel]="textModel"
                                            [textObject]="treat" (alteredTextObject)="onAlteredTextObject($event)"></app-translatable-texts>
                </div>
                <div class="col-md-6 col-lg-4">
                    <app-image-handler *ngIf="treat" imageName="Treat Image" imageIndex="0" imageUrl="{{ treat.imageURL }}"
                                       imagePath="treats/{{ treat.key }}/"
                                       (uploadedImageUrl)="onUploadedImageUrl($event)"></app-image-handler>
                </div>
            </div>
        </div>
    </section>
</div>
