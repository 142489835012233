import {Component} from '@angular/core';
import {Subject} from "rxjs";
import {Destination} from "../../../interfaces/destination";
import {AuthService} from "../../../core/auth.service";
import {DestinationService} from "../../../firebase-services/destination.service";
import {DomSanitizer} from "@angular/platform-browser";
import {takeUntil} from "rxjs/operators";
import {DestinationManagersService} from "./destination-managers.service";
import {DestinationManagers} from "../../../interfaces/destination-manager";
import {RoleService} from "../../../core/role.service";
import {Roles} from "../../../interfaces/role";

@Component({
    selector: 'app-destination-managers',
    templateUrl: './destination-managers.component.html',
    styleUrl: './destination-managers.component.css'
})
export class DestinationManagersComponent {
    destroy$: Subject<boolean> = new Subject<boolean>();
    roles = Roles;
    destinations: Destination[];
    destinationManagers: DestinationManagers = {};
    onlineIsManager: { [destinationKey: string]: boolean } = {};
    numberOfTrailAreas: { [destinationKey: string]: number } = {};
    numberOfEvents: { [destinationKey: string]: number } = {};
    numberOfManagers: { [destinationKey: string]: number } = {};
    displayTrash: { [destinationKey: string]: boolean } = {};

    constructor(
        public authService: AuthService,
        private roleService: RoleService,
        private destinationService: DestinationService,
        private destinationManagersService: DestinationManagersService,
        private domSanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.destinationService.getAllDestinations()
            .pipe(takeUntil(this.destroy$))
            .subscribe((destinations) => {
                this.getDestinationsDetail(destinations);
                console.log(destinations);
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    getDestinationsDetail(destinations: Destination[]): void {
        this.loadDestinationsManagers();
        for (const destination of destinations) {
            // Number of Trail Areas
            if (destination.trailAreaKeys) {
                this.numberOfTrailAreas[destination.key] = Object.keys(destination.trailAreaKeys).length;
            }
            // Number of Events
            if (destination.eventKeys) {
                this.numberOfEvents[destination.key] = Object.keys(destination.eventKeys).length;
            }
            // display trash
            if (!this.numberOfTrailAreas[destination.key] && !this.numberOfEvents[destination.key]) {
                this.displayTrash[destination.key] = true;
            }
            // Online is Manager?
            this.onlineIsManager[destination.key] = false;
            this.authService.destinations.forEach((onlineUserDestination) => {
                if (onlineUserDestination.key === destination.key) {
                    this.onlineIsManager[destination.key] = true;
                }
            });
        }
        this.destinations = destinations;
    }

    loadDestinationsManagers(): void {
        this.destinationManagersService.getDestinationsManagers()
            .pipe(takeUntil(this.destroy$)).subscribe((dmList) => {
            this.destinationManagers = dmList;
            this.populateNumberOfManagers(dmList);
        });
    }

    populateNumberOfManagers(destinationManagers: DestinationManagers) {
        for (const destinationKey in destinationManagers) {
            if (destinationManagers.hasOwnProperty(destinationKey)) {
                this.numberOfManagers[destinationKey] = destinationManagers[destinationKey].length;
            }
        }
    }

    addManager(userKey: string, destinationKey: string): Promise<void> {
        return this.roleService.assignUserRole(userKey, this.roles.DESTINATION_MANAGER, this.authService.user.userID).then(() => {
            return this.destinationManagersService.addDestinationManager(userKey, destinationKey).then(() => {
            }).catch((err) => console.error('An error occurred: ', err.message));
        }).catch((err) => console.error('An error occurred: ', err.message));
    }

    removeManager(profileKey: string, destinationKey: string): Promise<void> {
        $('#' + destinationKey + profileKey).remove();
        let revokeRole = true;
        for (const destination in this.destinationManagers) {
            if (destination !== destinationKey) {
                this.destinationManagers[destination].forEach((user) => {
                    if (user.userID === profileKey) {
                        revokeRole = false;
                    }
                });
            }
        }
        return this.destinationManagersService.removeDestinationManager(profileKey, destinationKey).then(() => {
            console.log(revokeRole);
            if (revokeRole) {
                return this.roleService.revokeUserRole(profileKey, this.roles.DESTINATION_MANAGER);
            }
        }).catch((err) => console.log(err));
    }

    deleteDestination(key: string): Promise<void> {
        for (const i in this.destinations) {
            if (this.destinations[i].key === key) {
                this.destinations.splice(+i, 1);
                break;
            }
        }
        return this.destinationService.deleteDestination(key);
    }

    setOnlineBackgroundImage() {
        return this.domSanitizer.bypassSecurityTrustStyle('background-image: url(' + this.authService.user.userPicture + ')');
    }

}
