import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { TrailAreaService } from '../../../firebase-services/trail-area.service';
import { EventService } from '../../../firebase-services/event.service';
import { TrailService } from '../../../firebase-services/trail.service';
import { PoiService } from '../../../firebase-services/poi.service';

// Interfaces
import { DraftPoi } from '../../../interfaces/poi';
import { Trail } from '../../../interfaces/trail';
import { TrailArea } from '../../../interfaces/trailArea';
import { Event } from '../../../interfaces/event';

@Component({
    selector: 'app-trail-area-event-edit',
    templateUrl: './trail-area-event-edit.component.html',
    styleUrls: ['./trail-area-event-edit.component.css']
})
export class TrailAreaEventEditComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    area: TrailArea = null;
    event: Event = null;

    // Parts of the trail area
    trails: Trail[] = null;
    pois: DraftPoi[] = null;

    routerRoot: string;

    constructor(
        private route: ActivatedRoute,
        private trailAreaService: TrailAreaService,
        private eventService: EventService,
        private trailService: TrailService,
        private poiService: PoiService
    ) {
    }

    ngOnInit() {
        const areaObservable = this.trailAreaService.getTrailArea(this.route.snapshot.paramMap.get('areaKey'));
        areaObservable
            .pipe(takeUntil(this.destroy$))
            .subscribe((trailArea) => {
                this.routerRoot = '/trail-area/' + trailArea.key;
                this.trailService.getTrailsOnTrailArea(trailArea.key)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((trails) => this.trails = trails);
                this.poiService.getPoisForTrailArea(trailArea.key)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((pois) => this.pois = pois);
                this.area = trailArea;
            });
        this.eventService.getEvent(this.route.snapshot.paramMap.get('eventKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe(event => this.event = event);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
