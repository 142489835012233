import { Injectable } from '@angular/core';
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database/';
import { from, Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import firebase from 'firebase/compat';

// Services
import { AuthService } from '../core/auth.service';
import { ModelAssistantService } from '../services/model-assistant.service';
import { TrailService } from './trail.service';

// Interfaces
import { FILE_NOT_UPLOADED_ERROR, ON_TRAIL_AWARD_KEY, OnTrailApplication } from '../interfaces/on-trail';

import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class OnTrailService {

    onTrailManagersRef: firebase.database.Reference;

    constructor(
        private trailService: TrailService,
        private db: AngularFireDatabase,
        private authService: AuthService,
        private modelAssistantService: ModelAssistantService
    ) {
        this.onTrailManagersRef = this.db.database.ref('trackManagers');
    }

    private static snapshotToOnTrailApplication(snapshot: AngularFireAction<DatabaseSnapshot<OnTrailApplication>>): OnTrailApplication {
        const onTrailApplication: OnTrailApplication = snapshot.payload.val();
        onTrailApplication.key = snapshot.key;
        onTrailApplication.status = parseInt(onTrailApplication.status, 10);
        if (onTrailApplication.gpxUrl && onTrailApplication.gpxUrl.indexOf(FILE_NOT_UPLOADED_ERROR) !== -1) {
            onTrailApplication.gpxUrl = null;
        }
        if (onTrailApplication.contractUrl && onTrailApplication.contractUrl.indexOf(FILE_NOT_UPLOADED_ERROR) !== -1) {
            onTrailApplication.contractUrl = null;
        }
        if (onTrailApplication.imgUrl && onTrailApplication.imgUrl.indexOf(FILE_NOT_UPLOADED_ERROR) !== -1) {
            onTrailApplication.imgUrl = null;
        }
        if (onTrailApplication.start && onTrailApplication.start.lat === 'NaN') {
            onTrailApplication.start = null;
        }
        if (onTrailApplication.stop && onTrailApplication.stop.lat === 'NaN') {
            onTrailApplication.stop = null;
        }
        return onTrailApplication;
    }

    getOnTrailApplication(onTrailApplicationKey: string): Observable<OnTrailApplication> {
        return this.db.object<OnTrailApplication>(this.onTrailManagersRef.child(onTrailApplicationKey).ref).snapshotChanges()
            .pipe(
                map((onTrailApplicationSnap) => OnTrailService.snapshotToOnTrailApplication(onTrailApplicationSnap))
            );
    }

    /**
     * Gets all on trail applications
     */
    getOnTrailApplications(): Observable<OnTrailApplication[]> {
        return this.db.list<OnTrailApplication>(this.onTrailManagersRef.ref).snapshotChanges()
            .pipe(
                map((onTrailApplicationSnaps) => {
                    const onTrailApplications: OnTrailApplication[] = [];
                    onTrailApplicationSnaps.forEach((onTrailApplicationSnap) => {
                        const onTrailApplication: OnTrailApplication = OnTrailService.snapshotToOnTrailApplication(onTrailApplicationSnap);
                        if (parseInt(onTrailApplication.deleted, 10) !== 1) {
                            onTrailApplications.push(onTrailApplication);
                        }
                    });
                    return onTrailApplications;
                })
            );
    }

    loadTrailForApplication(onTrailApplication: OnTrailApplication): Observable<OnTrailApplication> {
        return this.modelAssistantService.getTrail(onTrailApplication.trackId)
            .pipe(
                switchMap((trail) => {
                    let trailReadyPromise;
                    if (trail) {
                        if (typeof trail.lengthInMeters !== 'number' || typeof trail.heightIncrease !== 'string' ||
                            typeof trail.heightDecrease !== 'string' ||
                            parseInt(trail.heightIncrease, 10).toString() !== trail.heightIncrease ||
                            parseInt(trail.heightDecrease, 10).toString() !== trail.heightDecrease) {
                            console.log('updating trail...', trail);
                            trailReadyPromise = this.trailService.updatePath(trail.key, trail.encodedPolyline);
                        } else {
                            trailReadyPromise = Promise.resolve();
                        }
                        onTrailApplication.trail = trail;
                    } else {
                        onTrailApplication.trackId = null;
                        console.error('Remove trail on application', onTrailApplication, trail);
                        trailReadyPromise = this.removeTrailOnApplication(onTrailApplication.key);
                    }
                    const applicationPromise: Promise<OnTrailApplication> = trailReadyPromise
                        .then(() => onTrailApplication);
                    return from(applicationPromise);
                })
            );
    }

    setTrailOnApplication(onTrailApplicationKey: string, trailKey: string): Promise<void> {
        return this.onTrailManagersRef.child(onTrailApplicationKey).update({trackId: trailKey});
    }

    unlinkTrailFromApplication(onTrailApplication: OnTrailApplication): Promise<void> {
        return this.onTrailManagersRef.child(onTrailApplication.trail.key).update({trackId: null})
            .then(() => {
                return this.trailService.removeAward(ON_TRAIL_AWARD_KEY, onTrailApplication.trail.key);
            });
    }

    updateOnTrailApplicationStatus(onTrailApplication: OnTrailApplication): Promise<void> {
        return this.onTrailManagersRef.child(onTrailApplication.key).update({status: onTrailApplication.status});
    }

    deleteOnTrailApplication(onTrailApplication: OnTrailApplication): Promise<void> {
        return this.onTrailManagersRef.child(onTrailApplication.key).update({deleted: 1});
    }

    duplicateOnTrailApplication(onTrailApplication: OnTrailApplication): void {
        const duplicate: OnTrailApplication = Object.assign({}, onTrailApplication);
        duplicate.note =
            'Copy of application \'' + onTrailApplication.key + '\' by ' + this.authService.user.name + '.\n' + onTrailApplication.note;
        duplicate.trackName = (onTrailApplication.trackName || onTrailApplication.trail.name) + ' (copy)';
        duplicate.status = 0;
        duplicate.timestamp = moment.now();
        duplicate.trackId = null;
        duplicate.key = null;
        duplicate.trail = null;
        this.onTrailManagersRef.push(duplicate);
    }

    private removeTrailOnApplication(onTrailApplicationKey: string): Promise<any> {
        return this.onTrailManagersRef.child(onTrailApplicationKey).update({trackId: null});
    }
}
