<div class="container-fluid">
    <div class="row">
        <div class="col-md-6">

            <div class="card card-outline card-success">
                <div class="card-header">
                    <h3 *ngIf="_eventItem.refType == 1 && !_eventItem.voucherPin" class="card-title"><i class="fas fa-map-marker-alt"></i>
                        Event POI: {{ _eventItem.name }}</h3>
                    <h3 *ngIf="_eventItem.refType == 1 && _eventItem.voucherPin" class="card-title"><i class="fa fa-gift"></i> Event
                        Treat: {{ _eventItem.name }}</h3>
                    <h3 *ngIf="_eventItem.refType == 2" class="card-title"><i class="fas fa-route"></i> Event Trail: {{ _eventItem.name }}
                    </h3>
                </div>
                <div class="card-body">
                    <app-alert-info-box infoTrigger="{{ settingsChangedInfo }}">
                        <h4>Event item saved successfully</h4>
                    </app-alert-info-box>

                    <div *ngIf="_eventItem.refType == 1" class="form-group">
                        <div class="input-group">
                            <label for="voucherPin">Voucher-pin</label>
                            <div>
                                <input [(ngModel)]="_eventItem.voucherPin" type="checkbox" id="voucherPin" aria-describedby="voucherPinHelp"
                                       (change)="itemChanged()" (keyup)="itemChanged()">
                                <input [(ngModel)]="pin1" type="number" min="0" max="9" [disabled]="!_eventItem.voucherPin"
                                       (change)="itemChanged()" (keyup)="itemChanged()">
                                <input [(ngModel)]="pin2" type="number" min="0" max="9" [disabled]="!_eventItem.voucherPin"
                                       (change)="itemChanged()" (keyup)="itemChanged()">
                                <input [(ngModel)]="pin3" type="number" min="0" max="9" [disabled]="!_eventItem.voucherPin"
                                       (change)="itemChanged()" (keyup)="itemChanged()">
                                <input [(ngModel)]="pin4" type="number" min="0" max="9" [disabled]="!_eventItem.voucherPin"
                                       (change)="itemChanged()" (keyup)="itemChanged()">
                            </div>
                        </div>
                        <small class="form-text text-muted" id="voucherPinHelp">Alter voucher pin for the event item.</small>
                    </div>

                    <div class="form-group">
                        <label for="timeBoxed">Time box</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
								<span class="input-group-text">
									<input [(ngModel)]="eventItemIsTimeBoxed" type="checkbox" id="timeBoxed" (change)="itemChanged()">
                                    &nbsp;<i class="far fa-clock"></i>
								</span>
                            </div>
                            <input [disabled]="!eventItemIsTimeBoxed" type="text" class="form-control float-right" id="timebox"
                                   aria-describedby="timeboxHelp">
                        </div>
                        <small class="form-text text-muted" id="timeboxHelp">Use time box if the event element is only available or relevant for a
                            limited amount of time.</small>
                    </div>

                    <div class="form-group">
                        <label for="itemName">Original name</label>
                        <input [(ngModel)]="originalName" disabled="disabled" class="form-control" id="itemName">
                    </div>

                    <div class="form-group">
                        <div>
                            <label for="eventPoints">Event Points</label>
                            <input [(ngModel)]="_eventItem.eventPoints" id="eventPoints" aria-describedby="eventPointsHelp"
                                   class="form-control" type="number" min="0" max="1000" (change)="itemChanged()" (keyup)="itemChanged()">
                        </div>
                        <small class="form-text text-muted" id="eventPointsHelp">Used to sort explorers.</small>
                    </div>
                </div>
                <div class="card-footer">
                    <button [disabled]="disableSaveItem" (click)="saveItem(false)" class="btn btn-primary mr-2">Save</button>
                    <button [disabled]="disableSaveItem" (click)="saveItem(true)" class="btn btn-primary">Save and back</button>
                </div>
            </div>

        </div>
        <div class="col-md-12 col-lg-6">

            <app-translatable-texts textsName="Event Item" colorTheme="success" infoTrigger="{{ textsChangedInfo }}" [textModel]="textModel"
                                    [textObject]="_eventItem" (alteredTextObject)="onAlteredTextObject($event)"></app-translatable-texts>

        </div>
    </div>
</div>
