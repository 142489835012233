import {Component, OnDestroy} from '@angular/core';
import {Subject, tap} from "rxjs";
import {AuthService} from "../../core/auth.service";
import {LayoutService} from "../../core/layout.service";
import {takeUntil} from "rxjs/operators";
import {PoiManagerService} from "../../root/role-manager/poi-managers/poi-manager.service";
import {PoiEntities} from "../../interfaces/poi-manager";

@Component({
    selector: 'poi-manager-dashboard',
    templateUrl: './poi-manager-dashboard.component.html',
    styleUrls: ['./poi-manager-dashboard.component.css']
})
export class PoiManagerDashboardComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    poiEntities: PoiEntities;

    constructor(
        private poiManagerService: PoiManagerService,
        public layout: LayoutService,
        private authService: AuthService
    ) {
        this.poiManagerService.getPoiManagerEntities(this.authService.user.userID)
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe((e) => {
                this.poiEntities = e
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
