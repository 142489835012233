<div class="content-wrapper">
    <app-content-header-section contentPageId="trailAreaManagers" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-5" *ngIf="authService.isUser(roles.ADMIN)">
                    <div class="card card-outline card-warning">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-user-plus"></i> Assign Trail Area Manager</h3>
                        </div>
                        <div class="card-body">
                            <app-alert-info-box infoTrigger="{{ state }}">
                                <h4>Trail Area Manager saved successfully</h4>
                            </app-alert-info-box>
                            <div class="form-group">
                                <label for="selecttrailAreaManager"><i class="fas fa-user"></i> Select User</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <app-find-user modalId="selecttrailAreaManagerUser"
                                                           modalTitle="Find user to assign as trailArea manager"
                                                           id="selecttrailAreaManager"
                                                           (userSelected)="onUserSelected($event)"
                                                           btnClassType="-smaller custom-btn">
                                                <i title="Search for user" class="fas fa-search mr2"></i>
                                            </app-find-user>
                                        </span>
                                    </div>
                                    <input id="selectedUserText" aria-describedby="userHelp"
                                           class="form-control custom-input" value="{{ selectedUserName }}"
                                           placeholder="No user selected" readonly>
                                </div>
                                <small class="form-text text-muted" id="selectUserHelp">Select a user to assign as
                                    trail area manager.</small>
                            </div>
                            <div class="form-group">

                                <label for="selectTrailArea"><i class="fas fa-route"></i> Select Trail Area</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <app-find-trail-area modalId="selectTrailAreaEm"
                                                                 modalTitle="Find trail area to assign to trailArea manager"
                                                                 id="selectTrailAreaEm"
                                                                 (areaSelected)="onAreaSelected($event)"
                                                                 btnClassType="-smaller custom-btn">
                                                <i title="Search for trail area" class="custom-i fas fa-search"></i>
                                            </app-find-trail-area>
                                        </span>
                                    </div>
                                    <input id="selectedAreaText" aria-describedby="areaHelp"
                                           class="form-control custom-input" value="{{ selectedTrailAreaName }}"
                                           placeholder="No trail area selected" readonly>
                                </div>
                                <small class="form-text text-muted" id="selectAreaHelp">Select a trail area to assign to
                                    a trail area manager.</small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="saveRoleDisabled" type="submit" class="btn btn-primary"
                                    (click)="assignTrailAreaManager()">Save
                            </button>
                            <button type="reset" class="btn btn-default discard-btn"
                                    (click)="resetAssignTrailAreaManager()">
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-user-tie"></i> Trail area managers</h3>
                        </div>
                        <div class="card-body px-0">
                            <table *ngIf="allIsLoaded" id="managersDataTable" class="table">
                                <thead>
                                <tr>
                                    <th>Manager</th>
                                    <th>Trail Areas</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let manager of trailAreaManagerListItems">
                                    <td>
                                        <img class="img-fluid img-circle img-sm"
                                             src="{{ manager.user.userPicture }}" alt="">&nbsp;
                                        <strong>{{ manager.user.name }}</strong>
                                        <div class="mt-3" *ngIf="authService.isUser(roles.ADMIN)">
                                            <app-find-trail-area modalId="findTrailAreaFor{{manager.user.userID}}"
                                                                 modalTitle="Find trail area" id="selectTrailArea"
                                                                 (areaSelected)="onAreaAdded($event, manager.user.userID)"
                                                                 btnClassType="xs btn-success">
                                                <i class="fas fa-route"></i> Add area
                                            </app-find-trail-area>&nbsp;
                                            <button class="btn btn-xs btn-danger"
                                                    (click)="revokeManager(manager.user.userID, manager.trailAreas)">
                                                <i class="fas fa-trash"></i> Revoke manager
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <ul class="todo-list">
                                            <li *ngFor="let area of manager.trailAreas">
                                                <span class="text-secondary">{{ area.name }}</span>&nbsp;
                                                <small class="badge badge-danger">{{ area.country }}</small>
                                                <small class="badge badge-default">{{ area.key }}</small>
                                                <div *ngIf="authService.isUser(roles.ADMIN)" class="tools">
                                                    <i title="Remove trail area from user"
                                                       (click)="onAreaRemoved(manager.user.userID, area.key)"
                                                       class="far fa-trash-alt mr-2">
                                                    </i>
                                                </div>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>