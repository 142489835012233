import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Guards
import {
    AuthGuard,
    adminGuard,
    eventManagerGuard,
    dmoManagerGuard,
    trailCenterManagerGuard,
    countryManagerGuard, destinationManagerGuard, onTrailManagerGuard, organisationManagerGuard, trailAreaManagerGuard
} from './core/auth.guard';
import { SavedAndPublishedGuard } from './core/saved-and-published.guard';

// Components
import { LoginComponent } from './login/login.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
// Dashboard
import { DashboardComponent } from './dashboard/dashboard.component';
// Country
import { CountryTrailAreasAdminComponent } from './country/country-trail-areas-admin/country-trail-areas-admin.component';
import { CountryNewTrailAreaComponent } from './country/country-new-trail-area/country-new-trail-area.component';
import { CountryAdventuresComponent } from './country/country-adventures/country-adventures.component';
import { CountryAdventureEditComponent } from './country/country-adventures/country-adventure-edit/country-adventure-edit.component';
import { CountryExplorersComponent } from './country/country-adventures/country-explorers/country-explorers.component';
import {
    CountryAdventureItemEditComponent
} from './country/country-adventures/country-adventure-edit/country-adventure-item-edit/country-adventure-item-edit.component';
import { CountryContactResponsibleComponent } from './country/country-contact-responsible/country-contact-responsible.component';
// Destination
import { DestinationComponent } from './destination/destination.component';
import { DestinationTrailAreasComponent } from './destination/destination-trail-areas/destination-trail-areas.component';
import { DestinationAdventuresComponent } from './destination/destination-adventures/destination-adventures.component';
import {
    DestinationAdventureEditComponent
} from './destination/destination-adventures/destination-adventure-edit/destination-adventure-edit.component';
import {
    DestinationAdventureExplorersComponent
} from './destination/destination-adventures/destination-adventure-explorers/destination-adventure-explorers.component';
import {
    DestinationAdventureItemEditComponent
} from './destination/destination-adventures/destination-adventure-item-edit/destination-adventure-item-edit.component';
// Trail Area
import { TrailAreaComponent } from './trail-area/trail-area.component';
import { TrailsComponent } from './trail-area/trails/trails.component';
import { TrailEditComponent } from './trail-area/trails/trail-edit/trail-edit.component';
import { PoisComponent } from './trail-area/pois/pois.component';
import { PoiEditComponent } from './trail-area/pois/poi-edit/poi-edit.component';
import { TrailAreaNewsComponent } from './trail-area/trail-area-news/trail-area-news.component';
import { TrailAreaDraftsComponent } from './trail-area/trail-area-news/trail-area-drafts/trail-area-drafts.component';
import { TrailAreaEditDraftComponent } from './trail-area/trail-area-news/trail-area-edit-draft/trail-area-edit-draft.component';
import { TrailAreaStoriesComponent } from './trail-area/trail-area-news/trail-area-stories/trail-area-stories.component';
import { TrailAreaModifyStoryComponent } from './trail-area/trail-area-news/trail-area-modify-story/trail-area-modify-story.component';
import { TrailAreaStoryArchiveComponent } from './trail-area/trail-area-news/trail-area-story-archive/trail-area-story-archive.component';
import { TrailAreaAdventuresComponent } from './trail-area/trail-area-adventures/trail-area-adventures.component';
import {
    TrailAreaAdventureEditComponent
} from './trail-area/trail-area-adventures/trail-area-adventure-edit/trail-area-adventure-edit.component';
import { TrailAreaExplorersComponent } from './trail-area/trail-area-adventures/trail-area-explorers/trail-area-explorers.component';
import {
    TrailAreaAdventureItemEditComponent
} from './trail-area/trail-area-adventures/trail-area-adventure-edit/trail-area-adventure-item-edit/trail-area-adventure-item-edit.component';
import { AreaManagersComponent } from './trail-area/area-managers/area-managers.component';
import { RideStatisticsComponent } from './trail-area/ride-statistics/ride-statistics.component';
// Organisation
import { MembershipComponent } from './organisation/membership/membership.component';
import { MembersComponent } from './organisation/members/members.component';
// OnTrail
import { OnTrailStatusComponent } from './on-trail/on-trail-status/on-trail-status.component';
import { OnTrailApplicationComponent } from './on-trail/on-trail-status/on-trail-application/on-trail-application.component';
import { OnTrailContactsComponent } from './on-trail/on-trail-contacts/on-trail-contacts.component';
// Root
import { RootDashboardComponent } from './root/root-dashboard/root-dashboard.component';
import { KioskOrdersComponent } from './root/kiosk-orders/kiosk-orders.component';
import { NewsRoomComponent } from './root/news-room/news-room.component';
import { NewsRoomDraftsComponent } from './root/news-room/news-room-drafts/news-room-drafts.component';
import { NewsRoomEditDraftComponent } from './root/news-room/news-room-edit-draft/news-room-edit-draft.component';
import { NewsRoomStoriesComponent } from './root/news-room/news-room-stories/news-room-stories.component';
import { NewsRoomModifyStoryComponent } from './root/news-room/news-room-modify-story/news-room-modify-story.component';
import { NewsRoomArchiveComponent } from './root/news-room/news-room-archive/news-room-archive.component';
import { NewsRoomWelcomeComponent } from './root/news-room/news-room-welcome/news-room-welcome.component';
import { PremiumProductComponent } from './root/premium/premium-product/premium-product.component';
import { PremiumMembersComponent } from './root/premium/premium-members/premium-members.component';
import { VoronoiComponent } from './root/voronoi/voronoi.component';
import { NewDestinationComponent } from './root/new-destination/new-destination.component';
import { ShapefileImportComponent } from './root/trail-manager/shapefile-import/shapefile-import.component';
import { ExportFacilitydbComponent } from './root/trail-manager/export-facilitydb/export-facilitydb.component';
import { TrailsImportComponent } from './root/trail-manager/trails-import/trails-import.component';
import { PoiCategoriesComponent } from './root/poiManager/poi-categories/poi-categories.component';
import { EditPoiCategoryComponent } from './root/poiManager/poi-categories/edit-poi-category/edit-poi-category.component';
import { PoiValidatorComponent } from './root/poiManager/poi-validator/poi-validator.component';
import { PoiImportComponent } from './root/poiManager/poi-import/poi-import.component';
import { RtwEventsComponent } from './root/rtw/rtw-events/rtw-events.component';
import { RtwEventParticipantsComponent } from './root/rtw/rtw-event-participants/rtw-event-participants.component';
import { EditRtwEventComponent } from './root/rtw/edit-rtw-event/edit-rtw-event.component';
import { CreateRtwEventComponent } from './root/rtw/create-rtw-event/create-rtw-event.component';
import { RidesComponent } from './root/rides/rides.component';
import { RideComponent } from './root/rides/ride/ride.component';
import { MediaLibraryComponent } from './root/media-library/media-library.component';
import { EmailExtractComponent } from './root/email-extract/email-extract.component';
import { TreatsComponent } from './root/treats/treats.component';
import { TreatEditComponent } from './root/treats/treat-edit/treat-edit.component';
import { SponsorComponent } from './root/sponsor/sponsor.component';
import { SponsorEditComponent } from './root/sponsor/sponsor-edit/sponsor-edit.component';
import {RoleManagerComponent} from "./root/role-manager/role-manager.component";
import {EventManagersComponent} from "./root/role-manager/event-managers/event-managers.component";
// event
import { EventManagerDashboardComponent } from "./event/event-manager-dashboard/event-manager-dashboard.component";
// trail-center
import {TrailCenterDashboardComponent} from "./trail-center/trail-center-dashboard/trail-center-dashboard.component";
import {DevToolsComponent} from "./root/dev-tools/dev-tools.component";
import {DmoManagersComponent} from "./root/role-manager/dmo-managers/dmo-managers.component";
import {CountryManagersComponent} from "./root/role-manager/country-managers/country-managers.component";
import {
    DestinationManagersComponent
} from "./root/role-manager/destination-managers/destination-managers.component";
import {TrailAreaManagersComponent} from "./root/role-manager/trail-area-managers/trail-area-managers.component";
import {or} from "@angular/fire/firestore";

const routes: Routes = [
    {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard]},
    {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
    {path: 'country-manager/:countryCode/trail-areas-admin', component: CountryTrailAreasAdminComponent, canActivate: [countryManagerGuard]},
    {path: 'country-manager/:countryCode/new-trail-area', component: CountryNewTrailAreaComponent, canActivate: [countryManagerGuard]},
    {path: 'country-manager/:countryCode/adventures', component: CountryAdventuresComponent, canActivate: [eventManagerGuard]},
    {path: 'country-manager/:countryCode/adventures/:adventureKey', component: CountryAdventureEditComponent, canActivate: [eventManagerGuard]},
    {
        path: 'country-manager/:countryCode/adventures/:adventureKey/explorers',
        component: CountryExplorersComponent,
        canActivate: [eventManagerGuard]
    },
    {
        path: 'country-manager/:countryCode/adventures/:adventureKey/item/:adventureItemKey',
        component: CountryAdventureItemEditComponent,
        canActivate: [eventManagerGuard]
    },
    {path: 'country-manager/:countryCode/contact-responsible', component: CountryContactResponsibleComponent, canActivate: [countryManagerGuard]},
    {path: 'destination/:destinationKey/settings', component: DestinationComponent, canActivate: [destinationManagerGuard]},
    {path: 'destination/:destinationKey/trail-areas', component: DestinationTrailAreasComponent, canActivate: [destinationManagerGuard]},
    {path: 'destination/:destinationKey/adventures', component: DestinationAdventuresComponent, canActivate: [eventManagerGuard]},
    {path: 'destination/:destinationKey/adventures/:adventureKey', component: DestinationAdventureEditComponent, canActivate: [eventManagerGuard]},
    {
        path: 'destination/:destinationKey/adventures/:adventureKey/explorers',
        component: DestinationAdventureExplorersComponent,
        canActivate: [eventManagerGuard]
    },
    {
        path: 'destination/:destinationKey/adventures/:adventureKey/item/:adventureItemKey',
        component: DestinationAdventureItemEditComponent,
        canActivate: [eventManagerGuard]
    },
    {path: 'trail-area/:key/settings', component: TrailAreaComponent, canActivate: [trailAreaManagerGuard]},
    {path: 'trail-area/:key/trails', component: TrailsComponent, canActivate: [trailAreaManagerGuard]},
    {path: 'trail-area/:areaKey/trails/:trailKey', component: TrailEditComponent, canActivate: [trailAreaManagerGuard]},
    {path: 'trail-area/:key/pois', component: PoisComponent, canActivate: [dmoManagerGuard]},
    {
        path: 'trail-area/:areaKey/pois/:poiKey',
        component: PoiEditComponent,
        canActivate: [dmoManagerGuard],
        canDeactivate: [SavedAndPublishedGuard]
    },
    {path: 'trail-area/:trailAreaKey/news-stories', component: TrailAreaNewsComponent, canActivate: [trailAreaManagerGuard]},
    {path: 'trail-area/:trailAreaKey/news-stories/drafts', component: TrailAreaDraftsComponent, canActivate: [trailAreaManagerGuard]},
    {path: 'trail-area/:trailAreaKey/news-stories/drafts/:newsStoryKey', component: TrailAreaEditDraftComponent, canActivate: [trailAreaManagerGuard]},
    {path: 'trail-area/:trailAreaKey/news-stories/stories', component: TrailAreaStoriesComponent, canActivate: [trailAreaManagerGuard]},
    {
        path: 'trail-area/:trailAreaKey/news-stories/stories/:newsStoryKey',
        component: TrailAreaModifyStoryComponent,
        canActivate: [trailAreaManagerGuard]
    },
    {path: 'trail-area/:trailAreaKey/news-stories/archive', component: TrailAreaStoryArchiveComponent, canActivate: [trailAreaManagerGuard]},
    {path: 'trail-area/:areaKey/adventures', component: TrailAreaAdventuresComponent, canActivate: [eventManagerGuard]},
    {path: 'trail-area/:areaKey/adventures/:adventureKey', component: TrailAreaAdventureEditComponent, canActivate: [eventManagerGuard]},
    {path: 'trail-area/:areaKey/adventures/:adventureKey/explorers', component: TrailAreaExplorersComponent, canActivate: [eventManagerGuard]},
    {
        path: 'trail-area/:areaKey/adventures/:adventureKey/item/:adventureItemKey',
        component: TrailAreaAdventureItemEditComponent,
        canActivate: [eventManagerGuard]
    },
    {path: 'trail-area/:trailAreaKey/area-managers', component: AreaManagersComponent, canActivate: [trailAreaManagerGuard]},
    {path: 'trail-area/:trailAreaKey/ride-statistics', component: RideStatisticsComponent, canActivate: [trailAreaManagerGuard]},
    {path: 'organisation/:organisationKey/membership', component: MembershipComponent, canActivate: [organisationManagerGuard]},
    {path: 'organisation/:organisationKey/members', component: MembersComponent, canActivate: [organisationManagerGuard]},
    {path: 'on-trail/status', component: OnTrailStatusComponent, canActivate: [onTrailManagerGuard]},
    {path: 'on-trail/application/:applicationKey', component: OnTrailApplicationComponent, canActivate: [onTrailManagerGuard]},
    {path: 'on-trail/contacts', component: OnTrailContactsComponent, canActivate: [onTrailManagerGuard]},
    {path: 'trail-center/trail-center-dashboard', component: TrailCenterDashboardComponent, canActivate: [trailCenterManagerGuard]},
    {path: 'root/dashboard', component: RootDashboardComponent, canActivate: [adminGuard]},
    {path: 'root/kiosk-orders', component: KioskOrdersComponent, canActivate: [adminGuard]},
    {path: 'root/news-room', component: NewsRoomComponent, canActivate: [adminGuard]},
    {path: 'root/news-room/drafts', component: NewsRoomDraftsComponent, canActivate: [adminGuard]},
    {path: 'root/news-room/drafts/:newsStoryKey', component: NewsRoomEditDraftComponent, canActivate: [adminGuard]},
    {path: 'root/news-room/stories', component: NewsRoomStoriesComponent, canActivate: [adminGuard]},
    {path: 'root/news-room/stories/:newsStoryKey', component: NewsRoomModifyStoryComponent, canActivate: [adminGuard]},
    {path: 'root/news-room/archive', component: NewsRoomArchiveComponent, canActivate: [adminGuard]},
    {path: 'root/news-room/welcome', component: NewsRoomWelcomeComponent, canActivate: [adminGuard]},
    {path: 'root/premium/product', component: PremiumProductComponent, canActivate: [adminGuard]},
    {path: 'root/premium/members', component: PremiumMembersComponent, canActivate: [adminGuard]},
    {path: 'root/countries-manager/voronoi', component: VoronoiComponent, canActivate: [adminGuard]},
    {path: 'root/destinations-manager/new-destination', component: NewDestinationComponent, canActivate: [adminGuard]},
    {path: 'root/trail-manager/shapefile-import', component: ShapefileImportComponent, canActivate: [adminGuard]},
    {path: 'root/trail-manager/export-facilitydb', component: ExportFacilitydbComponent, canActivate: [adminGuard]},
    {path: 'root/trail-manager/import-trails', component: TrailsImportComponent, canActivate: [adminGuard]},
    {path: 'root/poi-manager/poi-categories', component: PoiCategoriesComponent, canActivate: [adminGuard]},
    {
        path: 'root/poi-manager/poi-categories/:poiCategoryLabel/edit',
        component: EditPoiCategoryComponent,
        canActivate: [adminGuard],
        canDeactivate: [SavedAndPublishedGuard]
    },
    {path: 'root/poi-manager/poi-validator', component: PoiValidatorComponent, canActivate: [adminGuard]},
    {
        path: 'root/poi-manager/poi-validator/:poiKey',
        component: PoiEditComponent,
        canActivate: [adminGuard],
        canDeactivate: [SavedAndPublishedGuard]
    },
    {path: 'root/poi-manager/poi-import', component: PoiImportComponent, canActivate: [adminGuard]},
    {path: 'root/rtw/events/list', component: RtwEventsComponent, canActivate: [adminGuard]},
    {path: 'root/rtw/events/:rtwEventKey/participants', component: RtwEventParticipantsComponent, canActivate: [adminGuard]},
    {path: 'root/rtw/events/:rtwEventKey/edit', component: EditRtwEventComponent, canActivate: [adminGuard]},
    {path: 'root/rtw/events/create', component: CreateRtwEventComponent, canActivate: [adminGuard]},
    {path: 'root/rides', component: RidesComponent, canActivate: [adminGuard]},
    {path: 'root/rides/ride/:rideKey', component: RideComponent, canActivate: [adminGuard]},
    {path: 'root/mediaLibrary', component: MediaLibraryComponent, canActivate: [adminGuard]},
    {path: 'root/emailExtract', component: EmailExtractComponent, canActivate: [adminGuard]},
    {path: 'root/treats', component: TreatsComponent, canActivate: [adminGuard]},
    {path: 'root/treats/:treatKey', component: TreatEditComponent, canActivate: [adminGuard]},
    {path: 'root/sponsor', component: SponsorComponent, canActivate: [adminGuard]},
    {path: 'root/sponsor/:sponsorKey', component: SponsorEditComponent, canActivate: [adminGuard]},
    {path: 'root/role-manager', component: RoleManagerComponent, canActivate: [adminGuard]},
    {path: 'event-manager-dashboard', component: EventManagerDashboardComponent, canActivate: [eventManagerGuard]},
    {path: 'root/role-manager/event-managers', component: EventManagersComponent, canActivate: [adminGuard]},
    {path: 'root/dev-tools', component: DevToolsComponent, canActivate: [adminGuard]},
    {path: 'root/role-manager/dmo-managers', component: DmoManagersComponent, canActivate: [adminGuard]},
    {path: 'root/role-manager/country-managers', component: CountryManagersComponent, canActivate: [adminGuard]},
    {path: 'root/role-manager/destination-managers', component: DestinationManagersComponent, canActivate: [adminGuard]},
    {path: 'root/role-manager/trail-area-managers', component: TrailAreaManagersComponent, canActivate: [adminGuard]},

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
