import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

// Services
import { AuthService } from '../../core/auth.service';
import { LayoutService } from '../../core/layout.service';
import { MediaLibraryService } from '../../firebase-services/media-library.service';
import { PoiService } from '../../firebase-services/poi.service';
import { PoiCategoryService } from '../../firebase-services/poi-category.service';
import { PoiStateService } from '../../services/poi-state.service';
import { CountryService } from '../../firebase-services/country.service';

// Interfaces
import { PublishedStates, StatesBundle } from '../../interfaces/general';
import { CreateOnMap } from '../../interfaces/map';
import { MediaIcon } from '../../interfaces/media-library';
import { DraftPoi, PublicPoi } from '../../interfaces/poi';
import { PublicPoiCategory } from '../../interfaces/poi-category';
import {Roles} from "../../interfaces/role";
import {Country} from "../../interfaces/countries";

declare var $: any;

@Component({
    selector: 'app-country-pois',
    templateUrl: './country-pois.component.html',
    styleUrls: ['./country-pois.component.css']
})
export class CountryPoisComponent implements OnInit, AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    country: Country = null;
    allPois: DraftPoi[] = null;
    missingPois: DraftPoi[] = null;
    pois: DraftPoi[] = null;
    poiStates: StatesBundle = {};
    firstTimeLoadingPois = true;

    // The key of the selected POI.
    selectedPoiKey: string = null;

    removePoiCandidate: DraftPoi = null;

    private mediaIcons: MediaIcon[] = null;
    private publicPoiCategories$: Observable<{ [label: string]: PublicPoiCategory }>;
    roles = Roles;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public authService: AuthService,
        public layout: LayoutService,
        private mediaLibraryService: MediaLibraryService,
        private poiService: PoiService,
        private poiCategoryService: PoiCategoryService,
        private countryService: CountryService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initialise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
        this.publicPoiCategories$ = this.poiCategoryService.getPublicPoiCategories()
            .pipe(takeUntil(this.destroy$));
    }

    ngOnInit() {
        if ((this.authService.isUser(this.roles.ADMIN) || this.authService.isUser(this.roles.POI_MANAGER)) && this.missingPois === null) {
            this.selectedPoiKey = null;
            this.loadAllPois();
        }
        this.loadMediaIcons();
    }

    ngAfterViewChecked() {
        this.createSelect2();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


    /**
     * Load data for the current country
     */
    init() {
        this.country = null;
        this.removePoiCandidate = null;
        this.getCountry(this.route.snapshot.paramMap.get('key'));
    }

    addPoiToCountry() {
        this.poiService.addPoiToCountry(this.selectedPoiKey, this.country.countryCode)
            .then(() => this.selectedPoiKey = null)
            .catch(err => {
                console.error(err);
                alert('An error happened. Check the console');
            });
    }

    setRemovePoiCandidate(poi: DraftPoi) {
        this.removePoiCandidate = poi;
        $('#modal-remove').modal('show');
    }

    removePoiFromCountry(poiKey: string) {
        this.poiService.removePoiFromCountry(poiKey, this.country.countryCode)
            .then(() => {
                this.removePoiCandidate = null;
                $('#modal-remove').modal('hide');
            });
    }

    public setSelectedPoi(key: string) {
        this.selectedPoiKey = key;
    }

    createNewPoi(newEventOnMap: CreateOnMap): Promise<boolean> {
        const newPoi: DraftPoi = {
            name: newEventOnMap.name,
            latitude: newEventOnMap.lat,
            longitude: newEventOnMap.lng,
            geohash: newEventOnMap.geohash,
            active: false,
            iconUrl: this.mediaIcons[0].pngUrl,
            mediaIconKey: this.mediaIcons[0].key
        };
        return this.poiService.createCountryPoi(this.country.countryCode, newPoi)
            .then((newKey) => this.router.navigate(['/country/' + this.country.countryCode + '/country-pois/' + newKey]));
    }

    private loadAllPois() {
        this.poiService.getAllPois()
            .pipe(take(1))
            .subscribe((allPois) => {
                this.allPois = allPois;
                this.setMissingPois();
            });
    }

    private setMissingPois() {
        if (this.allPois === null || this.pois === null) {
            return;
        }
        const missingPois: DraftPoi[] = [];
        this.allPois.forEach((allPoi) => {
            let found = false;
            for (const i in this.pois) {
                if (this.pois[i].key === allPoi.key) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                missingPois.push(allPoi);
            }
        });
        missingPois.sort((a, b) => this.layout.nameSort(a, b));
        this.missingPois = missingPois;
    }

    private createSelect2() {
        if (this.missingPois !== null && this.firstTimeLoadingPois) {
            this.firstTimeLoadingPois = false;
            const selectPoiElement = $('#selectPoi');
            // Enable search in dropdown.
            selectPoiElement.select2({
                theme: 'bootstrap4',
                placeholder: 'Select an existing POI'
            });

            // Set the selected value in the select correctly.
            selectPoiElement.on('select2:select', (event: any) => this.setSelectedPoi(event.params.data.id));
            // Select nothing to display placeholder
            selectPoiElement.val(null).trigger('change');
        }
    }

    private getCountry(countryCode: string): void {
        this.countryService.getCountry(countryCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe((country) => {
                this.country = country;
            });
        this.poiService.getPoisForCountry(countryCode)
            .pipe(takeUntil(this.destroy$))
            .subscribe((pois) => {
                pois.forEach((poi) => {
                    this.poiStates[poi.key] = {
                        altered: false,
                        canPublish: false,
                        icon: 'fas fa-sync-alt fa-spin',
                        isPublished: false,
                        outOfSync: false,
                        progress: PublishedStates.DRAFT_ALTERED,
                        theme: 'warning'
                    };
                    this.loadPoiState(poi);
                });

                this.pois = pois.sort((a, b) => this.layout.nameSort(a, b));

                this.setMissingPois();
            });
    }

    loadPoiState(poi: DraftPoi) {
        const publicPoi$ = this.poiService.getPublicPoi(poi.key)
            .pipe(takeUntil(this.destroy$));
        combineLatest([publicPoi$, this.publicPoiCategories$])
            .subscribe(([publicPoi, publicPoiCategories]) => {
                PoiStateService.updateState<DraftPoi, PublicPoi>(
                    poi, publicPoi, this.poiStates[poi.key], publicPoiCategories, poi.active);
            });
    }

    private loadMediaIcons() {
        this.mediaLibraryService.getMediaIcons()
            .pipe(takeUntil(this.destroy$))
            .subscribe((mediaIcons) => this.mediaIcons = mediaIcons);
    }
}
