import {TrailArea} from "./trailArea";

export const MINIMUM_TIME_SLOT_MINUTES = 15;
export const MINIMUM_TIME_SLOT_MILLISECONDS = MINIMUM_TIME_SLOT_MINUTES * 60000;

export interface Sponsor {
    key?: string;
    alias?: string;
    link?: string;
    activeFrom?: number;
    activeTo?: number;
    imageURL?: string;
    imageWidth?: number;
    imageHeight?: number;
    sponsorType: SponsorTargetType;
    autoRenewable?: Boolean;
    longitude?: number;
    latitude?: number;
    radius?: number;
    country?: string;
    trailTargetKeys?: Array<string>;
    countryFilter?: string;
    isMainSponsor?: boolean;
    areas?: TrailArea[];
}

export enum SponsorTargetType {
    NO_SPONSOR_TARGET_TYPE_SELECTED = 'noSponsorTargetTypeSelected',
    TRAIL_AREA_SPECIFIC_SPONSOR = 'trailAreaSpecificSponsor',
    AREA_SPECIFIC_SPONSOR = 'areaSpecificSponsor',
    COUNTRY_SPECIFIC_SPONSOR = 'countrySpecificSponsor'
}

// optional: Record type annotation guaranties that 
// all the values from the enum are presented in the mapping
export const SponsorTargetType2LabelMapping: Record<SponsorTargetType, string> = {
    [SponsorTargetType.NO_SPONSOR_TARGET_TYPE_SELECTED]: "No type chosen",
    [SponsorTargetType.TRAIL_AREA_SPECIFIC_SPONSOR]: "Sponsorship for trail area",
    [SponsorTargetType.AREA_SPECIFIC_SPONSOR]: "Sponsorship for geographic area",
    [SponsorTargetType.COUNTRY_SPECIFIC_SPONSOR]: "Sponsorship for country",
};

