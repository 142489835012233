import {Component, OnDestroy} from '@angular/core';
import {Group, GroupMember, GroupMemberStates} from "../../interfaces/group";
import {PressCampService} from "../press-camp.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {LayoutService} from "../../core/layout.service";
import {Meetup} from "../../interfaces/meetup";
import {AuthService} from "../../core/auth.service";

@Component({
    selector: 'app-press-camp-overview',
    templateUrl: './press-camp-overview.component.html',
    styleUrl: './press-camp-overview.component.css'
})
export class PressCampOverviewComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    pressCampGroup: Group;
    pressCampMeetups: Meetup[] = [];
    deletingPressCamp = false;
    creatingMeetup = false;
    members: GroupMember[];
    imageLoaded = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public layout: LayoutService,
        private authService: AuthService,
        private pressCampService: PressCampService,
    ) {
        const pcKey = this.route.snapshot.paramMap.get('pressCampKey');
        this.pressCampService.getPressCampGroup(pcKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((group) => {
                this.pressCampGroup = group;
                this.pressCampGroup.groupKey = pcKey;
            });
        this.pressCampService.getPressCampMeetups(pcKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((meetups) => {
                this.pressCampMeetups = meetups;
            });
        this.pressCampService.getPressCampGroupMembers(pcKey)
            .pipe(takeUntil(this.destroy$)).subscribe((membersList) => {
            this.members = membersList.sort((a, b) => {
                const stateOrder = {
                    [GroupMemberStates.STATE_JOINED]: 1,
                    [GroupMemberStates.STATE_INVITED]: 2
                };
                return stateOrder[a.state] - stateOrder[b.state];
            });
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    deletePressCamp(group: Group): void {
        this.deletingPressCamp = true;
        this.pressCampService.deletePressCampGroup(group).then(() => {
            this.router.navigate(["/press-camp/press-camp-manager-dashboard/"]);

        });
    }

    createNewPressCampMeetup(): void {
        this.creatingMeetup = true;
        this.pressCampService.createNewPressCampMeetup(this.pressCampGroup.groupKey).then((meetupKey) => {
            this.router.navigate(["/press-camp/press-camp-meetup-edit/" + meetupKey]);
        });
    }

    deletePressCampMeetup(meetup: Meetup) {
        this.pressCampService.deletePressCampMeetup(meetup).then((success) => {
            if (!success) window.alert('Something went wrong while deleting meetup, please try again.');
        });
    }
}
