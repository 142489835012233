export interface Group {
    groupKey: string,
    name: string;
    description: string;
    isPrivate: boolean;
    ownerId: string;
    memberCount: number;
    coverImgUrl: string;
    geohash: string;
    applicationNeeded: boolean;
}

export interface GroupMember {
    name: string;
    imageUrl: string;
    role: GroupMemberRoles;
    state: GroupMemberStates;
    application?: boolean;
    profileKey?: string;
}

export interface GroupMembersAction {
    groupKey: string;
    action: GroupMemberActions;
    whoKeys: string[];
}

export enum GroupMemberStates {
    STATE_JOINED = 'joined',
    STATE_INVITED = 'invited'
}

export enum GroupMemberRoles {
    ROLE_OWNER = 'owner',
    ROLE_MEMBER = 'member',
    ROLE_OUTSIDER = 'outsider'
}

export enum GroupMemberActions {
    ACTION_INVITE = 'invite',
    ACTION_JOIN = 'join',
    ACTION_LEAVE = 'leave'
}
