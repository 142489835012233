<div class="card card-outline card-success" *ngIf="events">
    <div class="card-header">
        <h3 class="card-title">Events</h3>
    </div>
    <div class="card-body">
        <ul class="todo-list">
            <li *ngFor="let event of filteredEvents">
                <i class="fas fa-eye-slash" *ngIf="!event.active" title="Deactivated"></i>
                <i class="far fa-compass" *ngIf="event.active"></i>
                {{ event.name }}
                <div class="tools">
                    <a *ngIf="event.explorerCount > 0"
                       title="View the {{ event.explorerCount }} explorers of the '{{event.name}}'-event"
                       routerLink="{{ routerRoot }}/events/{{ event.key }}/explorers"><i class="fas fa-biking mr-2"></i></a>
                    <a routerLink="{{ routerRoot }}/events/{{ event.key }}"><i class="far fa-edit"
                                                                                   title="Edit the '{{ event.name }}'-event"></i></a>
                </div>
            </li>
        </ul>
    </div>
</div>
