import { Component, Input } from '@angular/core';

import { Event } from '../../interfaces/event';
import {AuthService} from "../../core/auth.service";

@Component({
    selector: 'app-event-list',
    templateUrl: './event-list.component.html',
    styleUrls: ['./event-list.component.css']
})
export class EventListComponent {

    @Input() routerRoot: string;
    @Input() events: Event[];

    constructor(
        private authService: AuthService
    ) {
    }

    get filteredEvents(): Event[] {
        return this.events.filter(event => {
            if(event.creatorKey !== null) {
                return event.creatorKey === this.authService.user.userID
            }
        });
    }
}
