import {AfterViewChecked, Component, EventEmitter, Input, Output} from '@angular/core';
import {take} from 'rxjs/operators';
import {Destination} from "../../interfaces/destination";
import {DestinationService} from "../../firebase-services/destination.service";

declare var $: any;

@Component({
    selector: 'app-find-destination',
    templateUrl: './find-destination.component.html',
    styleUrls: ['./find-destination.component.css']
})
export class FindDestinationComponent implements AfterViewChecked {
    @Input() modalId: string;
    @Input() modalTitle: string;
    @Input() btnClassType: string;
    @Output() destinationSelected = new EventEmitter<string>();

    SEARCH_TIMEOUT = 1000;
    searchTimeout: number;
    lastSearchTerm = '';
    disableSearch = false;
    searchInput: HTMLInputElement = null;
    foundDestinations: Destination[] = [];

    constructor(
        private destinationService: DestinationService
    ) {
    }

    ngAfterViewChecked() {
        if (this.searchInput === null) {
            this.searchInput = <HTMLInputElement>document.getElementById('searchForDestinations' + this.modalId);
        }
    }

    focusInSearch(): void {
        window.setTimeout(() => this.searchInput.focus(), 500);
    }

    searchForDestinations(): void {
        window.clearTimeout(this.searchTimeout);
        const searchTerm: string = this.searchInput.value;
        if (searchTerm === this.lastSearchTerm || searchTerm.length < 3) {
            return;
        }
        this.searchTimeout = window.setTimeout(() => this.startDatabaseSearch(searchTerm), this.SEARCH_TIMEOUT);
    }

    startDatabaseSearch(searchTerm: string): void {
        if (this.disableSearch) {
            return;
        }
        this.disableSearch = true;
        this.lastSearchTerm = searchTerm;
        const countries$ = this.destinationService.searchByName(searchTerm);
        countries$
            .pipe(take(1))
            .subscribe((destinations) => {
                this.foundDestinations = destinations;
                this.disableSearch = false;
                window.setTimeout(() => this.searchInput.focus(), 0);
            });
    }

    selectDestination(destinationKey: string): void {
        this.destinationSelected.emit(destinationKey);
        $('#findDestinationModal_' + this.modalId).modal('hide');
    }

    copyText(text: string): Promise<void> {
        return navigator.clipboard.writeText(text);
    }
}
