import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { TreatService } from '../../../firebase-services/treat.service';

// Interfaces
import { MINIMUM_TIME_SLOT_MINUTES, Treat } from '../../../interfaces/treat';
import { TextModel, TextModelItemType, TextObject } from '../../../interfaces/text-model';

import * as moment from 'moment';

declare var $: any;

@Component({
    selector: 'app-treat-edit',
    templateUrl: './treat-edit.component.html',
    styleUrls: ['./treat-edit.component.css']
})
export class TreatEditComponent implements OnInit, AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    textModel: TextModel = {items: []};

    treat: Treat = null;

    settingsChangedInfo = 0;
    textsChangedInfo = 0;
    disableSaveSettings = true;

    activePeriodIsLoaded = false;
    activePeriodName = '#activePeriod';
    timeFormat = 'DD-MM-YYYY HH:mm';

    constructor(
        private route: ActivatedRoute,
        private treatService: TreatService
    ) {
        this.initTextModel();
    }

    ngOnInit() {
        this.disableSaveSettings = true;
        this.treatService.getTreat(this.route.snapshot.paramMap.get('treatKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((treat) => this.treat = treat);
    }

    ngAfterViewChecked() {
        if (this.treat !== null && !this.activePeriodIsLoaded && $(this.activePeriodName).length > 0) {
            const treatEditComponent = this;
            $(function () {
                $(treatEditComponent.activePeriodName).daterangepicker(
                    {
                        timePicker: true,
                        timePicker24Hour: true,
                        timePickerIncrement: MINIMUM_TIME_SLOT_MINUTES,
                        startDate: moment(treatEditComponent.treat.activeFrom),
                        endDate: moment(treatEditComponent.treat.activeTo),
                        locale: {
                            format: treatEditComponent.timeFormat
                        }
                    },
                    function (start: moment.Moment, end: moment.Moment) {
                        treatEditComponent.treat.activeFrom = start.unix() * 1000;
                        treatEditComponent.treat.activeTo = end.unix() * 1000;
                    }
                );
            });
            this.activePeriodIsLoaded = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    initTextModel(): void {
        this.textModel.items.push({
            name: 'Creator Name',
            varName: 'creatorName',
            help: 'Name displayed in top of treat (If left empty, "Mountain Bike United" will be used)',
            placeholder: 'Awesome Explorer Shop',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Title',
            varName: 'topOfMindTitle',
            help: 'The treat title - keep it very short.',
            placeholder: 'Adventurous title',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Description',
            varName: 'topOfMindSubHeader',
            help: 'The sub title or description of the treat.',
            placeholder: 'This treat will give you more event',
            type: TextModelItemType.TEXT_AREA
        });
        this.textModel.items.push({
            name: 'Link',
            varName: 'link',
            help: 'Web page to link to.',
            placeholder: 'www.mtbutd.com/treatTargetURL',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Call To Action Header',
            varName: 'callToActionHeader',
            help: 'Header for call to action footer',
            placeholder: 'Visit sponsor',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Call To Action Sub Header',
            varName: 'callToActionSubHeader',
            help: 'Sub Header for call to action footer',
            placeholder: 'Explore the great offers of our excellent sponsor',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'Call To Action Button Text',
            varName: 'callToActionButtonText',
            help: 'Text displayed on Button in call to action footer',
            placeholder: 'More',
            type: TextModelItemType.INPUT
        });
    }

    /**
     * Sets state-variable when settings are changed
     */
    settingsChanged(): void {
        this.disableSaveSettings = false;
    }

    /**
     * Save settings
     */
    saveSettings(): void {
        this.disableSaveSettings = true;
        this.treatService.updateSettings(this.treat)
            .then(() => this.settingsChangedInfo++)
            .catch((err) => console.error('Settings-Update error occurred:', err.message));
    }

    onAlteredTextObject(alteredTextObject: TextObject): void {
        this.treatService.updateTexts(<Treat>alteredTextObject)
            .then(() => this.textsChangedInfo++)
            .catch((err) => console.error('Text-Update error occurred:', err.message));
    }

    onUploadedImageUrl(uploadedImageUrl: string): void {
        this.treat.imageURL = uploadedImageUrl;
        this.treatService.uploadedImage(this.treat)
            .catch((err) => console.error('Image upload failure', err));
    }

    onUploadedLogoImageUrl(uploadedLogoImageUrl: string): void {
        this.treat.logoURL = uploadedLogoImageUrl;
        this.treatService.uploadedLogoImage(this.treat)
            .catch((err) => console.error('Image upload failure', err));
    }
}
