import {Component, OnDestroy} from '@angular/core';
import {PressCampService} from "../press-camp.service";
import {Router} from "@angular/router";
import {Group} from "../../interfaces/group";
import {PressCampManagerService} from "../../root/role-manager/press-camp-managers/press-camp-manager.service";
import {Subject} from "rxjs";
import {AuthService} from "../../core/auth.service";
import {map, takeUntil} from "rxjs/operators";
import {LayoutService} from "../../core/layout.service";

@Component({
    selector: 'app-press-camp-manager-dashboard',
    templateUrl: './press-camp-manager-dashboard.component.html',
    styleUrl: './press-camp-manager-dashboard.component.css'
})
export class PressCampManagerDashboardComponent implements OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    pressCamps: Group[];
    creatingGroup = false;

    constructor(
        private router: Router,
        public layout: LayoutService,
        private authService: AuthService,
        private pressCampManagerService: PressCampManagerService,
        private pressCampService: PressCampService
    ) {
        this.pressCampManagerService.getPressCampManagerGroups(this.authService.user.userID)
            .pipe(
                takeUntil(this.destroy$),
                map((groupList) => {
                    this.pressCamps = groupList;
                })
            ).subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    createNewPressCamp(): void {
        this.creatingGroup = true;
        this.pressCampService.createNewPressCampGroup().then((pressCampKey) => {
            this.router.navigate(["/press-camp/press-camp-group-edit/" + pressCampKey]);
        });
    }
}
