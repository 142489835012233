<div class="content-wrapper">
    <app-content-header-section contentPageId="roleManager" [pageParams]="{ }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3">
                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-user-check"></i> Create New Role</h3>
                        </div>
                        <div class="card-body">
                            <app-alert-info-box infoTrigger="{{ stateCreateRole }}">
                                <h4>Role saved successfully</h4>
                            </app-alert-info-box>
                            <div class="form-group">
                                <label for="newRoleNameField"><i class="fas fa-user-tie"></i> Role Name</label>
                                <div class="input-group">
                                    <input class="form-control" [(ngModel)]="newRoleName"
                                           aria-describedby="createRoleNameHelp"
                                           placeholder="Enter Name" id="newRoleNameField"
                                           (keyup)="onNewRoleNameChanged()"
                                           (change)="onNewRoleNameChanged()">
                                </div>
                                <small class="form-text text-muted" id="createRoleNameHelp"> Choose a name for your new
                                    role.</small>
                            </div>
                            <div class="form-group">
                                <label for="createRoleDescriptionField"><i class="far fa-user"></i> Role
                                    description</label>
                                <div class="input-group">
                                    <textarea class="form-control" [(ngModel)]="newRoleDescription"
                                              aria-describedby="createRoleDescriptionHelp" rows="3"
                                              placeholder="Enter Description" id="createRoleDescriptionField"
                                              (keyup)="onNewRoleDescriptionChanged()"
                                              (change)="onNewRoleDescriptionChanged()"></textarea>
                                </div>
                                <small class="form-text text-muted" id="createRoleDescriptionHelp"> Describe what a
                                    role can do or access.</small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="saveNewRoleDisabled" type="submit" class="btn btn-primary"
                                    (click)="saveNewRole()">Save
                            </button>&nbsp;
                            <button type="reset" class="btn btn-default" (click)="resetCreateRole()">
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card card-outline card-warning">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-user-secret"></i> Administrators
                            </h3>
                        </div>
                        <div class="card-body">
                            <ul class="todo-list">
                                <li class="primaryListItem" *ngFor="let admin of adminUsers">
                                    <img class="img-fluid img-circle img-sm"
                                         src="{{ admin.userPicture }}" alt="">
                                    <span class="text">{{ admin.name }}</span>
                                    <small class="badge badge-default">{{ admin.userID }}</small>
                                    <div class="tools">
                                        <i *ngIf="authService.isUser(roles.ADMIN)"
                                           title="Remove user as administrator"
                                           (click)="revokeAdminUser(admin.userID)"
                                           class="far fa-trash-alt mr-2"></i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="card-footer">
                            <app-find-user modalId="assignRole"
                                           modalTitle="Find user to assign as administrator" id="selectRoleUser"
                                           (userSelected)="assignAdminUser($event)" btnClassType="success">Add admin
                            </app-find-user>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-user-tie"></i> Roles
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <ul class="todo-list">
                                    <li class="primaryListItem" *ngFor="let role of assignableRoles">
                                        <span class="text">{{ role.name }}</span>
                                        <small class="badge badge-default">{{ role.id }}</small>
                                        <div class="floatRightTools" *ngIf="role.id !== 'admin'">
                                            <button class="btn btn-s">
                                                <i title="Go to {{role.name}}s"
                                                   routerLink="{{ getManagersRouterLink(role.id) }}"
                                                   class="fas fa-users-cog mr-2 blue"></i>
                                            </button>
                                        </div>
                                        <ul class="secondaryList" *ngIf="role.description">
                                            <li class="secondaryListItem">
                                                <span class="text-secondary">{{ role.description }}</span>
<!--                                                <div class="tools" *ngIf="authService.isUser(roles.ADMIN)">-->
<!--                                                    <app-edit-role-description modalId="{{ role.id }}"-->
<!--                                                                               modalTitle="Edit {{ role.name }} description"-->
<!--                                                                               oldRoleDesc="{{ role.description }}"-->
<!--                                                                               btnClassType="sm"-->
<!--                                                                               (newRoleDescription)="updateRoleDescription(role.id, $event)">-->
<!--                                                        <i title="Edit role description"-->
<!--                                                           class="far fa-edit mr-2 blue"></i>-->
<!--                                                    </app-edit-role-description>-->
<!--                                                </div>-->
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
