import {Injectable} from '@angular/core';
import firebase from "firebase/compat";
import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from "@angular/fire/compat/database";
import {UserService} from "../../../firebase-services/user.service";
import {TrailAreaService} from "../../../firebase-services/trail-area.service";
import {combineLatest, forkJoin, Observable, of} from "rxjs";
import {map, switchMap, take} from "rxjs/operators";
import {TrailArea} from "../../../interfaces/trailArea";
import {TrailAreaManager, TrailAreaManagerListItem, TrailAreaManagers} from "../../../interfaces/trail-area-manager";
import {User} from "../../../interfaces/user";

@Injectable({
    providedIn: 'root'
})
export class TrailAreaManagerService {
    private trailAreaManagersRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private userService: UserService,
        private trailAreaService: TrailAreaService
    ) {
        this.trailAreaManagersRef = this.db.database.ref('managers/trailAreaManagers')
    }

    getTrailAreaManagersListItems(): Observable<TrailAreaManagerListItem[]> {
        return this.db.list<TrailAreaManager>(this.trailAreaManagersRef.ref).snapshotChanges()
            .pipe(
                switchMap(taSnapshots => forkJoin(
                    taSnapshots.map(taSnapshot => {
                        const taManager: TrailAreaManager = this.trailAreaManagerFromSnap(taSnapshot);
                        return this.userService.getUser(taManager.userKey).pipe(
                            take(1),
                            switchMap(taUser => taManager.trailAreas
                                ? this.trailAreaService.getTrailAreas(taManager.trailAreas).pipe(
                                    take(1),
                                    map(taTrailAreas => ({
                                            user: taUser,
                                            trailAreas: taTrailAreas.sort((a, b) => a.country.localeCompare(b.country))
                                        })
                                    )
                                )
                                : of({user: taUser, trailAreas: []})
                            )
                        );
                    })
                ))
            );
    }

    getTrailAreasManagers(areas: TrailArea[]): Observable<TrailAreaManagers> {
        return this.db.list<TrailAreaManager>(this.trailAreaManagersRef).snapshotChanges()
            .pipe(
                switchMap(snapshots => {
                    const trailAreaManagersObservables = snapshots.map(snapshot => {
                        const trailAreaManagers = this.trailAreaManagerFromSnap(snapshot);
                        return this.userService.getUser(trailAreaManagers.userKey).pipe(
                            take(1),
                            map(user => ({
                                user,
                                trailAreas: trailAreaManagers.trailAreas
                            }))
                        );
                    });
                    return forkJoin(trailAreaManagersObservables).pipe(
                        map(trailAreaManagers => {
                            const result: TrailAreaManagers = {};
                            trailAreaManagers.forEach(tam => {
                                tam.trailAreas.forEach(taKey => {
                                    areas.forEach((area => {
                                        if (area.key === taKey) {
                                            if (!result[taKey]) {
                                                result[taKey] = [];
                                            }
                                            result[taKey].push(tam.user);
                                        }
                                    }));
                                });
                            });
                            return result;
                        })
                    );
                })
            );
    }

    public getTrailAreaManagerTrailAreas(userKey: string): Observable<TrailArea[]> {
        return this.db.list<string>(this.trailAreaManagersRef.child(userKey).child('trailAreas').ref).snapshotChanges()
            .pipe(
                map(taKeys => taKeys.map(taKey => taKey.key)),
                switchMap(trailAreaKeys =>
                    trailAreaKeys.length
                        ? combineLatest(trailAreaKeys.map(key => this.trailAreaService.getTrailArea(key)))
                        : of(null)
                )
            );
    }

    public getManagersForTrailArea(areaKey: string): Observable<User[]> {
        return this.db.list<TrailAreaManager>(this.trailAreaManagersRef.ref).snapshotChanges()
            .pipe(
                map(areaManagerSnapshots =>
                    areaManagerSnapshots
                        .map(m => this.trailAreaManagerFromSnap(m))
                        .filter(manager => manager.trailAreas.includes(areaKey))
                        .map(manager => manager.userKey)
                ),
                switchMap(userKeys =>
                    userKeys.length > 0
                        ? forkJoin(userKeys.map(userKey => this.userService.getUser(userKey).pipe(take(1))))
                        : of([])
                )
            );
    }

    public isUserTrailAreaManager(userKey: string, areaKey: string): Observable<boolean> {
        return this.db.object<string>(this.trailAreaManagersRef.child(userKey).child('trailAreas').child(areaKey).ref)
            .snapshotChanges()
            .pipe(
                take(1),
                map(value => value.payload.exists())
            );
    }

    public getTrailAreaManagersNumberForArea(areaKey: string): Observable<number> {
        return this.db.list<TrailAreaManager>(this.trailAreaManagersRef.ref).snapshotChanges()
            .pipe(
                map(areaManagerSnapshots =>
                    areaManagerSnapshots
                        .map(m => this.trailAreaManagerFromSnap(m))
                        .filter(manager => manager.trailAreas.includes(areaKey))
                        .map(manager => manager.userKey).length
                )
            );
    }

    public addTrailAreaToTrailAreaManager(userKey: string, areaKey: string): Promise<void> {
        return this.trailAreaManagersRef.child(userKey).child('trailAreas').update({[areaKey]: areaKey});
    }

    public removeTrailAreaFromTrailAreaManager(userKey: string, areaKey: string): Promise<void> {
        return this.trailAreaManagersRef.child(userKey).child('trailAreas').child(areaKey).remove();
    }

    private trailAreaManagerFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<TrailAreaManager>>): TrailAreaManager {
        const taManager: TrailAreaManager = snapshot.payload.val();
        taManager.userKey = snapshot.key;
        taManager.trailAreas = Object.values(taManager.trailAreas ?? []);
        return taManager;
    }
}
