import {Component} from '@angular/core';
import {Subject} from "rxjs";
import {Roles} from "../../../interfaces/role";
import {User} from "../../../interfaces/user";
import {AuthService} from "../../../core/auth.service";
import {RoleService} from "../../../core/role.service";
import {takeUntil} from "rxjs/operators";

declare var $: any;

@Component({
    selector: 'app-press-camp-managers',
    templateUrl: './press-camp-managers.component.html',
    styleUrl: './press-camp-managers.component.css'
})
export class PressCampManagersComponent {
    destroy$: Subject<boolean> = new Subject<boolean>();
    roles = Roles;
    pressCampManagers: User[];
    allIsLoaded = false;
    dataTableCreated = false;
    dataTable: any = null;
    managersDataTableName = "#pressCampManagersDataTable";

    constructor(
        public authService: AuthService,
        private roleService: RoleService,
    ) {
        this.roleService.getRoleUsers(this.roles.PRESS_CAMP_MANAGER).pipe(takeUntil(this.destroy$)).subscribe((managers) => {
            this.pressCampManagers = managers;
            this.allIsLoaded = true;
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && $("#pressCampManagersDataTable>tbody>tr").length > 0) {
            this.dataTable = $(this.managersDataTableName).DataTable({
                order: [[0, "asc"]]
            });
            this.dataTableCreated = true;
        }
    }

    assignPressCampManager(userKey: string): Promise<void> {
        return this.roleService.assignUserRole(userKey, this.roles.PRESS_CAMP_MANAGER, this.authService.user.userID).then(() => {
            this.resetDataTable();
        });
    }

    revokePressCampManager(userKey: string): Promise<void> {
        return this.roleService.revokeUserRole(userKey, this.roles.PRESS_CAMP_MANAGER).then(() => {
            this.resetDataTable();
        });
    }

    resetDataTable() {
        this.allIsLoaded = false;
        this.dataTable.destroy();
        this.dataTableCreated = false;
    }
}
