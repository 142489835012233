import {Injectable} from '@angular/core';
import firebase from "firebase/compat";
import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from "@angular/fire/compat/database";
import {combineLatest, forkJoin, Observable, of} from "rxjs";
import {map, switchMap, take} from "rxjs/operators";
import {CountryManager, CountryManagers} from "../../../interfaces/country-manager";
import {UserService} from "../../../firebase-services/user.service";
import {CountryService} from "../../../firebase-services/country.service";
import {Country} from "../../../interfaces/countries";

@Injectable({
    providedIn: 'root'
})
export class CountryManagersService {

    countryManagersRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private userService: UserService,
        private countryService: CountryService
    ) {
        this.countryManagersRef = this.db.database.ref('managers/countryManagers')
    }

    getCountriesManagers(): Observable<CountryManagers> {
        return this.db.list<CountryManager>(this.countryManagersRef).snapshotChanges()
            .pipe(
                switchMap(snapshots => {
                    const countryManagerObservables = snapshots.map(snapshot => {
                        const countryManager = this.countryManagerFromSnap(snapshot);
                        return this.userService.getUser(countryManager.userKey).pipe(
                            take(1),
                            map(user => ({
                                user,
                                countries: countryManager.countries
                            }))
                        );
                    });
                    return forkJoin(countryManagerObservables).pipe(
                        map(countryManagers => {
                            const result: CountryManagers = {};
                            countryManagers.forEach(dm => {
                                dm.countries.forEach(countryKey => {
                                    if (!result[countryKey]) {
                                        result[countryKey] = [];
                                    }
                                    result[countryKey].push(dm.user);
                                });
                            });
                            console.log(result);
                            return result;
                        })
                    );
                })
            );
    }

    getCountryManagerCountries(userKey: string): Observable<Country[]> {
        return this.db.list<string>(this.countryManagersRef.child(userKey).child('countries').ref)
            .snapshotChanges().pipe(
                map(cKeys => cKeys.map(cKey => cKey.key)),
                switchMap(countryKeys => countryKeys.length
                    ? combineLatest(countryKeys.map(key => this.countryService.getCountry(key)))
                    : of([])
                )
            );
    }

    public isUserCountryManager(userKey: string, countryCode: string): Observable<boolean> {
        return this.db.object<string>(this.countryManagersRef.child(userKey).child('countries').child(countryCode).ref)
            .snapshotChanges()
            .pipe(
                take(1),
                map(value => value.payload.exists())
            );
    }

    addCountryManager(userKey: string, countryKey: string): Promise<void> {
        return this.countryManagersRef.child(userKey).child('countries').update({[countryKey]: countryKey});
    }

    removeCountryManager(userKey: string, countryKey: string): Promise<void> {
        return this.countryManagersRef.child(userKey).child('countries').child(countryKey).remove();
    }

    private countryManagerFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<CountryManager>>): CountryManager {
        const countryManager: CountryManager = snapshot.payload.val();
        countryManager.userKey = snapshot.key;
        countryManager.countries = Object.values(countryManager.countries ?? []);
        return countryManager;
    }
}
