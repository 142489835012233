import {AfterViewChecked, Component, OnDestroy} from '@angular/core';
import {Subject} from "rxjs";
import {Roles} from "../../../interfaces/role";
import {AuthService} from "../../../core/auth.service";
import {TrailAreaService} from "../../../firebase-services/trail-area.service";
import {UserService} from "../../../firebase-services/user.service";
import {RoleService} from "../../../core/role.service";
import {take, takeUntil} from "rxjs/operators";
import {TrailAreaManager, TrailAreaManagerListItem} from "../../../interfaces/trail-area-manager";
import {TrailAreaManagerService} from "./trail-area-manager.service";
import {TrailArea} from "../../../interfaces/trailArea";

declare var $: any;

@Component({
    selector: 'app-trail-area-managers',
    templateUrl: './trail-area-managers.component.html',
    styleUrl: './trail-area-managers.component.css'
})
export class TrailAreaManagersComponent implements OnDestroy, AfterViewChecked {
    destroy$: Subject<boolean> = new Subject<boolean>();
    roles = Roles;
    trailAreaManagers: TrailAreaManager[];
    trailAreaManagerListItems: TrailAreaManagerListItem[];
    state: 0;
    saveRoleDisabled = true;
    selectedUserKey: string;
    selectedUserName: string;
    selectedUserImage: string;
    selectedTrailAreaKey: string;
    selectedTrailAreaName: string;
    allIsLoaded = false;
    dataTableCreated = false;
    dataTable: any = null;
    managersDataTableName = "#managersDataTable";

    constructor(
        public authService: AuthService,
        private trailAreaManagerService: TrailAreaManagerService,
        private trailAreaService: TrailAreaService,
        private userService: UserService,
        private roleService: RoleService
    ) {
        this.trailAreaManagerListItems = [];
        this.trailAreaManagerService.getTrailAreaManagersListItems()
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe((list) => {
                this.trailAreaManagerListItems = list;
                this.allIsLoaded = true;
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && $("#managersDataTable>tbody>tr").length > 0) {
            this.dataTable = $(this.managersDataTableName).DataTable({
                order: [[0, "asc"]],
                columnDefs: [{orderable: false, targets: 1}]
            });
            this.dataTableCreated = true;
        }
    }

    onAreaAdded($event: string, userKey: string): Promise<void> {
        this.allIsLoaded = false;
        this.dataTable.destroy();
        this.dataTableCreated = false;
        return this.trailAreaManagerService.addTrailAreaToTrailAreaManager(userKey, $event);
    }

    onAreaRemoved(userKey: string, areaKey: string): Promise<void> {
        this.allIsLoaded = false;
        this.dataTable.destroy();
        this.dataTableCreated = false;
        for (let item of this.trailAreaManagerListItems) {
            if (item.user.userID === userKey && item.trailAreas.length === 1) {
                this.trailAreaManagerService.removeTrailAreaFromTrailAreaManager(userKey, areaKey).then(() => {
                    return this.roleService.revokeUserRole(userKey, this.roles.TRAIL_AREA_MANAGER)
                        .catch((err) => console.error('Sorry, an error occurred: ', err.message));
                }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
            } else {
                return this.trailAreaManagerService.removeTrailAreaFromTrailAreaManager(userKey, areaKey)
                    .catch((err) => console.error('Sorry, an error occurred: ', err.message));
            }
        }
        return;
    }

    onUserSelected(userKey: string) {
        this.selectedUserKey = userKey;
        this.userService.getUser(userKey)
            .pipe(take(1)).subscribe((user) => {
            this.selectedUserName = user.name;
            this.selectedUserImage = user.userPicture;
        });
        if (this.selectedTrailAreaKey) this.saveRoleDisabled = false;
    }

    onAreaSelected(areaKey: string) {
        this.selectedTrailAreaKey = areaKey;
        this.trailAreaService.getTrailArea(areaKey)
            .pipe(take(1)).subscribe((area) => this.selectedTrailAreaName = area.name);
        if (this.selectedUserKey) this.saveRoleDisabled = false;
    }

    assignTrailAreaManager(): void {
        this.allIsLoaded = false;
        this.dataTable.destroy();
        this.dataTableCreated = false;
        this.roleService.assignUserRole(this.selectedUserKey, this.roles.TRAIL_AREA_MANAGER, this.authService.user.userID).then(() => {
            return this.trailAreaManagerService.addTrailAreaToTrailAreaManager(this.selectedUserKey, this.selectedTrailAreaKey).then(() => {
                this.state++
                this.resetAssignTrailAreaManager();
            }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
        }).catch((err) => console.error('Sorry, an error occurred: ', err.message));
    }

    resetAssignTrailAreaManager() {
        this.selectedUserKey = '';
        this.selectedUserName = '';
        this.selectedUserImage = '';
        this.selectedTrailAreaKey = '';
        this.selectedTrailAreaName = '';
        this.saveRoleDisabled = true;
    }

    async revokeManager(managerKey: string, trailAreaKeys: TrailArea[]): Promise<void> {
        this.allIsLoaded = false;
        this.dataTable.destroy();
        this.dataTableCreated = false;
        try {
            const removeTrailAreaPromises = trailAreaKeys.map(area =>
                this.trailAreaManagerService.removeTrailAreaFromTrailAreaManager(managerKey, area.key)
            );

            await Promise.all(removeTrailAreaPromises);

            await this.roleService.revokeUserRole(managerKey, this.roles.TRAIL_AREA_MANAGER);

        } catch (err) {
            console.error('Sorry, an error occurred: ', err.message);
        }
    }

}
