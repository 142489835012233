import { Injectable } from '@angular/core';

// Interfaces
import { Breadcrumb, ContentLayout, ContentPage, PageParameters } from '../interfaces/layout';

const typeLayouts: { [contentType: string]: ContentLayout } = {
    adventure: {
        icon: 'far fa-compass',
        pluralIcon: 'fas fa-compass',
        theme: 'success'
    },
    adventureElement: {
        icon: 'fas fa-puzzle-piece',
        pluralIcon: 'fas fa-puzzle-piece',
        theme: 'warning'
    },
    category: {
        icon: 'fas fa-tag',
        pluralIcon: 'fas fa-tags',
        theme: 'success'
    },
    contact: {
        icon: 'far fa-address-book',
        pluralIcon: 'fas fa-address-book',
        theme: 'primary'
    },
    country: {
        icon: 'far fa-flag',
        pluralIcon: 'fas fa-flag',
        theme: 'navy'
    },
    countryManager: {
        icon: 'fas fa-user-cog',
        pluralIcon: 'fas fa-users-cog',
        theme: 'navy'
    },
    destination: {
        icon: 'far fa-heart',
        pluralIcon: 'fas fa-heart',
        theme: 'maroon'
    },
    destinationManager: {
        icon: 'fas fa-hand-holding-heart',
        pluralIcon: 'fas fa-hand-holding-heart',
        theme: 'maroon'
    },
    explorer: {
        icon: 'fas fa-biking',
        pluralIcon: 'fas fa-biking',
        theme: 'success'
    },
    icon: {
        icon: 'fas fa-icons',
        pluralIcon: 'fas fa-icons',
        theme: 'primary'
    },
    image: {
        icon: 'far fa-image',
        pluralIcon: 'far fa-images',
        theme: 'info'
    },
    kioskOrder: {
        icon: 'fas fa-shopping-basket',
        pluralIcon: 'fas fa-shopping-basket',
        theme: 'success'
    },
    manager: {
        icon: 'fas fa-user-cog',
        pluralIcon: 'fas fa-users-cog',
        theme: 'warning'
    },
    mapElement: {
        icon: 'fas fa-draw-polygon',
        pluralIcon: 'fas fa-shapes',
        theme: 'success'
    },
    mediaLibrary: {
        icon: 'fas fa-photo-video',
        pluralIcon: 'fas fa-photo-video',
        theme: 'dark'
    },
    member: {
        icon: 'fas fa-user',
        pluralIcon: 'fas fa-users',
        theme: 'maroon'
    },
    membership: {
        icon: 'fas fa-id-badge',
        pluralIcon: 'far fa-id-badge',
        theme: 'success'
    },
    news: {
        icon: 'far fa-newspaper',
        pluralIcon: 'fas fa-newspaper',
        theme: 'success'
    },
    newsArchive: {
        icon: 'fas fa-archive',
        pluralIcon: 'fas fa-archive',
        theme: 'danger'
    },
    newsDraft: {
        icon: 'fas fa-pencil-alt',
        pluralIcon: 'fas fa-pencil-alt',
        theme: 'warning'
    },
    newsWelcome: {
        icon: 'fas fa-door-open',
        pluralIcon: 'fas fa-door-open',
        theme: 'purple'
    },
    ontrail: {
        icon: 'fas fa-certificate',
        pluralIcon: 'fas fa-certificate',
        theme: 'danger'
    },
    organisation: {
        icon: 'fas fa-university',
        pluralIcon: 'fas fa-university',
        theme: 'maroon'
    },
    poi: {
        icon: 'fas fa-map-marker-alt',
        pluralIcon: 'fas fa-map-marker-alt',
        theme: 'danger'
    },
    premium: {
        icon: 'far fa-gem',
        pluralIcon: 'fas fa-gem',
        theme: null
    },
    profile: {
        icon: 'far fa-id-card',
        pluralIcon: 'fas fa-id-card',
        theme: null
    },
    ride: {
        icon: 'fas fa-biking',
        pluralIcon: 'fas fa-biking',
        theme: 'maroon'
    },
    admin: {
        icon: 'fas fa-user-secret',
        pluralIcon: 'fas fa-user-secret',
        theme: 'dark'
    },
    rtw: {
        icon: 'fas fa-mountain',
        pluralIcon: 'fas fa-mountain',
        theme: 'lime'
    },
    rtwEvent: {
        icon: 'fas fa-calendar-day',
        pluralIcon: 'fas fa-calendar-alt',
        theme: 'fuchsia'
    },
    shapes: {
        icon: 'fas fa-vector-square',
        pluralIcon: 'fas fa-globe',
        theme: 'success'
    },
    trail: {
        icon: 'fas fa-route',
        pluralIcon: 'fas fa-route',
        theme: 'info'
    },
    trailArea: {
        icon: 'far fa-map',
        pluralIcon: 'fas fa-map',
        theme: 'primary'
    },
    treat: {
        icon: 'fas fa-coffee',
        pluralIcon: 'fas fa-coffee',
        theme: 'teal'
    },
    sponsor: {
        icon: 'fa fa-gift',
        pluralIcon: 'fa fa-gift',
        theme: 'Sponsor'
    },
    sponsorEdit: {
        icon: 'fa fa-gift',
        pluralIcon: 'fa fa-gift',
        theme: 'SponsorEdit'
    },
    unknown: {
        icon: 'fas fa-question-circle',
        pluralIcon: 'far fa-question-circle',
        theme: 'warning'
    },
    validator: {
        icon: 'far fa-check-circle',
        pluralIcon: 'fas fa-check-circle',
        theme: 'warning'
    },
    event: {
        icon: 'fa fa-biking',
        pluralIcon: 'fa fa-biking',
        theme: 'teal'
    },
    eventManager: {
        icon: 'fas fa-chart-line',
        pluralIcon: 'fas fa-chart-line',
        theme: 'teal'
    },
    trailCenter: {
        icon: 'fa fa-house-user',
        pluralIcon: 'fa fa-award',
        theme: 'teal'
    },
    trailCenterDashboard: {
        icon: 'fas fa-chart-line',
        pluralIcon: 'fas fa-chart-line',
        theme: 'teal'
    }
};

const contentPages: { [contentPage: string]: ContentPage } = {
    dashboard: {
        icon: 'fas fa-tachometer-alt',
        id: 'dashboard',
        linkText: 'Home',
        path: 'dashboard',
        parentId: null,
        title: 'Dashboard',
        titleLead: ''
    },

    userProfile: {
        icon: typeLayouts.profile.icon,
        id: 'userProfile',
        linkText: 'Your Profile',
        path: 'profile',
        parentId: 'dashboard',
        title: 'Your Profile',
        titleLead: ':profileName'
    },

    countries: {
        icon: typeLayouts.country.pluralIcon,
        id: 'countries',
        linkText: 'Countries',
        path: null,
        parentId: 'dashboard',
        title: null,
        titleLead: null
    },

    country: {
        icon: typeLayouts.country.icon,
        id: 'country',
        linkText: ':countryName',
        path: null,
        parentId: 'countries',
        title: null,
        titleLead: null
    },

    countryTrailAreasAdmin: {
        icon: typeLayouts.trailArea.pluralIcon,
        id: 'countryTrailAreasAdmin',
        linkText: 'Manage Trail Areas',
        path: 'country-manager/:countryCode/trail-areas-admin',
        parentId: 'country',
        title: 'Trail Areas in :countryName',
        titleLead: 'Trail Area Manager'
    },

    countryNewTrailArea: {
        icon: typeLayouts.trailArea.icon,
        id: 'countryNewTrailArea',
        linkText: 'Add New Trail Area',
        path: 'country-manager/:countryCode/new-trail-area',
        parentId: 'country',
        title: 'New Trail Area in :countryName',
        titleLead: 'Add Trail Area'
    },

    countryAdventures: {
        icon: typeLayouts.adventure.pluralIcon,
        id: 'countryAdventures',
        linkText: 'Adventures',
        path: 'country-manager/:countryCode/adventures',
        parentId: 'country',
        title: 'Adventures in :countryName',
        titleLead: 'Overview'
    },

    countryAdventureEdit: {
        icon: typeLayouts.adventure.icon,
        id: 'countryAdventureEdit',
        linkText: ':adventureName',
        path: 'country-manager/:countryCode/adventures/:adventureKey',
        parentId: 'countryAdventures',
        title: ':adventureName',
        titleLead: 'Edit Adventure'
    },

    countryExplorers: {
        icon: typeLayouts.explorer.pluralIcon,
        id: 'countryExplorers',
        linkText: 'Explorers',
        path: 'country-manager/:countryCode/adventures/:adventureKey/explorers',
        parentId: 'countryAdventureEdit',
        title: 'Adventure Explorers',
        titleLead: ':adventureName'
    },

    countryContactResponsible: {
        icon: typeLayouts.contact.pluralIcon,
        id: 'countryContactResponsible',
        linkText: 'Contacts',
        path: 'country-manager/:countryCode/contact-responsible',
        parentId: 'country',
        title: 'Contacts',
        titleLead: 'in :countryName'
    },

    destinations: {
        icon: typeLayouts.destination.pluralIcon,
        id: 'destinations',
        linkText: 'Destinations',
        path: null,
        parentId: 'dashboard',
        title: null,
        titleLead: null
    },

    destination: {
        icon: typeLayouts.destination.icon,
        id: 'destination',
        linkText: ':destinationName',
        path: 'destination/:destinationKey/settings',
        parentId: 'destinations',
        title: ':destinationName',
        titleLead: 'Overview'
    },

    destinationTrailAreas: {
        icon: typeLayouts.trailArea.pluralIcon,
        id: 'destinationTrailAreas',
        linkText: 'Trail Areas',
        path: 'destination/:destinationKey/trail-areas',
        parentId: 'destination',
        title: 'Trail Areas in :destinationName',
        titleLead: 'Overview'
    },

    destinationAdventures: {
        icon: typeLayouts.adventure.pluralIcon,
        id: 'destinationAdventures',
        linkText: 'Adventures',
        path: 'destination/:destinationKey/adventures',
        parentId: 'destination',
        title: 'Adventures in :destinationName',
        titleLead: 'Overview'
    },

    destinationAdventureEdit: {
        icon: typeLayouts.adventure.icon,
        id: 'destinationAdventureEdit',
        linkText: ':adventureName',
        path: 'destination/:destinationKey/adventures/:adventureKey',
        parentId: 'destinationAdventures',
        title: ':adventureName',
        titleLead: 'Edit Adventure'
    },

    destinationAdventureItemEdit: {
        icon: typeLayouts.adventureElement.icon,
        id: 'destinationAdventureItemEdit',
        linkText: ':adventureItemName',
        path: 'destination/:destinationKey/adventures/:adventureKey/:adventureItemKey',
        parentId: 'destinationAdventureEdit',
        title: ':adventureItemName',
        titleLead: 'Element in :adventureName'
    },

    destinationAdventureExplorers: {
        icon: typeLayouts.explorer.pluralIcon,
        id: 'destinationAdventureExplorers',
        linkText: 'Explorers',
        path: 'destination/:destinationKey/adventures/:adventureKey/explorers',
        parentId: 'destinationAdventureEdit',
        title: 'Adventure Explorers',
        titleLead: ':adventureName'
    },

    trailAreas: {
        icon: typeLayouts.trailArea.pluralIcon,
        id: 'trailAreas',
        linkText: 'Trail Areas',
        path: null,
        parentId: 'dashboard',
        title: null,
        titleLead: null
    },

    trailArea: {
        icon: typeLayouts.trailArea.icon,
        id: 'trailArea',
        linkText: ':trailAreaName',
        path: 'trail-area/:trailAreaKey/settings',
        parentId: 'trailAreas',
        title: ':trailAreaName',
        titleLead: 'Edit Trail Area, :trailAreaKey'
    },

    trails: {
        icon: typeLayouts.trail.pluralIcon,
        id: 'trails',
        linkText: 'Trails',
        path: 'trail-area/:trailAreaKey/trails',
        parentId: 'trailArea',
        title: 'Trails in :trailAreaName',
        titleLead: 'Overview'
    },

    trailEdit: {
        icon: typeLayouts.trail.icon,
        id: 'trailEdit',
        linkText: ':trailName',
        path: 'trail-area/:trailAreaKey/trails/:trailKey',
        parentId: 'trails',
        title: ':trailName',
        titleLead: 'Edit Trail'
    },

    pois: {
        icon: typeLayouts.poi.pluralIcon,
        id: 'pois',
        linkText: 'POIs',
        path: 'trail-area/:trailAreaKey/pois',
        parentId: 'trailArea',
        title: 'Point of Interests in :trailAreaName',
        titleLead: 'Overview'
    },

    poiEdit: {
        icon: typeLayouts.poi.icon,
        id: 'poiEdit',
        linkText: ':poiName',
        path: 'trail-area/:trailAreaKey/pois/:poiKey',
        parentId: 'pois',
        title: ':poiName',
        titleLead: 'Edit Point of Interest'
    },

    areaManagers: {
        icon: typeLayouts.manager.pluralIcon,
        id: 'areaManagers',
        linkText: 'Area Managers',
        path: 'trail-area/:trailAreaKey/area-managers',
        parentId: 'trailArea',
        title: 'Area Managers in :trailAreaName',
        titleLead: 'Overview'
    },

    trailAreaNews: {
        icon: typeLayouts.news.pluralIcon,
        id: 'trailAreaNews',
        linkText: 'Stories',
        path: 'trail-area/:trailAreaKey/news-stories',
        parentId: 'trailArea',
        title: 'News Room',
        titleLead: 'Stories in :trailAreaName'
    },

    trailAreaDrafts: {
        icon: typeLayouts.newsDraft.pluralIcon,
        id: 'trailAreaDrafts',
        linkText: 'Drafts',
        path: 'trail-area/:trailAreaKey/news-stories/drafts',
        parentId: 'trailAreaNews',
        title: 'Story Drafts',
        titleLead: 'in :trailAreaName'
    },

    trailAreaEditDraft: {
        icon: typeLayouts.newsDraft.icon,
        id: 'trailAreaEditDraft',
        linkText: 'Draft',
        path: 'trail-area/:trailAreaKey/news-stories/drafts/:newsStoryKey',
        parentId: 'trailAreaDrafts',
        title: ':trailAreaName Draft',
        titleLead: 'Edit Story Draft'
    },

    trailAreaStories: {
        icon: typeLayouts.news.pluralIcon,
        id: 'trailAreaStories',
        linkText: 'Stories',
        path: 'trail-area/:trailAreaKey/news-stories/stories',
        parentId: 'trailAreaNews',
        title: 'Stories',
        titleLead: 'in :trailAreaName'
    },

    trailAreaModifyStory: {
        icon: typeLayouts.news.icon,
        id: 'trailAreaModifyStory',
        linkText: 'Story',
        path: 'trail-area/:trailAreaKey/news-stories/stories/:newsStoryKey',
        parentId: 'trailAreaStories',
        title: ':trailAreaName Story',
        titleLead: 'Edit'
    },

    trailAreaStoryArchive: {
        icon: typeLayouts.newsArchive.pluralIcon,
        id: 'trailAreaArchive',
        linkText: 'Archive',
        path: 'trail-area/:trailAreaKey/news-stories/archive',
        parentId: 'trailAreaNews',
        title: 'Archived Stories',
        titleLead: 'in :trailAreaName'
    },

    trailAreaAdventures: {
        icon: typeLayouts.adventure.pluralIcon,
        id: 'trailAreaAdventures',
        linkText: 'Adventures',
        path: 'trail-area/:trailAreaKey/adventures',
        parentId: 'trailArea',
        title: 'Adventures in :trailAreaName',
        titleLead: 'Overview'
    },

    trailAreaAdventureEdit: {
        icon: typeLayouts.adventure.icon,
        id: 'trailAreaAdventureEdit',
        linkText: ':adventureName',
        path: 'trail-area/:trailAreaKey/adventures/:adventureKey',
        parentId: 'trailAreaAdventures',
        title: ':adventureName',
        titleLead: 'Edit Adventure'
    },

    trailAreaAdventureItemEdit: {
        icon: typeLayouts.adventureElement.icon,
        id: 'trailAreaAdventureItemEdit',
        linkText: ':adventureItemName',
        path: 'trail-area/:trailAreaKey/adventures/:adventureKey/:adventureItemKey',
        parentId: 'trailAreaAdventureEdit',
        title: ':adventureItemName',
        titleLead: 'Element in :adventureName'
    },

    trailAreaExplorers: {
        icon: typeLayouts.explorer.pluralIcon,
        id: 'trailAreaExplorers',
        linkText: 'Explorers',
        path: 'trail-area/:trailAreaKey/adventures/:adventureKey/explorers',
        parentId: 'trailAreaAdventureEdit',
        title: 'Adventure Explorers',
        titleLead: ':adventureName'
    },

    rideStatistics: {
        icon: 'fas fa-chart-pie',
        id: 'rideStatistics',
        linkText: 'Ride Statistics',
        path: 'trail-area/:trailAreaKey/ride-statistics',
        parentId: 'trailArea',
        title: 'Ride Statistics',
        titleLead: ':trailAreaName'
    },

    onTrail: {
        icon: typeLayouts.ontrail.pluralIcon,
        id: 'onTrail',
        linkText: 'OnTrail',
        path: null,
        parentId: 'dashboard',
        title: null,
        titleLead: null
    },

    onTrailStatus: {
        icon: typeLayouts.ontrail.pluralIcon,
        id: 'onTrailStatus',
        linkText: 'Status',
        path: 'ontrail/status',
        parentId: 'onTrail',
        title: 'OnTrail',
        titleLead: 'Status'
    },

    organisation: {
        icon: typeLayouts.organisation.pluralIcon,
        id: 'organisation',
        linkText: 'Organisation',
        path: null,
        parentId: 'dashboard',
        title: null,
        titleLead: null
    },

    organisationMembership: {
        icon: typeLayouts.organisation.icon,
        id: 'organisationMembership',
        linkText: 'Membership',
        path: 'organisation/:organisationKey/membership',
        parentId: 'organisation',
        title: ':organisationName',
        titleLead: 'Organisation Membership'
    },

    organisationMembers: {
        icon: typeLayouts.member.pluralIcon,
        id: 'organisationMembers',
        linkText: 'Members',
        path: 'organisation/:organisationKey/members',
        parentId: 'organisation',
        title: ':organisationName',
        titleLead: 'Organisation Members'
    },

    onTrailContacts: {
        icon: typeLayouts.contact.pluralIcon,
        id: 'onTrailContacts',
        linkText: 'Contacts',
        path: 'ontrail/contacts',
        parentId: 'onTrail',
        title: 'OnTrail',
        titleLead: 'Contacts'
    },

    admin: {
        icon: typeLayouts.admin.pluralIcon,
        id: 'admin',
        linkText: 'Admin Access',
        path: null,
        parentId: 'dashboard',
        title: null,
        titleLead: null
    },

    adminDashboard: {
        icon: 'fas fa-chart-line',
        id: 'adminDashboard',
        linkText: 'Admin Dashboard',
        path: 'root/dashboard',
        parentId: 'admin',
        title: 'Dashboard',
        titleLead: 'For Administrators'
    },

    kioskOrders: {
        icon: typeLayouts.kioskOrder.pluralIcon,
        id: 'kioskOrders',
        linkText: 'Kiosk Orders',
        path: 'root/kiosk-orders',
        parentId: 'admin',
        title: 'Kiosk Orders',
        titleLead: null
    },

    newsRoom: {
        icon: typeLayouts.news.pluralIcon,
        id: 'newsRoom',
        linkText: 'News Room',
        path: 'root/news-room',
        parentId: 'admin',
        title: 'Mountainbike United News Room',
        titleLead: 'Overview'
    },

    newsRoomDrafts: {
        icon: typeLayouts.newsDraft.pluralIcon,
        id: 'newsRoomDrafts',
        linkText: 'Drafts',
        path: 'root/news-room/drafts',
        parentId: 'newsRoom',
        title: 'Drafts',
        titleLead: 'not yet Published'
    },

    newsRoomEditDraft: {
        icon: typeLayouts.newsDraft.icon,
        id: 'newsRoomEditDraft',
        linkText: 'Draft',
        path: 'root/news-room/drafts/:newsStoryKey',
        parentId: 'newsRoomDrafts',
        title: 'Story Draft',
        titleLead: 'Edit'
    },

    newsRoomStories: {
        icon: typeLayouts.news.pluralIcon,
        id: 'newsRoomStories',
        linkText: 'Stories',
        path: 'root/news-room/stories',
        parentId: 'newsRoom',
        title: 'Stories',
        titleLead: 'Published'
    },

    newsRoomModifyStory: {
        icon: typeLayouts.news.icon,
        id: 'newsRoomModifyStory',
        linkText: 'Story',
        path: 'root/news-room/stories/:newsStoryKey',
        parentId: 'newsRoomStories',
        title: 'Story',
        titleLead: 'Modify'
    },

    newsRoomArchive: {
        icon: typeLayouts.newsArchive.pluralIcon,
        id: 'newsRoomArchive',
        linkText: 'Archive',
        path: 'root/news-room/archive',
        parentId: 'newsRoom',
        title: 'Archived Stories',
        titleLead: null
    },

    newsRoomWelcome: {
        icon: typeLayouts.newsWelcome.pluralIcon,
        id: 'newsRoomWelcome',
        linkText: 'Welcome Stories',
        path: 'root/news-room/welcome',
        parentId: 'newsRoom',
        title: 'Welcome Stories',
        titleLead: 'Triggered when users join Mountainbike United'
    },

    premium: {
        icon: typeLayouts.premium.pluralIcon,
        id: 'premium',
        linkText: 'Premium',
        path: null,
        parentId: 'admin',
        title: null,
        titleLead: null
    },

    premiumProduct: {
        icon: typeLayouts.membership.icon,
        id: 'premiumProduct',
        linkText: 'Membership',
        path: 'root/premium/product',
        parentId: 'premium',
        title: 'Premium',
        titleLead: 'Membership'
    },

    premiumMembers: {
        icon: typeLayouts.member.pluralIcon,
        id: 'premiumMembers',
        linkText: 'Members',
        path: 'root/premium/members',
        parentId: 'premium',
        title: 'Premium',
        titleLead: 'Members'
    },

    countriesManager: {
        icon: typeLayouts.country.pluralIcon,
        id: 'countriesManager',
        linkText: 'Countries Manager',
        path: null,
        parentId: 'admin',
        title: null,
        titleLead: null
    },

    countryManagers: {
        icon: typeLayouts.countryManager.pluralIcon,
        id: 'countryManager',
        linkText: 'Managers',
        path: 'root/countries-manager/managers',
        parentId: 'countriesManager',
        title: 'Countries Manager',
        titleLead: 'Managers'
    },

    voronoi: {
        icon: typeLayouts.shapes.pluralIcon,
        id: 'voronoi',
        linkText: 'Voronoi',
        path: 'root/country-manager/voronoi',
        parentId: 'countriesManager',
        title: 'Voronoi Shapes',
        titleLead: 'Generate for Country'
    },

    destinationsManager: {
        icon: typeLayouts.destination.pluralIcon,
        id: 'destinationsManager',
        linkText: 'Destinations Manager',
        path: 'root/destinations-manager/list',
        parentId: 'admin',
        title: 'Destinations Manager',
        titleLead: 'List Overview'
    },

    newDestination: {
        icon: typeLayouts.destination.icon,
        id: 'addDestination',
        linkText: 'Add Destination',
        path: 'root/destinations-manager/new-destination',
        parentId: 'destinationsManager',
        title: 'Destinations Manager',
        titleLead: 'Add Destination'
    },

    trailManager: {
        icon: typeLayouts.trail.pluralIcon,
        id: 'trailManager',
        linkText: 'Trail Manager',
        path: null,
        parentId: 'admin',
        title: null,
        titleLead: null
    },

    shapefileImport: {
        icon: typeLayouts.mapElement.pluralIcon,
        id: 'shapefileImport',
        linkText: 'Shapefile Viewer',
        path: 'root/trail-manager/shapefile-import',
        parentId: 'trailManager',
        title: 'Shapefile',
        titleLead: 'Viewer'
    },

    exportFacilitydb: {
        icon: 'fas fa-database',
        id: 'exportFacilitydb',
        linkText: 'Export Trails',
        path: '/root/trail-manager/export-facilitydb',
        parentId: 'trailManager',
        title: 'Export Trails',
        titleLead: 'Made for Facilitetsdatabasen'
    },

    trailsImport: {
        icon: typeLayouts.trail.pluralIcon,
        id: 'trailsImport',
        linkText: 'Import Trails',
        path: 'root/trail-manager/import-trails',
        parentId: 'trailManager',
        title: 'Import Trails',
        titleLead: 'From Esri-JSON'
    },

    poiManager: {
        icon: typeLayouts.poi.pluralIcon,
        id: 'poiManager',
        linkText: 'POI Manager',
        path: null,
        parentId: 'admin',
        title: null,
        titleLead: null
    },

    poiCategories: {
        icon: typeLayouts.category.pluralIcon,
        id: 'poiCategories',
        linkText: 'POI Categories',
        path: '/root/poi-manager/poi-categories',
        parentId: 'poiManager',
        title: 'POI Categories',
        titleLead: 'Manage Offered Categories'
    },

    editPoiCategory: {
        icon: typeLayouts.category.icon,
        id: 'editPoiCategory',
        linkText: 'Edit POI Category',
        path: '/root/poi-manager/poi-categories/:poiCategoryLabel/edit',
        parentId: 'poiCategories',
        title: 'Edit :poiCategoryName',
        titleLead: 'POI Category'
    },

    poiValidator: {
        icon: typeLayouts.validator.pluralIcon,
        id: 'poiValidator',
        linkText: 'POI Validator',
        path: '/root/poi-manager/poi-validator',
        parentId: 'poiManager',
        title: 'POI Validator',
        titleLead: 'Validate POIs'
    },

    editPoi: {
        icon: typeLayouts.poi.icon,
        id: 'editPoi',
        linkText: ':poiName',
        path: 'root/poi-manager/poi-validator/:poiKey',
        parentId: 'poiValidator',
        title: ':poiName',
        titleLead: 'Edit Point of Interest'
    },

    poiImport: {
        icon: typeLayouts.poi.pluralIcon,
        id: 'poiImport',
        linkText: 'POI Import',
        path: '/root/poi-manager/poi-import',
        parentId: 'poiManager',
        title: 'POI Import',
        titleLead: 'Import POIs from APIs'
    },

    rtw: {
        icon: typeLayouts.rtw.icon,
        id: 'rtw',
        linkText: 'Ride the World',
        path: null,
        parentId: 'admin',
        title: null,
        titleLead: null
    },

    rtwEvents: {
        icon: typeLayouts.rtwEvent.pluralIcon,
        id: 'rtwEvents',
        linkText: 'RtW Events',
        path: '/root/rtw/events/list',
        parentId: 'rtw',
        title: 'Ride the World Events',
        titleLead: 'List of Events'
    },

    rtwEventParticipants: {
        icon: typeLayouts.member.pluralIcon,
        id: 'rtwEventParticipant',
        linkText: 'RtW Event Participant',
        path: '/root/rtw/events/:rtwEventKey/participants',
        parentId: 'rtwEvents',
        title: 'RtW Event Participants',
        titleLead: ':rtwEventName'
    },

    editRtwEvent: {
        icon: typeLayouts.rtwEvent.icon,
        id: 'editRtwEvent',
        linkText: 'Edit RtW Event',
        path: '/root/rtw/events/:rtwEventKey/edit',
        parentId: 'rtwEvents',
        title: 'Edit RtW Event',
        titleLead: ':rtwEventName'
    },

    createRtwEvent: {
        icon: typeLayouts.rtwEvent.icon,
        id: 'createRtwEvent',
        linkText: 'Create RtW Event',
        path: '/root/rtw/events/create',
        parentId: 'rtwEvents',
        title: 'Create RtW Event',
        titleLead: 'New Events'
    },

    rides: {
        icon: typeLayouts.ride.pluralIcon,
        id: 'rides',
        linkText: 'Rides',
        path: '/root/rides',
        parentId: 'admin',
        title: 'Rides',
        titleLead: 'with Mountainbike United'
    },

    mediaLibrary: {
        icon: typeLayouts.mediaLibrary.pluralIcon,
        id: 'mediaLibrary',
        linkText: 'Media Library',
        path: '/root/mediaLibrary',
        parentId: 'admin',
        title: 'Media Library',
        titleLead: 'Manage standard media files'
    },

    emailExtract: {
        icon: 'fas fa-mail-bulk',
        id: 'emailExtract',
        linkText: 'Email Extract',
        path: '/root/emailExtract',
        parentId: 'admin',
        title: 'Email Extract',
        titleLead: 'List of profiles with email'
    },

    roleManager: {
        icon: typeLayouts.manager.pluralIcon,
        id: 'roleManager',
        linkText: 'Role Manager',
        path: '/root/role-manager',
        parentId: 'admin',
        title: 'Role Manager',
        titleLead: 'Manage user roles'
    },

    eventManagers: {
        icon: typeLayouts.manager.pluralIcon,
        id: 'eventManagers',
        linkText: 'Event Managers',
        path: '/root/event-managers',
        parentId: 'admin',
        title: 'Event Managers',
        titleLead: 'Administration'
    },

    treats: {
        icon: typeLayouts.treat.pluralIcon,
        id: 'treats',
        linkText: 'Treats',
        path: '/root/treats',
        parentId: 'admin',
        title: 'Treats',
        titleLead: null
    },

    treatsEdit: {
        icon: typeLayouts.treat.icon,
        id: 'treatsEdit',
        linkText: 'Edit treat',
        path: '/root/treats/:treatKey',
        parentId: 'treats',
        title: ':treatName',
        titleLead: 'Edit treat'
    },
    sponsor: {
        icon: typeLayouts.sponsor.pluralIcon,
        id: 'sponsor',
        linkText: 'Sponsor',
        path: '/root/sponsor',
        parentId: 'admin',
        title: 'Sponsor',
        titleLead: null
    },
    sponsorEdit: {
        icon: typeLayouts.sponsorEdit.icon,
        id: 'sponsorEdit',
        linkText: 'Edit Campaign',
        path: '/root/sponsor/:sponsorKey',
        parentId: 'admin',
        title: 'Campaign',
        titleLead: "Edit Campaign"
    },

    eventManager: {
        icon: typeLayouts.event.icon,
        id: 'eventManager',
        linkText: 'Event Manager',
        path: null,
        parentId: 'dashboard',
        title: null,
        titleLead: null
    },

    eventManagerDashboard: {
        icon: typeLayouts.eventManager.icon,
        id: 'eventManagerDashboard',
        linkText: 'Dashboard',
        path: '/event-manager-dashboard',
        parentId: 'eventManager',
        title: 'Event Manager Dashboard',
        titleLead: null
    },

    trailCenter: {
        icon: typeLayouts.trailCenter.icon,
        id: 'trailCenter',
        linkText: 'Trail Center',
        path: null,
        parentId: 'dashboard',
        title: null,
        titleLead: null
    },
    trailCenterDashboard: {
        icon: typeLayouts.trailCenterDashboard.icon,
        id: 'trailCenterDashboard',
        linkText: 'Dashboard',
        path: '/trail-center/trail-center-dashboard',
        parentId: 'trailCenter',
        title: 'Trail Center Manager Dashboard',
        titleLead: null
    }
};

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    constructor() {
    }

    getBreadcrumbs(contentPageId: string, pageParams: PageParameters): Breadcrumb[] {
        return this.followTheBreadcrumbs(contentPages[contentPageId], pageParams, []);
    }

    private followTheBreadcrumbs(contentPage: ContentPage, pageParams: PageParameters, breadcrumbs: Breadcrumb[]): Breadcrumb[] {
        if (contentPage.parentId === null) {
            return breadcrumbs;
        }
        const crumb: Breadcrumb = {
            linkText: this.getLinkText(contentPages[contentPage.parentId].id, pageParams),
            url: this.getUrl(contentPages[contentPage.parentId].id, pageParams)
        };
        breadcrumbs.unshift(crumb);
        return this.followTheBreadcrumbs(contentPages[contentPage.parentId], pageParams, breadcrumbs);
    }

    getUrl(contentPageId: string, pageParams: PageParameters): string {
        let url: string = contentPages[contentPageId].path;
        if (url === null) {
            return null;
        }
        Object.keys(pageParams).forEach((param) => {
            url = url.replace(':' + param, pageParams[param]);
        });
        return '/' + url;
    }

    getTitle(contentPageId: string, pageParams: PageParameters): string {
        let title: string = contentPages[contentPageId].title;
        Object.keys(pageParams).forEach((param) => {
            title = title.replace(':' + param, pageParams[param]);
        });
        return title;
    }

    getTitleLead(contentPageId: string, pageParams: PageParameters): string {
        let titleLead: string = contentPages[contentPageId].titleLead;
        Object.keys(pageParams).forEach((param) => {
            titleLead = titleLead.replace(':' + param, pageParams[param]);
        });
        return titleLead;
    }

    getContentPageIcon(contentPageId: string): string {
        return contentPages[contentPageId].icon;
    }

    getLinkText(contentPageId: string, pageParams: PageParameters): string {
        let linkText: string = contentPages[contentPageId].linkText;
        Object.keys(pageParams).forEach((param) => {
            linkText = linkText.replace(':' + param, pageParams[param]);
        });
        return linkText;
    }

    getIcon(contentType: string): string {
        return typeLayouts[contentType].icon;
    }

    getPluralIcon(contentType: string): string {
        return typeLayouts[contentType].pluralIcon;
    }

    getTheme(contentType: string): string {
        return typeLayouts[contentType].theme;
    }

    nameSort(a: any, b: any): number {
        const aName = (a.name ?? '').toLowerCase();
        const bName = (b.name ?? '').toLowerCase();
        if (aName < bName) {
            return -1;
        }
        if (bName < aName) {
            return 1;
        }
        return 0;
    }
}