<div class="content-wrapper">
    <app-content-header-section *ngIf="pressCampGroup" contentPageId="pressCampOverview"
                                [pageParams]="{ pressCampName: pressCampGroup.name, pressCampKey: pressCampGroup.groupKey }">
    </app-content-header-section>

    <section>
        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-12" *ngIf="pressCampGroup && members">
                        <div [ngClass]="'small-box bg-' + layout.getTheme('memberCount')">
                            <div class="inner">
                                <h3>{{ pressCampGroup.memberCount }}/{{ members.length }}</h3>
                                <p>Members</p>
                            </div>
                            <div class="icon">
                                <i [ngClass]="layout.getPluralIcon('memberCount')"></i>
                            </div>
                            <a class="small-box-footer"
                               [routerLink]="layout.getUrl('pressCampGroupEdit', { pressCampKey: pressCampGroup.groupKey, pressCampName: pressCampGroup.name })"
                               [fragment]="groupMemberBox">
                                Go to members <i class="fas fa-arrow-circle-right"></i>
                            </a>
                            <div *ngIf="pressCampGroup.memberCount < 0" class="overlay">
                                <i class="fas fa-3x fa-sync-alt fa-spin"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12" *ngIf="pressCampMeetups">
                        <div [ngClass]="'small-box bg-' + layout.getTheme('meetupCount')">
                            <div class="inner">
                                <h3>{{ pressCampMeetups.length }}</h3>
                                <p>Meetups</p>
                            </div>
                            <div class="icon">
                                <i [ngClass]="layout.getPluralIcon('meetupCount')"></i>
                            </div>
                            <a class="small-box-footer pointer"
                               (click)="createNewPressCampMeetup()">
                                Create new meetup <i class="fas fa-arrow-circle-right"></i>
                            </a>
                            <div *ngIf="creatingMeetup" class="overlay">
                                <i class="fas fa-3x fa-sync-alt fa-spin"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12" *ngIf="pressCampGroup">
                        <div [ngClass]="'small-box bg-' + layout.getTheme('editGroup')">
                            <div class="inner">
                                <h3>Edit</h3>
                                <p>Press Camp Group</p>
                            </div>
                            <div class="icon">
                                <i [ngClass]="layout.getPluralIcon('editGroup')"></i>
                            </div>
                            <a class="small-box-footer"
                               [routerLink]="layout.getUrl('pressCampGroupEdit', { pressCampKey: pressCampGroup.groupKey, pressCampName: pressCampGroup.name })">
                                Go to edit group <i class="fas fa-arrow-circle-right"></i>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12" *ngIf="pressCampGroup">
                        <div [ngClass]="'small-box bg-' + layout.getTheme('deleteGroup')">
                            <div class="inner">
                                <h3>Delete</h3>
                                <p>Press Camp</p>
                            </div>
                            <div class="icon">
                                <i [ngClass]="layout.getPluralIcon('deleteGroup')"></i>
                            </div>
                            <a class="small-box-footer pointer"
                               (click)="deletePressCamp(pressCampGroup)">
                                Delete entire Group <i class="fas fa-exclamation-triangle"></i>
                            </a>
                            <div *ngIf="deletingPressCamp" class="overlay">
                                <i class="fas fa-3x fa-sync-alt fa-spin"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="pressCampGroup">
                    <div class="col-md-6">
                        <div class="card card-outline card-gray">
                            <div class="card-header">
                                <h3 class="card-title"><i class="fas fa-binoculars"></i> Overview</h3>
                            </div>
                            <div class="gradient-container position-relative" style="min-height: 300px;">
                                <img
                                    class="img-fluid"
                                    [src]="pressCampGroup?.coverImgUrl || 'https://firebasestorage.googleapis.com/v0/b/singletracker-beta.appspot.com/o/mediaLibrary%2FdefaultImages%2Fsub_bg.png?alt=media&token=b025f409-ef29-4290-aba3-b920c2705200'"
                                    alt="Press Camp Cover Image"
                                    (load)="imageLoaded = true">
                                <ng-container *ngIf="imageLoaded">
                                    <div class="gradient-overlay"></div>
                                    <div class="text-content">
                                        <h3>{{ pressCampGroup.name }}</h3>
                                        <h5>{{ pressCampGroup.description }}</h5>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card card-outline card-blue">
                            <div class="card-header">
                                <h3 class="card-title"><i class="fas fa-users"></i> Members
                                </h3>
                            </div>
                            <div class="card-body">
                                <ul class="todo-list">
                                    <li class="primaryListItem" *ngFor="let member of members">
                                        <img class="img-fluid img-circle img-sm"
                                             src="{{ member.imageUrl }}" alt="">
                                        <span class="text">{{ member.name }}</span>
                                        <small class="badge badge-default"
                                               *ngIf="member.role === 'owner'">{{ member.role }}</small>
                                        <small class="badge badge-success"
                                               *ngIf="member.state === 'joined'">Member</small>
                                        <small class="badge badge-danger" *ngIf="member.state === 'invited'">Invite
                                            pending</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card card-outline card-green">
                            <div class="card-header">
                                <h3 class="card-title"><i class="fas fa-map-pin"></i> Meetups
                                </h3>
                            </div>
                            <div class="card-body">
                                <ul class="todo-list">
                                    <li class="primaryListItem" *ngFor="let meetup of pressCampMeetups">
                                        <span class="text">{{ meetup.name }}</span>
                                        <div class="tools">
                                            <i title="Edit meetup"
                                               [routerLink]="layout.getUrl('pressCampMeetupEdit', { meetupKey: meetup.meetupId, meetupName: meetup.name })"
                                               class="fas fa-edit text-blue"></i>&nbsp;
                                            <i title="Delete meetup"
                                               (click)="deletePressCampMeetup(meetup)"
                                               class="fas fa-trash"></i>
                                        </div>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
