import {Injectable} from '@angular/core';
import {AngularFireAction, AngularFireDatabase, DatabaseSnapshot} from "@angular/fire/compat/database";
import {combineLatest, forkJoin, Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {UserService} from "../../../firebase-services/user.service";
import {TrailAreaService} from "../../../firebase-services/trail-area.service";
import firebase from "firebase/compat";
import {EventManager, EventManagerListItem} from "../../../interfaces/event-manager";
import {TrailArea} from "../../../interfaces/trailArea";

@Injectable({
    providedIn: 'root'
})
export class EventManagerService {
    private eventManagersRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase,
        private userService: UserService,
        private trailAreaService: TrailAreaService
    ) {
        this.eventManagersRef = this.db.database.ref('managers/eventManagers')
    }

    getEventManagersListItems(): Observable<EventManagerListItem[]> {
        return this.db.list<EventManager>(this.eventManagersRef.ref).snapshotChanges()
            .pipe(
                switchMap(emSnapshots => forkJoin(
                    emSnapshots.map(emSnapshot => {
                        const eventManager: EventManager = this.EventManagerFromSnap(emSnapshot);
                        return this.userService.getUser(eventManager.userKey).pipe(
                            take(1),
                            switchMap(emUser => eventManager.trailAreas
                                ? this.trailAreaService.getTrailAreas(eventManager.trailAreas).pipe(
                                    take(1),
                                    map(emTrailAreas => ({
                                            user: emUser,
                                            trailAreas: emTrailAreas.sort((a, b) => a.country.localeCompare(b.country))
                                        })
                                    )
                                )
                                : of({user: emUser, trailAreas: []})
                            )
                        );
                    })
                ))
            );
    }

    public getEventManagerTrailAreas(userKey: string): Observable<TrailArea[]> {
        return this.db.list<string>(this.eventManagersRef.child(userKey).child('trailAreas').ref).snapshotChanges()
            .pipe(
                map(taKeys => taKeys.map(taKey => taKey.key)),
                switchMap(trailAreaKeys =>
                    trailAreaKeys.length
                        ? combineLatest(trailAreaKeys.map(key => this.trailAreaService.getTrailArea(key)))
                        : of([])
                )
            );
    }

    private EventManagerFromSnap(snapshot: AngularFireAction<DatabaseSnapshot<EventManager>>): EventManager {
        const eventManager: EventManager = snapshot.payload.val();
        eventManager.userKey = snapshot.key;
        eventManager.trailAreas = Object.values(eventManager.trailAreas ?? []);
        return eventManager;
    }

    public addTrailAreaToEventManager(userKey: string, areaKey: string): Promise<void> {
        return this.eventManagersRef.child(userKey).child('trailAreas').update({[areaKey]: areaKey});
    }

    public removeTrailAreaFromEventManager(userKey: string, areaKey: string): Promise<void> {
        return this.eventManagersRef.child(userKey).child('trailAreas').child(areaKey).remove();
    }

}