<div class="content-wrapper">
    <app-content-header-section *ngIf="destination" contentPageId="destinationTrailAreas"
                                [pageParams]="{ destinationName: destination.name, destinationKey: destination.key }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">

                    <div class="card card-outline card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Trail Areas</h3>
                        </div>
                        <div class="card-body p-0">
                            <ul class="todo-list" *ngIf="destinationTrailAreas">
                                <li *ngFor="let trailArea of destinationTrailAreas">
                                    <i class="far fa-map"></i> {{ trailArea.name }}
                                    <div class="tools">
                                        <i *ngIf="authService.isUser(roles.ADMIN)" title="Remove trail area from destination"
                                           (click)="removeTrailAreaFromDestination(trailArea.key)" class="far fa-trash-alt mr-2"></i>
                                        <a *ngIf="isTrailAreaAdmin[trailArea.key]" routerLink="/trail-area/{{ trailArea.key }}/trails"
                                           class="mr-2">
                                            <i class="fas fa-route" title="Trails in '{{trailArea.name}}'"></i>
                                        </a>
                                        <a *ngIf="isTrailAreaAdmin[trailArea.key]" routerLink="/trail-area/{{ trailArea.key }}/settings">
                                            <i class="far fa-edit" title="Edit the '{{trailArea.name}}'-trail area"></i>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="overlay" *ngIf="!destinationTrailAreas">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-md-6" *ngIf="authService.isUser(roles.ADMIN)">

                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">+&nbsp;<i class="far fa-map"></i> Add new Trail Area</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="name">Name (default, en)</label>
                                <input [(ngModel)]="newTrailAreaName" id="name" aria-describedby="nameHelp" class="form-control"
                                       placeholder="Trail area name">
                                <small class="form-text text-muted" id="nameHelp">Name the new trail area.</small>
                            </div>
                            <div class="form-group">
                                <label for="countryCode">Country</label>
                                <select *ngIf="countries" [(ngModel)]="newTrailAreaCountryCode" id="countryCode" aria-describedby="countryCodeHelp"
                                        class="form-control">
                                    <option *ngFor="let country of countries" value="{{ country.countryCode }}">{{ country.name }}
                                        , {{ country.countryCode }}</option>
                                </select>
                                <small class="form-text text-muted" id="countryCodeHelp">Select a country for the new trail area.</small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button (click)="createNewTrailArea()" class="btn btn-danger">Create new trail area</button>
                        </div>
                    </div>

                    <div class="card card-outline card-dark">
                        <div class="card-header">
                            <h3 class="card-title">Add existing trail area to this destination</h3>
                        </div>
                        <div class="card-body" *ngIf="freeTrailAreas">
                            <div class="input-group">
                                <span class="input-group-prepend"><span class="input-group-text">+&nbsp;<i class="far fa-map"></i></span></span>
                                <select id="selectTrailArea">
                                    <option *ngFor="let trailArea of freeTrailAreas; let i = index" value="{{ i }}">{{ trailArea.name }}
                                        [in {{ trailArea.country | uppercase }}] (key: {{ trailArea.key }})
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="card-footer" *ngIf="freeTrailAreas">
                            <button class="btn btn-success" (click)="addToDestination()" type="button"
                                    [disabled]="selectedFreeTrailAreaIndex === null">Add trail area to destination
                            </button>
                        </div>
                        <div class="overlay" *ngIf="!freeTrailAreas">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>

<div class="modal fade" id="modal-events" data-backdrop="static">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Events detected on trail area. Please choose what to do...</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <div class="radio">
                        <label>
                            <input type="radio" name="eventAction" id="eventAction" value="1" checked="checked"> Move events from trail
                            area to destination (recommended).
                        </label>
                    </div>
                    <div class="radio">
                        <label>
                            <input type="radio" name="eventAction"> Leave events on trail area.
                        </label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="handleEvents()">Continue</button>
            </div>
        </div>
    </div>
</div>
