<div class="content-wrapper">
    <app-content-header-section *ngIf="trailArea" contentPageId="trailAreaPois"
                                [pageParams]="{ trailAreaName: trailArea.name, trailAreaKey: trailArea.key }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div *ngIf="trailArea" class="row">
                <div class="col-md-6">

                    <div class="card card-outline card-danger">
                        <div class="card-header">
                            <h3 class="card-title">POIs</h3>
                        </div>
                        <div class="card-body">
                            <div class="list-group" *ngIf="pois">
                                <a *ngFor="let poi of pois" class="list-group-item list-group-item-action"
                                   [routerLink]="layout.getUrl('trailAreaPoiEdit', {trailAreaKey: trailArea.key, poiKey: poi.key})">
                                    <h5 class="mb-1">
                                        <i [ngClass]="poiStates[poi.key].icon + ' fa-fw text-' + poiStates[poi.key].theme + ' mr-2'"></i>
                                        {{ poi.name || 'POI without a name' }}
                                        <img *ngIf="poi.iconUrl" [src]="poi.iconUrl" width="32" height="32" class="" alt="POI Icon">
                                    </h5>
                                    <p class="text-right mb-1">
                                        <span appClickPreventDefault appClickStopPropagation>
                                            <i title="Remove POI from trail area" class="far fa-trash-alt text-danger"
                                               (click)="setRemovePoiCandidate(poi)"></i>
                                        </span>
                                    </p>
                                </a>
                            </div>
                        </div>
                        <div class="overlay" *ngIf="pois === null">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-md-6">

                    <app-create-on-map [themeColor]="layout.getTheme('poi')" [themeIcon]="layout.getIcon('poi')" typeName="POI"
                                       zoomRestrictions="loose" [areaObject]="trailArea" (createdOnMap)="createNewPoi($event)"></app-create-on-map>

                    <div class="card card-outline card-dark">
                        <div class="card-header">
                            <h3 class="card-title">Add existing POI to this area</h3>
                        </div>
                        <div id="assignPoiToTrailArea" class="card-body" *ngIf="missingPois && pois">
                            <div class="input-group">
                                <span class="input-group-prepend"><span class="input-group-text">+ <i class="fas fa-map-marker-alt"></i></span></span>
                                <select [(ngModel)]="selectedPoiKey" id="selectPoi">
                                    <option *ngFor="let poi of missingPois" [value]="poi.key">{{ poi.name }} ({{ poi.latitude | number:'1.0-3' }}
                                        , {{ poi.longitude | number:'1.0-3' }}), (key: {{ poi.key }})
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="card-footer" *ngIf="missingPois">
                            <button [disabled]="selectedPoiKey===null" class="btn btn-success" (click)="addPoiToTrailArea()" type="button">Add POI to
                                area
                            </button>
                        </div>
                        <div class="overlay" *ngIf="!missingPois">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>

<div class="modal fade" id="modal-remove" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Remove POI from trail area?</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    This action will remove the POI from the trail area.<br>
                    It will <strong>not</strong> be deleted.<br>
                    You can restore it from the add-list.
                </div>
                <div *ngIf="removePoiCandidate && trailArea" class="text-bold">Remove {{ removePoiCandidate.name }} from {{ trailArea.name }}?</div>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-default" data-dismiss="modal">No, don't remove</button>
                <button type="button" class="btn btn-primary" (click)="removePoiFromTrailArea(removePoiCandidate.key)" *ngIf="removePoiCandidate">Yes,
                    remove
                </button>
            </div>
        </div>
    </div>
</div>
