<div class="content-wrapper">
    <app-content-header-section *ngIf="area" contentPageId="trails"
                                [pageParams]="{ trailAreaName: area.name, trailAreaKey: area.key }"></app-content-header-section>

    <section *ngIf="area" class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">

                    <div class="card card-outline card-info">
                        <div class="card-header">
                            <h3 class="card-title">Trails</h3>
                        </div>
                        <div class="card-body p-0">
                            <ul class="todo-list" *ngIf="trails">
                                <li *ngFor="let trail of trails">
                                    <i class="fas fa-eye-slash mr-2" *ngIf="!trail.active" title="Deactivated"></i>
                                    <i class="fas fa-route mr-2" *ngIf="trail.active"></i>
                                    {{trail.name}} ({{trail.key}})
                                    <div class="tools">
                                        <i *ngIf="authService.isUser(roles.ADMIN)" title="Remove trail from trail area"
                                           (click)="setRemoveTrailCandidate(trail)" class="far fa-trash-alt mr-2"></i>
                                        <a routerLink="/trail-area/{{area.key}}/trails/{{trail.key}}">
                                            <i class="far fa-edit" title="Edit the '{{trail.name}}'-trail"></i>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="overlay" *ngIf="!trails">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
                <div class="col-md-6">

                    <div class="card card-info">
                        <div class="card-header">
                            <h3 class="card-title">+&nbsp;<i class="fas fa-route"></i> Add new trail</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="name">Name (default, en)</label>
                                <input [(ngModel)]="newTrailName" id="name" aria-describedby="nameHelp" class="form-control" placeholder="Trail name">
                                <small class="form-text text-muted" id="nameHelp">Name your new trail.</small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button (click)="createNewTrail()" class="btn btn-danger">Create new trail</button>
                        </div>
                    </div>

                    <div class="card card-outline card-dark" *ngIf="authService.isUser(roles.ADMIN)">
                        <div class="card-header">
                            <h3 class="card-title">Add existing trail to this area</h3>
                        </div>
                        <div class="card-body" *ngIf="unlinkedTrailsLoaded">
                            <div class="input-group">
                                <span class="input-group-prepend"><span class="input-group-text">+&nbsp;<i class="fas fa-route"></i></span></span>
                                <select [(ngModel)]="selectedTrailKey" id="selectTrail">
                                    <option *ngFor="let trail of unlinkedTrails" [value]="trail.key">{{ trail.name }} ({{ trail.key }})</option>
                                </select>
                            </div>
                        </div>
                        <div class="card-footer" *ngIf="unlinkedTrailsLoaded">
                            <button [disabled]="selectedTrailKey===null" class="btn btn-success" (click)="addTrailToTrailArea()" type="button">
                                Add trail to area
                            </button>
                        </div>
                        <div class="overlay" *ngIf="!unlinkedTrailsLoaded">
                            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>

<div class="modal fade" id="modal-remove" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Remove trail from trail area?</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <p *ngIf="removeTrailCandidate && area">Are you sure you want to remove {{ removeTrailCandidate.name }} from {{ area.name }}?</p>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-default" data-dismiss="modal">No, don't remove</button>
                <button type="button" class="btn btn-primary" (click)="removeTrailFromTrailArea(removeTrailCandidate.key)"
                        *ngIf="removeTrailCandidate">Yes, remove
                </button>
            </div>
        </div>
    </div>
</div>
