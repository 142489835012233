import { Component, Input, AfterViewChecked } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { EventService } from '../../firebase-services/event.service';

// Interfaces
import { Event, EventItem } from '../../interfaces/event';
import { User, Explorer, ExplorerItemStatus } from '../../interfaces/user';

declare var $: any;
import * as moment from 'moment';

@Component({
    selector: 'app-explorers',
    templateUrl: './explorers.component.html',
    styleUrls: ['./explorers.component.css']
})
export class ExplorersComponent implements AfterViewChecked {
    destroy$: Subject<boolean> = new Subject<boolean>();

    @Input() set event(event: Event) {
        this._event = event;
        this.init();
    }

    _event: Event;

    explorerUsers: User[] = null;
    explorers: Explorer[] = null;
    items: EventItem[] = null;

    dataTableCreated: boolean = false;
    allIsLoaded: boolean = false;
    editing: boolean = false;
    now: number;

    constructor(
        private eventService: EventService
    ) {
        this.now = moment.now();
    }

    ngAfterViewChecked() {
        if (!this.dataTableCreated && ($('#explorersDataTable>tbody>tr').length > 0)) {
            $('#explorersDataTable').DataTable({
                'order': [[this.items.length + 1, 'desc']]
            });
            this.dataTableCreated = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    private init() {
        this.explorerUsers = null;
        this.explorers = null;
        this.items = null;
        this.eventService.getEventItems(this._event.key)
            .pipe(takeUntil(this.destroy$))
            .subscribe((items) => {
                let _items: EventItem[] = [];
                for (var i = 0; i < items.length; i++) {
                    if (items[i].order && items[i].eventPoints) {
                        _items.push(items[i]);
                    }
                }
                this.items = _items.sort((a, b) => {
                    return a.order - b.order;
                });
                this.getExplorerItemStatuses();
            });
        this.eventService.getExplorers(this._event.key)
            .pipe(takeUntil(this.destroy$))
            .subscribe((users) => {
                this.explorerUsers = users;
                this.getExplorerItemStatuses();
            });
    }

    /**
     * Loads the item statuses for each explorer
     */
    private getExplorerItemStatuses(): void {
        if (this._event && this.items && this.explorerUsers && this.allIsLoaded === false) {
            this.eventService.getExplorersItemStatuses(this._event.key, this.items, this.explorerUsers)
                .pipe(takeUntil(this.destroy$))
                .subscribe(updatedExplorers => {
                    this.explorers = updatedExplorers;
                    this.allIsLoaded = true;
                });
        }
    }

    startEditMode(): void {
        this.editing = true;
    }

    registerElement(profileKey: string, explorerElementStatus: ExplorerItemStatus): number {
        const newStatus: ExplorerItemStatus = <ExplorerItemStatus>{};
        if (!explorerElementStatus.startTime) {
            newStatus.startTime = this.now;
        }
        if (explorerElementStatus.type === 2) {
            newStatus.percentageCompleted = explorerElementStatus.percentageCompleted;
        }
        this.eventService.setEventElementStatus(profileKey, this._event.key, explorerElementStatus.eventElementKey, newStatus);
        return this.now;
    }

    deleteElement(profileKey: string, eventElementKey: string): void {
        this.eventService.deleteEventElementStatus(profileKey, this._event.key, eventElementKey);
    }

    updateView(): void {
        this.init();
    }
}
