<div class="content-wrapper">
    <app-content-header-section *ngIf="sponsor" contentPageId="sponsorEdit"
                                [pageParams]="{ sponsorKey:sponsor.key, sponsorName:sponsor.alias}"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 col-lg-4">
                    <div *ngIf="sponsor" class="card card-outline card-teal">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fas fa-cogs"></i> Campaign settings</h3>
                        </div>
                        <div class="card-body" *ngIf="sponsor">
                            <app-alert-info-box infoTrigger="{{ settingsChangedInfo }}">
                                <h4>Campaign settings saved successfully</h4>
                            </app-alert-info-box>
                            <div class="alert alert-warning alert-dismissible d-none" role="alert">
                                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                            </div>
                            <div class="form-group">
                                <label for="activePeriod">Active period</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <i class="input-group-text far fa-clock"></i>
                                    </div>
                                    <input (change)="settingsChanged()" (keyup)="settingsChanged()"
                                           type="text" class="form-control" id="activePeriod"
                                           aria-describedby="activePeriodHelp">
                                </div>
                                <small class="form-text text-muted" id="activePeriodHelp">When the campaign will show up
                                    in the apps.</small>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label for="campaignAlias">Alias</label>
                                    <input [(ngModel)]="sponsor.alias" (change)="settingsChanged()"
                                           (keyup)="settingsChanged()" id="campaignAlias"
                                           aria-describedby="campaignAliasHelp" class="form-control">
                                </div>
                                <small class="form-text text-muted" id="campaignAliasHelp">Internal name for the
                                    campaign. Not used publicly.</small>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label for="campaignLink">Link</label>
                                    <input [(ngModel)]="sponsor.link" (change)="settingsChanged()"
                                           (keyup)="settingsChanged()" id="campaignLink"
                                           aria-describedby="campaignLinkHelp" class="form-control"
                                           placeholder="awsome-mtb-shop.com">
                                </div>
                                <small class="form-text text-muted" id="campaignLinkHelp">Link for sponsor
                                    website.</small>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="disableSaveSettings" (click)="saveSettings()" class="btn btn-primary">
                                Save settings
                            </button>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="card card-outline card-info" *ngIf="countries">
                        <div class="card-header">
                            <h3 class="card-title"><i class="fa fa-gift"></i> Setup sponsorship type</h3>
                        </div>
                        <div *ngIf="sponsor" class="card-body">
                            <app-alert-info-box infoTrigger="{{ typeChangedInfo }}">
                                <h4>Campaign type saved successfully</h4>
                            </app-alert-info-box>
                            <div class="alert alert-warning alert-dismissible d-none" role="alert">
                                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                            </div>
                            <label for="targetType"><i class="fa fa-gift"></i> Choose type</label>
                            <select [(ngModel)]="sponsor.sponsorType" class="form-control" id="targetType"
                                    aria-describedby="targetTypeHelp"
                                    (change)="typeChanged()" (keyup)="typeChanged()">
                                <option *ngFor="let targetType of sponsorTargetType"
                                        [value]="targetType">
                                    {{sponsorTargetType2LabelMapping[targetType]}}
                                </option>
                            </select>
                            <small class="form-text text-muted" id="targetTypeHelp">Select type of sponsorship.</small>
                            <br>
                            <div [ngSwitch]="sponsor.sponsorType">
                                <div *ngSwitchDefault class="">
                                    <label for="country"><i class="fa fa-gift"></i> Choose sponsorship type </label>
                                    <ul>
                                        <li>TrailArea - support specific trailArea(s)</li>
                                        <li>Local - Get banners shown within a specific radius</li>
                                        <li>Country - For main sponsors</li>
                                    </ul>
                                </div>
                                <div *ngSwitchCase="'trailAreaSpecificSponsor'">
                                    <div class="form-group">
                                        <label for="country"><i class="far fa-map"></i> Select trail area</label>
                                        <div style="display: flex; align-items:center;">
                                            <select [(ngModel)]="selectedTrailArea" class="form-control" id="country"
                                                    aria-describedby="countryHelp"
                                                    (change)="typeChanged()" (keyup)="typeChanged()">
                                                <option *ngFor="let trailArea of trailAreas" [ngValue]="trailArea">
                                                    {{ trailArea.name }} [in {{ trailArea.country | uppercase }}]
                                                </option>
                                            </select>
                                            <span style="align-items: center; padding: 3px; ;" class="">
                                            <button class="btn btn-info"
                                                    (click)="selectArea(selectedTrailArea)"> <label
                                                    for="selectedTrailArea"><i class="fa fa-plus"></i></label>
                                            </button>
                                        </span>
                                        </div>
                                        <small class="form-text text-muted" id="countryHelp">Select trailArea(s) to
                                            setup sponsorship</small>
                                    </div>
                                    <div class="card-body p-0">
                                        <table class="table" *ngIf="selectedTrailAreas" (change)="typeChanged()"
                                               (keyup)="typeChanged()">
                                            <tbody>
                                            <tr *ngFor="let trailArea of selectedTrailAreas">
                                                <td><i class="far fa-map"></i> {{ trailArea.name }}
                                                </td>
                                                <td>
                                                    <button class="btn btn-xs" (click)="deleteSelectedArea(trailArea)">
                                                        <label for="selectedArea"><i
                                                                class="far fa-trash-alt"></i></label>
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div *ngSwitchCase="'areaSpecificSponsor'" class="">
                                    <div class="form-group">
                                        <label for="country"><i class="fa fa-map-pin"></i> Local radius</label>

                                        <div>
                                            <label for="alias">Latitude</label>
                                            <input [(ngModel)]="sponsor.latitude" (change)="typeChanged()"
                                                   (keyup)="typeChanged()" id="alias"
                                                   aria-describedby="aliasHelp" class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div>
                                            <label for="alias">Longitude</label>
                                            <input [(ngModel)]="sponsor.longitude" (change)="typeChanged()"
                                                   (keyup)="typeChanged()" id="alias"
                                                   aria-describedby="aliasHelp" class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div>
                                            <label for="alias">Radius</label>
                                            <input [(ngModel)]="sponsor.radius" (change)="typeChanged()"
                                                   (keyup)="typeChanged()" id="alias"
                                                   aria-describedby="aliasHelp" class="form-control">
                                        </div>
                                    </div>


                                </div>
                                <div *ngSwitchCase="'countrySpecificSponsor'" class="">
                                    <div class="form-group">
                                        <label for="country"><i class="far fa-flag"></i> Select country</label>
                                        <select [(ngModel)]="selectedCountry" class="form-control" id="country"
                                                aria-describedby="countryHelp"
                                                (change)="typeChanged()" (keyup)="typeChanged()">
                                            <option *ngFor="let country of countries" [ngValue]="country">
                                                {{ country.name }}
                                            </option>
                                        </select>
                                        <small class="form-text text-muted" id="countryHelp">Select country for global
                                            banners.</small>
                                    </div>

                                    <div class="form-group">
                                        <label><i class="fas fa-comment-dollar mr-2"></i> The trails in this area are
                                            free?</label>
                                        <div>
                                            <input [(ngModel)]="isMainSponsor" type="radio" name="isFree"
                                                   value="true"
                                                   (change)="mainSponsorStatusChanged()"> Yes
                                            <input [(ngModel)]="isMainSponsor" type="radio" name="isFree"
                                                   value="false"
                                                   (change)="mainSponsorStatusChanged()"> No
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="disableSaveType" (click)="saveType()" class="btn btn-primary">
                                Save sponsorship type
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <app-image-handler *ngIf="sponsor" imageName="Banner Image" imageIndex="0"
                                       imageUrl="{{ sponsor.imageURL }}"
                                       imagePath="sponsor/{{ sponsor.key }}/" (imageSize)="setImageSize($event)"
                                       (uploadedImageUrl)="onUploadedImageUrl($event)"></app-image-handler>
                </div>
            </div>
        </div>
    </section>
</div>
