import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {Organisation} from "../../../interfaces/organisation";
import firebase from "firebase/compat";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {OrganisationService} from "../../../firebase-services/organisation.service";

@Injectable({
  providedIn: 'root'
})
export class OrganisationManagerService {

  private organisationManagersRef: firebase.database.Reference;

  constructor(
      private db: AngularFireDatabase,
      private organisationService: OrganisationService
  ) {
    this.organisationManagersRef = this.db.database.ref('managers/organisationManagers');
  }

  public getOrganisationManagerOrganisations(userKey: string): Observable<Organisation[]> {
    return this.db.list<string>(this.organisationManagersRef.child(userKey).child('organisations').ref).snapshotChanges()
        .pipe(
            map(orgKeys => orgKeys.map(orgKey => orgKey.key)),
            switchMap(organisationKeys =>
                organisationKeys.length
                    ? combineLatest(organisationKeys.map(key => this.organisationService.getOrganisation(key)))
                    : of([])
            )
        );
  }

  public addOrganisationToOrganisationManager(userKey: string, orgKey: string): Promise<void> {
    return this.organisationManagersRef.child(userKey).child('organisations').update({[orgKey]: orgKey});
  }
}
