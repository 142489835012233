<div class="content-wrapper" *ngIf="pressCampMeetup">
    <app-content-header-section contentPageId="pressCampMeetupEdit"
                                [pageParams]="{meetupKey: pressCampMeetup.meetupId, meetupName: pressCampMeetup.name}">
    </app-content-header-section>

    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-5">
                    <div class="col-md-12">
                        <div class="card card-outline card-info">
                            <div class="card-header">
                                <h3 class="card-title"><i class="fas fa-cogs"></i> Settings</h3>
                            </div>
                            <div class="card-body">
                                <app-alert-info-box infoTrigger="{{ meetupSettingsSaved }}">
                                    <h4>Press camp meetup settings saved successfully</h4>
                                </app-alert-info-box>
                                <div class="form-group">
                                    <label for="pressCampName">Name</label>
                                    <div class="input-group">
                                        <input class="form-control" id="pressCampName"
                                               [(ngModel)]="pressCampMeetup.name"
                                               aria-describedby="pressCampNameHelp"
                                               placeholder="Enter meetup name">
                                    </div>
                                    <small class="form-text text-muted" id="pressCampNameHelp">
                                        Choose a name for your press camp.
                                    </small>
                                    <small *ngIf="!pressCampMeetup.name" class="form-text text-danger"><i
                                            class="fas fa-exclamation-triangle"></i>
                                        Please provide a meetup name.
                                    </small>
                                </div>
                                <div class="form-group">
                                    <label for="pressCampDescription">Description</label>
                                    <div class="input-group">
                                    <textarea class="form-control" id="pressCampDescription"
                                              [(ngModel)]="pressCampMeetup.description"
                                              aria-describedby="pressCampDescriptionHelp" rows="3"
                                              placeholder="Enter meetup description">
                                    </textarea>
                                    </div>
                                    <small class="form-text text-muted" id="pressCampDescriptionHelp">
                                        Describe your press camp meetup.
                                    </small>
                                    <small *ngIf="!pressCampMeetup.description" class="form-text text-danger"><i
                                            class="fas fa-exclamation-triangle"></i>
                                        Please provide a meetup description.
                                    </small>
                                </div>
                                <div class="form-group">
                                    <label for="trailArea">Trail area</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <app-find-trail-area modalId="selectTrailArea"
                                                                 modalTitle="Find trail area for meetup"
                                                                 id="selectTrailArea"
                                                                 (areaSelected)="onAreaSelected($event)"
                                                                 btnClassType="no-btn">
                                                <span class="input-group-text">
                                                    <i class="fas fa-route p-1"></i>
                                                </span>
                                            </app-find-trail-area>
                                        </div>
                                        <input type="text" class="form-control" id="trailArea" readonly
                                               [(ngModel)]="selectedTrailAreaName"
                                               aria-describedby="trailAreaHelp" placeholder="Select trail area">
                                    </div>
                                    <small class="form-text text-muted" id="trailAreaHelp">
                                        Trail area as meeting place for meetup. Leave blank if meeting place is not
                                        at a trail area.
                                    </small>
                                </div>
                                <div class="form-group">
                                    <label for="meetingPlace">Meeting place</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <i class="input-group-text far fa-map pointer"
                                               (click)="openMapPicker()"></i>
                                        </div>
                                        <input type="text" class="form-control" id="meetingPlace" readonly
                                               [(ngModel)]="meetingPlaceName"
                                               aria-describedby="meetingPlaceHelp" placeholder="Select meeting place">
                                    </div>
                                    <small class="form-text text-muted" id="meetingPlaceHelp">Meeting place for the
                                        press camp
                                        meetup.</small>
                                    <small *ngIf="meetingPlaceName === ''" class="form-text text-danger"><i
                                            class="fas fa-exclamation-triangle"></i>
                                        Please provide a meeting place.
                                    </small>
                                </div>
                                <div *ngIf="isMapPickerOpen" class="modal" (click)="closeMapPicker()">
                                    <div class="modal-content" (click)="$event.stopPropagation()">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <h5>Select meeting place</h5>
                                            <button class="btn" (click)="closeMapPicker()">
                                                <i class="fas fa-times"></i>
                                            </button>
                                        </div>
                                        <app-map-picker
                                                (locationSelected)="onLocationSelected($event)"
                                                [latitude]="pressCampMeetup.latitude"
                                                [longitude]="pressCampMeetup.longitude"></app-map-picker>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="dateTime">Date and time</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <i class="input-group-text far fa-clock"></i>
                                        </div>
                                        <input type="text" class="form-control" id="dateTime"
                                               aria-describedby="dateTimeHelp"
                                               placeholder="Select Date and Time">
                                    </div>
                                    <small class="form-text text-muted" id="dateTimeHelp">Date and time for the
                                        press camp
                                        meetup.</small>
                                    <small *ngIf="meetingPlaceName === ''" class="form-text text-danger"><i
                                            class="fas fa-exclamation-triangle"></i>
                                        Please provide a meeting place before selecting a time and date.
                                    </small>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button
                                        [disabled]="saveMeetupSettingsDisabled"
                                        type="submit"
                                        class="btn btn-primary position-relative"
                                        (click)="saveSettings()">
                                    <span class="d-block text-center"
                                          [style.visibility]="savingSettings ? 'hidden' : 'visible'">
                                        Save settings
                                    </span>
                                    <span *ngIf="savingSettings"
                                          class="position-absolute w-100 text-center"
                                          style="top: 50%; left: 0; transform: translateY(-50%); text-align: center;">
                                        <i class="fas fa-sync-alt fa-spin"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card card-outline card-warning">
                            <div class="card-header">
                                <h3 class="card-title"><i class="fas fa-map-pin"></i> Participants
                                </h3>
                            </div>
                            <div class="card-body">
                                <app-alert-info-box infoTrigger="{{ meetupParticipantsInvited }}">
                                    <h4>Press camp meetup participants invited successfully</h4>
                                </app-alert-info-box>
                                <div class="form-group" *ngIf="pressCampMeetupParticipants">
                                    <label>
                                        <h6>Accepted</h6>
                                    </label>
                                    <ul class="todo-list">
                                        <li class="primaryListItem"
                                            *ngFor="let participant of pressCampMeetupParticipants">
                                            <img class="img-fluid img-circle img-sm"
                                                 src="{{ participant.userPicture }}" alt="">
                                            <span class="text">{{ participant.name }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="form-group" *ngIf="pressCampMeetupPendingInvites
                                    && pressCampMeetupPendingInvites.length > 0">
                                    <label>
                                        <h6>Pending invites</h6>
                                    </label>
                                    <ul class="todo-list">
                                        <li class="primaryListItem"
                                            *ngFor="let participant of pressCampMeetupPendingInvites">
                                            <img class="img-fluid img-circle img-sm"
                                                 src="{{ participant.userPicture }}" alt="">
                                            <span class="text">{{ participant.name }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card-footer d-flex align-items-center" *ngIf="filterList && pressCampMeetup?.groupKey">
                                <app-group-member-picker modalId="{{ pressCampMeetup.groupKey }}"
                                                         modalTitle="Select users from members of the group"
                                                         [groupKey]="pressCampMeetup.groupKey"
                                                         [filterList]="filterList"
                                                         id="selectFromGroupMembers"
                                                         (membersSelected)="onGroupMembersSelected($event)"
                                                         btnClassType="success"> Invite group members
                                </app-group-member-picker>
                                <span *ngIf="invitingParticipants" class="ml-auto">
                                    <i class="fas fa-sync-alt fa-spin"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <app-image-handler *ngIf="pressCampMeetup" imageName="Press camp meetup cover image" imageIndex="0"
                                       sizeNote="No particular preferred size, though 1:1 aspect ratio is good."
                                       imageUrl="{{ pressCampMeetup.imageUrl }}"
                                       imagePath="meetups/{{ pressCampMeetup.meetupId }}/"
                                       (uploadedImageUrl)="onUploadedImageUrl($event)">
                    </app-image-handler>
                </div>
            </div>
        </div>
    </div>
</div>

