<div *ngIf="destination" class="content-wrapper">
    <app-content-header-section *ngIf="destination" contentPageId="destinationEvents"
                                [pageParams]="{ destinationName: destination.name, destinationKey: destination.key }"></app-content-header-section>

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div *ngIf="events" class="col-md-6">

                    <app-event-list [routerRoot]="routerRoot" [events]="events"></app-event-list>

                </div>
                <div *ngIf="destination" class="col-md-6">

                    <app-create-on-map [themeColor]="layout.getTheme('event')" [themeIcon]="layout.getIcon('event')" typeName="Event"
                                       [areaObject]="destination" (createdOnMap)="createNewEvent($event)"></app-create-on-map>

                </div>
            </div>
        </div>
    </section>

</div>
