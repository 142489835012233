import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';

// Services
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database/';

// Interfaces
import { Sponsor, SponsorTargetType, MINIMUM_TIME_SLOT_MILLISECONDS, MINIMUM_TIME_SLOT_MINUTES  } from '../interfaces/sponsor';

import firebase from 'firebase/compat';
import * as moment from 'moment';
import { TextObject } from '../interfaces/text-model';

@Injectable({
    providedIn: 'root'
})
export class SponsorService {

     sponsorRef: firebase.database.Reference;
    // treatHitRef: firebase.database.Reference;
    // treatExposureRef: firebase.database.Reference;
    // treatsTabTextsRef: firebase.database.Reference;

    constructor(
        private db: AngularFireDatabase
    ) {
         this.sponsorRef = this.db.database.ref('sponsor');
        // this.treatHitRef = this.db.database.ref('treatHit');
        // this.treatExposureRef = this.db.database.ref('treatExposure');
        // this.treatsTabTextsRef = this.db.database.ref('texts').child('treatsTab');
    }

    private static sponsorSnapshotToSponsor(snapshot: AngularFireAction<DatabaseSnapshot<Sponsor>>): Sponsor {
        console.log("sponsnapshot.keysorKey1", snapshot.key)
        const sponsor = {
            key: snapshot.key,
            ...snapshot.payload.val()
        };
        if (typeof sponsor.lang !== 'object' || sponsor.lang === null) {
            sponsor.lang = {};
        }
        return sponsor;
    }

    /**
     * Gets a specific treat from key
     */
    getSponsor(sponsorKey: string): Observable<Sponsor> {
        console.log("sponsorKey1", sponsorKey)
        return this.db.object<Sponsor>(`sponsor/${sponsorKey}`).snapshotChanges().pipe(
            map((rawTreat) => SponsorService.sponsorSnapshotToSponsor(rawTreat))
        );
    }

    /**
     * Gets all treats without statistics
     */
    getSponsors(): Observable<Sponsor[]> {
        return this.db.list<Sponsor>(`sponsor`).snapshotChanges().pipe(
            take(1),
            map((rawTreats) => rawTreats.map((rawTreat) => SponsorService.sponsorSnapshotToSponsor(rawTreat)))
        );
    }

    // enhanceTreatWithStatistics(treat: Sponsor): Observable<Sponsor> {
    //     const hitsObservable = this.db.list<TreatHit>(this.treatHitRef.child(sponsor.key).ref).valueChanges();
    //     const exposuresObservable = this.db.list<TreatExposure>(this.treatExposureRef.child(treat.key).ref).snapshotChanges();
    //     return zip(hitsObservable, exposuresObservable).pipe(
    //         take(1),
    //         map(([hits, exposureSnaps]) => {
    //             const exposures: { [key: string]: TreatExposure } = {};
    //             exposureSnaps.forEach((exposureSnap) => {
    //                 exposures[exposureSnap.key] = exposureSnap.payload.val();
    //             });
    //             treat.myTreatsExposures = 0;
    //             treat.topOfMindExposures = 0;
    //             treat.myTreatsHits = 0;
    //             treat.topOfMindHits = 0;
    //             Object.keys(exposures).forEach((exposureKey) => {
    //                 if (exposures[exposureKey].type === ExposureType.MYTREATS) {
    //                     treat.myTreatsExposures++;
    //                 } else {
    //                     treat.topOfMindExposures++;
    //                 }
    //             });
    //             hits.forEach((hit) => {
    //                 if (exposures[hit.treatExposureKey].type === ExposureType.MYTREATS) {
    //                     treat.myTreatsHits++;
    //                 } else {
    //                     treat.topOfMindHits++;
    //                 }
    //             });
    //             return treat;
    //         })
    //     );
    // }

    /**
     * Updates the settings of the Treat
     */
    updateSettings(sponsor: Sponsor): Promise<void> {
        console.log("sponsor.key:", sponsor)
        return this.sponsorRef.child(sponsor.key).update(<Sponsor>{
            key: sponsor.key || null,
            activeFrom: sponsor.activeFrom || 0,
            activeTo: sponsor.activeTo || (sponsor.activeFrom + 900000) || 900000,
            alias: sponsor.alias || null,
            link:  sponsor.link || null,
            imageURL: sponsor.imageURL || null,
             sponsorType: sponsor.sponsorType || SponsorTargetType.NO_SPONSOR_TARGET_TYPE_SELECTED,
             autoRenewable: sponsor.autoRenewable || null,
            longitude: sponsor.longitude || 0,
            latitude: sponsor.latitude || 0,
            radius: sponsor.radius || 0,
            country: sponsor.country || null,
            trailTargetKeys: sponsor.trailTargetKeys,
            countryFilter: sponsor.countryFilter || null,
            imageWidth: sponsor.imageWidth || 0,
            imageHeight: sponsor.imageHeight || 0
            

            
        //    targetTopOfMind: treat.targetTopOfMind || 0,
         //   targetMyTreats: treat.targetMyTreats || 0
        });
    }

    /**
     * Updates the image of the Treat
     */
    uploadedImage(sponsor: Sponsor): Promise<void> {
        return this.sponsorRef.child(sponsor.key).update(<Sponsor>{
            imageURL: sponsor.imageURL
        });
    }


    /**
     * Updates the image of the Treat
     */
    uploadedImageSize(sponsor: Sponsor): Promise<void> {
        return this.sponsorRef.child(sponsor.key).update(<Sponsor>{
            imageHeight: sponsor.imageHeight,
            imageWidth: sponsor.imageWidth
        });
    }

    // /**
    //  * Updates the texts of the Treat
    //  */
    // updateTexts(sponsor: Sponsor): Promise<void> {
    //     // Delete texts that are blank
    //     Object.keys(sponsor.lang).forEach((lang) => {
    //         Object.keys(sponsor.lang[lang]).forEach((text) => {
    //             if (!sponsor.lang[lang][text] || sponsor.lang[lang][text] === 'undefined') {
    //                 sponsor.lang[lang][text] = null;
    //             }
    //         });
    //     });

    //     const updateElement = <Sponsor>{
    //      //   topOfMindTitle: sponsor.topOfMindTitle,
    //        // topOfMindSubHeader: sponsor.topOfMindSubHeader || null,
    //         link: sponsor.link || null,
    //         lang: sponsor.lang
    //     };
    //     return this.sponsorRef.child(sponsor.key).update(updateElement);
    // }

    /**
     * Copy treat
     * @todo: Copy image
     */
    copySponsor(sponsor: Sponsor): Promise<string> {
        const activeFrom: number = Math.ceil(Math.max(sponsor.activeTo, moment.now()) / MINIMUM_TIME_SLOT_MILLISECONDS)
            * MINIMUM_TIME_SLOT_MILLISECONDS;
        const activeTo: number = Math.ceil(
            (activeFrom + Math.max(Math.abs(sponsor.activeTo - sponsor.activeFrom), MINIMUM_TIME_SLOT_MILLISECONDS))
            / MINIMUM_TIME_SLOT_MILLISECONDS) * MINIMUM_TIME_SLOT_MILLISECONDS;
        const copy: Sponsor = {
            alias: sponsor.alias + ' copy',
            //   topOfMindTitle: sponsor.topOfMindTitle,
            //  topOfMindSubHeader: sponsor.topOfMindSubHeader,
            link: sponsor.link,
            lang: sponsor.lang,
            activeFrom: activeFrom,
            activeTo: activeTo,
            imageURL: '',
            sponsorType: sponsor.sponsorType,
            longitude: sponsor.longitude,
            latitude: sponsor.latitude,
            radius: sponsor.radius,
            country: sponsor.country,
            trailTargetKeys: sponsor.trailTargetKeys,
            countryFilter: sponsor.countryFilter,
            imageWidth: sponsor.imageWidth,
            imageHeight: sponsor.imageHeight
            
            //autoRenewable: undefined
        };
        const thenablePromise = this.sponsorRef.push(copy);
        return thenablePromise
            .then(() => thenablePromise.key);
    }


    newSponsor(): Promise<string> {
        const newCampaign: Sponsor = {
            alias: "new",
            sponsorType: SponsorTargetType.NO_SPONSOR_TARGET_TYPE_SELECTED
            

        };
    
       
        const thenablePromise = this.sponsorRef.push(newCampaign);
        console.log("thenablePromise:", thenablePromise.key) 
        return thenablePromise
            .then(() => thenablePromise.key);
    }




    // getTreatsTabTexts(): Observable<TreatsTabTexts> {
    //     return this.db.object<TreatsTabTexts>(this.treatsTabTextsRef.ref).valueChanges();
    // }

    // updateTreatsTabTexts(treatsTabTexts: TextObject): Promise<void> {
    //     // Delete texts that are blank
    //     for (const lang in treatsTabTexts.lang) {
    //         if (typeof treatsTabTexts.lang[lang] === 'object') {
    //             for (const text in treatsTabTexts.lang[lang]) {
    //                 if (!treatsTabTexts.lang[lang][text] || treatsTabTexts.lang[lang][text] === 'undefined') {
    //                     treatsTabTexts.lang[lang][text] = null;
    //                 }
    //             }
    //         }
    //     }

    //     const updateElement = <TextObject>{
    //         title: treatsTabTexts.title,
    //         description: treatsTabTexts.description || null,
    //         lang: treatsTabTexts.lang
    //     };
    //     return this.treatsTabTextsRef.update(updateElement);
    // }
}
