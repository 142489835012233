import {AfterViewChecked, Component, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

// Services
import {SponsorService} from 'src/app/firebase-services/sponsor.service';
import {AuthService} from 'src/app/core/auth.service';
import {UserService} from 'src/app/firebase-services/user.service';
import {CountryService} from 'src/app/firebase-services/country.service';
import {DestinationService} from 'src/app/firebase-services/destination.service';
import {TrailAreaService} from 'src/app/firebase-services/trail-area.service';

// Interfaces
import {Country} from 'src/app/interfaces/countries';
import {TrailArea} from 'src/app/interfaces/trailArea';

// Interfaces
import {
    MINIMUM_TIME_SLOT_MINUTES,
    Sponsor,
    SponsorTargetType,
    SponsorTargetType2LabelMapping
} from '../../../interfaces/sponsor';

import * as moment from 'moment';

declare var $: any;


@Component({
    selector: 'app-sponsor-edit',
    templateUrl: './sponsor-edit.component.html',
    styleUrls: ['./sponsor-edit.component.css']
})
export class SponsorEditComponent implements AfterViewChecked, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    public sponsorTargetType2LabelMapping = SponsorTargetType2LabelMapping;

    public sponsorTargetType = Object.values(SponsorTargetType);

    sponsor: Sponsor = null;
    country: Country = null;
    countries: Country[] = [];
    trailAreas: TrailArea[] = null;

    typeChangedInfo = 0;
    settingsChangedInfo = 0;
    disableSaveType = true;
    disableSaveSettings = true;
    selectedCountry: Country = null;
    selectedTrailAreas: TrailArea[] = [];
    selectedTrailArea: TrailArea = null;
    isMainSponsor = 'false';

    activePeriodIsLoaded = false;
    activePeriodName = '#activePeriod';
    timeFormat = 'DD-MM-YYYY HH:mm';

    constructor(
        public authService: AuthService,
        private countryService: CountryService,
        private destinationService: DestinationService,
        private trailAreaService: TrailAreaService,
        private userService: UserService,
        private route: ActivatedRoute,
        private sponsorService: SponsorService
    ) {
        this.countryService.getCountries()
            .pipe(take(1))
            .subscribe((countries) => this.countries = countries);

        this.sponsorService.getSponsor(this.route.snapshot.paramMap.get('sponsorKey'))
            .pipe(takeUntil(this.destroy$))
            .subscribe((sponsor) => {
                if (sponsor.trailTargetKeys) {
                    this.trailAreaService.getTrailAreas(sponsor.trailTargetKeys)
                        .pipe(take(1))
                        .subscribe((trailAreas) => this.selectedTrailAreas = trailAreas);
                }
                if (sponsor.country) {
                    this.selectedCountry = this.countries.find((c) => c.countryCode === sponsor.country);
                }
                this.isMainSponsor = sponsor.isMainSponsor !== false ? 'true' : 'false';
                this.sponsor = sponsor;
            });

        this.trailAreaService.getFreeTrailAreas()
            .pipe(take(1))
            .subscribe((freeTrailAreas) => this.trailAreas = freeTrailAreas);
    }

    ngAfterViewChecked() {
        if (this.sponsor !== null && !this.activePeriodIsLoaded && $(this.activePeriodName).length > 0) {
            const sponsorEditComponent = this;
            $(function () {
                $(sponsorEditComponent.activePeriodName).daterangepicker(
                    {
                        timePicker: true,
                        timePicker24Hour: true,
                        timePickerIncrement: MINIMUM_TIME_SLOT_MINUTES,
                        startDate: moment(sponsorEditComponent.sponsor.activeFrom),
                        endDate: moment(sponsorEditComponent.sponsor.activeTo),
                        locale: {
                            format: sponsorEditComponent.timeFormat
                        }
                    },
                    function (start: moment.Moment, end: moment.Moment) {
                        sponsorEditComponent.sponsor.activeFrom = start.unix() * 1000;
                        sponsorEditComponent.sponsor.activeTo = end.unix() * 1000;
                    }
                );
            });
            this.activePeriodIsLoaded = true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    mainSponsorStatusChanged(): void {
        this.disableSaveType = false;
    }

    deleteSelectedArea(trailArea: TrailArea): void {
        this.selectedTrailAreas = this.selectedTrailAreas.filter(area => area.key !== trailArea.key);
    }

    selectArea(trailArea: TrailArea) {
        this.selectedTrailAreas.push(trailArea);
    }

    /**
     * Sets state-variable when settings are changed
     */
    settingsChanged(): void {
        this.disableSaveSettings = false;
    }

    /**
     * Sets state-variable when type is changed
     */
    typeChanged(): void {
        this.disableSaveType = false;
    }

    /**
     * Save settings
     */
    saveSettings(): void {
        this.disableSaveSettings = true;
        console.log("Saving sponsor settings: ", this.sponsor)
        this.sponsorService.updateSettings(this.sponsor)
            .then(() => this.settingsChangedInfo++)
            .catch((err) => console.error('Settings-Update error occurred:', err.message));
    }

    /**
     * Save target types
     */
    saveType(): void {
        this.disableSaveType = true;
        if (this.selectedCountry) {
            console.log("this.selectedCountry", this.selectedCountry)
            this.sponsor.country = this.selectedCountry.countryCode
            this.sponsor.countryFilter = this.selectedCountry.countryCode
        }
        this.sponsor.trailTargetKeys = this.selectedTrailAreas.map(a => a.key);
        this.sponsor.isMainSponsor = this.isMainSponsor === 'true';
        console.log("Saving sponsor type: ", this.sponsor)
        this.sponsorService.updateType(this.sponsor)
            .then(() => this.typeChangedInfo++)
            .catch((err) => console.error('Type update error occurred:', err.message));
    }

    setImageSize(size: { width: number, height: number }) {
        console.log("width", size.width)
        console.log("height", size.height)
        this.sponsor.imageWidth = size.width
        this.sponsor.imageHeight = size.height
        this.sponsorService.uploadedImageSize(this.sponsor)
            .catch((err) => console.error('ImageSize failure', err));
    }

    onUploadedImageUrl(uploadedImageUrl: string): void {
        this.sponsor.imageURL = uploadedImageUrl;
        this.sponsorService.uploadedImage(this.sponsor)
            .catch((err) => console.error('Image upload failure', err));
    }
}