import { environment } from '../../environments/environment';

import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import {map, take} from 'rxjs/operators';

import polyTool from '@pirxpilot/google-polyline';

// Services
import { CollectionService } from '../firebase-services/collection.service';
import { PoiService } from '../firebase-services/poi.service';
import { PoiCategoryService } from '../firebase-services/poi-category.service';
import { TrailService } from '../firebase-services/trail.service';

// Interfaces
import { Destination } from '../interfaces/destination';
import {CountryOnMap, DestinationOnMap, TrailAreaOnMap} from '../interfaces/map';
import { DraftPoi, PublicPoi } from '../interfaces/poi';
import { PublicPoiCategory } from '../interfaces/poi-category';
import { Trail } from '../interfaces/trail';
import { LatLngArray, TrailArea } from '../interfaces/trailArea';
import {Country} from "../interfaces/countries";
import {TrailAreaService} from "../firebase-services/trail-area.service";

const zoomThresholds = {
    showPoiAt: 12,
    hideTrailAreaAt: 14
};

@Injectable({
    providedIn: 'root'
})
export class MapWorkerService {

    infoWindow: google.maps.InfoWindow;

    publicPoiCategories$: Observable<{ [label: string]: PublicPoiCategory }>;

    constructor(
        private collectionService: CollectionService,
        private poiService: PoiService,
        private poiCategoryService: PoiCategoryService,
        private trailService: TrailService
    ) {
        this.infoWindow = new google.maps.InfoWindow({
            disableAutoPan: false
        });
        // POI categories
        this.publicPoiCategories$ = this.poiCategoryService.getPublicPoiCategories();
    }

    private static addPoiMarker(
        icon: google.maps.Icon, position: google.maps.LatLngLiteral, startVisible: boolean, editing = false): google.maps.Marker {
        const marker = new google.maps.Marker({
            animation: google.maps.Animation.DROP,
            clickable: false,
            draggable: false,
            icon: icon,
            optimized: false,
            position: position,
            visible: editing || startVisible,
            opacity: (editing ? 1 : 0.8)
        });
        if (editing) {
            marker.setZIndex(500);
        }
        return marker;
    }

    private static getIconClusterSetup(length: number): { size: number, offsets: { x: number, y: number }[] } {
        // Find out how many icons to put in each row
        const maxRowSize = Math.ceil(Math.sqrt(length));
        const iconRows = Math.ceil(length / maxRowSize);
        const rows = [];
        for (let i = 0; i < iconRows; i++) {
            const iconsInRow = Math.floor(length / iconRows) + (i < (length % iconRows) ? 1 : 0);
            rows.push(iconsInRow);
        }

        const offsets: { x: number, y: number }[] = [];
        const iconSize = length > maxRowSize * 2 ? 16 : length > 4 ? 20 : length > 1 ? 24 : 32;

        // Position icons individually in each row
        for (let i = 0; i < rows.length; i++) {
            let x = rows[i] / 2 * iconSize;
            for (let j = 0; j < rows[i]; j++) {
                offsets.push({x: x, y: iconSize * (iconRows - i)});
                x -= iconSize;
            }
        }

        // Return values, needed for setting up the icon cluster
        return {
            offsets: offsets,
            size: iconSize
        };
    }

    countryToMap(country: Country, googleMap: google.maps.Map, editPoiKey: string = null): Observable<CountryOnMap> {
        const markerLatLngLiteral: google.maps.LatLngLiteral = {
            lat: (country.boundsNorth + country.boundsSouth) / 2,
            lng: (country.boundsEast + country.boundsWest) / 2
        };
        const marker = new google.maps.Marker({
            position: markerLatLngLiteral,
            draggable: false,
            clickable: false,
            map: googleMap
        });

        const pois$ = this.poiService.getPoisForCountry(country.countryCode);

        // Poi Markers
        const poiMarkerClusters$ = combineLatest([this.publicPoiCategories$, pois$])
            .pipe(
                map(([publicPoiCategories, pois]) => {
                    const poiMarkerClusters: { [poiKey: string]: google.maps.Marker[] } = {};
                    pois.forEach((poi) => {
                        if (poi.active) {
                            poiMarkerClusters[poi.key] = MapWorkerService.poiToMap(
                                poi, googleMap, publicPoiCategories, poi.key === editPoiKey);
                        }
                    });
                    return poiMarkerClusters;
                })
            );

        return combineLatest([poiMarkerClusters$])
            .pipe(
                map(([poiMarkerClusters]) => {
                    const countryOnMap: TrailAreaOnMap = {
                        centerMarker: marker,
                        poiMarkerClusters: poiMarkerClusters,
                        editPoiKey: editPoiKey
                    };

                    MapWorkerService.countryMapZoomed(googleMap, countryOnMap);

                    return countryOnMap;
                })
            );
    }

    destinationToMap(destination: Destination, googleMap: google.maps.Map, editPoiKey: string = null): Observable<DestinationOnMap> {
        const markerLatLngLiteral: google.maps.LatLngLiteral = {
            lat: (destination.boundsNorth + destination.boundsSouth) / 2,
            lng: (destination.boundsEast + destination.boundsWest) / 2
        };
        const marker = new google.maps.Marker({
            position: markerLatLngLiteral,
            draggable: false,
            clickable: false,
            map: googleMap
        });

        const pois$ = this.poiService.getPoisForDestination(destination.key);

        // Poi Markers
        const poiMarkerClusters$ = combineLatest([this.publicPoiCategories$, pois$])
            .pipe(
                map(([publicPoiCategories, pois]) => {
                    const poiMarkerClusters: { [poiKey: string]: google.maps.Marker[] } = {};
                    pois.forEach((poi) => {
                        if (poi.active) {
                            poiMarkerClusters[poi.key] = MapWorkerService.poiToMap(
                                poi, googleMap, publicPoiCategories, poi.key === editPoiKey);
                        }
                    });
                    return poiMarkerClusters;
                })
            );

        return combineLatest([poiMarkerClusters$])
            .pipe(
                map(([poiMarkerClusters]) => {
                    const destinationOnMap: DestinationOnMap = {
                        centerMarker: marker,
                        poiMarkerClusters: poiMarkerClusters,
                        editPoiKey: editPoiKey
                    };

                    MapWorkerService.destinationMapZoomed(googleMap, destinationOnMap);

                    return destinationOnMap;
                })
            );
    }

    static destinationToMap(destination: Destination, googleMap: google.maps.Map): any {
        const markerLatLngLiteral: google.maps.LatLngLiteral = {
            lat: (destination.boundsNorth + destination.boundsSouth) / 2,
            lng: (destination.boundsEast + destination.boundsWest) / 2
        };
        const marker = new google.maps.Marker({
            position: markerLatLngLiteral,
            draggable: false,
            clickable: false,
            map: googleMap
        });
        return {
            centerMarker: marker
        };
    }

    static trailToMap(trail: Trail, googleMap: google.maps.Map): google.maps.Polyline {
        const trailLatLngArray: LatLngArray = polyTool.decode(trail.encodedPolyline, {factor: 1e6});

        const trailLatLngLiteralArray: google.maps.LatLngLiteral[] = [];
        trailLatLngArray.forEach((latLng) => {
            trailLatLngLiteralArray.push({lat: latLng[1], lng: latLng[0]});
        });

        return new google.maps.Polyline({
            path: trailLatLngLiteralArray,
            strokeColor: trail.color,
            map: googleMap,
            icons: [{
                fixedRotation: false,
                icon: {
                    strokeColor: '#CCCCCC',
                    path: google.maps.SymbolPath.FORWARD_OPEN_ARROW
                },
                repeat: '100px'
            }]
        });
    }

    static poiToMap(poi: PublicPoi | DraftPoi, googleMap: google.maps.Map,
                    publicPoiCategories: { [label: string]: PublicPoiCategory }, editing = false): google.maps.Marker[] {
        const startVisible = (googleMap.getZoom() > zoomThresholds.showPoiAt);
        const markerLatLngLiteral = {lat: poi.latitude, lng: poi.longitude};
        const markers: google.maps.Marker[] = [];

        // Create array with URLs icons to draw in the cluster
        let clusterIconUrls: string[] = [];
        if (PoiService.isBackendPoi(poi)) {
            clusterIconUrls = PoiService.getPoiClusterIcons(poi, publicPoiCategories);
        } else if (PoiService.isPublicPoi(poi)) {
            clusterIconUrls = poi.categoryIconUrls;
        }

        // Get setup-specs for drawing this number of icons in a cluster
        if (clusterIconUrls.length > 0) {
            const iconClusterSetup = MapWorkerService.getIconClusterSetup(clusterIconUrls.length);

            // Create and add the icons
            clusterIconUrls.forEach((iconUrl) => {
                const iconOffset = iconClusterSetup.offsets.shift();
                const icon: google.maps.Icon = {
                    anchor: new google.maps.Point(iconOffset.x, iconOffset.y),
                    scaledSize: new google.maps.Size(iconClusterSetup.size, iconClusterSetup.size),
                    url: iconUrl
                };
                const marker = MapWorkerService.addPoiMarker(icon, markerLatLngLiteral, startVisible, editing);
                if (markers.length > 0) {
                    marker.bindTo('position', markers[0]); // Follow marker 0
                }
                markers.push(marker);
            });
        }

        // If we do not have a marker at this point, use fallback iconUrl
        if (markers.length === 0 && PoiService.isBackendPoi(poi)) {
            const icon = {
                url: poi.iconUrl,
                scaledSize: new google.maps.Size(32, 32)
            };
            markers.push(MapWorkerService.addPoiMarker(icon, markerLatLngLiteral, startVisible, editing));
        }

        // Add all markers to map at once
        markers.forEach((marker) => {
            marker.setMap(googleMap);
        });

        return markers;
    }

    private static countryMapZoomed(googleMap, countryOnMap: CountryOnMap) {
        googleMap.addListener('zoom_changed', () => {
            const zoomLevel = googleMap.getZoom();
            Object.entries(countryOnMap.poiMarkerClusters).forEach(([poiKey, poiMarkerCluster]) => {
                poiMarkerCluster.forEach((poiMarker) => {
                    poiMarker.setVisible(zoomLevel > zoomThresholds.showPoiAt || (poiKey === countryOnMap.editPoiKey));
                });
            });
            // countryOnMap.centerMarker.setVisible(zoomLevel < zoomThresholds.hideTrailAreaAt);
        });
    }

    private static destinationMapZoomed(googleMap, destinationOnMap: DestinationOnMap) {
        googleMap.addListener('zoom_changed', () => {
            const zoomLevel = googleMap.getZoom();
            Object.entries(destinationOnMap.poiMarkerClusters).forEach(([poiKey, poiMarkerCluster]) => {
                poiMarkerCluster.forEach((poiMarker) => {
                    poiMarker.setVisible(zoomLevel > zoomThresholds.showPoiAt || (poiKey === destinationOnMap.editPoiKey));
                });
            });
            // destinationOnMap.centerMarker.setVisible(zoomLevel < zoomThresholds.hideTrailAreaAt);
        });
    }

    private static trailAreaMapZoomed(googleMap, trailAreaOnMap: TrailAreaOnMap) {
        googleMap.addListener('zoom_changed', () => {
            const zoomLevel = googleMap.getZoom();
            Object.entries(trailAreaOnMap.poiMarkerClusters).forEach(([poiKey, poiMarkerCluster]) => {
                poiMarkerCluster.forEach((poiMarker) => {
                    poiMarker.setVisible(zoomLevel > zoomThresholds.showPoiAt || (poiKey === trailAreaOnMap.editPoiKey));
                });
            });
            trailAreaOnMap.centerMarker.setVisible(zoomLevel < zoomThresholds.hideTrailAreaAt);
        });
    }

    trailAreaToMap(trailArea: TrailArea, googleMap: google.maps.Map, editPoiKey: string = null): Observable<TrailAreaOnMap> {
        const updateTimestamp$ = (trailArea.country ? this.collectionService.getCollectionUpdated(trailArea.country) :
            of(Math.floor(Date.now() / 1000000)));
        const trailAreaMarker$ = updateTimestamp$
            .pipe(
                map((updatedTimestamp) => {
                    // Trail Area Center Marker
                    const markerLatLngLiteral: google.maps.LatLngLiteral = {
                        lat: (trailArea.boundsNorth + trailArea.boundsSouth) / 2,
                        lng: (trailArea.boundsEast + trailArea.boundsWest) / 2
                    };
                    const marker = new google.maps.Marker({
                        position: markerLatLngLiteral,
                        draggable: false,
                        clickable: true,
                        map: googleMap,
                        label: trailArea.name[0],
                        visible: (googleMap.getZoom() < zoomThresholds.hideTrailAreaAt)
                    });

                    marker.addListener('click', () => {
                        this.infoWindow.setContent('<div><h3>' + trailArea.name + '</h3></div>' +
                            '<div>Pos: (' + (Math.round((trailArea.boundsNorth + trailArea.boundsSouth) / 0.002) / 1000) + ', ' +
                            Math.round((trailArea.boundsEast + trailArea.boundsWest) / 0.002) / 1000 + ')</div>' +
                            '<img src="https://storage.googleapis.com/' + environment.firebase.storageBucket + '/areaConquered/' +
                            trailArea.country + '/' + trailArea.key + '.png?u=' + updatedTimestamp.toString() +
                            '" alt="' + trailArea.name + '" width="160" height="120" class="p-0" />');
                        this.infoWindow.open({
                            anchor: marker
                        });
                    });

                    return marker;
                })
            );

        const pois$ = this.poiService.getPoisForTrailArea(trailArea.key);

        // Poi Markers
        const poiMarkerClusters$ = combineLatest([this.publicPoiCategories$, pois$])
            .pipe(
                map(([publicPoiCategories, pois]) => {
                    const poiMarkerClusters: { [poiKey: string]: google.maps.Marker[] } = {};
                    pois.forEach((poi) => {
                        if (poi.active) {
                            poiMarkerClusters[poi.key] = MapWorkerService.poiToMap(
                                poi, googleMap, publicPoiCategories, poi.key === editPoiKey);
                        }
                    });
                    return poiMarkerClusters;
                })
            );

        // Trail Polylines
        const trailPolylines$ = this.trailService.getTrails(Object.keys(trailArea.trailKeys))
            .pipe(
                map((trails) => {
                    const trailPolylines: { [trailKey: string]: google.maps.Polyline } = {};
                    trails.forEach((trail) => {
                        if (trail.active) {
                            trailPolylines[trail.key] = MapWorkerService.trailToMap(trail, googleMap);
                        }
                    });
                    return trailPolylines;
                })
            );

        return combineLatest([trailAreaMarker$, poiMarkerClusters$, trailPolylines$])
            .pipe(
                map(([trailAreaMarker, poiMarkerClusters, trailPolylines]) => {
                    const trailAreaOnMap: TrailAreaOnMap = {
                        centerMarker: trailAreaMarker,
                        poiMarkerClusters: poiMarkerClusters,
                        trailPolylines: trailPolylines,
                        editPoiKey: editPoiKey
                    };

                    MapWorkerService.trailAreaMapZoomed(googleMap, trailAreaOnMap);

                    return trailAreaOnMap;
                })
            );
    }

}
