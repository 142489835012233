import { AfterViewChecked, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

// Services
import { DestinationService } from '../firebase-services/destination.service';
import { TrailAreaService } from '../firebase-services/trail-area.service';
import { MapWorkerService } from '../services/map-worker.service';

// Interfaces
import { Destination } from '../interfaces/destination';
import { DefaultMapProp } from '../interfaces/map';
import { TextModel, TextModelItemType, TextObject } from '../interfaces/text-model';
import { TrailArea } from '../interfaces/trailArea';

@Component({
    selector: 'app-destination',
    templateUrl: './destination.component.html',
    styleUrls: ['./destination.component.css']
})
export class DestinationComponent implements AfterViewChecked, OnDestroy {
    @ViewChild('gmap') gmapElement: ElementRef;
    destroy$: Subject<boolean> = new Subject<boolean>();
    textModel: TextModel = {items: []};
    textsChangedInfo = 0;

    destination: Destination = null;

    trailAreaCount = -1;
    eventCount = -1;

    trailAreas: TrailArea[] = null;

    private map: google.maps.Map = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private mapWorkerService: MapWorkerService,
        private destinationService: DestinationService,
        private trailAreaService: TrailAreaService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initialise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
        this.initTextModel();
    }

    ngAfterViewChecked() {
        if (this.map !== null || typeof this.gmapElement === 'undefined') {
            return;
        }

        this.map = new google.maps.Map(this.gmapElement.nativeElement, DefaultMapProp);
        const mapBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
        mapBounds.extend({lat: this.destination.boundsNorth, lng: this.destination.boundsEast});
        mapBounds.extend({lat: this.destination.boundsSouth, lng: this.destination.boundsWest});
        this.map.fitBounds(mapBounds, {bottom: 1, left: 1, right: 1, top: 1});
        if (this.trailAreas.length > 1) {
            MapWorkerService.destinationToMap(this.destination, this.map);
        }
        this.trailAreas.forEach((trailArea) => {
            const rectangle = new google.maps.Rectangle({
                map: this.map,
                clickable: false,
                draggable: false,
                bounds: {
                    north: trailArea.boundsNorth,
                    east: trailArea.boundsEast,
                    south: trailArea.boundsSouth,
                    west: trailArea.boundsWest
                },
                strokeColor: 'black',
                strokeWeight: 1,
                strokeOpacity: 0.5,
                editable: false,
                fillOpacity: 0
            });
            this.mapWorkerService.trailAreaToMap(trailArea, this.map)
                .subscribe();
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    private initTextModel(): void {
        this.textModel.items.push({
            name: 'Name',
            varName: 'name',
            help: 'The name of this destination.',
            placeholder: 'Destination name',
            type: TextModelItemType.INPUT
        });
    }

    private init() {
        this.map = null;
        this.destination = null;
        this.trailAreaCount = -1;
        this.eventCount = -1;
        this.trailAreas = null;
        this.loadFromFirebase(this.route.snapshot.paramMap.get('destinationKey'));
    }

    onAlteredTextObject(alteredTextObject: TextObject): void {
        this.destinationService.updateTexts(<Destination>alteredTextObject)
            .then(() => this.textsChangedInfo++)
            .catch((err) => console.error('Text-Update error occurred:', err.message));
    }


    private loadFromFirebase(destinationKey: string) {
        const destinationObservable = this.destinationService.getDestination(destinationKey)
            .pipe(takeUntil(this.destroy$));

        destinationObservable
            .subscribe((destination) => {
                this.destination = destination;
                this.eventCount = Object.keys(destination.eventKeys).length;
                const trailAreaKeys = Object.keys(this.destination.trailAreaKeys);
                this.trailAreaCount = trailAreaKeys.length;
                this.getTrailAreas(trailAreaKeys);
            });
    }

    private getTrailAreas(trailAreaKeys: string[]) {
        this.trailAreaService.getTrailAreas(trailAreaKeys)
            .pipe(take(1))
            .subscribe((trailAreas) => this.trailAreas = trailAreas.filter((trailArea) => trailArea.boundsNorth > -90));
    }
}
