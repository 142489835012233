import { TranslatableObject } from './general';

export enum PoiCategoriesAccessLevels {
    ROOT = 'root',
    SYSTEM = 'system',
    TRAIL_AREA_ADMIN = 'admin'
}

export interface PublicPoiSubCategory extends TranslatableObject {
    name: string;
    sortOrder: number;
}

export interface PublicPoiCategory extends PublicPoiSubCategory {
    iconUrl: string;
    subCategories?: { [subLabel: string]: PublicPoiSubCategory };
}

export interface PoiCategoryBase extends TranslatableObject {
    accessLevel: PoiCategoriesAccessLevels;
    definition: string;
    geofa_facil_ty_k?: number;
    mediaDefaultImageKey?: string;
    mediaDefaultImageUrl?: string;
    name?: string;
    sortOrder: number;
}

export interface PoiSubCategory extends PoiCategoryBase {
    enabled?: boolean;
    parent: string;
}

export interface PoiCategory extends PoiCategoryBase {
    mediaIconKey?: string;
    mediaIconUrl?: string;
    subCategories?: {
        [subCategoryLabel: string]: PoiSubCategory
    };
}

export const PoiCategories: { [label: string]: PoiCategory } = {
    treat: {
        accessLevel: PoiCategoriesAccessLevels.ROOT,
        definition: 'A treat is available here.',
        sortOrder: 0,
        subCategories: {
            treat_all: {
                accessLevel: PoiCategoriesAccessLevels.ROOT,
                definition: 'A treat available to all.',
                parent: 'treat',
                sortOrder: 0
            },
            treat_premium: {
                accessLevel: PoiCategoriesAccessLevels.ROOT,
                definition: 'A treat available only to premium.',
                parent: 'treat',
                sortOrder: 0
            },
            treat_bikeFriendly: {
                accessLevel: PoiCategoriesAccessLevels.ROOT,
                definition: 'Append this category to a POI to approve it as bike friendly.',
                parent: 'treat',
                sortOrder: 0
            }
        }
    },
    parking: {
        accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
        definition: 'A place to park your car while riding.',
        geofa_facil_ty_k: 1291,
        sortOrder: 0
    },
    trailCenter: {
        accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
        definition: 'A central place in a trail area, with many facilities in one place.',
        sortOrder: 0,
    },
    trailHead: {
        accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
        definition: 'A starting point for trails in this area.',
        sortOrder: 0
    },
    waterTap: {
        accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
        definition: 'A public place to get water. May be closed during winter.',
        geofa_facil_ty_k: 1222,
        sortOrder: 0
    },
    provisions: {
        accessLevel: PoiCategoriesAccessLevels.ROOT,
        definition: 'A place to refuel your energy depots.',
        sortOrder: 0,
        subCategories: {
            provisions_cafe: {
                accessLevel: PoiCategoriesAccessLevels.ROOT,
                definition: 'Have a cup of coffee or a beer at the cafe.',
                sortOrder: 0,
                parent: 'provisions'
            }
        }
    },
    toilet: {
        accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
        definition: 'Public available toilet.',
        geofa_facil_ty_k: 1012,
        sortOrder: 0
    },
    bikeWash: {
        accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
        definition: 'A place to wash your bike after your ride.',
        sortOrder: 0
    },
    trailFeature: {
        accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
        definition: 'A nice feature on the trail.',
        sortOrder: 0,
        subCategories: {
            trailFeature_gapJump: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'A jump where you need to clear the gap between the takeoff and the landing.',
                parent: 'trailFeature',
                sortOrder: 0
            },
            trailFeature_tableTop: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'A jump with a flat top instead of a gap.',
                parent: 'trailFeature',
                sortOrder: 0
            },
            trailFeature_skinny: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'A narrow feature to test and train your balance.',
                parent: 'trailFeature',
                sortOrder: 0
            },
            trailFeature_rockGarden: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'A bumpy section with rocks.',
                parent: 'trailFeature',
                sortOrder: 0
            },
            trailFeature_switchBacks: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'A section with very narrow turns.',
                parent: 'trailFeature',
                sortOrder: 0
            },
            trailFeature_drop: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'A sudden drop on the trail.',
                parent: 'trailFeature',
                sortOrder: 0
            }
        }
    },
    bikeTrack: {
        accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
        definition: 'A place to ride BMX or MTB.',
        geofa_facil_ty_k: 4041,
        sortOrder: 0,
        subCategories: {
            bikeTrack_technicalCourse: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'A course with technical challenges to improve your technical skills.',
                parent: 'bikeTrack',
                sortOrder: 0
            },
            bikeTrack_pumpTrack: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'A pump track where you need to pump your bike to keep it going.',
                parent: 'bikeTrack',
                sortOrder: 0
            }
        }
    },
    picnic: {
        accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
        definition: 'A place to enjoy your food.',
        sortOrder: 0,
        subCategories: {
            picnic_campfireHut: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'Tables with seating for enjoying a sausage from the grill and a beer.',
                geofa_facil_ty_k: 3091,
                parent: 'picnic',
                sortOrder: 0
            },
            picnic_tables: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'Tables with seating for enjoying a sausage from the grill and a beer.',
                geofa_facil_ty_k: 1201,
                parent: 'picnic',
                sortOrder: 0
            }
        }
    },
    bikeService: {
        accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
        definition: 'A place with service facilities for your bike.',
        sortOrder: 0,
        subCategories: {
            bikeService_mechanic: {
                accessLevel: PoiCategoriesAccessLevels.ROOT,
                definition: 'A mechanic who can fix everything in five minutes.',
                parent: 'bikeService',
                sortOrder: 0
            },
            bikeService_bikeShop: {
                accessLevel: PoiCategoriesAccessLevels.ROOT,
                definition: 'A shop with bike parts and new bikes.',
                parent: 'bikeService',
                sortOrder: 0
            },
            bikeService_serviceStation: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'A place with pump and other facilities for bike service.',
                geofa_facil_ty_k: 1012,
                parent: 'bikeService',
                sortOrder: 0
            }
        }
    },
    chargeStation: {
        accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
        definition: 'Charger for e-MTBs.',
        geofa_facil_ty_k: 1172,
        sortOrder: 0
    },
    accommodation: {
        accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
        definition: 'A place to stay for the night. Might be shelter; might be a hotel.',
        sortOrder: 0,
        subCategories: {
            accommodation_hotel: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'A hotel to stay inn.',
                geofa_facil_ty_k: 3061,
                parent: 'accommodation',
                sortOrder: 0
            },
            accommodation_camping: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'A site to stay in your caravan or tent.',
                geofa_facil_ty_k: 3041,
                parent: 'accommodation',
                sortOrder: 0
            },
            accommodation_shelter: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'A primitive place to sleep with a roof.',
                geofa_facil_ty_k: 3012,
                parent: 'accommodation',
                sortOrder: 0
            },
            accommodation_tentSite: {
                accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
                definition: 'A site where you can put up your tent for the night.',
                geofa_facil_ty_k: 3031,
                parent: 'accommodation',
                sortOrder: 0
            }
        }
    },
    viewPoint: {
        accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
        definition: 'A place with a view.',
        geofa_facil_ty_k: 2022,
        sortOrder: 0
    },
    attraction: {
        accessLevel: PoiCategoriesAccessLevels.TRAIL_AREA_ADMIN,
        definition: 'A tourist attraction worth a visit.',
        sortOrder: 0
    },
    guide: {
        accessLevel: PoiCategoriesAccessLevels.SYSTEM,
        definition: 'A guide for your upcoming event.',
        sortOrder: 0
    },
};
