import { Component } from '@angular/core';

@Component({
  selector: 'app-organisation-managers',
  templateUrl: './organisation-managers.component.html',
  styleUrl: './organisation-managers.component.css'
})
export class OrganisationManagersComponent {

}
