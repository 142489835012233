import { AfterViewChecked, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { LayoutService } from '../core/layout.service';
import { PoiService } from '../firebase-services/poi.service';
import { TrailAreaService } from '../firebase-services/trail-area.service';
import { UserService } from '../firebase-services/user.service';

// Interfaces
import { TrailArea } from '../interfaces/trailArea';
import { TextModel, TextModelItemType, TextObject } from '../interfaces/text-model';

import * as moment from 'moment';
import { TrailBuilderOrganisation } from '../interfaces/trail-builder-organisation';
import { TrailBuilderOrganisationService } from '../firebase-services/trail-builder-organisation.service';
import { DefaultMapProp } from '../interfaces/map';
import { MapWorkerService } from '../services/map-worker.service';

declare var $: any;

@Component({
    selector: 'app-trail-area',
    templateUrl: './trail-area.component.html',
    styleUrls: ['./trail-area.component.css']
})
export class TrailAreaComponent implements AfterViewChecked, OnDestroy {
    @ViewChild('gmap') gmapElement: ElementRef;
    destroy$: Subject<boolean> = new Subject<boolean>();
    textModel: TextModel;

    mobilePayRegex = /^[0-9a-zA-Z]+$/;

    trailArea: TrailArea;
    isAccessibleForFree = 'true';

    builderOrganisation: TrailBuilderOrganisation;

    textsChangedInfo = 0;
    aboutChangedInfo = 0;
    trailBuilderChangedInfo = 0;
    disableSaveAbout: boolean;
    disableSaveBuilder: boolean;
    showEditCreatedDate: boolean;

    trailCount = -1;
    poiCount = -1;
    adventureCount = -1;
    areaManagersCount = -1;

    private map: google.maps.Map = null;

    constructor(
        public layout: LayoutService,
        private route: ActivatedRoute,
        private router: Router,
        private poiService: PoiService,
        private mapWorkerService: MapWorkerService,
        private trailAreaService: TrailAreaService,
        private userService: UserService,
        private trailBuilderOrganisationService: TrailBuilderOrganisationService
    ) {
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {
                // If it is a NavigationEnd event re-initialise the component
                if (e instanceof NavigationEnd) {
                    this.init();
                }
            });
    }

    ngAfterViewChecked() {
        if (this.map !== null || typeof this.gmapElement === 'undefined') {
            return;
        }

        this.map = new google.maps.Map(this.gmapElement.nativeElement, DefaultMapProp);
        const mapBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
        mapBounds.extend({lat: this.trailArea.boundsNorth, lng: this.trailArea.boundsEast});
        mapBounds.extend({lat: this.trailArea.boundsSouth, lng: this.trailArea.boundsWest});
        this.map.fitBounds(mapBounds, {bottom: 1, left: 1, right: 1, top: 1});
        this.mapWorkerService.trailAreaToMap(this.trailArea, this.map)
            .subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    /**
     * Shows the edit created date form element
     */
    editDateCreated(): void {
        this.showEditCreatedDate = true;
        const pickerOptions: any = {
            singleDatePicker: true,
            showDropdowns: true,
            autoApply: true,
            minYear: 1990,
            maxYear: parseInt(moment().format('YYYY'), 10),
            locale: {
                format: 'YYYY-MM-DD',
                firstDay: 1
            }
        };
        if (typeof this.trailArea.dateCreated === 'string') {
            pickerOptions.startDate = this.trailArea.dateCreated;
        } else {
            pickerOptions.autoUpdateInput = false;
        }
        window.setTimeout(() => $('#dateCreated').daterangepicker(pickerOptions, (start: moment.Moment) => {
            this.trailArea.dateCreated = start.format('YYYY-MM-DD');
            this.aboutChanged();
        }), 0);
    }

    aboutChanged(): void {
        this.trailArea.isAccessibleForFree = (this.isAccessibleForFree === 'true');
        this.disableSaveAbout = false;
    }

    saveAbout(): void {
        this.disableSaveAbout = true;
        this.trailAreaService.updateAbout(this.trailArea)
            .then(() => this.aboutChangedInfo++)
            .catch(err => console.error('About-Update error occurred:', err.message));
    }

    onUploadedImageUrl(uploadedImageUrl: string) {
        this.trailAreaService.updateImage(this.trailArea, uploadedImageUrl)
            .then(() => this.trailArea.imageUrl = uploadedImageUrl);
    }

    onAlteredTextObject(alteredTextObject: TextObject): void {
        this.trailAreaService.updateTexts(<TrailArea>alteredTextObject)
            .then(() => this.textsChangedInfo++)
            .catch((err) => console.error('Text-Update error occurred:', err.message));
    }

    onLanguageAdded(newLanguageCode: string) {
        this.trailAreaService.addLanguage(this.trailArea, newLanguageCode)
            .subscribe((updatedTrailArea) => {
                this.trailArea = updatedTrailArea;
            });
    }

    updateLocationTexts(): void {
        this.trailAreaService.applyGeoMetaToTrailArea(this.trailArea, 0, true);
        document.getElementById('location-texts-button').hidden = true;
    }

    saveBuilder(): void {
        this.disableSaveBuilder = true;
        if (!this.builderOrganisation.name || !this.builderOrganisation.cvr ||
            !this.builderOrganisation.phone || !this.builderOrganisation.email) {
            this.builderOrganisation.mobilePay = null;
        }
        if (!this.mobilePayRegex.test(this.builderOrganisation.mobilePay)) {
            window.alert('Invalid MobilePay number.');
            document.getElementById('orgMobilePay').focus();
            this.disableSaveBuilder = false;
            return;
        }
        this.trailBuilderOrganisationService.update(this.builderOrganisation, this.trailArea.key)
            .then(() => this.trailBuilderChangedInfo++)
            .catch(err => console.error('Trail builder organisation-Update error occurred:', err.message));
    }

    builderChanged(): void {
        this.disableSaveBuilder = false;
    }

    private initTextModel(): void {
        this.textModel = {items: []};
        this.textModel.items.push({
            html: '<i class="fas fa-exclamation-triangle mr-2 text-warning"></i>Warning Text',
            name: 'Warning Text',
            varName: 'warningText',
            help: 'A special notice shown in an orange box for the app-user. Use scarcely.',
            placeholder: 'E.g. Closed for Race day saturday, or: Trail X closed due to maintenance until sunday.',
            type: TextModelItemType.TEXT_AREA
        });
        this.textModel.items.push({
            name: 'Area Name',
            varName: 'name',
            help: 'The name of this trail area.',
            placeholder: 'Trail area name',
            type: TextModelItemType.INPUT
        });
        this.textModel.items.push({
            name: 'About the Area',
            varName: 'articleBody',
            help: 'Your good all inclusive description of the area.',
            placeholder: 'This is what makes this area special',
            type: TextModelItemType.TEXT_AREA
        });
    }

    /**
     * Load data for the current trail area
     */
    private init() {
        this.map = null;
        this.trailArea = null;
        this.isAccessibleForFree = 'true';

        this.disableSaveAbout = true;
        this.disableSaveBuilder = true;
        this.showEditCreatedDate = false;
        this.trailCount = -1;
        this.poiCount = -1;
        this.adventureCount = -1;
        this.areaManagersCount = -1;
        const trailAreaKey = this.route.snapshot.paramMap.get('key');
        this.initTextModel();
        this.getTrailArea(trailAreaKey);
    }

    private getTrailArea(trailAreaKey: string): void {
        const trailAreaObservable = this.trailAreaService.getTrailArea(trailAreaKey)
            .pipe(takeUntil(this.destroy$));

        trailAreaObservable
            .subscribe((trailArea) => {
                this.isAccessibleForFree = trailArea.isAccessibleForFree !== false ? 'true' : 'false';
                this.trailCount = Object.keys(trailArea.trailKeys).length;
                this.adventureCount = Object.keys(trailArea.adventureKeys).length;
                this.trailArea = trailArea;
                if (!trailArea.dateCreated) { // Open date created - ready for input
                    this.editDateCreated();
                }
            });

        this.poiService.getPoiCountForTrailArea(trailAreaKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe(poiCount => this.poiCount = poiCount);

        this.userService.getTrailAreaAdministratorsCount(trailAreaKey)
            .pipe(takeUntil(this.destroy$))
            .subscribe((count) => this.areaManagersCount = count);

        const builderOrganisationObservable = this.trailBuilderOrganisationService.getTrailBuilderOrganisation(trailAreaKey)
            .pipe(takeUntil(this.destroy$));

        combineLatest([trailAreaObservable, builderOrganisationObservable])
            .subscribe(([trailArea, trailBuilderOrganisation]) => {
                if (trailArea.country !== 'dk') {
                    return;
                }

                this.builderOrganisation = trailBuilderOrganisation;
                const varName = 'donateMotivator';
                let textItemIsCreated = false;

                this.textModel.items.forEach((textItem) => {
                    if (textItem.varName === varName) {
                        textItemIsCreated = true;
                        textItem.readonly = (!this.builderOrganisation.mobilePay);
                    }
                });

                if (!textItemIsCreated) {
                    this.textModel.items.push({
                        name: 'Donate Motivator',
                        varName: varName,
                        help: 'The reason why our users should donate to your trails.',
                        placeholder: 'Your support means that we can maintain your favorite trail...',
                        type: TextModelItemType.INPUT,
                        readonly: (!this.builderOrganisation.mobilePay)
                    });
                }
            });
    }

}
