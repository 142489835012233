import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// Guards
import {
    adminGuard,
    AuthGuard,
    countryManagerGuard,
    destinationManagerGuard,
    eventManagerGuard,
    newsRoomManagerGuard,
    onTrailManagerGuard,
    organisationManagerGuard,
    poiManagerGuard,
    sponsorManagerGuard,
    trailAreaManagerGuard,
    trailCenterManagerGuard,
    treatManagerGuard,
    pressCampManagerGuard
} from './core/auth.guard';
import {SavedAndPublishedGuard} from './core/saved-and-published.guard';

// Components
import {LoginComponent} from './login/login.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
// Dashboard
import {DashboardComponent} from './dashboard/dashboard.component';
// Country
import {CountryTrailAreasAdminComponent} from './country/country-trail-areas-admin/country-trail-areas-admin.component';
import {CountryNewTrailAreaComponent} from './country/country-new-trail-area/country-new-trail-area.component';
import {CountryExplorersComponent} from './country/country-events/country-explorers/country-explorers.component';
import {
    CountryContactResponsibleComponent
} from './country/country-contact-responsible/country-contact-responsible.component';
// Destination
import {DestinationComponent} from './destination/destination.component';
import {DestinationTrailAreasComponent} from './destination/destination-trail-areas/destination-trail-areas.component';
import {DestinationEventsComponent} from './destination/destination-events/destination-events.component';
import {
    DestinationEventEditComponent
} from './destination/destination-events/destination-event-edit/destination-event-edit.component';
import {
    DestinationEventExplorersComponent
} from './destination/destination-events/destination-event-explorers/destination-event-explorers.component';
import {
    DestinationEventItemEditComponent
} from './destination/destination-events/destination-event-item-edit/destination-event-item-edit.component';
// Trail Area
import {TrailAreaComponent} from './trail-area/trail-area.component';
import {TrailsComponent} from './trail-area/trails/trails.component';
import {TrailEditComponent} from './trail-area/trails/trail-edit/trail-edit.component';
import {TrailAreaPoisComponent} from './trail-area/trail-area-pois/trail-area-pois.component';
import {
    TrailAreaPoiEditComponent
} from './trail-area/trail-area-pois/trail-area-poi-edit/trail-area-poi-edit.component';
import {TrailAreaNewsComponent} from './trail-area/trail-area-news/trail-area-news.component';
import {TrailAreaDraftsComponent} from './trail-area/trail-area-news/trail-area-drafts/trail-area-drafts.component';
import {
    TrailAreaEditDraftComponent
} from './trail-area/trail-area-news/trail-area-edit-draft/trail-area-edit-draft.component';
import {TrailAreaStoriesComponent} from './trail-area/trail-area-news/trail-area-stories/trail-area-stories.component';
import {
    TrailAreaModifyStoryComponent
} from './trail-area/trail-area-news/trail-area-modify-story/trail-area-modify-story.component';
import {
    TrailAreaStoryArchiveComponent
} from './trail-area/trail-area-news/trail-area-story-archive/trail-area-story-archive.component';
import {TrailAreaEventsComponent} from './trail-area/trail-area-events/trail-area-events.component';
import {
    TrailAreaEventEditComponent
} from './trail-area/trail-area-events/trail-area-event-edit/trail-area-event-edit.component';
import {
    TrailAreaExplorersComponent
} from './trail-area/trail-area-events/trail-area-explorers/trail-area-explorers.component';
import {
    TrailAreaEventItemEditComponent
} from './trail-area/trail-area-events/trail-area-event-edit/trail-area-event-item-edit/trail-area-event-item-edit.component';
import {AreaManagersComponent} from './trail-area/area-managers/area-managers.component';
import {RideStatisticsComponent} from './trail-area/ride-statistics/ride-statistics.component';
// Organisation
import {MembershipComponent} from './organisation/membership/membership.component';
import {MembersComponent} from './organisation/members/members.component';
// OnTrail
import {OnTrailStatusComponent} from './on-trail/on-trail-status/on-trail-status.component';
import {
    OnTrailApplicationComponent
} from './on-trail/on-trail-status/on-trail-application/on-trail-application.component';
import {OnTrailContactsComponent} from './on-trail/on-trail-contacts/on-trail-contacts.component';
// Root
import {RootDashboardComponent} from './root/root-dashboard/root-dashboard.component';
import {KioskOrdersComponent} from './root/kiosk-orders/kiosk-orders.component';
import {NewsRoomComponent} from './root/news-room/news-room.component';
import {NewsRoomDraftsComponent} from './root/news-room/news-room-drafts/news-room-drafts.component';
import {NewsRoomEditDraftComponent} from './root/news-room/news-room-edit-draft/news-room-edit-draft.component';
import {NewsRoomStoriesComponent} from './root/news-room/news-room-stories/news-room-stories.component';
import {NewsRoomModifyStoryComponent} from './root/news-room/news-room-modify-story/news-room-modify-story.component';
import {NewsRoomArchiveComponent} from './root/news-room/news-room-archive/news-room-archive.component';
import {NewsRoomWelcomeComponent} from './root/news-room/news-room-welcome/news-room-welcome.component';
import {PremiumProductComponent} from './root/premium/premium-product/premium-product.component';
import {PremiumMembersComponent} from './root/premium/premium-members/premium-members.component';
import {VoronoiComponent} from './root/voronoi/voronoi.component';
import {NewDestinationComponent} from './root/new-destination/new-destination.component';
import {ShapefileImportComponent} from './root/trail-manager/shapefile-import/shapefile-import.component';
import {ExportFacilitydbComponent} from './root/trail-manager/export-facilitydb/export-facilitydb.component';
import {TrailsImportComponent} from './root/trail-manager/trails-import/trails-import.component';
import {PoiCategoriesComponent} from './root/poiManager/poi-categories/poi-categories.component';
import {EditPoiCategoryComponent} from './root/poiManager/poi-categories/edit-poi-category/edit-poi-category.component';
import {PoiValidatorComponent} from './root/poiManager/poi-validator/poi-validator.component';
import {PoiImportComponent} from './root/poiManager/poi-import/poi-import.component';
import {RtwEventsComponent} from './root/rtw/rtw-events/rtw-events.component';
import {RtwEventParticipantsComponent} from './root/rtw/rtw-event-participants/rtw-event-participants.component';
import {EditRtwEventComponent} from './root/rtw/edit-rtw-event/edit-rtw-event.component';
import {CreateRtwEventComponent} from './root/rtw/create-rtw-event/create-rtw-event.component';
import {RidesComponent} from './root/rides/rides.component';
import {RideComponent} from './root/rides/ride/ride.component';
import {MediaLibraryComponent} from './root/media-library/media-library.component';
import {EmailExtractComponent} from './root/email-extract/email-extract.component';
import {TreatsComponent} from './root/treats/treats.component';
import {TreatEditComponent} from './root/treats/treat-edit/treat-edit.component';
import {SponsorComponent} from './root/sponsor/sponsor.component';
import {SponsorEditComponent} from './root/sponsor/sponsor-edit/sponsor-edit.component';
import {RoleManagerComponent} from "./root/role-manager/role-manager.component";
import {EventManagersComponent} from "./root/role-manager/event-managers/event-managers.component";
// event
import {EventManagerDashboardComponent} from "./event/event-manager-dashboard/event-manager-dashboard.component";
// trail-center
import {TrailCenterDashboardComponent} from "./trail-center/trail-center-dashboard/trail-center-dashboard.component";
import {DevToolsComponent} from "./root/dev-tools/dev-tools.component";
import {DmoManagersComponent} from "./root/role-manager/dmo-managers/dmo-managers.component";
import {CountryManagersComponent} from "./root/role-manager/country-managers/country-managers.component";
import {DestinationManagersComponent} from "./root/role-manager/destination-managers/destination-managers.component";
import {TrailAreaManagersComponent} from "./root/role-manager/trail-area-managers/trail-area-managers.component";
import {SponsorManagersComponent} from "./root/role-manager/sponsor-managers/sponsor-managers.component";
import {TreatManagersComponent} from "./root/role-manager/treat-managers/treat-managers.component";
import {OrganisationManagersComponent} from "./root/role-manager/organisation-managers/organisation-managers.component";
import {NewsRoomManagersComponent} from "./root/role-manager/news-room-managers/news-room-managers.component";
import {CountryEventsComponent} from "./country/country-events/country-events.component";
import {
    CountryEventItemEditComponent
} from "./country/country-events/country-event-edit/country-event-item-edit/country-event-item-edit.component";
import {CountryEventEditComponent} from "./country/country-events/country-event-edit/country-event-edit.component";
import {PoiManagersComponent} from "./root/role-manager/poi-managers/poi-managers.component";
import {PoiManagerDashboardComponent} from "./poi/poi-manager-dashboard/poi-manager-dashboard.component";
import {CountryPoisComponent} from "./country/country-pois/country-pois.component";
import {CountryPoiEditComponent} from "./country/country-pois/country-poi-edit/country-poi-edit.component";
import {DestinationPoisComponent} from "./destination/destination-pois/destination-pois.component";
import {
    DestinationPoiEditComponent
} from "./destination/destination-pois/destination-poi-edit/destination-poi-edit.component";
import {PressCampManagersComponent} from "./root/role-manager/press-camp-managers/press-camp-managers.component";
import {
    PressCampManagerDashboardComponent
} from "./press-camp/press-camp-manager-dashboard/press-camp-manager-dashboard.component";
import {PressCampGroupEditComponent} from "./press-camp/press-camp-group-edit/press-camp-group-edit.component";
import {PressCampOverviewComponent} from "./press-camp/press-camp-overview/press-camp-overview.component";
import {PressCampMeetupEditComponent} from "./press-camp/press-camp-meetup-edit/press-camp-meetup-edit.component";

const routes: Routes = [
    {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard]},
    {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
    {
        path: 'country-manager/:countryCode/trail-areas-admin',
        component: CountryTrailAreasAdminComponent,
        canActivate: [countryManagerGuard]
    },
    {
        path: 'country-manager/:countryCode/new-trail-area',
        component: CountryNewTrailAreaComponent,
        canActivate: [countryManagerGuard]
    },
    {path: 'country-manager/:countryCode/events', component: CountryEventsComponent, canActivate: [eventManagerGuard]},
    {
        path: 'country-manager/:countryCode/events/:eventKey',
        component: CountryEventEditComponent,
        canActivate: [eventManagerGuard]
    },
    {
        path: 'country-manager/:countryCode/events/:eventKey/explorers',
        component: CountryExplorersComponent,
        canActivate: [eventManagerGuard]
    },
    {
        path: 'country-manager/:countryCode/events/:eventKey/item/:eventItemKey',
        component: CountryEventItemEditComponent,
        canActivate: [eventManagerGuard]
    },
    {
        path: 'country-manager/:countryCode/contact-responsible',
        component: CountryContactResponsibleComponent,
        canActivate: [countryManagerGuard]
    },
    {path: 'country/:key/country-pois', component: CountryPoisComponent, canActivate: [poiManagerGuard]},
    {
        path: 'country/:countryCode/country-pois/:poiKey',
        component: CountryPoiEditComponent,
        canActivate: [poiManagerGuard],
        canDeactivate: [SavedAndPublishedGuard]
    },
    {
        path: 'destination/:destinationKey/settings',
        component: DestinationComponent,
        canActivate: [destinationManagerGuard]
    },
    {
        path: 'destination/:destinationKey/trail-areas',
        component: DestinationTrailAreasComponent,
        canActivate: [destinationManagerGuard]
    },
    {
        path: 'destination/:destinationKey/events',
        component: DestinationEventsComponent,
        canActivate: [eventManagerGuard]
    },
    {
        path: 'destination/:destinationKey/events/:eventKey',
        component: DestinationEventEditComponent,
        canActivate: [eventManagerGuard]
    },
    {
        path: 'destination/:destinationKey/events/:eventKey/explorers',
        component: DestinationEventExplorersComponent,
        canActivate: [eventManagerGuard]
    },
    {
        path: 'destination/:destinationKey/events/:eventKey/item/:eventItemKey',
        component: DestinationEventItemEditComponent,
        canActivate: [eventManagerGuard]
    },
    {path: 'destination/:key/destination-pois', component: DestinationPoisComponent, canActivate: [poiManagerGuard]},
    {
        path: 'destination/:destinationKey/destination-pois/:poiKey',
        component: DestinationPoiEditComponent,
        canActivate: [destinationManagerGuard],
        canDeactivate: [SavedAndPublishedGuard]
    },
    {path: 'trail-area/:key/settings', component: TrailAreaComponent, canActivate: [trailAreaManagerGuard]},
    {path: 'trail-area/:key/trails', component: TrailsComponent, canActivate: [trailAreaManagerGuard]},
    {path: 'trail-area/:areaKey/trails/:trailKey', component: TrailEditComponent, canActivate: [trailAreaManagerGuard]},
    {path: 'trail-area/:key/trail-area-pois', component: TrailAreaPoisComponent, canActivate: [poiManagerGuard]},
    {
        path: 'trail-area/:areaKey/trail-area-pois/:poiKey',
        component: TrailAreaPoiEditComponent,
        canActivate: [poiManagerGuard],
        canDeactivate: [SavedAndPublishedGuard]
    },
    {
        path: 'trail-area/:trailAreaKey/news-stories',
        component: TrailAreaNewsComponent,
        canActivate: [trailAreaManagerGuard]
    },
    {
        path: 'trail-area/:trailAreaKey/news-stories/drafts',
        component: TrailAreaDraftsComponent,
        canActivate: [trailAreaManagerGuard]
    },
    {
        path: 'trail-area/:trailAreaKey/news-stories/drafts/:newsStoryKey',
        component: TrailAreaEditDraftComponent,
        canActivate: [trailAreaManagerGuard]
    },
    {
        path: 'trail-area/:trailAreaKey/news-stories/stories',
        component: TrailAreaStoriesComponent,
        canActivate: [trailAreaManagerGuard]
    },
    {
        path: 'trail-area/:trailAreaKey/news-stories/stories/:newsStoryKey',
        component: TrailAreaModifyStoryComponent,
        canActivate: [trailAreaManagerGuard]
    },
    {
        path: 'trail-area/:trailAreaKey/news-stories/archive',
        component: TrailAreaStoryArchiveComponent,
        canActivate: [trailAreaManagerGuard]
    },
    {path: 'trail-area/:areaKey/events', component: TrailAreaEventsComponent, canActivate: [eventManagerGuard]},
    {
        path: 'trail-area/:areaKey/events/:eventKey',
        component: TrailAreaEventEditComponent,
        canActivate: [eventManagerGuard]
    },
    {
        path: 'trail-area/:areaKey/events/:eventKey/explorers',
        component: TrailAreaExplorersComponent,
        canActivate: [eventManagerGuard]
    },
    {
        path: 'trail-area/:areaKey/events/:eventKey/item/:eventItemKey',
        component: TrailAreaEventItemEditComponent,
        canActivate: [eventManagerGuard]
    },
    {
        path: 'trail-area/:trailAreaKey/area-managers',
        component: AreaManagersComponent,
        canActivate: [trailAreaManagerGuard]
    },
    {
        path: 'trail-area/:trailAreaKey/ride-statistics',
        component: RideStatisticsComponent,
        canActivate: [trailAreaManagerGuard]
    },
    {
        path: 'organisation/:organisationKey/membership',
        component: MembershipComponent,
        canActivate: [organisationManagerGuard]
    },
    {
        path: 'organisation/:organisationKey/members',
        component: MembersComponent,
        canActivate: [organisationManagerGuard]
    },
    {path: 'on-trail/status', component: OnTrailStatusComponent, canActivate: [onTrailManagerGuard]},
    {
        path: 'on-trail/application/:applicationKey',
        component: OnTrailApplicationComponent,
        canActivate: [onTrailManagerGuard]
    },
    {path: 'on-trail/contacts', component: OnTrailContactsComponent, canActivate: [onTrailManagerGuard]},
    {
        path: 'trail-center/trail-center-dashboard',
        component: TrailCenterDashboardComponent,
        canActivate: [trailCenterManagerGuard]
    },
    {
        path: 'press-camp/press-camp-manager-dashboard',
        component: PressCampManagerDashboardComponent,
        canActivate: [pressCampManagerGuard]
    },
    {
        path: 'press-camp/press-camp-overview/:pressCampKey',
        component: PressCampOverviewComponent,
        canActivate: [pressCampManagerGuard]
    },
    {
        path: 'press-camp/press-camp-group-edit/:pressCampKey',
        component: PressCampGroupEditComponent,
        canActivate: [pressCampManagerGuard]
    },
    {
        path: 'press-camp/press-camp-meetup-edit/:meetupKey',
        component: PressCampMeetupEditComponent,
        canActivate: [pressCampManagerGuard]
    },
    {path: 'root/treats', component: TreatsComponent, canActivate: [treatManagerGuard]},
    {path: 'root/treats/:treatKey', component: TreatEditComponent, canActivate: [treatManagerGuard]},
    {path: 'root/sponsor', component: SponsorComponent, canActivate: [sponsorManagerGuard]},
    {path: 'root/sponsor/:sponsorKey', component: SponsorEditComponent, canActivate: [sponsorManagerGuard]},
    {path: 'root/dashboard', component: RootDashboardComponent, canActivate: [adminGuard]},
    {path: 'root/kiosk-orders', component: KioskOrdersComponent, canActivate: [adminGuard]},
    {path: 'root/news-room', component: NewsRoomComponent, canActivate: [newsRoomManagerGuard]},
    {path: 'root/news-room/drafts', component: NewsRoomDraftsComponent, canActivate: [newsRoomManagerGuard]},
    {
        path: 'root/news-room/drafts/:newsStoryKey',
        component: NewsRoomEditDraftComponent,
        canActivate: [newsRoomManagerGuard]
    },
    {path: 'root/news-room/stories', component: NewsRoomStoriesComponent, canActivate: [newsRoomManagerGuard]},
    {
        path: 'root/news-room/stories/:newsStoryKey',
        component: NewsRoomModifyStoryComponent,
        canActivate: [newsRoomManagerGuard]
    },
    {path: 'root/news-room/archive', component: NewsRoomArchiveComponent, canActivate: [newsRoomManagerGuard]},
    {path: 'root/news-room/welcome', component: NewsRoomWelcomeComponent, canActivate: [newsRoomManagerGuard]},
    {path: 'root/premium/product', component: PremiumProductComponent, canActivate: [adminGuard]},
    {path: 'root/premium/members', component: PremiumMembersComponent, canActivate: [adminGuard]},
    {path: 'root/countries-manager/voronoi', component: VoronoiComponent, canActivate: [adminGuard]},
    {path: 'root/destinations-manager/new-destination', component: NewDestinationComponent, canActivate: [adminGuard]},
    {path: 'root/trail-manager/shapefile-import', component: ShapefileImportComponent, canActivate: [adminGuard]},
    {path: 'root/trail-manager/export-facilitydb', component: ExportFacilitydbComponent, canActivate: [adminGuard]},
    {path: 'root/trail-manager/import-trails', component: TrailsImportComponent, canActivate: [adminGuard]},
    {path: 'root/poi-manager/poi-categories', component: PoiCategoriesComponent, canActivate: [adminGuard]},
    {
        path: 'root/poi-manager/poi-categories/:poiCategoryLabel/edit',
        component: EditPoiCategoryComponent,
        canActivate: [adminGuard],
        canDeactivate: [SavedAndPublishedGuard]
    },
    {path: 'root/poi-manager/poi-validator', component: PoiValidatorComponent, canActivate: [adminGuard]},
    {
        path: 'root/poi-manager/poi-validator/:poiKey',
        component: TrailAreaPoiEditComponent,
        canActivate: [adminGuard],
        canDeactivate: [SavedAndPublishedGuard]
    },
    {path: 'root/poi-manager/poi-import', component: PoiImportComponent, canActivate: [adminGuard]},
    {path: 'root/rtw/events/list', component: RtwEventsComponent, canActivate: [adminGuard]},
    {
        path: 'root/rtw/events/:rtwEventKey/participants',
        component: RtwEventParticipantsComponent,
        canActivate: [adminGuard]
    },
    {path: 'root/rtw/events/:rtwEventKey/edit', component: EditRtwEventComponent, canActivate: [adminGuard]},
    {path: 'root/rtw/events/create', component: CreateRtwEventComponent, canActivate: [adminGuard]},
    {path: 'root/rides', component: RidesComponent, canActivate: [adminGuard]},
    {path: 'root/rides/ride/:rideKey', component: RideComponent, canActivate: [adminGuard]},
    {path: 'root/mediaLibrary', component: MediaLibraryComponent, canActivate: [adminGuard]},
    {path: 'root/emailExtract', component: EmailExtractComponent, canActivate: [adminGuard]},
    {path: 'root/role-manager', component: RoleManagerComponent, canActivate: [adminGuard]},
    {path: 'event-manager-dashboard', component: EventManagerDashboardComponent, canActivate: [eventManagerGuard]},
    {path: 'poi-manager-dashboard', component: PoiManagerDashboardComponent, canActivate: [poiManagerGuard]},
    {path: 'root/role-manager/event-managers', component: EventManagersComponent, canActivate: [adminGuard]},
    {path: 'root/dev-tools', component: DevToolsComponent, canActivate: [adminGuard]},
    {path: 'root/role-manager/dmo-managers', component: DmoManagersComponent, canActivate: [adminGuard]},
    {path: 'root/role-manager/country-managers', component: CountryManagersComponent, canActivate: [adminGuard]},
    {
        path: 'root/role-manager/destination-managers',
        component: DestinationManagersComponent,
        canActivate: [adminGuard]
    },
    {path: 'root/role-manager/trail-area-managers', component: TrailAreaManagersComponent, canActivate: [adminGuard]},
    {path: 'root/role-manager/sponsor-managers', component: SponsorManagersComponent, canActivate: [adminGuard]},
    {path: 'root/role-manager/treat-managers', component: TreatManagersComponent, canActivate: [adminGuard]},
    {
        path: 'root/role-manager/organisation-managers',
        component: OrganisationManagersComponent,
        canActivate: [adminGuard]
    },
    {path: 'root/role-manager/news-room-managers', component: NewsRoomManagersComponent, canActivate: [adminGuard]},
    {path: 'root/role-manager/poi-managers', component: PoiManagersComponent, canActivate: [adminGuard]},
    {path: 'root/role-manager/press-camp-managers', component: PressCampManagersComponent, canActivate: [adminGuard]},

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        anchorScrolling: 'enabled',
        scrollOffset: [0, 0]
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
